import React from 'react';
import PropTypes from 'prop-types';
import Button from 'ui/Button';
import CustomModal from 'components/CustomModal';
import { offerCategory } from 'utils/offersFormHelper';

const NewProductModal = ({open, title, onClose, done, category}) => {

  const productLabel = category === 'merch' ? 'Product' : offerCategory[category].label
  const buttonContent = `No, see ${category === 'ticket' ? 'Event' : `${productLabel}s`}`

  const customModalContent =
    { 
      title: `Create another ${productLabel}?`,
      content: `${title} saved successfully! Would you like to create another ${productLabel} now?`,
      button: <Button fullwidth secondary onClick={onClose}>{buttonContent}</Button>,
      secondaryButton: <Button fullwidth onClick={done}>{"Yes, create another"}</Button>
    };

  return (
    <CustomModal
      open={ open }
      handleClose={ onClose }
      customModalContent={ customModalContent } />
  );
};

NewProductModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  done: PropTypes.func,
  category: PropTypes.string
};

export default NewProductModal;