import React from 'react';
import PropTypes from 'prop-types';

import Card from 'ui/Card';

import CardHeader from './CardHeader';
import ButtonGroup from './ButtonGroup';

const CardGrid = ({ events }) => {

  return (
    <div className='grid'>
      { events.map((event) => {
        const { id, title, start_date, end_date, location, image_url, external_url, slug, short_description, sessions} = event;
        const shownStartDate = sessions?.length > 0 ? sessions[0].start_date : start_date;
        const shownEndDate = sessions?.length > 0 ? sessions[sessions.length - 1].end_date : end_date;

        return (
          <Card
            cropped={true}
            key={ id }
            image_url={ image_url }

            header={<CardHeader
                      title={title}
                      start_date={shownStartDate}
                      end_date={shownEndDate}
                      location={location}
                      sessionsCount={sessions?.length}
                  />}

            description={short_description}

            footer={<ButtonGroup
                      external_url={external_url}
                      slug={slug}
                    />}
          />
        )})}
    </div>
  );
};

CardGrid.propTypes = {
    events: PropTypes.array,
};

export default CardGrid;
