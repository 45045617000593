import React, { useState } from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';

import PasswordResetResource from 'resources/user/PasswordResetResource';

import PublicLayout from 'components/layouts/Public'
import ResetForm from 'components/authForms/ResetForm';

import { t } from 'i18n/index';

const content = {
  reset :{
    title: t('account:common:forgotPasswordAndResetPasswordPageTitle'),
    description: t('account:common:forgotPasswordAndResetPasswordPageDescription'),
    button: t('literal:reset'),
  },
  new :{
    title: t('account:resetPassword:newUserPageTitle'),
    description: t('account:resetPassword:newUserPageDescription'),
    button: t('literal:signIn'),
  },
};

const ResetPassword = () => {
  const [description, setDescription] = useState(true);

  const params = useParams();

  const resetToken = params.resetToken;
  const account = useSuspense(PasswordResetResource.detail(), {id: resetToken}); // eslint-disable-line
  const labels = account.is_new ? content.new : content.reset;

  return (
    <PublicLayout isLogin={true} >
      <div className="form-title">{ labels.title }</div>
      { description && <p>{ labels.description}</p> }
      <ResetForm resetToken={ resetToken } handleDescription={ setDescription } buttonDescription={ labels.button }/>
    </PublicLayout>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default ResetPassword;
