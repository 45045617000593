import React from "react";
import PropTypes from "prop-types";

import Details from "./Details";
import ActionButtons from "./ActionButtons";
import Media from "./Media";
import Publishing from "./Publishing";
import Tickets from "./Tickets";
import SessionTicketsEditor from "./SessionTicketsEditor";

import SeoOptions from "components/SeoOptions";
import Frequency from "./Frequency";

const EventForm = ({
  event,
  setEvent,
  onSubmit,
  loading,
  onChange,
  onError,
  unsavedChanges,
  setUnsavedChanges,
}) => {
  
  const { id, single } = event;

  const showFrequencyAndSessionTickets = !single && !id;
  
  return (
    <div>
      <Details
        event={event}
        onEventChange={setEvent}
        onChange={onChange}
        setUnsavedChanges={setUnsavedChanges}
      />

      {single && (
          <Publishing
            event={event}
            onEventChange={setEvent}
            onChange={onChange}
            setUnsavedChanges={setUnsavedChanges}
          />
      )}
      
      {showFrequencyAndSessionTickets && (
        <>
          <Frequency
            event={event}
            onEventChange={setEvent}
            setUnsavedChanges={setUnsavedChanges}
          />
          <SessionTicketsEditor
            event={event}
            onEventChange={setEvent}
            onChange={onChange}
            setUnsavedChanges={setUnsavedChanges}
          />
        </>
      )}

      {!showFrequencyAndSessionTickets &&
        <Tickets
          event={event}
          onEventChange={setEvent}
          onChange={onChange}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />}

      <Media
        object={event}
        onObjectChange={setEvent}
        onError={onError}
        setUnsavedChanges={setUnsavedChanges}
      />

      <SeoOptions
        slug={event.slug}
        onChange={onChange}
        showShortDescription={true}
        short_description={event.short_description}
      />

      <ActionButtons
        status={event?.status}
        loading={loading}
        onSubmit={onSubmit}
      />
    </div>
  );
};

EventForm.propTypes = {
  event: PropTypes.object,
  setEvent: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  unsavedChanges: PropTypes.bool,
  setUnsavedChanges: PropTypes.func,
};

export default EventForm;
