import React from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import { Button, Alert } from '@mui/material';

const StripeConnectAlert = ({organizationId}) => {

    const history = useHistory();

    const handleRedirect = () => {
        history.push(`/organizations/${organizationId}/settings/payments`)
      };

  return (
    <Alert severity="warning"
        sx={{marginBottom: "20px"}}
        action={
            <Button color="inherit" size="small" onClick={handleRedirect}>
                Complete
            </Button>
        }>
        To start receiving payments you need to finish your account verification
    </Alert>
  )
}

StripeConnectAlert.propTypes = {
    organizationId: PropTypes.number,
};

export default StripeConnectAlert;
