import React from "react";
import PropTypes from "prop-types"

import { TextField } from "@mui/material";

const Code = ({title, onChange }) => {

  const label = "Code *";

  return (
      <TextField
        fullWidth
        color="warning"
        name="code"
        size="small"
        value={title}
        onChange={onChange}
        label={label} />
  );
};

Code.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
};

export default Code;
