import React from 'react';
import PropTypes from 'prop-types';

import ButtonGroup from 'components/OfferCardButtonGroup';
import Tags from 'components/Tags';

import Label from 'ui/text/Label';

import { getServiceFeeLabel } from 'utils/profile/getServiceFeeLabel';

const Footer = ({product, onAction, onClick, organizationTags, showApplicationFee=undefined}) => {

  const serviceFeesLabel = getServiceFeeLabel(showApplicationFee);
  const showApplicationFeeLabel = product.price > 0 && showApplicationFee !== undefined;

    return (
    <div className='footer'>
        <Tags tag_ids={product.tag_ids} organizationTags={organizationTags}/>
        <ButtonGroup
          product={ product }
          onAction={ onAction }
          onClick={ onClick } />

        { showApplicationFeeLabel && <Label secondary padded small className={'application-fee-disclaimer'}>{serviceFeesLabel}</Label> }
    </div>
  );
};

Footer.propTypes = {
  product: PropTypes.object,
  onAction: PropTypes.func,
  onClick: PropTypes.func,
  organizationTags: PropTypes.array,
  showApplicationFee: PropTypes.bool
};

export default Footer;
