import { useEffect, useRef } from 'react';
import { useController } from '@rest-hooks/react';

import ThemeResource from 'resources/Profile/ThemeResource';

import setCustomTheme from 'utils/setCustomTheme';
import { defaultTheme } from 'utils/themes';

const useThemeController = (organizationSlug, setLoading) => {

  const { fetch } = useController();
  const isMounted = useRef(true);

  const applyTheme = async () => {
    try {
      const theme = await fetch(ThemeResource.detail(), { id: organizationSlug, organization_slug: organizationSlug });
      if (isMounted.current) {
        if (theme && theme.theme) {
          setCustomTheme(theme.theme);
        }
        if (setLoading) {
          setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error fetching theme:', error);
    }
  };

  useEffect(() => {
    applyTheme();
    return () => {
      isMounted.current = false;
      setCustomTheme(defaultTheme);
    };
  }, []);
}

export default useThemeController;