import React, { useState } from 'react';

import GroupsList from './GroupsList';
import NewGroupModal from './NewGroupModal';
import useOrganizationAdmin from 'utils/useOrganizationAdmin';

import Button from 'ui/Button';

const Telegram = () => {
  const [openNewModal, setOpenNewModal] = useState(false);
  const organization = useOrganizationAdmin();

  return (
    <div>
      <h3>Telegram</h3>

      <GroupsList organization={organization} />

      <Button minwidth onClick={ () => setOpenNewModal(true) }>Add Group Chat</Button>

      <NewGroupModal
        organization={organization}
        open={openNewModal}
        onClose={() => setOpenNewModal(false)}
      />
    </div>
  );
};

export default Telegram;
