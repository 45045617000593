import React from 'react';
import PropTypes from 'prop-types';

import InvoiceForm from './InvoiceForm';

import Subtitle from 'ui/text/Subtitle';

import { t } from 'i18n/index';

const InvoiceDetails = ({ setStep, senderName, senderEmail, paymentIntentId}) => {

  return (
    <div>
      <Subtitle>{t('literal:billingDetails')}</Subtitle>
      <p>{t('profile:memberships:claimModal:invoiceFormDescription')}</p>

      <InvoiceForm 
        setStep={setStep}
        senderName={ senderName }
        senderEmail={ senderEmail }
        paymentIntentId={ paymentIntentId }
      />
      
    </div>
  );
}

InvoiceDetails.propTypes = {
  setStep: PropTypes.func,
  senderName: PropTypes.string,
  senderEmail: PropTypes.string,
  paymentIntentId: PropTypes.string
};

export default InvoiceDetails;
