import * as React from 'react';
import PropTypes from 'prop-types';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

const Manage = ({id, subscribed, onUpdate }) => {
  const [open, setOpen] = React.useState(false);

  const handleUnsubscribe = () => {
    onUpdate(id, false);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button secondary
              onClick={ () => subscribed ? setOpen(true) : onUpdate(id, true) }>
              { subscribed ? "Unsubscribe" : 'Subscribe' }
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          Unsubscribe Newsletter
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Alert severity="warning">
              <b>Please Note</b><br/>
              By unsubscribing from the newsletter, you won&apos;t get emails anymore, but you can still enjoy all club benefits as long as your membership stays active.
            </Alert>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleUnsubscribe}>
            Unsubscribe
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

Manage.propTypes = {
  id: PropTypes.number,
  subscribed: PropTypes.bool,
  onUpdate: PropTypes.func
};

export default Manage;
