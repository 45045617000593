import React from "react";
import PropTypes from "prop-types";

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { capitalize } from "utils/capitalize";

const statuses = ['draft', 'live', 'suspended'];

const Status = ({status, onChange}) => {

  return (
    <FormControl fullWidth>
        <InputLabel color="warning">Status *</InputLabel>
        <Select
          label="Status *"
          name="status"
          color="warning"
          size="small"
          fullWidth
          value={ status }
          onChange={ onChange }

        >
          { statuses.map((option) => (
            <MenuItem key={option} value={option}>
              {capitalize(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

Status.propTypes = {
  status: PropTypes.string,
  onChange: PropTypes.func
};

export default Status;