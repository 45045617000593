import React, { useState } from 'react'
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from 'react-router-dom';

import PromoCodeResource from 'resources/organization/PromoCodeResource';

import ConfirmModal from 'components/ConfirmModal';

import ActionButtons from './ActionButtons';

import Button from 'ui/Button';
import CustomChip from 'ui/CustomChip';

import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import copyToClipboard from 'utils/copyToClipboard';
import { useError } from 'utils/useErrorController';
import isMobileDevice from 'utils/mobileAndTabletCheck';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { List, ListItem, ListItemText } from '@mui/material';

const PromoCodesList = () => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedPromoCode, setSelectedPromoCode] = useState(null);

    const { fetch } = useController();
    const history = useHistory();
    const params  = useParams();

    const organizationId = params.organizationId;

    const data = useSuspense(PromoCodeResource.list(), {organization_id: organizationId});
    const { setOpen, setMessage, setSeverity } = useToast();
    const { handleError, loading, setLoading } = useError();

    const handleDelete = async () => {
        try {
            setLoading(true);
            await fetch(PromoCodeResource.delete(), {organization_id: organizationId, id: selectedPromoCode.id});
            await fetch(PromoCodeResource.list(), {organization_id: organizationId});
            customToast('success', `Promo Code Deleted`, setOpen, setSeverity, setMessage);
            setSelectedPromoCode(null);
            setLoading(false);
        } catch (error) {
            handleError(error)
        }
    };

    const handleConfirmDeleteModal = (promoCode) => {
        setSelectedPromoCode(promoCode);
        setConfirmDelete(true);
    };

    const handleCopy = (code) => {
        copyToClipboard(code, setOpen, setSeverity, setMessage)
    };

    const isMobile = isMobileDevice();
    const listFlexDirection = isMobile ? 'column' : 'row';
    const listGap = isMobile ? '0px' : '20px';
    const listItemStyle = {
        backgroundColor: "#ffff", 
        margin: '16px 0px', 
        display: 'flex', 
        flexDirection: listFlexDirection, 
        borderRadius: 'var(--radius)',  
        padding: '20px', 
        gap: listGap
    };
        
    return (

    <div>
        <List sx={{padding: 'var(--mobilePadding)'}}>

            <Button icon={AddCircleOutlineIcon} highlighted fullwidth onClick={() => history.push(`/organizations/${organizationId}/perks/promo-codes/new`)} loading={loading}>
                Add new Promo Code
            </Button>

            {data.map((promoCode, index) => (
                <ListItem 
                    key={index + 1}
                    sx={listItemStyle}>

                    <ListItemText 
                        sx={{padding: '8px 0px', margin: '0px'}}
                        primary={promoCode.code} />
                    
                    {promoCode.status && <CustomChip label={promoCode.status} minwidth padded/>}

                    <ActionButtons
                        promoCode={promoCode} 
                        loading={loading}
                        organizationId={organizationId}
                        history={history}
                        handleConfirmDeleteModal={handleConfirmDeleteModal} 
                        handleCopy={handleCopy} 
                    />

                </ListItem>
            ))}
        </List>

        <ConfirmModal
                title={`Deleting Promo Code`}
                open={confirmDelete}
                setOpen={setConfirmDelete}
                onConfirm={() => handleDelete()}
            >
                You are about to delete a promo code. Would you like to continue?
        </ConfirmModal>

    </div>
  )
};

export default PromoCodesList;