import React from 'react';
import PropTypes from 'prop-types';

import Description from "ui/text/Description";
import Button from 'ui/Button';

import isMobileDevice from "utils/mobileAndTabletCheck";

const shortLabels = ["S", "M", "T", "W", "T", "F", "S"];
const abbreviatedLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const WeekdaySelector = ({event, onEventChange, setUnsavedChanges}) => {

    const days = isMobileDevice() ? shortLabels : abbreviatedLabels;

    const { repeat_days, start_time } = event;

    const handleRepeatDay = (value) => {
        setUnsavedChanges(true);

        const newRepeatDays = [...repeat_days];
        const dayIndex = newRepeatDays.findIndex(day => Object.keys(day)[0] === value.toString());

        if (dayIndex === -1) {
            newRepeatDays.push({ [value]: start_time });
        } else {
            newRepeatDays.splice(dayIndex, 1);
        }

        newRepeatDays.sort((a, b) => Object.keys(a)[0] - Object.keys(b)[0]);

        const newEvent = { ...event, repeat_days: newRepeatDays };
        onEventChange(newEvent);
    };  

  return (
    <>
        <Description>Repeat Weekly On:</Description>
        <div className="weekday-selector">
            {days.map((day, index) => (
                <Button
                    key={index}
                    rounded
                    secondary={!repeat_days.some(day => Object.keys(day)[0] === index.toString())}
                    onClick={() => handleRepeatDay(index)} >
                    {day}
                </Button>
            ))}
        </div>
    </>
  );
};

WeekdaySelector.propTypes = {
  event: PropTypes.object,
  onEventChange: PropTypes.func,
  setUnsavedChanges: PropTypes.func,
};

export default WeekdaySelector;