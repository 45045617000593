import PropTypes from 'prop-types';
import { usePermissions } from 'utils/context/PermissionsContext';

export const WithPermission = ({ permission, children, fallback }) => {

  const { permissions } = usePermissions();

  if ( permissions[permission] ){
    return children;
  } else if (fallback) {
    return fallback;
  } else {
    return null;
  }
};

WithPermission.propTypes = {
    children: PropTypes.node,
    permission: PropTypes.string,
    fallback: PropTypes.node,
};

export default WithPermission;
