import React from 'react'
import PropTypes from 'prop-types'

import Description from 'ui/text/Description';
import Button from 'ui/Button';
import { capitalize } from 'utils/capitalize';
import { TextField } from '@mui/material';

const PointsEditor = ({ points, setPoints, handlePointsSaveRequest, selectedSubscriptions }) => {

  const handleChange = ({target: {value}}) => {
    setPoints(value)
  };

  const action = points >= 0 ? 'grant' : 'deduct';
  const showInstructions = selectedSubscriptions.length === 0 || points === 0;

  const renderDescription = () => {
    if (showInstructions) {
      return ' Use the checkboxes and filters in the table below to select the users you want to send points to.';
    }

    const absPoints = Math.abs(points);
    const preposition = action === 'grant' ? 'to' : 'from';
    const pointsSuffix = absPoints ? 's' : '';
    const userCount = selectedSubscriptions.length > 1 ? selectedSubscriptions.length : '';
    const userSuffix = userCount > 1 ? 's' : '';
    
    return ` You are about to ${action} ${absPoints} point${pointsSuffix} ${preposition} the ${userCount} selected user${userSuffix}.`;
  };

  return (
    <div className='points-editor'>
      <Description>How many points do you want to grant or deduct? {renderDescription()}</Description>
      <div className='input'>
        <TextField
          name="points"
          label={`Points to ${action}`}
          color="warning"
          value={points}
          type="number"
          inputProps={{ inputMode: 'numeric'}}
          onChange={handleChange} />        
        <Button onClick={handlePointsSaveRequest} minwidth>{capitalize(action)} Points</Button>
      </div>
    </div>
  );
};

PointsEditor.propTypes = {
  points: PropTypes.number,
  setPoints: PropTypes.func,
  handlePointsSaveRequest: PropTypes.func,
  handlePointsCancel: PropTypes.func,
  selectedSubscriptions: PropTypes.array
};

export default PointsEditor;