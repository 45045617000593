import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';

import DialogContent from '@mui/material/DialogContent';

import { t } from 'i18n/index';
import { TextField } from '@mui/material';

const Content = ({isLoggedIn, step, email, setEmail}) => {
  return (
    <DialogContent dividers>

        {step === 1 &&
          <Description> 
            {isLoggedIn 
              ? t('profile:connectModal:loggedInContent') 
              : t('profile:connectModal:loggedOutContent')}
          </Description>}

        {step === 2 &&
          <div className='content'>
            <Description>
              {t('profile:connectModal:step2Contnet')} 
            </Description>
            <TextField
              label={t('literal:email')}
              value={email}
              onChange={e => setEmail(e.target.value)}
              color='warning'
            />
          </div>}

    </DialogContent>
  );
};

Content.propTypes = {
  isLoggedIn: PropTypes.bool,
  step: PropTypes.number,
  email: PropTypes.string,
  setEmail: PropTypes.func
};

export default Content;