import React from "react";
import PropTypes from "prop-types";

import Social from "ui/PageElement/Social";

const Elements = ({ socials, organizationSlug }) => {

  return (
    <div className="social-elements">
      {socials.map((pageElement, index) => (
        <Social key={index} pageElement={pageElement} organizationSlug={organizationSlug} />
      ))}
    </div>
  );
};

Elements.propTypes = {
  socials: PropTypes.array,
  organizationSlug: PropTypes.string
};

export default Elements;
