import { priceRegex } from "utils/pricingsConsts";
import { rewardType, courseType } from "utils/constants";
import { capitalize } from "utils/capitalize";
import { convertPriceFromCents } from "utils/rewards";

const handleValidation = (record) => {
  const { reward, course } = record;

  const pass = reward.category === rewardType.PASS.value;
  const collectible = reward.category === rewardType.COLLECTIBLE.value;
  const mysteryBox = course.category === courseType.MYSTERYBOX.value;

  validateTitle(reward);
  validateMedia(reward);

  if (pass) {
    validateDescription(reward);
  }

  const paid = reward.pricings === null || reward.pricings.length > 0;
  const mustHavePrice = collectible || (mysteryBox && paid) || (pass && paid);
  if (mustHavePrice) {
    validatePrice(reward);
  }
  if (mysteryBox) {
    validateSupplyLimit(reward);
  }
  if (reward.metadata) {
    validateProperties(reward.metadata);
  }
};

const validateTitle = ({ title }) => {
  if (title.trim() === "") {
    throw "Please provide a title.";
  }
};

const validateDescription = ({ description }) => {
  if (description.trim() === "") {
    throw "Please provide a description.";
  }
};

const validatePrice = ({ pricings }) => {
  if (pricings === null) {
    throw "Please provide a price.";
  }

  pricings.forEach((pricing) => {
    if (!priceRegex.test(convertPriceFromCents(pricing.price))) {
      throw "Price must be in this format: 123456.12";
    }
  });
};

const validateSupplyLimit = ({ supply_limit, category }) => {
  if (!supply_limit || supply_limit < 1) {
    throw `Please set your ${capitalize(
      rewardType[category.toUpperCase()].label
    )} supply limit.`;
  }
};

const validateMedia = ({ image_filename }) => {
  if (!image_filename) {
    throw "Please upload an image.";
  } 
};

const validateProperties = (properties) => {
  if (
    properties.filter(({ key, value }) => (key && !value) || (!key && value))
      .length > 0
  ) {
    throw "Please assign a property key to each value and vice-versa.";
  }
};

export default handleValidation;
