import React from "react";
import PropTypes from "prop-types"

import { slugify } from 'utils/slugify';

import { TextField } from "@mui/material";

const Title = ({title, onChange, object, onObjectChange, isTicket}) => {

  const label = "Title *";
  const handleBlur = () => isTicket ? null : slugify(object, onObjectChange);

  return (
      <TextField
        fullWidth
        color="warning"
        name="title"
        size="small"
        value={title}
        onChange={onChange}
        label={label}
        onBlur={() => handleBlur()} />
  );
};

Title.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  isTicket: PropTypes.bool
};

export default Title;
