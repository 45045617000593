import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';
import Label from 'ui/text/Label';

const CollectionContractAddress = ({ pricings, external_contract_address, onChange }) => {
  return (
    <>
      <TextField
        fullWidth
        size='small'
        name="external_contract_address"
        label={'External Contract Address'}
        color="warning"
        value={external_contract_address}
        onChange={onChange}
        disabled={pricings === null || pricings.length === 0}
        />
      <Label secondary padded>A user holding an NFT from this contract address will be able to subscribe this tier.</Label>
    </>
      
  );
};

CollectionContractAddress.propTypes = {
  pricings: PropTypes.array,
  external_contract_address: PropTypes.string,
  onChange: PropTypes.func
};

export default CollectionContractAddress;