import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';
import FormSection from 'ui/FormSection';

const ReplyTo = ({ reply_to_email, onChange, disabled }) => {
  return (
    <FormSection title={'Reply to Email'}>
      <TextField  
          label={'Reply to Email *'}
          name={'reply_to_email'}
          value={reply_to_email || ''}
          onChange={onChange}
          fullWidth
          size='small'
          color="warning"
          disabled={disabled}>
      </TextField>
    </FormSection>
  );
};

ReplyTo.propTypes = {
  reply_to_email: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default ReplyTo;