const validateFile = (file) => {
    if (!file) {
        throw 'Please upload a file';
    }
};

const validateTier = (tier) => {
    if (!tier) {
        throw 'Please select a tier';
    }
};

const validateEndDate = (sellable, end_date) => {
    if (sellable && !end_date) {
        throw 'Please select an end date';
    }
};

const handleImportValidation = (subscription) => {
    const {file, tier, sellable, end_date} = subscription

    validateFile(file);
    validateTier(tier); 
    validateEndDate(sellable, end_date);
};
``
export default handleImportValidation;