import React from 'react';
import PropTypes from 'prop-types';

import { capitalize } from 'utils/capitalize';

import Chip from '@mui/material/Chip';

import './styles.css';

const CustomChip = ({ label, minwidth, padded }) => {

  const containerClasses = ['chip-container'];
  if (padded) containerClasses.push('padded');

  const classes = ['chip'];

  const chipStyles = {
    active: 'success',
    expiring: 'warning',
    downgrading: 'warning',
    expired: 'error',
    draft: 'warning',
    published: 'success',
  };

  classes.push(chipStyles[label]);
  if (minwidth) classes.push('minwidth')

  return (
    <div className={containerClasses.join(' ')}>
        <Chip
            label={`• ${capitalize(label)}`}
            variant="outlined"
            className={classes.join(' ')}
        />
    </div>
  );
};

CustomChip.propTypes = {
  label: PropTypes.string,
  minwidth: PropTypes.bool,
  padded: PropTypes.bool
};

export default CustomChip;
