import React from 'react';
import PropTypes from 'prop-types';


import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';

import { t } from 'i18n/index';

const ErrorModal = ({ open, message, onClose, button }) => {

    const renderMessage = () => {
      if (message.length > 1) {
        return (
          <ul>
            {message.map((msg, index) => (
              <li key={index}>{msg}</li>
            ))}
          </ul>
        );
      }
      return message;
    };

    return (
      <Dialog open={open} fullWidth={ true } maxWidth='sm'>
      <DialogTitle>
        <ErrorIcon sx={{
              color: 'red',
              verticalAlign: 'middle',
              paddingRight: '2%',
              width: '36px',
          }} />
        {t('literal:error')}
        {onClose && (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
            }}
            >
              <CloseIcon />
          </IconButton>
        )} 
      </DialogTitle>
      <DialogContent dividers>
            {message && renderMessage()}
            {button}
      </DialogContent>
    </Dialog>
  );
};

ErrorModal.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.array,
  onClose: PropTypes.func,
  button: PropTypes.node,
};

export default ErrorModal;