import React from 'react';
import PropTypes from 'prop-types';

import SimpleSlider from './SimpleSlider';
import SingleImage from './SingleImage';

const Images = ({objects, selectedIndex, handleClose}) => {

  if (!objects || objects.length === 0) return null;
  
  return (
    <>
        {objects.length > 1 ?
            <SimpleSlider objects={objects} selectedIndex={selectedIndex} handleClose={handleClose}/>
            : 
            <SingleImage object={objects[0]} />
        }
    </>
  );
};

Images.propTypes = {
  objects: PropTypes.array,
  selectedIndex: PropTypes.number,
  handleClose: PropTypes.func
};

export default Images;