import React, { useState } from "react";
import { useController } from "@rest-hooks/react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import PostResource from "resources/organization/PostResource";

import Layout from "components/layouts";

import PostForm from "./Form";
import createFormData from "./createFormData";
import handleValidation from "./handleValidation";
import NewsletterModal from "./NewsletterModal";
import TestNewsletterModal from "../NewsletterModal";

import { useToast } from "utils/context/ToastContext";
import customToast from "utils/customToast";
import { useError } from "utils/useErrorController";

const PostFormWrapper = ({
  pageInfo,
  organizationId,
  postData,
  resource,
  resourceParams,
  toastMessage,
}) => {

  const { fetch } = useController();
  const history = useHistory();

  const { setOpen, setMessage, setSeverity } = useToast();
  const { handleError, validate, loading, setLoading } = useError();

  const [openNewsletterModal, setOpenNewsletterModal] = useState(false);
  const [openTestNewsletterModal, setOpenTestNewsletterModal] = useState(false);
  const [post, setPost] = useState(postData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    savePost(post);
  };

  const savePost = async (post) => {
    try {
      validate(post, handleValidation);
      const formData = createFormData(post);

      await fetch(resource, resourceParams, formData);
      handleSuccessfulResponse();
    } catch (error) {
      if (!post.id) {
        setPost({ ...post, status: "draft" });
      }
      handleError(error);
    }
  };

  const handleSuccessfulResponse = async () => {
    await fetch(PostResource.list(), { organization_id: organizationId });
    customToast("success", toastMessage, setOpen, setSeverity, setMessage);
    handleCloseCustomModal();
    history.goBack();
  };

  const handlePublish = () => {
    const newPost = { ...post, status: "published" };
    setPost(newPost);
    savePost(newPost);
  };

  const handleCloseCustomModal = () => {
    setOpenTestNewsletterModal(null);
    setOpenNewsletterModal(null);
    setLoading(false);
  };

  const handleChange = ({target: {name, value}}) => {
    let newPost = { ...post, [name]: value };
    setPost(newPost);
  };

  return (
    <Layout
      context="teacher"
      back={true}
      pageInfo={pageInfo}
      activeMenuItem="content"
    >
      <PostForm
        post={post}
        setPost={setPost}
        organizationId={organizationId}
        onSubmit={handleSubmit}
        onPublish={handlePublish}
        loading={loading}
        setLoading={setLoading}
        handleError={handleError}
        onChange={handleChange}
        onSendNewsletter={() => setOpenNewsletterModal(true)}
        onSendTestNewsletter={() => setOpenTestNewsletterModal(true)}
      />

      <NewsletterModal
        post={post}
        setPost={setPost}
        open={openNewsletterModal}
        onClick={handleSubmit}
        onClose={handleCloseCustomModal}
        loading={loading}
        organizationId={organizationId}
      />

      <TestNewsletterModal
        open={openTestNewsletterModal}
        onClose={handleCloseCustomModal}
        postId={post.id}
        organizationId={organizationId} 
      />

    </Layout>
  );
};

PostFormWrapper.propTypes = {
  pageInfo: PropTypes.object,
  postData: PropTypes.object,
  resource: PropTypes.func,
  resourceParams: PropTypes.object,
  toastMessage: PropTypes.string,
  organizationId: PropTypes.number,
};

export default PostFormWrapper;
