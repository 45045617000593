import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const AmountSelector = ({ checkoutVars, onCheckoutVarsChange }) => {

  const { amount, maxAmount } = checkoutVars;
  
  if (maxAmount <= 1) return null;

  const atMaxAmount = maxAmount <= amount;
  const atMinAmount = 1 == amount;

  const deduct = () => {
      onCheckoutVarsChange({...checkoutVars, amount: amount - 1 });
  };

  const add = () => {
      onCheckoutVarsChange({...checkoutVars, amount: amount + 1 });
  };

  return (
    <div className={`amount-selector`}>
      <div className="button">
        <IconButton
          className={`MuiAmountIconButton ${ atMinAmount && "MuiAmountIconButtonDisabled" }`}
          onClick={deduct}
          disableRipple={true}
          disabled={atMinAmount}
        >
          <RemoveIcon />
        </IconButton>
      </div>

      {amount}

      <div className="button">
        <IconButton
          className={`MuiAmountIconButton ${ atMaxAmount && "MuiAmountIconButtonDisabled" }`}
          onClick={add}
          disableRipple={true}
          disabled={atMaxAmount}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
};

AmountSelector.propTypes = {
  checkoutVars: PropTypes.object.isRequired,
  onCheckoutVarsChange: PropTypes.func.isRequired
};

export default AmountSelector;
