import { getDateFormat } from 'utils/tables/getDateFormat';
import { getDateSelector } from 'utils/tables/getDateSelector';

import { convertPriceFromCents , currencySymbol } from 'utils/rewards';

const paymentTypes = {
  'stripe': 'Credit Card',
  'utrust': 'Crypto',
  'eupago': 'MBWay',
}

// const eventTypes = {
//   'subscription_purchase': 'Subscription Purchase',
//   'subscription_renewal': 'Subscription Renewal',
//   'collectible_purchase': 'Collectible Purchase',
// }

export const ordersColumns = [
    {
      field: 'id',
      name: 'Order ID',
      sortable: true,
      width: "10%",
      selector: (row) => row.id,
    },
    {
      field: 'status',
      name: 'Status',
      sortable: true,
      width: "15%",
      selector: (row) => row.status
    },
    {
      field: 'user_name',
      name: 'User Name',
      sortable: true,
      width: "15%",
      selector: (row) => row.user,
    },
    {
      field: 'user_email',
      name: 'User Email',
      sortable: true,
      width: "30%",
      selector: (row) => row.email,
    },
    {
      field: 'item_name',
      name: 'Item Name',
      sortable: true,
      width: "25%",
      selector: (row) => row.item_title
    },
    {
      field: 'item_price',
      name: 'Item Price',
      sortable: true,
      width: "15%",
      selector: (row) => `${convertPriceFromCents(row.item_price)} ${currencySymbol[row.currency]}`
    },
    {
      field: 'amount',
      name: 'Quantity',
      sortable: true,
      width: "15%",
      selector: (row) => row.amount
    },
    {
      field: 'application_fee',
      name: 'Service Fees',
      sortable: true,
      width: "15%",
      selector: (row) => `${row.application_fee ? convertPriceFromCents(row.application_fee) : 0} ${currencySymbol[row.currency]}`
    },
    {
      field: 'total_price',
      name: 'Total Amount',
      sortable: true,
      width: "15%",
      selector: (row) => `${convertPriceFromCents(row.total_amount)} ${currencySymbol[row.currency]}`
    },
    {
      field: 'payment_provider',
      name: 'Payment Method',
      width: "15%",
      selector: (row) => paymentTypes[row.payment_provider]
    },
    {
      field: 'completed_at',
      name: 'Completed At',
      sortable: true,
      width: "20%",
      selector: (row) => getDateSelector(row.charge_succeded_at),
      format: (row) => getDateFormat(row.charge_succeded_at, 'lll'),
    },
  ];
