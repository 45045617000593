import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const emptyStudent = { first_name: '', last_name: '', email: '' };

const StudentForm = ({record={}, onSubmit, buttonTitle=null, CancelButton=null}) => {
  const [student, setCourse] = useState({...emptyStudent, ...record });

  const handleChange = (event) => {
    let newStudent = {...student};
    newStudent[event.target.name] = event.target.value;
    setCourse(newStudent);
  };

  return (
    <form onSubmit={ (e) => onSubmit(e, student) } >
      <div className="new-course-wrapper">
        <div className={ `new-form-field` }>
          <TextField
            autoFocus
            fullWidth
            name="first_name"
            label="First Name"
            color="warning"
            value={ student.first_name }
            onChange={event => handleChange(event)}
          />
        </div>
        <div className={ `new-form-field` }>
          <TextField
              fullWidth
              name="last_name"
              label="Last Name"
              color="warning"
              value={ student.last_name }
              onChange={event => handleChange(event)}
            />
        </div>
        <div className={ `new-form-field` }>
          <TextField
              fullWidth
              name="email"
              label="Email"
              color="warning"
              value={ student.email }
              onChange={event => handleChange(event)}
            />
        </div>
      </div>

      <div className={ `new-form-field` }>
        <Button variant="contained" color="primary" type="submit">
          { buttonTitle ? 
            buttonTitle : 
            (record.id ? 'Update' : 'Create')
          }
        </Button>

        { CancelButton && <CancelButton /> }
      </div>
    </form>
  )
};

StudentForm.propTypes = {
  record: PropTypes.object,
  onSubmit: PropTypes.func,
  buttonTitle: PropTypes.string,
  CancelButton: PropTypes.func,
};

export default StudentForm;