import React from 'react';
import PropTypes from 'prop-types';

import Button from "ui/Button";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const ButtonGroup = ({handleClose, handleNewTicketSubmit, loading}) => {
  return (
    <>
        <Button secondary onClick={handleClose} fullwidth loading={loading}>
          Cancel
        </Button>
        <Button
          highlighted
          icon={AddCircleOutlineIcon}
          fullwidth
          onClick={handleNewTicketSubmit}
          loading={loading}
        >
          Add Ticket
        </Button>
    </>
)}

ButtonGroup.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleNewTicketSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default ButtonGroup;