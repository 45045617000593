export const createFormData = (subscription, welcomeEmail=null) => {
    const formData = new FormData();
    const {email, tier, end_date, send_welcome_email, file} = subscription;
    const fields = [
        { key: 'email', value: email },
        { key: 'end_date', value: end_date },
        { key: 'tier', value: tier },
    ]

    if (send_welcome_email === 'true') {
        fields.push({ key: 'send_welcome_email', value: true });
    }

    if (send_welcome_email === 'personalized') {
        fields.push({ key: 'welcome_email', value: JSON.stringify(welcomeEmail)});
    }

    if (file) {
        fields.push({ key: 'file', value: file });
    }

    fields.forEach(field => {
        formData.append(field.key, field.value);
    });

    return formData;
};
