import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

const ClaimLimit = ({claim_limit, onChange, published}) => {
  return (
      <TextField
          fullWidth
          size='small'
          name="claim_limit"
          label={`Max amount per user. Leave empty for 1`}
          color="warning"
          value={claim_limit}
          type="number"
          onChange={onChange}
          disabled={published}
          inputProps={{ inputMode: 'numeric'}} />
  );
};

ClaimLimit.propTypes = {
  claim_limit: PropTypes.object,
  onChange: PropTypes.func,
  published: PropTypes.bool,
};

export default ClaimLimit;