import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController } from "@rest-hooks/react";
import { useParams } from 'react-router-dom';

import PaymentProviderResource from "resources/Profile/PaymentProviderResource";

import InvoiceDetails from './InvoiceDetails';
import PaymentCollection from './PaymentCollection';
import PaymentProcessed from './PaymentProcessed';
import InvoiceCreated from './InvoiceCreated';
import TicketDetails from './TicketDetails';
import TicketsSent from './TicketsSent';

import Confetti from 'components/Confetti';

import { offerCategory } from 'utils/offersFormHelper';
import { useError } from "utils/useErrorController";
import useSliderController from 'utils/useSliderController';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { t } from 'i18n/index';

const stripeDefaultState = {
    clientSecret: "",
    stripePublishableKey: "",
    stripeAccountId: "",
}

const orderDefaultState = {
    paymentIntentId: "",
    selectedProvider: "",
    providers: [],
};

const PaymentModal = ({  pricingId, object, open, step, setStep, handleModalsClose, stripe_purchase, modalWidth, amount=1, promoCode=null }) => {

    const [orderState, setOrderState] = useState(orderDefaultState);
    const [stripeState, setStripeState] = useState(stripeDefaultState);
    const [content, setContent] = useState(null);
    const [userPhone, setUserPhone] = useState('');

    const handleClaimReset = () => {
        resetVariables();
        handleModalsClose();
    };

    const resetVariables = () => {
        setStripeState(stripeDefaultState);
        setOrderState(orderDefaultState);
        setUserPhone('');
    };

    const isTicket = object?.category === offerCategory.ticket.value;
    const isPass = object?.category === 'pass';
    const suffix = amount > 1 ? "s" : "";

    const { fetch } = useController();
    const {organizationSlug} = useParams();
    const { handleError, setLoading } = useError();    
    const { openSliderComponent, handleImageClick, SliderComponent} = useSliderController(
        t('profile:memberships:claimModal:atTheDoorModalTitle', {suffix}),
        t('profile:memberships:claimModal:atTheDoorModalDescription', {suffix})
    );

    const showStep2 = step === 2 && !openSliderComponent;
    const searchParams = new URLSearchParams(window.location.search);
    const atTheDoor = searchParams.has('openTicket') 

    const handleShowTickets = (uuids) => {
        if (atTheDoor)  {
            handleImageClick(uuids, 0);
        } else {
            return
        }
    };

    const handleProviderSelection = (provider, availableProviders) => {
        setOrderState((previousValue) => ({...previousValue, selectedProvider: provider, providers: availableProviders}));
    };

    const handleStripeKeys = (stripePublishableKey, stripeAccountId) => {
        if (!stripePublishableKey || !stripeAccountId) return;
        setStripeState((previousValue) => ({...previousValue, stripePublishableKey: stripePublishableKey, stripeAccountId: stripeAccountId}));
    };

    const fetchAvailablePaymentMethods = async () => {
        try {
          setLoading(true);

          const response = await fetch(PaymentProviderResource.detail(), {
            organization_slug: organizationSlug,
            id: "payment_providers",
          });

          handleStripeKeys(response.stripe_publishable_key, response.stripe_account_id);

          if (isPass) {
            if (!response.providers.includes("stripe")) throw t("errors:stripeNotConnected");
            handleProviderSelection("stripe", response.providers);
          } else if (response.providers.includes("eupago")) {
            handleProviderSelection("eupago", response.providers);
          } else if (response.providers.includes("stripe")) {
            handleProviderSelection("stripe", response.providers);
          } else {
            handleError(t("errors:noPaymentProviders"));
          }

          setLoading(false);
        } catch (error) {
          handleError(error);
        }
      };

      useEffect(() => {
        if (pricingId) {
            fetchAvailablePaymentMethods();
        }
      }, [pricingId]);

    if (!pricingId) {
        return null;
    }

    return (
        <>
            {showStep2 &&
                <Confetti />
            }

            <Dialog open={open && !!pricingId} fullWidth maxWidth={modalWidth} hideBackdrop={step === 2}>
                <IconButton
                    onClick={() => handleClaimReset()}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 12,
                        color: 'black',
                        zIndex: 1000
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    {step === 1 &&
                        <PaymentCollection
                            object={ object }
                            pricingId={ pricingId }
                            requestedAmount={ amount }
                            userPhone={ userPhone }
                            onUserPhoneChange={ setUserPhone }
                            stripeState={ stripeState }
                            onStripeStateChange={ setStripeState }
                            orderState={ orderState }
                            onOrderChange={ setOrderState }
                            onContentChange={ setContent }
                            onStepChange={ setStep }
                            isPass={ isPass }
                            onShowTickets={ handleShowTickets }
                            promoCode={ promoCode }
                        />
                    }
                    {showStep2 &&
                        <PaymentProcessed
                            setStep={ setStep }
                            handleClaimReset={ handleClaimReset }
                            stripe_purchase={ stripe_purchase }
                            isTicket={ isTicket }
                            amount={ amount }
                            content={ content }
                            organizationTitle={ object?.organization?.organization_title }
                            atTheDoor={ atTheDoor }
                        />
                    }
                    {step === 3 &&
                        <TicketDetails
                            setStep={ setStep }
                            handleClaimReset={ handleClaimReset }
                            paymentIntentId={ orderState.paymentIntentId }
                            amount={ amount }
                        />
                    }
                    {step === 4 &&
                        <TicketsSent
                            setStep={ setStep }
                            handleClaimReset={ handleClaimReset }
                        />
                    }
                    {step === 5 &&
                        <InvoiceDetails
                            setStep={ setStep }
                            senderName={ object?.participant?.name }
                            senderEmail={ object?.participant?.email }
                            paymentIntentId={ orderState.paymentIntentId }
                        />
                    }
                    {step === 6 &&
                        <InvoiceCreated
                            handleClaimReset={ handleClaimReset }
                            isTicket={ isTicket }
                        />
                    }
                </DialogContent>
            </Dialog>

            <SliderComponent />
        </>
    );
};

PaymentModal.propTypes = {
    pricingId: PropTypes.number,
    object: PropTypes.object,
    open: PropTypes.bool,
    step: PropTypes.number,
    setStep: PropTypes.func,
    handleModalsClose: PropTypes.func,
    stripe_purchase: PropTypes.bool,
    modalWidth: PropTypes.string,
    amount: PropTypes.number,
    promoCode: PropTypes.string,
};

export default PaymentModal;
