import React from 'react';
import PropTypes from 'prop-types';

import SearchableFilter from 'ui/SearchableFilter';
import Filter from 'ui/Filter';

const OrderFilter = ({ onChange }) => {

  const organizationFilter = <SearchableFilter label={"organization"} onChange={onChange} />

  const statusFilter = <Filter onChange={onChange} label="status" table={'orders'} />

  return (
    [organizationFilter, statusFilter]
  );
};

OrderFilter.propTypes = {
  onChange: PropTypes.func,
};

export default OrderFilter;
