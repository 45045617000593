import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';

import formattedDate from 'utils/formattedDate';

import TollIcon from '@mui/icons-material/Toll';
import LockIcon from '@mui/icons-material/Lock';
import TimerIcon from '@mui/icons-material/Timer';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import moment from 'moment';
import { t } from "i18n/index";

const Criteria = ({product, userPoints}) => {

    const {points, tier_titles, end_date, available_amount} = product;

    const showEndDate = end_date && moment(end_date).diff(moment(), 'hours') < 48;
    const formatedEndDate = formattedDate(end_date, 'fromNow');

    const showAvailableAmount = available_amount && available_amount < 15;

    const isPublic = !tier_titles;
    const tierTitles = tier_titles && tier_titles.join(', ');
    const isPrivate = tierTitles === 'private';
    const suffix = tier_titles && tier_titles.length > 1 ? 's' : '';

    const userPointsLabel = userPoints || t('literal:none_sc');

    const criteriaData = [
      {
        icon: TollIcon,
        iconColor: 'warning',
        text: t('profile:perks:redeemModal:criteria:points', {points, userPointsLabel}),
        condition: (points > 0),
      },
      {
        icon: LockIcon,
        iconColor: 'inherit',
        text: t('profile:perks:redeemModal:criteria:exclusive', {tierTitles, suffix}),
        condition: !isPrivate && !isPublic,
      },
      {
        icon: TimerIcon,
        iconColor: 'info',
        text: t('profile:perks:redeemModal:criteria:date', {formatedEndDate}),
        condition: showEndDate,
      },
      {
        icon: ProductionQuantityLimitsIcon,
        iconColor: 'error',
        text: t('profile:perks:redeemModal:criteria:supply', {available_amount}),
        condition: showAvailableAmount,
      },
    ];


  return (
    <>
        {criteriaData.map((data) => (
            <>
            {data.condition ? (
                <div className="redeem-criteria">
                    <data.icon fontSize="small" color={data.iconColor} />
                    <Description>
                        {data.text} {data.name}
                    </Description>
                </div>
            ): (null)}
            </>
        ))}
    </>
  )
}

Criteria.propTypes = {
  product: PropTypes.object,
  userPoints: PropTypes.number,
};

export default Criteria;
