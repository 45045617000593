import React, { useState } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import NewsletterSettingsResource from 'resources/organization/NewsletterSettingsResource';

import createFormData from './createFormData';
import Preferences from './Preferences';
import Settings from './Settings';
import { handleValidation } from './handleValidation';

import Button from 'ui/Button';

import { useError } from 'utils/useErrorController';
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';

import { Alert } from '@mui/material';

const emptySettings = {
  address: '',
  city: '',
  country: '',
  post_code: '',
  reply_to_email: '',
  notifications_enabled: false,
  hide_banner: false,
};

const booleanAttrs = ['notifications_enabled', 'hide_banner'];

const NewslettersForm = ({ onUnsavedChanges }) => {

  const { fetch } = useController();
  const { setOpen, setMessage, setSeverity } = useToast();
  const { loading, setLoading, handleError, validate } = useError();

  const params = useParams();
  const organizationId = parseInt(params.organizationId);
  const savedSettings = useSuspense(NewsletterSettingsResource.detail(), { organization_id: organizationId, id: organizationId });

  const [newsletterSettings, setNewsLetterSettings] = useState({...emptySettings, ...savedSettings});

  const newRecord = !savedSettings.reply_to_email;
  const disabled = savedSettings.sender_created;

  const actionConfig = {
    toast: newRecord ? 'Settings created' : 'Settings updated',
    perform: newRecord ? NewsletterSettingsResource.create() : NewsletterSettingsResource.update()
  };

  const submitChanges = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();

      validate(newsletterSettings, handleValidation);
      const formData = createFormData(newsletterSettings, disabled);

      await fetch(actionConfig['perform'], { id: organizationId, organization_id: organizationId }, formData );
      handleSuccessfulResponse();

    } catch (error) {
      handleError(error);
    }
  };

  const handleEmailReset = async () => {
    try {
      setLoading(true);
      await fetch(NewsletterSettingsResource.delete(), { organization_id: organizationId, id: organizationId });
      handleSuccessfulResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const handleSuccessfulResponse = async () => {
    const updatedSettings = await fetch(NewsletterSettingsResource.detail(), { organization_id: organizationId, id: organizationId });
    setNewsLetterSettings({...emptySettings, ...updatedSettings})
    customToast('success', actionConfig['toast'], setOpen, setSeverity, setMessage);
    setLoading(false);
    onUnsavedChanges(false);
  };

  const handleChange = ({target: {name, value, checked}}) => {
    onUnsavedChanges(true);

    const newValue = booleanAttrs.includes(name) ? checked : value;
    const newSettings = { ...newsletterSettings, [name]: newValue}

    setNewsLetterSettings(newSettings);
  };

  return (
    <>
      <form onSubmit={ (event) => submitChanges(event) } >
        { disabled &&
          <Alert severity="info" sx={{ mr: '10px', ml: '10px' }}>
            Please contact us if you would like to update your details.
          </Alert>}

        <Settings
          onChange={handleChange}
          newsletterSettings={newsletterSettings}
          onNewsletterSettingsChange={setNewsLetterSettings}
          disabled={disabled}
        />

        <Preferences
          newsletterSettings={newsletterSettings}
          onCheckboxChange={handleChange}
          onChange={setNewsLetterSettings}
          onUnsavedChanges={onUnsavedChanges}
          onEmailReset={handleEmailReset}
          loading={loading}
        />

        <div className={ `new-form-field-footer` }>
          <Button loading={loading} minwidth >
            Save
          </Button>
        </div>
      </form>
    </>
  )
};

NewslettersForm.propTypes = {
    record: PropTypes.object,
    onUnsavedChanges: PropTypes.func
};

export default NewslettersForm;
