import React from 'react';
import PropTypes from 'prop-types';

import SearchableFilter from 'ui/SearchableFilter';

const ProductFilter = ({ onChange }) => {

  const organizationFilter = <SearchableFilter label={"organization"} onChange={onChange} />

  const productFilter = <SearchableFilter label={"product"} onChange={onChange} />
  
  return (
    [organizationFilter, productFilter]
  );
};

ProductFilter.propTypes = {
  onChange: PropTypes.func,
};

export default ProductFilter;
