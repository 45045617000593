import React from 'react';
import PropTypes from 'prop-types';

import MarkdownEditor from 'components/MarkdownEditor';

const Description = ({reward, setReward, isMembershipCollection}) => {
  
  const placeholder = `Description ${isMembershipCollection ? '*' : ''}`

  return (
      <MarkdownEditor 
        object={reward}
        setObject={setReward}
        attribute={"description"}
        placeholder={placeholder}
      />
  );
};

Description.propTypes = {
  reward: PropTypes.object,
  setReward: PropTypes.func,
  isMembershipCollection: PropTypes.bool
};

export default Description;


