import React from 'react'
import PropTypes from 'prop-types'

const SoundCloud = ({content}) => {

    const slashCount = (content && content.match(/\//g) || []).length;
    const height = slashCount === 4 ? "164px" : "400px";

    return (
        <div className='soundlcoud-container'>
            <iframe width="100%" height={height} scrolling="no" allow="autoplay" loading="lazy" 
                src={`https://w.soundcloud.com/player/?url=${content}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false`}>
            </iframe>
        </div> 
    );
};

SoundCloud.propTypes = {
    content: PropTypes.string
};

export default SoundCloud;