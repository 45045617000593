import { t } from "i18n/index";

const helpLabels = {
  already_issued: t('errors:alreadyIssuedMax'),
  not_eligible: t('errors:notEligible'),
  pre_approved: t('errors:privateClaim'),
  unavailable: t('errors:unavailable'),
};

export const getHelpText = (pass, unavailable) => {
  const { logged_in, is_enrolled, has_been_issued } = pass.participant;
  const { is_public } = pass;

  if (unavailable) {
    return helpLabels.unavailable;
  } else if (logged_in && has_been_issued) {
    return helpLabels.already_issued;
  } else if (logged_in && !has_been_issued && !is_public && !is_enrolled) {
    return helpLabels.not_eligible;
  } else if (!logged_in && !is_public) {
    return helpLabels.pre_approved;
  }
};
