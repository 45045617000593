import React, { useMemo, useState } from "react";
import { useSuspense, NetworkErrorBoundary } from "@rest-hooks/react";
import { useHistory, useParams } from "react-router-dom";

import TicketResource from "resources/checkin/TicketResource";
import EventResource from "resources/checkin/EventResource";

import TicketsList from "components/TicketsList";
import PageTop from "components/PageTop";
import SessionSelector from "components/SessionSelector";

import NetworkError from "./NetworkError";

const TicketsContent = () => {    

  const history = useHistory();
  const {eventUuid, sessionId} = useParams();
  const [selectedSessionId, setSelectedSessionId] = useState(sessionId);

  const requestParams = useMemo(() => {
    const params = { event_uuid: eventUuid };
    if (selectedSessionId) {
      params.session_id = selectedSessionId;
    }
    return params;
  }, [eventUuid, selectedSessionId]);

  //fetch event details to get event title, sessions, single, external_url and organization_logo_url
  const event = useSuspense(EventResource.detail(), { id: eventUuid });

  const pageInfo = useMemo(
    () => ({
      name: event?.title || "Tickets",
      description: "Manage your tickets and check-ins here",
    }),
    [event]
  );

  const handleSessionChange = ({target: {value}}) => {
    setSelectedSessionId(value);
    history.push(`/checkin/${eventUuid}/${value}`);
  };

  const showTicketList = event.single || selectedSessionId;

  return (
    <main>
        <div className="content">

        <PageTop pageInfo={ pageInfo } />

        {showTicketList ? (
          <TicketsList TicketResource={TicketResource} ticketResourceParams={requestParams} event={event} sessionId={selectedSessionId}/>
        ) : (
          <SessionSelector options={event.sessions} onChange={handleSessionChange} />
        )}

        </div>
    </main>
  );
};

const Tickets = () => {
  return (
    <NetworkErrorBoundary fallbackComponent={NetworkError}>
      <TicketsContent />
    </NetworkErrorBoundary>  
  )
};

export default Tickets;
