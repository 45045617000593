import React from 'react';
import PropTypes from 'prop-types';

import { Viewer } from '@toast-ui/react-editor';

const Content = ({description}) => {

  return (
    <div className='description' >
      <Viewer
          initialValue={description}
          extendedAutolinks={true}
          linkAttributes={{
            target: '_blank',
            rel: 'noopener noreferrer',
          }}
      />    
    </div>
  )
};

Content.propTypes = {
    description: PropTypes.string,
};

export default Content;
