import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import PromoCodeResource from 'resources/Profile/PromoCodeResource';

import Button from 'ui/Button';

import { useError } from 'utils/useErrorController';

import { IconButton, InputAdornment, TextField } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';

const PromoCodeValidator = ({objectId, onObjectChange, checkoutVars, onCheckoutVarsChange}) => {

  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const { fetch } = useController();
  const { organizationSlug } = useParams();
  const { handleError } = useError();

  const { promoCode } = checkoutVars;

  const validatePromoCode = async () => {
    try {
      const { success, product } = await fetch(PromoCodeResource.create(), {organization_slug: organizationSlug, product_id: objectId}, {promo_code: promoCode});
      onObjectChange(product)
      setError(!success);

    } catch (error) {
      handleError(error);
    }
  };

  const handlePromoCodeChange = (event) => {
    onCheckoutVarsChange({...checkoutVars, promoCode: event.target.value });
  };

  return (
    <>
    {open ? (
      <TextField
      label="Promo Code"
      size="small"
      margin='dense'
      value={promoCode}
      onChange={handlePromoCodeChange}
      fullWidth
      color="warning"
      error={error}
      helperText={error ? 'Invalid promo code' : ' '}
      InputProps={{endAdornment:
              <InputAdornment position="end">
                <IconButton
                  onClick={()=> validatePromoCode()}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </InputAdornment>
            }}
    />) : (
      <div style={{margin: "25px 0px 25px"}}>
        <Button
          text
          onClick={() => setOpen(true)}
          small
          icon={AddCircleOutlineOutlinedIcon}
        >
          Add Promo Code
        </Button>
      </div>
    )}
    </>
  );
};

PromoCodeValidator.propTypes = {
    objectId: PropTypes.number,
    onObjectChange: PropTypes.func,
    checkoutVars: PropTypes.object,
    onCheckoutVarsChange: PropTypes.func
};

export default PromoCodeValidator;