import React, { useState } from "react";
import PropTypes from "prop-types";

import PricingAndCurrencyInputFields from "components/PricingAndApplicationFeeFields";
import PriceField from "components/PricingAndApplicationFeeFields/PriceField";

import Description from "ui/text/Description";
import Button from "ui/Button";

import { frequencies, payableTypes, priceErrors, priceRegex, labels, currencies } from "utils/pricingsConsts";

import { priceIndexFromPricings, pricingsFromPriceIndex } from "utils/rewards";
import { courseType } from "utils/constants";

import { FormControlLabel, Stack, Typography, Switch } from "@mui/material";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';

const Pricing = ({pricings, collectionType, onChange, mysteryBoxReward, isDisabled, hasBeenIssued, currency}) => {
  if ( !payableTypes.includes(collectionType) && !mysteryBoxReward ) { return null; }

  const [errors, setErrors] = useState(priceErrors);
  const [advancedMode, setAdvancedMode] = useState(pricings != null && pricings.length > 1);
  const [prices, setPrices] = useState(priceIndexFromPricings(pricings));

  const free = (pricings != null && pricings.length === 0);
  const defaultFrequency = collectionType === "membership" ? "monthly" : "lifetime";

  const handleFreeChange = (e, value) => {
    if (value) {
      setAdvancedMode(false);
      setErrors(priceErrors);
      setPrices({ [defaultFrequency]: "" });
      onChange({target: {name: "pricings", value: []}});
    } else {
      onChange({target: {name: "pricings", value: null}});
    }
  };

  const handleChange = ({target: {name, value}}) => {

    if (value === "") {
      setErrors({...errors, [name]: false});
    } else if (!priceRegex.test(value)) {
      setErrors({...errors, [name]: true});
      return;
    }

    const newPrices = { ...prices, [name]: value };
    setPrices(newPrices);
    setErrors({...errors, [name]: false});
    const newPricings = pricingsFromPriceIndex(newPrices, currency);
    onChange({target: {name: "pricings", value: newPricings }});
  };

  const isMembershipCollection = collectionType === courseType.MEMBERSHIP.value;
  const showFreeSwitch = (isMembershipCollection || collectionType === courseType.MYSTERYBOX.value) && !isDisabled;

  return <>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Description bold large>Pricings</Description>
        { showFreeSwitch && 
          <>
            <FormControlLabel 
              control={
                <Switch
                  color="warning"
                  checked={ free }
                  onChange={handleFreeChange}
                  disabled={isDisabled || hasBeenIssued}
                  />
              }/>
            <Typography>Free</Typography> 
          </>
        }
      </Stack>

    <PricingAndCurrencyInputFields 
        defaultFrequency={defaultFrequency}
        price={prices[ defaultFrequency]}
        currency={currency}
        isDisabled={free}
        errors={errors}
        onPriceChange={handleChange}/>
    
    {  isMembershipCollection &&
      <>
      { (!advancedMode && !free) &&
        <Button text small icon={AddCircleOutlineOutlinedIcon} onClick={() => setAdvancedMode(true)}>Other frequencies and discounts</Button>
      }

      { advancedMode &&
        <>
          { frequencies.slice(1).map( (frequency, index) => (
              <PriceField key={index}
                        frequency={ frequency }
                        price={ prices[frequency] }
                        currency={ currency }
                        isDisabled={ free }
                        errors={errors}
                        onPriceChange={handleChange}
                        icon={currencies[currency].icon}
                        label={errors[ frequency ] ? labels["format"] : labels[frequency]}
                        />
          ))}
        </>
      }
      </>
    }
  </>;

};

Pricing.propTypes = {
  pricings: PropTypes.array,
  collectionType: PropTypes.string,
  onChange: PropTypes.func,
  mysteryBoxReward: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasBeenIssued: PropTypes.bool,
  currency: PropTypes.string
};

export default Pricing;

