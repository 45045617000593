import React from 'react';
import PropTypes from 'prop-types';

import Table from './Table';

import { validEmailRegex } from 'utils/validations/validateEmail';

import DialogContent from "@mui/material/DialogContent";

const Content = ({fullNameTable, rows, message}) => {

  const rowsWithErrors = rows.filter(row => row.email === undefined || !row.email.match(validEmailRegex));
  const shownRows = rowsWithErrors.length > 0 ? rowsWithErrors : rows;

  return (
    <DialogContent dividers>

        <Table rows={shownRows} fullNameTable={fullNameTable} />
        
        {shownRows.length > 10 && (
            <p className="label-message">Preview of the first 10 records</p>
        )}

        <p className="message">{message}</p>
        
    </DialogContent>
  );
};

Content.propTypes = {
  fullNameTable: PropTypes.bool,
  rows: PropTypes.array,
  message: PropTypes.string,
};

export default Content;