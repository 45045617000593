import { getDateFormat } from 'utils/tables/getDateFormat';
import { getDateSelector } from 'utils/tables/getDateSelector';

export const getRewardInstancesColumns = (allowMinting) => {
  const columns = [
    {
      field: 'fullName',
      name: 'Name',
      sortable: true,
      width: '20%',
      selector: (row) => `${row.first_name || ''} ${row.last_name || ''}`,
    },
    {
      field: 'email',
      name: 'Email Address',
      sortable: true,
      width: '25%',
      selector: (row) => `${row.email}`,
    },
    {
      field: 'wallet_address',
      name: 'Wallet Address',
      sortable: true,
      width: '35%',
      selector: (row) => (row.wallet_address ? row.wallet_address : ''),
    },
    {
      field: 'title',
      name: 'Tier title',
      sortable: true,
      width: '25%',
      selector: (row) => row.title,
    },
    {
      field: 'created_at',
      name: 'Date issued',
      sortable: true,
      width: '15%',
      selector: (row) => getDateSelector(row.created_at),
      cell: (row) => getDateFormat(row.created_at),
    },
  ];

  if (!allowMinting) {
    return columns.filter((column) => column.field !== 'wallet_address');
  }

  return columns;
};