import React from 'react';
import PropTypes from 'prop-types';

import { rewardType } from 'utils/constants';
import { capitalize } from 'utils/capitalize';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const LinkSettings = ({publicReward, category, isDisabled, onChange}) => {

  return (
      <FormControl fullWidth>
        <InputLabel id="reward-category-label" color="warning">QR Code / Link settings *</InputLabel>
        <Select
          labelId="reward-category-label"
          label="QR Code / Link settings"
          name="public"
          color="warning"
          size='small'
          value={publicReward}
          onChange={onChange}
          disabled={isDisabled}
        >
          <MenuItem value={true}> {`Public - Anyone with the link can claim the ${capitalize(rewardType[category.toUpperCase()].label)} `}</MenuItem>
          <MenuItem value={false}>{`Private - Only users in the contact Whitelist can claim the ${capitalize(rewardType[category.toUpperCase()].label)} `}</MenuItem>
        </Select>
      </FormControl>
  );
};

LinkSettings.propTypes = {
  publicReward: PropTypes.bool,
  category: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default LinkSettings;
