import React, { useState } from "react";
import { useController, useSuspense } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

import PageElementResource from "resources/organization/PageElementResource";
import RewardResource from "resources/organization/RewardResource";

import Layout from "components/layouts";
import ConfirmModal from "components/ConfirmModal";
import DragDropCtx from "components/DragDropCtx";
import DraggableList from "components/DragDropCtx/DraggableList";
import TabPanels from "components/TabPanels";

import ListItem from "ui/PageElement";
import Button from "ui/Button";
import Alert from "ui/Alert";

import ElementModal from "./ElementModal";
import { createFormData } from "./createFormData";
import { handleValidation } from "./handleValidation";
import { Placeholder } from "./Placeholder";

import { useError } from "utils/useErrorController";
import { activePasses } from 'utils/filterFunctions';
import { updateDraggableList } from "utils/updateDraggableList";
import { emptyPageElement, pageInfo } from "utils/pageElementsHelper";
import { useToast } from "utils/context/ToastContext";
import copyToClipboard from "utils/copyToClipboard";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import "./styles.css";

const actionButtons = [
  {label: "Add Link", icon: AddCircleOutlineIcon, value: "link", highlighted: true},
  {label: "Add Header", value: "title", secondary: true},
  // {label: "Add Social", value: "social", secondary: true},
]

const Element = () => {
  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const {links, socials, organization_slug} = useSuspense(PageElementResource.detail(), { organization_id: organizationId });

  const orgHasCalendar = links.find(link => link.element_type === "calendar");

  const rewards = useSuspense(RewardResource.list(), { organization_id: organizationId, });
  const passes = rewards.filter(activePasses);

  const { fetch } = useController();

  const {  handleError, loading, setLoading, validate } = useError();
  const { setOpen, setMessage, setSeverity } = useToast();

  //Destroy:
  const handleDeleteRequest = (id) => {
    setDeletingPageElement(id);
  };

  const handleDelete = async () => {
    try {
      await fetch(PageElementResource.delete(), { id: deletingPageElement, organization_id: organizationId, });
      handleSuccessfulResponse();

    } catch (error) {
      handleError(error);
    }
  };

  //Update:
  const handleDragEnd = async (result) => {
    try {
      if (result.source.droppableId === "socialElements") {
        updateDraggableList( socialElements, result.source.index, result.destination.index, setSocialElements );
      } else {
        updateDraggableList( pageElements, result.source.index, result.destination.index, setPageElements );
      }
      await fetch(PageElementResource.update(), { organization_id: organizationId, id: result.draggableId }, { position: result.destination.index });
      await fetch(PageElementResource.detail(), { organization_id: organizationId });
    } catch (error) {
      const {links, socials} = await fetch(PageElementResource.detail(), { organization_id: organizationId});
      setPageElements(createElementState(links));
      setSocialElements(createElementState(socials));
      handleError(error);
    }
  };

  const handleUpdateRequest = (element) => {
    setPageElement(element);
    setElementModal(true);
  };

  const handleUpdate = async () => {
    try {
      validate(pageElement, handleValidation);
      const formData = createFormData(pageElement);
      await fetch(PageElementResource.update(),{ organization_id: organizationId, id: pageElement.id }, formData);
      handleSuccessfulResponse();

    } catch (error) {
      handleError(error);
    }
  };

  //Create:
  const handleCreateRequest = (element_type) => {
    const newElement = { ...emptyPageElement, element_type: element_type };
    setPageElement(newElement);
    setElementModal(true);
  };

  const handleSubmit = async () => {
    try {
      validate(pageElement, handleValidation);
      const formData = createFormData(pageElement);
      await fetch( PageElementResource.create(), { organization_id: organizationId }, formData);
      handleSuccessfulResponse();

    } catch (error) {
      handleError(error);
    }
  };

  //common
  const handleSuccessfulResponse = async () => {
    const {links, socials} = await fetch(PageElementResource.detail(), { organization_id: organizationId});
    setPageElements(createElementState(links));
    setSocialElements(createElementState(socials));
    handleModalClose();
  };

  const handleModalClose = () => {
    setPageElement(null);
    setElementModal(false);
    setLoading(false);
    setDeletingPageElement(null);
  };

  const externalUrl = `${window.location.origin}/${organization_slug}`;

  const handleUrlCopy = () => {
    copyToClipboard(externalUrl, setOpen, setSeverity, setMessage)
  };

  const createElementState = (pageElements) => {
    return pageElements.map((pageElement) => ({
      id: pageElement.id,
      component: (
        <ListItem
          pageElement={pageElement}
          handleUpdateRequest={handleUpdateRequest}
          handleDeleteRequest={handleDeleteRequest}
          organizationId={organizationId}
        />
      ),
    }));
  };

  const [pageElements, setPageElements] = useState( createElementState(links) );
  const [socialElements, setSocialElements] = useState( createElementState(socials) );
  const [pageElement, setPageElement] = useState(null);
  const [deletingPageElement, setDeletingPageElement] = useState(null);
  const [elementModal, setElementModal] = useState(false);

  return (
    <Layout
      context="teacher"
      activeMenuItem="elements"
      pageInfo={pageInfo}
      cta={
          <Alert
            Icon={InfoOutlinedIcon}
            primaryText={<>
                <span className="bold">Your Club is live: </span>
                <a href={externalUrl} target="_blank" rel="noreferrer">{externalUrl}</a>
              </>
              }
            action={
              <Button onClick={handleUrlCopy} minWidth={loading} loading={loading}>
                Copy url
              </Button>
            } />
      }
    >
      <TabPanels labels={["About Page Links", "Social Media Links"]}>
        <div className="org-elements-list">
          <div className="button-group">
            {actionButtons.map((button, index) => (
              <Button key={index} icon={button.icon} highlighted={button.highlighted} secondary={button.secondary} fullwidth onClick={() => handleCreateRequest(button.value)} loading={loading}>
                {button.label}
              </Button>
            ))}
          </div>

          {links.length > 0 ? (
            <DragDropCtx handleDragEnd={handleDragEnd}>
              <DraggableList data={pageElements} droppableId="pageElements" />
            </DragDropCtx>
          ) : (
            <Placeholder />
          )}
        </div>
        <div  className="org-elements-list">
          <div className="button-group">
            <Button icon={AddCircleOutlineIcon} highlighted fullwidth onClick={() => handleCreateRequest("social")} loading={loading}>
              Add Social
            </Button>
          </div>
          {socials.length > 0 ? (
            <DragDropCtx handleDragEnd={handleDragEnd}>
              <DraggableList data={socialElements} droppableId="socialElements" />
            </DragDropCtx>
          ) : (
            <Placeholder />
          )}
        </div>
      </TabPanels>

      {pageElement && (
        <ElementModal
          open={elementModal}
          onClose={handleModalClose}
          onSubmit={pageElement.id ? handleUpdate : handleSubmit}
          pageElement={pageElement}
          setPageElement={setPageElement}
          loading={loading}
          passes={passes}
          orgHasCalendar={orgHasCalendar}
        />
      )}

      <ConfirmModal
        title={deletingPageElement && "Delete Element?"}
        open={deletingPageElement}
        onConfirm={handleDelete}
        setOpen={setDeletingPageElement}
      >
        Are you sure you want to delete this element?
      </ConfirmModal>
    </Layout>
  );
};

export default Element;
