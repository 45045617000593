import React from 'react';
import PropTypes from 'prop-types';

import Title from 'ui/text/Title';
import Description from 'ui/text/Description';
import Label from 'ui/text/Label';

import { t } from 'i18n/index';

const OrganizationDetails = ({orgTitle, orgDescription, orgImage, orgFollowers}) => {
  return (
    <>
        <img src={orgImage} alt={orgTitle} />

        <Title>{orgTitle}</Title>

        <div className="description">
        <Description>{orgDescription}</Description>
        </div>


        { orgFollowers >= 20 &&
          <Label secondary bold>{`${orgFollowers} ${t("literal:members")}`}</Label>
        }
    </>
  );
};

OrganizationDetails.propTypes = {
  orgTitle: PropTypes.string,
  orgDescription: PropTypes.string,
  orgImage: PropTypes.string,
  orgFollowers: PropTypes.number
};

export default OrganizationDetails;