import React from 'react';
import PropTypes from 'prop-types';

import Image from './Image';

import Description from 'ui/text/Description';
import SwipeableTemporaryDrawer from 'ui/SwipeableDrawer';

import './styles.css';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

const NavBar = ({ organization }) => {

  const {title, image, user_points, slug} = organization;

  return (
  <Box sx={{ flexGrow: 1 }}>
    <AppBar position="absolute" color='inherit'>
      <Toolbar className='profile-navbar'>

        <Image image={image} slug={slug}/>

        <Description>{title}</Description>

        <SwipeableTemporaryDrawer points={user_points} slug={slug} />

      </Toolbar>
    </AppBar>

  </Box>
)};

NavBar.propTypes = {
  organization: PropTypes.object,
};

export default NavBar;
