import React from 'react';
import PropTypes from 'prop-types';

import InternalEventAttributes from './InternalEventAttributes';

import FormSection from 'ui/FormSection';

import { FormGroup, Stack, Switch, Typography, FormControlLabel } from '@mui/material';
import Label from 'ui/text/Label';
import ExternalEventAttributes from './ExternalEventAttributes';

const Publishing = ({event, onChange, onEventChange, setUnsavedChanges}) => {
  const { ticketing, external_url, ticket_price, id, tickets} = event;

  const onSwitch = (event) => {
    setUnsavedChanges(true);
    onChange({
      target: {
        name: 'ticketing',
        value: !event.target.checked
      }
    });
  };

  const labels = {
    true : ["With this option you'll be able to manage the event entirely in Membrz.Club, including ticketing and check-in.", "Add the event details below and you'll be able to set up tickets and RSVP options after saving or publishing the event."],
    false: ["With this option you can set up the details and redirect your members to an external URL or ticketing partner.",  "The event will be published with the details on your calendar in the Link-in-Bio."]
  };

  return (
    <FormSection title={"Publishing Details"}>
      <FormGroup>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography>Membrz.Club</Typography>
          <FormControlLabel
            control={<Switch
                      name='status'
                      color="warning"
                      checked={!ticketing}
                      onChange={onSwitch} 
                      disabled={id}
                      />
            }/>
          <Typography>External Link</Typography>
        </Stack>
        <Label secondary>{labels[ticketing][0]}</Label>
        <Label secondary>{labels[ticketing][1]}</Label>
      </FormGroup>

      { ticketing &&
          <InternalEventAttributes
              object={event}
              onObjectChange={onEventChange}
              tickets={tickets}
              setUnsavedChanges={setUnsavedChanges}
          />
      }

      {!ticketing &&
          <ExternalEventAttributes
              external_url={external_url}
              ticket_price={ticket_price}
              onChange={onChange}
          />
      }
    </FormSection>
  );
};

Publishing.propTypes = {
  event: PropTypes.object,
  onChange: PropTypes.func,
  onEventChange: PropTypes.func,
  setUnsavedChanges: PropTypes.func
};

export default Publishing;
