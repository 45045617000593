import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'components/StatCard';

const Stats = ({ tickets }) => {

    const totalRevenue = tickets && tickets.length ? tickets[0].total_revenue : 0;
    const stat1 = tickets && tickets.length ? totalRevenue ? totalRevenue : 0 : 0;
    
    const stat2 = tickets && tickets.length ? tickets[0].total_count : 0;

    return (
        <div className="event-stats-grid grid custom-margin">

            <StatCard type='number'
                color='rgb(255, 99, 132)'
                highlight={ stat1 }
                title='Total Revenue' />

            <StatCard type='number'
                color='rgb(54, 162, 235)'
                highlight={ stat2 }
                title='Sold Tickets Count' />

        </div>
    )};

Stats.propTypes = {
    tickets: PropTypes.array
};

export default Stats;