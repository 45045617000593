import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';
import Accordion from 'ui/Accordion';

import './styles.css';

const FormSection = ({title, expandable=false, children, slim=false }) => {

  const classes = ['ui-form-section'];
  if (slim) classes.push('slim')
  
  if (expandable) {
    classes.push('expandable');
    
    return (
      <Accordion className={classes.join(' ')}
        summary={<Description bold large>{title}</Description>} 
        details={children}/>
    );
  }

  return (
    <div className={classes.join(' ')} >
      {title && <Description bold large>{title}</Description>}
      {children}
    </div>
  );
};

FormSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  expandable: PropTypes.bool,
  slim: PropTypes.bool
};

export default FormSection;