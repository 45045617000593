import React, { useState, useEffect } from "react";
import { useController } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

import PointsResource from "resources/organization/PointsResource";
import SubscriptionResource from "resources/organization/SubscriptionResource";

import DataSelectionTable from "components/DataTable/DataSelectionTable";
import ConfirmModal from "components/ConfirmModal.js";
import EmailConfirmModalContent from "components/EmailConfirmModalContent.js";
import PointsConfirmModalContent from "components/EmailConfirmModalContent.js";

import NewSubscriptionModal from "./NewSubscriptionModal";
import PointsEditor from "./PointsEditor.js";
import EmailEditor from "components/EmailEditor";
import Header from "./Header";
import SubscriptionFilter from "./SubscriptionFilter";

import { subscriptionsColumns } from "utils/tables/columns/subscriptionsColumns";
import { useError } from "utils/useErrorController";
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';
import { useEmailController } from "utils/useEmailController";
import usePaginationAndFilteringController from "utils/usePaginationAndFilteringController";

const Subscriptions = () => {
  const { fetch } = useController();

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);
  
  const [confirmModal, setConfirmModal] = useState(false);

  const [editingPoints, setEditingPoints] = useState(false);
  const [points, setPoints] = useState(0);

  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(null);

  const { handleError, loading, setLoading, validate } = useError();
  const { setOpen, setMessage, setSeverity } = useToast();

  const handlePointsCancel = () => {
    setEditingPoints(false);
    setPoints(0);
    setSelectedSubscriptions([]);
  };

  const {
    email,
    setEmail,
    editingEmail,
    handleEmailSendRequest,
    handleEmailSend,
    handleEditEmailClick,
    handleEmailCancel
  } = useEmailController(organizationId, setConfirmModal, null, null, selectedSubscriptions, setSelectedSubscriptions, handlePointsCancel);

  const {
    objectState, 
    fetchData,
    handleFilterChange,
    handleQueryChange,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: SubscriptionResource,
      fetchDataParams: { organization_id: organizationId },
  })
      
  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = (subscription) => {
    setActiveSubscription(subscription)
    setSubscriptionModal('single');
  };

  const handleClose = () => {
    setSubscriptionModal(false);
    setActiveSubscription(null);
  };

  const handleSelectionChange = (row) => {
    selectedSubscriptions.includes(row)
    ? setSelectedSubscriptions(selectedSubscriptions.filter(subscription => subscription.id !== row.id))
    : setSelectedSubscriptions([...selectedSubscriptions, row]);
  };

  const handlePointsSaveRequest = () => {
    if (selectedSubscriptions.length === 0) {
      handleError("Please select at least one user to add or remove points.");
    } else if (+points === 0) {
      handleError("Please enter a valid number of points.");
    } else {
      setConfirmModal(true);
    }
  };

  const handlePointsSave = async () => {
    try {
      setLoading(true);
      const userIds = selectedSubscriptions.map(subscription => subscription.user_id);
      await fetch(PointsResource.create(), {organization_id: organizationId}, {user_ids: userIds, points: +points})
      await fetchData();
      customToast('success', 'Points Saved', setOpen, setSeverity, setMessage);
      handlePointsCancel();
    } catch (error) {
      handleError(error);
    }
  };

  const handlePointsClick = (row) => {
    setEditingPoints(true);
    handleSelectionChange(row);
  };

  const handleEditPointsClick = () => {
    setEditingPoints(true);
    handleEmailCancel();
  };
 
  const allowUserSelection = editingPoints || editingEmail;

  return (
    <>
      <>
        <Header
          editingPoints={editingPoints}
          onEditingPointsChange={handleEditPointsClick}
          onPointsCancel={handlePointsCancel}
          editingEmail={editingEmail}
          onEditingEmailChange={handleEditEmailClick}
          onEmailCancel={handleEmailCancel}
          onSubscriptionModalChange={setSubscriptionModal}
          />

        {editingPoints &&
          <PointsEditor
            points={points}
            setPoints={setPoints}
            handlePointsSaveRequest={handlePointsSaveRequest}
            handlePointsCancel={handlePointsCancel}
            selectedSubscriptions={selectedSubscriptions}
            />
        }

        {editingEmail &&
          <EmailEditor 
            object={email}
            onObjectChange={setEmail}
            onEmailSendRequest={handleEmailSendRequest}
            onEmailCancel={handleEmailCancel}
            selectedRows={selectedSubscriptions}
            onError={handleError}
            />
        }

        <DataSelectionTable
          records={objectState.filteredObjects}
          columns={subscriptionsColumns(
            handleEdit, 
            allowUserSelection, 
            selectedSubscriptions, 
            handleSelectionChange, 
            handlePointsClick,
            objectState.filteredObjects
          )}
          onQueryChange={handleQueryChange}
          searchLabel={'Search by Name or Email'}
          queryString={objectState.query}
          serverPaginationProps={{
              paginationServer: true,
              paginationTotalRows: objectState.totalCount,
              page: objectState.page,
              onChangePage: handlePageChange
            }}

          filter={
            <SubscriptionFilter
              onChange={handleFilterChange}
            />
          }  
        />
      </>

      {subscriptionModal &&
        <NewSubscriptionModal
          open={subscriptionModal}
          onClose={handleClose}
          loading={loading}
          setLoading={setLoading}
          onError={handleError}
          fetchData={fetchData}
          record={activeSubscription}
          validate={validate} />}

      {confirmModal &&
        <ConfirmModal
              title={editingPoints ? 'Save points change?' : 'Send email?'}
              open={confirmModal}
              setOpen={setConfirmModal}
              onConfirm={ editingPoints ? handlePointsSave : handleEmailSend }
            >
              { editingPoints
                ? <PointsConfirmModalContent
                    points={points}
                    selectedUsers={selectedSubscriptions} />
                : <EmailConfirmModalContent
                    content={email?.content}
                    subject={email?.subject}
                    userCount={selectedSubscriptions?.length}
                  />
            }
        </ConfirmModal>}
    </>
  );
};

export default Subscriptions;
