import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import ShortLogo from './ShortLogo';
import OrgImage from './OrgImage';

const Image = ({image, slug}) => {

  return (
    <Link to={`/${slug}`}>
        {image
          ? <OrgImage image={image} />
          : <ShortLogo />}
    </Link>
  );
};

Image.propTypes = {
  image: PropTypes.string,
  slug: PropTypes.string
};

export default Image;
