import ApiResource from 'resources/ApiResource'

export default class AuthenticationResource extends ApiResource {
  static urlRoot = '/auth/authentication' as const;

  readonly auth_token: string = '';

  pk() {
    return this.auth_token?.toString();
  }

}
