import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { useSuspense } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useSuspense } from '@rest-hooks/react';
import GroupChatsResource from 'resources/organization/GroupChatsResource';
import copyToClipboard from 'utils/copyToClipboard';
import { useToast } from 'utils/context/ToastContext';
import Button from 'ui/Button';
import EditGroupForm from '../EditGroupForm';

const GroupsList = ({organization}) => {

  const [openEditModal, setOpenEditModal] = useState(false);
  const [groupChat, setGroupChat] = useState(null);
  const groupChats = useSuspense(GroupChatsResource.list(), { organization_id: organization.id });
  const { setOpen, setMessage, setSeverity } = useToast();

  const handleEditGroupChat = (groupId) => {
    setOpenEditModal(true);
    setGroupChat(groupChats.find(({id}) => id === groupId));
  };

  const handleCloseEdit = () => {
    setOpenEditModal(false);
    setGroupChat(null);
  };

  return (
    <>
     <List>
            {groupChats.map(({id, name, invite_url, access_tiers }) => (
                <ListItem
                    key={id}
                    sx={{backgroundColor: "#ffff", marginTop: '2px'}}>
                    <ListItemText primary={name} secondary={ access_tiers.map(({ title }) => title).join(', ')  } />

                    { access_tiers.length === 0 ?
                        <Button secondary onClick={() => handleEditGroupChat(id)}>Set Access</Button>
                      :
                      <>
                        <IconButton onClick={() => handleEditGroupChat(id)}>
                            <EditIcon color="warning" />
                        </IconButton>
                        <IconButton title='Copy Invite Link' onClick={() => copyToClipboard(invite_url, setOpen, setSeverity, setMessage)}>
                            <ContentCopyIcon color="warning" />
                        </IconButton>
                        <IconButton title='Open Invite Link' onClick={() => window.open(invite_url, '_blank')}>
                            <OpenInNewIcon color="warning" />
                        </IconButton>
                      </>
                    }
                </ListItem>
            ))}
        </List>

        {openEditModal && groupChat &&

        <EditGroupForm open={openEditModal}
                        organization={organization}
                        groupChat={groupChat}
                        onClose={ handleCloseEdit } />
        }
    </>
  )
}

GroupsList.propTypes = {
  organization: PropTypes.object
}

export default GroupsList
