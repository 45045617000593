import React from 'react';
import PropTypes from 'prop-types';

import TicketList from './TicketList';
import CapacityEditor from '../CapacityEditor';

import FormSection from 'ui/FormSection';
import Description from 'ui/text/Description';

const RecurrentEventTickets = ({event, handleDeleteRequest, handleDragEnd, handleRedirect, handleUrlCopy, onChange}) => {

    const { sessions, capacity } = event;

  return (
    <FormSection title={"Sessions and Tickets"}>
        {sessions.map((session, index) => (
            <div key={index}>
                <Description bold padded>{session.title}</Description>
                <TicketList
                    tickets={session.tickets}
                    disabled={session.over}
                    handleDeleteRequest={handleDeleteRequest}
                    handleDragEnd={handleDragEnd}
                    handleRedirect={handleRedirect}
                    handleUrlCopy={handleUrlCopy} />
            </div>
        ))}

        <CapacityEditor
            capacity={capacity}
            onChange={onChange} 
            showDivider />

    </FormSection>
  );
};

RecurrentEventTickets.propTypes = {
    event: PropTypes.object,
    handleDeleteRequest: PropTypes.func,
    handleDragEnd: PropTypes.func,
    handleRedirect: PropTypes.func,
    handleUrlCopy: PropTypes.func,
    onChange: PropTypes.func
};

export default RecurrentEventTickets;