import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';

import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import NewslettersResource from 'resources/user/NewslettersResource';
import Manage from './Manage';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FormSection from 'ui/FormSection';


const NewsletterPreferences = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const newsletters = useSuspense(NewslettersResource.list());

  const { fetch } = useController();

  const handleUpdate = (id, checked) => {
    fetch(NewslettersResource.update(), {id: id}, { mailing_list: checked });
  };

  return (
    <FormSection title={'Newsletters'}>
      <p>When you join a Club, you&apos;re automatically subscribed to the Club&apos;s newsletter.</p>
      <p>You can unsubscribe from the newsletter, and still enjoy all the club benefits as long as your membership stays active.</p>

      <List style={{ marginTop: '20px' }}>
        {newsletters.map(({ id, title, mailing_list }) => (
          <ListItem key={id}
                    onMouseEnter={() => setHoveredItem(id)}
                    onMouseLeave={() => setHoveredItem(null)}
                    sx={{ backgroundColor: "#ffff",
                          marginTop: '2px',
                          display: 'grid',
                          gridTemplateColumns: 'auto 1fr auto',
                          gap: '10px'}}
                    onMouseHover={ (e) => e.stopPropagation() }
                      >
            <ListItemIcon>
              { mailing_list ? <CheckBoxIcon color={ 'success' }/> : <CheckBoxOutlineBlankIcon /> }
            </ListItemIcon>
            <ListItemText primary={title} />
            { hoveredItem == id &&
              <Manage id={id} subscribed={mailing_list} onUpdate={handleUpdate} />
            }
          </ListItem>
        ))}
      </List>

    </FormSection>



  );
};

NewsletterPreferences.propTypes = {
    onError: PropTypes.func,
};

export default NewsletterPreferences;
