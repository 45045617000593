import React from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from './ConfirmModal';

const UnsavedChangesModal = ({open, setOpen, onConfirm}) => {
  return (
    <ConfirmModal
        title={'Discard changes?'}
        open={open}
        setOpen={setOpen}
        onConfirm={onConfirm}
    >
        You have unsaved changes. Moving pages now will cause these to be lost. Are you sure you want to continue?
    </ConfirmModal>
  )
};

UnsavedChangesModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func
};

export default UnsavedChangesModal;