import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ShareRewardModal from 'components/ShareRewardModal';
import ConfirmModal from 'components/ConfirmModal';

import { onNftScanUrl, rewardPDFDownload, rewardMediaDownload } from 'utils/urls';
import { claimState, rewardType } from 'utils/constants';
import { downloadFileFromUrl } from 'utils/downloadFile';

import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Teacher from './Teacher';
import Student from './Student';

const ActionButtons = ({ id, contract_address, role, reward, onDelete, onRedirectToMemberships, onClaim = null, published, courseId, organizationId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);

  const showManageSubscription = reward.category === rewardType.PASS.value;
  const claimedOnChain = reward.claim_state  === claimState.ONCHAIN.value;

  const showDeleteButton = reward.stats['issued'] === 0 && !published;
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
    handleClose();
  };

  const handlePDFDownload = () => {
    downloadFileFromUrl(rewardPDFDownload(id), `certificate_${id}.pdf`, 'application/pdf');
    handleClose();
  };

  const handleMediaDownload = () => {
      window.open(rewardMediaDownload(id), '_blank');
      handleClose();
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        className="badge-actions-menu"
        MenuListProps={{
          'aria-labelledby': 'badge-actions-menu',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

      { role === 'teacher' &&
        <Teacher  organizationId={organizationId}
                   courseId={courseId}
                   id={id}
                   showDeleteButton={showDeleteButton}
                   published={published}
                   onConfirmDelete={() => setConfirmDelete(true)} />
      }

      {role === "student" &&
          <Student showManageSubscription={showManageSubscription}
                   onRedirectToMemberships={() => onRedirectToMemberships(reward['organization_slug'])}
                   claimedOnChain={claimedOnChain}
                   onRedirect={() => window.open(onNftScanUrl(contract_address, id), "_blank")}
                   onClaim={ onClaim ? () => onClaim(id) : null }
                   handleOpenShareModal={handleOpenShareModal}
                   onImageDowload={handleMediaDownload}
                   onPDFDowload={handlePDFDownload}
                   />
      }
      </Menu>

      <ShareRewardModal
              open={ openShareModal }
              description={ reward.title }
              reward={ {id, ...reward} }
              onClose={ handleCloseShareModal }
              viewOnPublic={true}
            />

      <ConfirmModal
              title={`Delete ${rewardType[reward.category.toUpperCase()].label}?`}
              open={confirmDelete}
              setOpen={setConfirmDelete}
              onConfirm={ onDelete }
            >
              Are you sure you want to delete this {`${rewardType[reward.category.toUpperCase()].label}`}?
      </ConfirmModal>

    </div>
  );
};

ActionButtons.propTypes = {
  id: PropTypes.number,
  contract_address: PropTypes.string,
  role: PropTypes.string,
  reward: PropTypes.object,
  onDelete: PropTypes.func,
  onRedirectToMemberships: PropTypes.func,
  onClaim: PropTypes.func,
  published: PropTypes.bool,
  courseId: PropTypes.number,
  organizationId: PropTypes.number,
};

export default ActionButtons;
