import React from 'react';
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';
import Button from "ui/Button";

const SubmitButton = ({id, loading}) => {
  return (
    <Button minwidth loading={loading} icon={SaveIcon}>
        {!id ? "Create" : "Save"}
    </Button>
  );
};

SubmitButton.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool
};

export default SubmitButton;