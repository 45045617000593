import React from "react";
import PropTypes from "prop-types";
import ListItem from "./ListItem";

import { Stack } from '@mui/material';

import DragDropCtx from "components/DragDropCtx";
import { Droppable } from "react-beautiful-dnd";

const TicketList = ({tickets, disabled, handleDeleteRequest, handleDragEnd, handleRedirect, handleUrlCopy}) => {
  return (
    <DragDropCtx handleDragEnd={handleDragEnd}>
      <Droppable droppableId={"tickets"}>
        {(provided, snapshot) => (
          <Stack
            spacing={1}
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={`${snapshot.isDraggingOver ? "dragging-over" : ""}`}
          >
            {tickets &&
              tickets.map((ticket, index) => (
                <ListItem ticket={ticket} disabled={disabled} handleDeleteRequest={handleDeleteRequest} handleRedirect={handleRedirect} handleUrlCopy={handleUrlCopy} key={index} index={index}/>
            ))}
            {provided.placeholder}
          </Stack>
        )}
      </Droppable>
    </DragDropCtx>
  );
};

TicketList.propTypes = {
    tickets: PropTypes.array,
    disabled: PropTypes.bool,
    handleDeleteRequest: PropTypes.func,
    handleDragEnd: PropTypes.func,
    handleRedirect: PropTypes.func,
    handleUrlCopy: PropTypes.func
};

export default TicketList;
