import React from "react";
import PropTypes from "prop-types";

import { DragDropContext } from "react-beautiful-dnd";
import './styles.css'

const DragDropCtx = ({ children, handleDragEnd }) => {
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {children}
    </DragDropContext>
  );
};

DragDropCtx.propTypes = {
  children: PropTypes.node,
  handleDragEnd: PropTypes.func,
};

export default DragDropCtx;
