const generateMetatags = (
        page,
        setSocialMetatags,
        organization_title,
        organization_logo,
        customTitle,
        customDescription,
        customImage,
    ) => {

    if (!organization_title) return;

    let title = '';
    let description = '';

    const image = customImage || organization_logo;

    switch (page) {
        case 'singlePost':
            title = customTitle ? customTitle : `${organization_title} Newsletter`;
            description = customDescription || `Check out ${organization_title}'s latest post!`;
            break;
        case 'posts':
            title = `${organization_title} Newsletter`;
            description = `Follow & subscribe to ${organization_title}'s newsletter with its latest updates & content.`;
            break;
        case 'singleEvent':
            title = customTitle;
            description = customDescription || `Check out ${organization_title}'s upcoming events!`;
            break;
        case 'events':
            title = `${organization_title} Events`;
            description = `Check out the agenda and buy tickets for ${organization_title}'s upcoming events.`;
            break;
        case 'singleProduct':
            title = customTitle ? customTitle : `${organization_title} Store`;
            description = customDescription || `Check out and shop ${organization_title}'s unique products and perks.`;
            break;
        case 'store':
            title = `${organization_title} Store`;
            description = `Check out and shop ${organization_title}'s unique products and perks.`;
            break;
        case 'memberships':
            title = `${organization_title} Membership Options`;
            description = `Check out and sign up to ${organization_title}'s membership options.`;
            break;
        default:
            title = `${organization_title}'s Membrz Club`;
            description = `View ${organization_title}'s Membrz Club. Get access to exclusive content, events, and more.`;
            break;
        }

        setSocialMetatags(title, description, image, window.location.href);
};

export default generateMetatags;
