import React from "react";

import TableButton from "components/TableButton";
import { getDateFormat } from "utils/tables/getDateFormat";
import { getTableButtons } from "utils/tables/getTableButtons";
import { getRedeemLabel } from "utils/tables/getRedeemLabel";
import isMobileDevice from "utils/mobileAndTabletCheck";

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Tooltip } from "@mui/material";

export const activeOffersColumns = (handleDeleteRequest, handleUrlCopy, handlePreview, loading, organizationTags) => {

  let columns = [{
    field: "title",
    name: "Title",
    selector: (row) => row.title,
    sortable: true,
    width: "100%",
  },
  {
    width: "100%",
    selector: (row) => (
      <div className="active-offers-buttons">
        {getTableButtons(row, handleDeleteRequest, handleUrlCopy, handlePreview, loading, organizationTags).map((button, index) => (
          <TableButton
            title={button.title}
            Icon={button.icon}
            link={button.link}
            action={button.action}
            disabled={button.disabled}
            key={index}/>
        ))}
        <Tooltip title={'Reorder'}>
          <DragIndicatorIcon fontSize='small' color="warning" />
        </Tooltip>
      </div>
    )
  }];

  if (!isMobileDevice()) {
    const additionalColumns = [
        {
          field: "redeemed",
          name: "Redeemed",
          selector: (row) => getRedeemLabel(row.redeemed, row.supply_limit),
          sortable: true,
          width: "20%",
        },
        {
          field: "end_date",
          name: "End Date",
          selector: (row) => getDateFormat(row.end_date),
          //not a react data table so no need for format/selection/sortable as not sortable
          // format: (row) => getDateFormat(row.end_date),
          width: "20%",
        },
      ]

      const modifiedColumns = [
        {
          ...columns[0],
          width: "40%"
        },
        ...additionalColumns,
        {
          ...columns[1],
          width: "20%"
        },
      ];

    columns = modifiedColumns;
  }

  return (columns);
};
