import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class OrganizationUserResource extends AuthenticatedResource {
  static urlRoot = '/admin/organization_users'

  readonly id: number | undefined = undefined;
  readonly name: string = '';
  readonly email: string = '';
  readonly role: string = '';
  readonly organization_id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}