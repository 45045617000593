import { useParams } from 'react-router-dom';
import { useSuspense } from '@rest-hooks/react';
import OrganizationResource from 'resources/organization/OrganizationResource';

function useOrganizationAdmin() {

  const params = useParams();
  const organizationId = parseInt(params.organizationId);
  const organization = useSuspense(OrganizationResource.detail(), { id: organizationId });

  return organization;
}

export default useOrganizationAdmin;
