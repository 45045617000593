import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Content from './Content';
import Actions from './Actions';

const EditorModal = ({ object, onObjectChange, open, done, onClose }) => {
  
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth maxWidth='md'
    >  
      <DialogTitle id="confirm-dialog">Email Editor</DialogTitle>
      
      <Content object={object} onObjectChange={onObjectChange} />
      
      <Actions onClose={onClose} done={done} />
      
    </Dialog>
  );
};

EditorModal.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  open: PropTypes.bool,
  done: PropTypes.func,
  onClose: PropTypes.func
};

export default EditorModal;