import React from 'react';
import PropTypes from 'prop-types';

import { t } from 'i18n/index';

const OrderCardHeader = ({item}) => {

  return (
    <div className='fullwidth-card-header'>
        <h2>{item.reward_title}</h2>
        <p>{item.reference ? `# ${item.reference}` : t('orderCard:referenceNotFound')}</p>
    </div>
  );
};

OrderCardHeader.propTypes = {
    item: PropTypes.object,
};

export default OrderCardHeader;