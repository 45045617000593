import { validateStringField } from "utils/validations/validateStringField";

const handleValidation = (event) => {
    const { title, start_date, single, end_date, start_time, repeat_days, session_tickets, id } = event;

    validateStringField(title, "a valid title");
    validateStartDate(start_date)
    if (!single && !id) validateRecurringEvent(start_time, end_date, repeat_days, session_tickets);
};

const validateRecurringEvent = (start_time, end_date, repeat_days, session_tickets) => {
    validateStartTime(start_time);
    validateRepeatDays(repeat_days);
    validateEndDate(end_date);
    validateSessionTickets(session_tickets);
};

const validateSessionTickets = (session_tickets) => {
    if (session_tickets.length === 0) {
        throw "To create a recurring event, you must add at least one ticket type.";
    }
    const hasValidTitle = session_tickets.some(ticket => ticket.title && ticket.title.trim() !== "");
    if (!hasValidTitle) {
        throw "To create a recurring event, at least one ticket must have a non-empty title.";
    }
};

const validateRepeatDays = (repeat_days) => {
    if (repeat_days.length === 0) {
        throw "At least one repeat day must be selected.";
    }
};

const validateStartTime = (start_time) => {
    if (!start_time) {
        throw "Start time must not be empty.";
    }
};

const validateEndDate = (date) => {
    if (!date) {
        throw "End date must not be empty.";
    }
};

const validateStartDate = (date) => {
    if (!date) {
        throw "Start date must not be empty.";
    }
};
    
export default handleValidation;