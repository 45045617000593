import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Labels from './Labels';
import Media from './Media';
import Stats from './Stats';
import ClaimStatus from './ClaimStatus';
import ActionButtons from './ActionButtons';

import { isRewardUnavailable } from 'utils/rewards';

import 'assets/css/badge.css';

const BadgeCard = ({ id, course_id, title, course, claim_state="unclaimed", category="behaviour", media_type, image_url, video_url,
                      external_url, subscription_status, subscription_end_date, fixed=false, stats={}, onDelete=null, onRedirect=null, onClaim=null,
                      isTemplate, isPublic, active_state, supply_limit, end_date, mysteryBox, published, pricings, organization_slug, organization_title, contract_address, isInstance=false}) => {

  const [isHovering, setIsHovering] = useState(true);

  const params = useParams();
  const organizationId = params.organizationId ? parseInt(params.organizationId) : null;

  const courseId = params.courseId ? parseInt(params.courseId) : course_id;
  const reward = {
    supply_limit,
    stats: {
      issued: stats.issued
    },
    end_date,
    active_state
  };

  const unavailable = isRewardUnavailable(reward);
  const showSendButton = isHovering && !mysteryBox;
  const isTeacher = !isInstance;
  const isStudent = isInstance;
  const showActionButtons = true;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
  <div className={`card badge-card ${category == "achievement" ? "double" : ""} ${fixed ? "fixed" : ""} ${(!active_state && !isStudent) ? "grey" : ""}`}
       onMouseOver={handleMouseOver}
       onMouseLeave={handleMouseLeave} >

       <Labels category={category}
               isTemplate={ isTemplate }
               isPublic={ isPublic }
               isTeacher={ isTeacher }
               isStudent={ isStudent }
               claimState={ claim_state }
               subscriptionStatus={ subscription_status }
               subscriptionEndDate={ subscription_end_date }
               />

       { isHovering &&
          <div className="badge-actions-wrapper">
            { showActionButtons &&
            <ActionButtons  id={ id }
                            role={ isTeacher ? 'teacher' : 'student' }
                            reward={ {image_url, title, external_url, claim_state, category, course, stats, media_type, organization_slug} }
                            courseId={ courseId }
                            organizationId={ organizationId }
                            onDelete={ onDelete }
                            onRedirectToMemberships={ onRedirect }
                            onClaim={ onClaim }
                            published={ published }
                            contract_address={ contract_address }
                            />
            }
          </div>
        }

      <Media  id={ id }
              title={ title }
              media_type={ media_type }
              image_url={ image_url }
              video_url={ video_url } />


      { isTeacher &&
      <>
        <p className="title">{ title }</p>
        <Stats  id={ id }
                organizationId={ organizationId }
                courseId={ courseId }
                category={ category }
                unavailable={ unavailable }
                active_state={ active_state }
                supply_limit={ supply_limit }
                stats={ stats }
                showSendButton={ showSendButton }
                pricings={ pricings }
                />
      </> 
      }

      { isStudent &&
        <>
          <p className="title">{ organization_title }</p>
          <p>{ title }</p>
          <ClaimStatus  id={ id }
                category={ category }
                claim_state={ claim_state }
                external_url={ external_url }
                organization_slug={ organization_slug }
                onClaim={ onClaim } />
        </>     
      }
  </div>
)};

BadgeCard.propTypes = {
  id: PropTypes.number,
  course_id: PropTypes.number,
  title: PropTypes.string,
  claim_state: PropTypes.string,
  category: PropTypes.string,
  media_type: PropTypes.string,
  image_url: PropTypes.string,
  video_url: PropTypes.string,
  external_url: PropTypes.string,
  subscription_status: PropTypes.string,
  subscription_end_date: PropTypes.string,
  fixed: PropTypes.bool,
  stats: PropTypes.object,
  onDelete: PropTypes.func,
  onRedirect: PropTypes.func,
  onClaim: PropTypes.func,
  isTemplate: PropTypes.bool,
  isPublic: PropTypes.bool,
  course: PropTypes.string,
  active_state: PropTypes.bool,
  supply_limit: PropTypes.number,
  end_date: PropTypes.object,
  mysteryBox: PropTypes.bool,
  published: PropTypes.bool,
  pricings: PropTypes.array,
  organization_slug: PropTypes.string,
  organization_title: PropTypes.string,
  isInstance: PropTypes.bool,
  contract_address: PropTypes.string
};

export default BadgeCard;

