import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class PromoCodeResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/promo_codes' as const;

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}
