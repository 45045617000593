import SearchOrderInstanceResource from "resources/organization/SearchOrderInstanceResource";
import MembershipResource from "resources/Profile/MembershipResource";
import ProfileResource from "resources/Profile/ProfileResource";
import ProductResource from "resources/Profile/ProductResource";

import { t } from "i18n/index";

export const findOrderResult = async (
  paymentIntentId,
  fetch,
  handleStep,
  handleError,
  handleLoading,
  handleContentChange,
  handleShowTicket,
) => {
 
  try {
    const response = await fetch(SearchOrderInstanceResource.detail(), {
      organization_id: 1,
      id: paymentIntentId,
    });

    const {organization_slug, order_id_main, uuids, order_id, content_text} = response;

    if (order_id === order_id_main) {
      
      if (uuids) handleShowTicket(uuids);

      await fetch(MembershipResource.list(), {
        organization_slug: organization_slug,
      });
      await fetch(ProfileResource.detail(), {
        id: organization_slug,
      });
      await fetch(ProductResource.list(), {
        organization_slug: organization_slug,
      })
      handleStep(2);
      handleContentChange(content_text);
      handleLoading(false);

    } else {
      handleError(t("errors:failedToFindOrderResult"));
    }
  } catch (error) {
      handleError(t("errors:failedToFindOrderResult"));
  }
};
