import React from "react";
import PropTypes from "prop-types";

import { socialTypes } from "utils/pageElementsHelper";

import { FormControl, InputLabel, MenuItem, Select, } from "@mui/material";

const SocialSelector = ({ element_type, onChange }) => {

  return (
      <FormControl fullWidth >
        <InputLabel color="warning" size="small">Network</InputLabel>
        <Select
          label="Network"
          color="warning"
          name="element_type"
          size="small"
          value={element_type === "social" ? null : element_type}
          onChange={onChange}
        >

          {socialTypes.map((type, index) => (
            <MenuItem key={index} value={type.value}>
              {type.title}
            </MenuItem>
          ))}

        </Select>

      </FormControl>
  );
};

SocialSelector.propTypes = {
  element_type: PropTypes.string,
  onChange: PropTypes.func,
  passes: PropTypes.array,
};

export default SocialSelector;
