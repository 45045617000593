import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

const Header = ({ editingPoints, onEditingPointsChange, onPointsCancel, editingEmail, onEditingEmailChange, onEmailCancel, onSubscriptionModalChange }) => {

    const pointsButtonAction = editingPoints ? onPointsCancel : () => onEditingPointsChange(true);
    const pointsButtonChildren = editingPoints ? 'Cancel' : 'Manage Points';

    const emailButtonAction = editingEmail ? onEmailCancel : () => onEditingEmailChange(true);
    const emailButtonChildren = editingEmail ? 'Cancel' : 'Send Email';

  return (
    <div className="community-tab-header">
        <Button highlighted onClick={() => onSubscriptionModalChange('single')} fullwidth >
            Add Member
        </Button>
        <Button highlighted onClick={() => onSubscriptionModalChange('import')} fullwidth>
            Import Members
        </Button>
        <Button secondary={editingPoints} onClick={pointsButtonAction} fullwidth>
            {pointsButtonChildren}
        </Button>
        <Button secondary={editingEmail} onClick={emailButtonAction} fullwidth>
            {emailButtonChildren}
        </Button>
    </div>
  );
};

Header.propTypes = {
  editingPoints: PropTypes.bool,
  onEditingPointsChange: PropTypes.func,
  onPointsCancel: PropTypes.func,
  editingEmail: PropTypes.bool,
  onEditingEmailChange: PropTypes.func,
  onEmailCancel: PropTypes.func,
  onSubscriptionModalChange: PropTypes.func
};

export default Header;