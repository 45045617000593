import React from "react";
import PropTypes from "prop-types";


import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { validEmailRegex } from "utils/validations/validateEmail";

const Body = ({ rows, fullNameTable }) => {
    
  const emailRowStyle = (email) => {
    if (email === undefined || !email.match(validEmailRegex)) {
      return { color: "red" };
    }
  };

  return (
    <TableBody>
      {rows.slice(0, 10).map((item, key) => (
        <TableRow key={key}>
          {fullNameTable ? (
            <TableCell component="th" scope="row">
              {item.full_name || item.name}
            </TableCell>
          ) : (
            <>
              <TableCell component="th" scope="row">
                {item.first_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {item.last_name}
              </TableCell>
            </>
          )}

          <TableCell style={emailRowStyle(item.email)}>{item.email}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

Body.propTypes = {
  rows: PropTypes.array,
  fullNameTable: PropTypes.bool,
};

export default Body;
