import React from 'react'
import PropTypes from 'prop-types'
import AttachedVideo from './AttachedVideo'

const Videos = ({ video_urls }) => {

  if (!video_urls || video_urls.length === 0) return null;

  return (
    video_urls.map((videoUrl, index) => (
        <AttachedVideo key={`video-${index}`} videoUrl={videoUrl} />
    ))
  )
};

Videos.propTypes = {
  video_urls: PropTypes.array
};

export default Videos;