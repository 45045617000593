import React from "react";
import PropTypes from "prop-types";

import Chips from "./Chips";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const LockSelector = ({ passes, title="Visible to:", reward_ids, onChange, isDisabled=false, showPrivate=true }) => {

  const handleSelect = (event) => {
    const { value } = event.target;
    let newValue;

    if (value.at(-1) === "private") {
      newValue = ["private"].concat(passes.map((item) => item.id));
    } else if (value.at(-1) === "public") {
      newValue = ["public"];
    } else if (value) {
      newValue = value.filter(item => item !== "public" && item !== "private");
    } else {
      newValue = value;
    }

    onChange({target: {name: "reward_ids", value: newValue}});
  };

  const selectInput = <OutlinedInput id="select-multiple-chip" label="Chip" />;
  const selectRenderValue = (selected) => {
    return (
      <Chips selected={selected} passes={passes} />
    )
  };

  const tiersListStyles = { 
    maxHeight: "32px", 
    ...(showPrivate && { marginLeft: "16px" }) 
  };

  const publicLabel = showPrivate ? "Public - Everyone" : "Public & Free Members";
  
  return (
      <FormControl sx={{ width: "100%" }}>
        <InputLabel color="warning">{title}</InputLabel>
        <Select
          fullWidth
          multiple
          color="warning"
          size="small"
          label={title}
          disabled={isDisabled}
          onChange={handleSelect}
          value={reward_ids || []}
          input={selectInput}
          renderValue={selectRenderValue}
        >
          
          <MenuItem value={"public"} style={{ maxHeight: "32px" }}c>
            <Checkbox checked={reward_ids?.includes("public")} />
            {publicLabel}
          </MenuItem>

          { showPrivate && <MenuItem value={"private"} style={{ maxHeight: "32px" }}>
            <Checkbox checked={reward_ids?.includes("private")} />
            Private - Members Only
          </MenuItem>}

          {passes.map(({id, title}) => (
            <MenuItem
              key={id}
              value={id}
              style={tiersListStyles}
            >
              <Checkbox checked={reward_ids?.includes(id)} />
              <ListItemText primary={title} />
            </MenuItem>
          ))}

        </Select>
      </FormControl>
  );
};

LockSelector.propTypes = {
  passes: PropTypes.array,
  title: PropTypes.string,
  reward_ids: PropTypes.array,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  showPrivate: PropTypes.bool
};

export default LockSelector;
