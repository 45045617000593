import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useController } from "@rest-hooks/react";

import AuthenticationResource from 'resources/auth/AuthenticationResource';
import UserAccountResource from "resources/user/UserAccountResource";

import ConnectWalletButton from "components/authForms/WalletConnect/ConnectWalletButton";

import Button from "ui/Button";

import AuthToken from 'utils/localStorage/AuthToken';
import CoursesList from 'utils/localStorage/CoursesList';
import UserName from 'utils/localStorage/UserName';

import { DialogActions } from "@mui/material";

import { t } from 'i18n/index';
import { validateEmail } from "utils/validations/validateEmail";

const Actions = ({ onClose, done, isLoggedIn, handleError, step, setStep, email, loading, setLoading }) => {

  const { fetch } = useController();

  const handleSignUp = async ({wallet_address, challenge, signature}) => {
    try {
      setLoading(true);
      const data = new FormData();
      data.append('wallet_address', wallet_address);
      data.append('challenge', challenge);
      data.append('signature', signature);
      data.append('challenge_type', 'signup');

      const { auth_token, user, courses } = await fetch(AuthenticationResource.create(), data );

      if (auth_token){
        AuthToken.write(auth_token);
        const { name } = user;
        CoursesList.write(courses);
        UserName.write(name);
        setStep(2);
        setLoading(false);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      validateEmail(email, null, t('errors:emailMissing'));
      await fetch(UserAccountResource.update(), {id: 1}, {email: email});
      done();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (step === 1) {
      document.getElementsByClassName('connect-wallet-button')[0].click();
    }
  }, []);

  return (
    <DialogActions sx={{ gap: '10px' }}>

      <Button
        minwidth
        secondary
        onClick={onClose}>
          {t('literal:cancel')}
      </Button>

      {step === 1 &&
        <ConnectWalletButton
          challengeType={isLoggedIn ? 'claim' : 'signup'}
          done={isLoggedIn ? () => setStep(2) : handleSignUp}
          loading={loading}
          />}

      {step === 2 &&
        <Button
          minwidth
          onClick={handleSubmit}
          loading={loading}>
            {t('literal:submit')}
        </Button>}

    </DialogActions>
  );
};

Actions.propTypes = {
    onClose: PropTypes.func,
    done: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    handleError: PropTypes.func,
    step: PropTypes.number,
    setStep: PropTypes.func,
    email: PropTypes.string,
    loading: PropTypes.bool,
    setLoading: PropTypes.func
};

export default Actions;
