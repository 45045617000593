import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';

import RewardResource from 'resources/organization/RewardResource';

import Button from 'ui/Button';

import { rewardType } from 'utils/constants.js';
import TierSelector from './TierSelector';
import { useError } from 'utils/useErrorController';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import GroupChatsResource from 'resources/organization/GroupChatsResource';

const EditGroupForm = ({ organization, groupChat, open, onClose  }) => {
    const rewards = useSuspense(RewardResource.list(), {organization_id: organization.id});
    const tiers = rewards.filter(reward => reward.category === rewardType.PASS.value )
                          .map(({ id, title }) => ({ id, title }));

    const [accessTiers, setAccessTiers] = useState(groupChat?.access_tiers.map(({ id }) => id));

    const {loading, setLoading, handleError} = useError();
    const { fetch } = useController();

    const handleSave = async () => {
        try {
            setLoading(true);
            await fetch(GroupChatsResource.update(), { organization_id: organization.id, id: groupChat.id }, { access_tiers: accessTiers });
            setLoading(false);
            onClose();

        } catch (error) {
            setLoading(false);
            handleError(error);
        }
     };

  return (
    <Dialog open={open} fullWidth={ true } maxWidth='sm'>

        <DialogTitle>
            Set access tiers for {groupChat.name}
        </DialogTitle>

        <DialogContent dividers>
        <TierSelector allTiers={tiers}
                          accessTiers={accessTiers}
                          setGroupTiers={ setAccessTiers } />
        </DialogContent>



        <DialogActions>
          <Button secondary onClick={onClose}> Cancel </Button>
          <Button loading={loading} onClick={ handleSave }>
            Save
          </Button>
        </DialogActions>


    </Dialog>
  );
};

EditGroupForm.defaultProps = {
    groupChat: {},
};

EditGroupForm.propTypes = {
    organization: PropTypes.object,
    groupChat: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default EditGroupForm;
