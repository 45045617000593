import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';
import formattedDate from 'utils/formattedDate';

const Date = ({date, single}) => {

  const day = `${formattedDate(date, "day")}${single ? '' : '+'}` ;
  const month = formattedDate(date, "month");

  return (
    <div className='ui-date'>
        <div className='day'>{day}</div>
        <div className='month'>{month}</div>
    </div>
  )
};

Date.propTypes = {
    date: PropTypes.string,
    single: PropTypes.bool
};

export default Date;
