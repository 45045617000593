import React from "react";
import Link from "@mui/material/Link";
import { termsOfUseLink, privacyPolicyLink } from "utils/urls";
import { t } from "i18n/index";

export const TCLabel = (
  <p className="label-in-checkbox">
    {t("tcLabel:agreement")}&nbsp;
    <Link href={termsOfUseLink} target="_blank">
      {t("literal:termsAndConditions")}
    </Link>
    &nbsp;{t("literal:and_sc")}&nbsp;
    <Link href={privacyPolicyLink} target="_blank">
      {t("literal:privacyPolicy")}
    </Link>
  </p>
);
