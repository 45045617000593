import React from 'react';
import PropTypes from 'prop-types';

import Subject from './Subject';
import Body from './Body';

import Label from 'ui/text/Label';

const WelcomeEmail = ({ newsletterSettings, onChange, onUnsavedChanges, onEmailReset}) => {

  const { welcome_email } = newsletterSettings;
  
  const handleSubjectChange = ({target: {name, value}}) => {
    onUnsavedChanges(true);
    const newWelcomeEmail = { ...welcome_email, [name]: value };
    const newSettings = { ...newsletterSettings, welcome_email: newWelcomeEmail };
    onChange(newSettings);
  };

  const handleBodyChange = (newWelcomeEmail) => {
    onUnsavedChanges(true);
    const newSettings = { ...newsletterSettings, welcome_email: newWelcomeEmail };
    onChange(newSettings);
  };

  return (
    <>
      <Subject subject={welcome_email.subject} onSubjectChange={handleSubjectChange} />

      {/* Keep label to ensure spacing between elements */}
      <Label secondary padded>
        Change your default Welcome Email. <br />
        This will be sent when a new user joins your club or when you add members manually if you select the option  &apos;Yes, Send Default Email &apos; <br/>
      </Label>

      <Body email={welcome_email} onChange={handleBodyChange} onEmailReset={onEmailReset} />
    </>
  );
};

WelcomeEmail.propTypes = {
  newsletterSettings: PropTypes.object,
  onChange: PropTypes.func,
  onUnsavedChanges: PropTypes.func,
  onEmailReset: PropTypes.func,
};

export default WelcomeEmail;
