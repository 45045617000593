import React from 'react';
import PropTypes from 'prop-types';

import Subtitle from "ui/text/Subtitle";
import Button from "ui/Button";
import Label from 'ui/text/Label';

import AuthToken from 'utils/localStorage/AuthToken';
import { useFreeSubscription } from 'utils/freeSubscriptionController';

import { t } from "i18n/index";

const CardHeader = ({title, external_contract_address, claim_token, shownPrice, already_issued_to, already_a_paying_member, handleClick, loading, activeFrequency}) => {

  const isFree = shownPrice === t('literal:free').toUpperCase();
  const isLoggedIn = !!AuthToken.read()
  const buttonChild = isFree ? shownPrice : shownPrice + ' / ' + t(`profile:memberships:paymentFrequency:${activeFrequency}`)

  const {openFreeSubscriptionModal} = useFreeSubscription();

  const renderButton = (buttonChild, claimingWithNFT) => {
    if (!buttonChild) return null;

    const disabled = already_issued_to || (already_a_paying_member && claimingWithNFT);

    return (
      <Button
        highlighted
        loading={loading}
        fullwidth={true}
        disabled={disabled}
        onClick={isFree && !isLoggedIn
                    ? () => openFreeSubscriptionModal()
                    : (e) => handleClick(e, claim_token, claimingWithNFT)}
        >
        {buttonChild}
      </Button>
    );
  };

  return (
      <>
        <Subtitle>{title}</Subtitle>

        { external_contract_address &&
          <div className="button-with-label">
            { renderButton(t('literal:claimWithNFT'), true) }
            <Label>or</Label>
          </div>
        }

        {renderButton(buttonChild)}
      </>
  );
};

CardHeader.propTypes = {
  title: PropTypes.string,
  external_contract_address: PropTypes.string,
  claim_token: PropTypes.string,
  shownPrice: PropTypes.string,
  already_issued_to: PropTypes.bool,
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
  activeFrequency: PropTypes.string,
  already_a_paying_member: PropTypes.bool,
  organizationSlug: PropTypes.string,
};

export default CardHeader;
