import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import IntegrationsList from './List';
import Telegram from './Telegram';
import Notion from './Notion';
import { Route, Switch } from 'react-router-dom';

const Integrations = ({match}) => {
  return (

    <>
    <Suspense fallback={ <div style={{ width: '100%', height: '100vh'}}></div> }>
      <Switch>
          <Route path={ match.path + `/telegram` } component={ Telegram } />
          <Route path={ match.path + `/notion` } component={ Notion } />
          <Route path={ match.path + `/` } component={ IntegrationsList } />
      </Switch>
    </Suspense>
    </>
  );
};

Integrations.propTypes = {
  match: PropTypes.object
};

export default Integrations;
