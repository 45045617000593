import React from 'react';
import PropTypes from 'prop-types';

import Button from "ui/Button";

const ActionButtons = ({ status, loading, onSubmit }) => {

  const buttonLabel = status === 'published' ? 'Save' : 'Publish';

  return (
    <div className="new-form-field-footer">
        <Button 
          minwidth 
          secondary
          loading={loading}
          onClick={(e) => onSubmit(e, 'draft')}>
            Save as Draft
        </Button>
        <Button 
          minwidth 
          loading={loading}
          onClick={(e) => onSubmit(e, 'published')}>
            {buttonLabel}
        </Button>
    </div>
  );
};

ActionButtons.propTypes = {
  status: PropTypes.string,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default ActionButtons;
