import React from "react";
import { useSuspense } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

import EventResource from "resources/organization/EventResource";

import EventFormWrapper from "./EventFormWrapper";

const UpdateEvent = () => {

  const pageInfo = {
    name: "Update event",
  };

  const params = useParams();
  const organizationId = parseInt(params.organizationId);
  const eventId = parseInt(params.eventId);
  const eventData = useSuspense(EventResource.detail(),{ organization_id: organizationId, id: eventId })

  const resource = EventResource.update();
  const resourceParams = { id: eventId, organization_id: organizationId };

  const toastMessage = 'Event updated successfully';

  return (
    <EventFormWrapper 
      pageInfo={pageInfo}
      organizationId={organizationId}
      eventData={eventData}
      resource={resource}
      resourceParams={resourceParams}
      toastMessage={toastMessage}
    />
  );
};

export default UpdateEvent;
