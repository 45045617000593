import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Divider, Skeleton } from '@mui/material';

import Description from 'ui/text/Description';
import Label from 'ui/text/Label';

import { currencySymbol } from 'utils/rewards';
import { convertPriceFromCents } from "utils/rewards";
import formattedDate from 'utils/formattedDate';

import { t } from "i18n/index";

const OrderSummary = ({currency, unit_price, discount_price, net_discount, quantity, frequency, imageUrl, organizationTitle, title, applicationFee, start_date, showApplicationFee}) => {
 
  const totalItemPrice            = unit_price * quantity;
  const totalDiscountAmount       = net_discount * quantity || 0;
  const totalItemPriceWithDiscount= discount_price * quantity || 0;
  const totalApplicationFeeAmount = applicationFee * quantity || 0;
  const totalPriceWithFees        = (totalItemPriceWithDiscount || totalItemPrice) + totalApplicationFeeAmount;

  const currencySign            = currencySymbol[currency];
  const formatWithCurrency      = (value) => `${convertPriceFromCents(value)} ${currencySign}`;

  const formattedUnitPrice      = formatWithCurrency(unit_price);
  const formattedTotalItemPrice = formatWithCurrency(totalItemPrice);
  const formattedDiscountAmount = totalDiscountAmount > 0 ? formatWithCurrency(totalDiscountAmount) : null;
  const formattedApplicationFee = formatWithCurrency(totalApplicationFeeAmount);
  const formattedTotalPrice     = formatWithCurrency(totalPriceWithFees);

  const applicationFeeDisplay = formattedApplicationFee || <Skeleton width={40}/>;
  const totalDueDisplay = showApplicationFee && !formattedApplicationFee ? <Skeleton width={40}/> : formattedTotalPrice;

  return (
    <div className='summary'>
        <Description bold large>{t('literal:orderSummary')}</Description>
        <div className='summary-row'>
            <div className='summary-row gap'>
                <Avatar src={imageUrl} />
                <div className='summary-column'>
                    <Description>{organizationTitle}</Description>
                    <Label>{title}</Label>
                    {start_date && <Label secondary>{formattedDate(start_date, 'datetime')}</Label>}
                </div>
            </div>
            <Description>{formattedUnitPrice} {quantity > 1 && `x ${quantity}`}</Description>
        </div>

        <Divider />

        <div className='summary-row'>
            <Description>{t(`profile:memberships:claimModal:summary:${frequency}`)}</Description>
            <Description>{formattedTotalItemPrice}</Description>
        </div>

        {formattedDiscountAmount &&
          <div className='summary-row'>
              <Description>{t(`literal:discount`)}</Description>
              <Description>- {formattedDiscountAmount}</Description>
          </div>}

        <Divider />

        {showApplicationFee &&
          <>
            <div className='summary-row'>
                <Description>{t('literal:applicationFee')}</Description>
                <Description>{applicationFeeDisplay}</Description>
            </div>
            <Divider />
          </>}

        <div className='summary-row'>
            <Description bold>{t('profile:memberships:claimModal:totalDue')}</Description>
            <Description bold>{totalDueDisplay}</Description>
        </div>
    </div>
  );
};

OrderSummary.propTypes = {
  currency: PropTypes.string.isRequired,
  unit_price: PropTypes.number.isRequired,
  discount_price: PropTypes.number,
  net_discount: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  organizationTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  applicationFee: PropTypes.number,
  start_date: PropTypes.string,
  showApplicationFee: PropTypes.bool
};

export default OrderSummary;
