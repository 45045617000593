import { t } from 'i18n/index';

export const FREQUENCY = {
  monthly: 1,
  quarterly: 3,
  yearly: 12,
  lifetime: 1,
};

export const rewardType = {
  BEHAVIOUR: {
    value: "behaviour",
    label: t('rewardType:behaviour'),
  },
  ACHIEVEMENT: {
    value: "achievement",
    label: t('rewardType:achievement'),
  },
  COLLECTIBLE: {
    value: "collectible",
    label: t('rewardType:collectible'),
  },
  MYSTERYBOX: {
    value: "mysteryBox",
    label: t('rewardType:behaviour'),
  },
  PASS: {
    value: "pass",
    label: t('rewardType:tier'),
  }
};

export const activityType = {
  tuist:   'Surprise NFT',
};

export const claimState = {
  UNCLAIMED: {
    value : 'unclaimed',
    label: t('literal:unclaimed')
  },
  ONCHAIN: {
    value : 'onchain',
    label: t('literal:onchain')
  },
  OFFCHAIN: {
    value : 'offchain',
    label: t('literal:offchain')
  }
};

export const courseType = {
  MEMBERSHIP : {
    value: "membership",
    label: "Memberships"
  },
  EVENT : {
    value: "event",
    label: "Event Badges & Proof-of-Attendance"
  },
  SELLABLECOLLECTIBLES : {
    value: "sellableCollectibles",
    label: "Digital Collectibles & Art"
  },
  MYSTERYBOX : {
    value: "mysteryBox",
    label: "Surprise NFT"
  },
  COURSE : {
    value: "course",
    label: "Course Certificates & Awards"
  },
  CHALLENGE : {
    value: "challenge",
    label: "Treasure Hunt & Challenge"
  },
  WORKSHOP : {
    value: "workshop",
    label: "Other"
  },
};

export const paymentProviders = {
  STRIPE: {
    value: "stripe",
    label: "Stripe keys",
    keys: [{
            value: "publishable_key",
            label: "Publishable Key",
            helper: `
**Publishable Key**
1. Go to the Stripe dashboard, API keys section, by visiting: [Stripe API Keys](https://dashboard.stripe.com/apikeys)
2. In the "Standard keys" section, locate and copy your "Publishable key."
                    `
          }, {
            value: "api_key",
            label: "Restricted Key",
            helper: `
**Restricted Key**

1. Go to the Stripe dashboard, API keys section, by visiting: [Stripe API Keys](https://dashboard.stripe.com/apikeys)
2. In the "Restricted keys" section, click "Create Restricted Key."
3. In the "Create Restricted Key" form:
   1. Edit the Key Name to: "Membrz.Club Restricted Key."
   2. Configure the permissions as follows:
      - Customers: "Write"
      - PaymentIntents: "Write"
      - Products: "Write"
      - Credit Notes: "Read"
      - Customer Portal: "Write"
      - Prices: "Write"
      - Subscriptions: "Write"
      - Webhook Endpoints: "Write"
   3. Click "Create Key."
4. In the "Restricted keys" section, find your newly created key, click "Reveal Key," and then copy the "Restricted Key."
                    `

          }],
  },
//   EUPAGO: {
//     value: "eupago",
//     label: "Eupago",
//     keys: [{
//             value: "api_key",
//             label: "Chave API",
//             helper: `
// **Chave API**
// - Go to: [Eupago Backoffice](https://clientes.eupago.pt/backoffice/index.html)
// - Select: Canais > Adicionar canal
//   - Nome: Membrz.Club Webhook
// - Select: Criar > Editar
// - Check: 'Receber notificação para um URL'
// - Copy URL:
//   - https://api.crowdclass.io/webhooks/eupago
// - Select: Guardar > Revelar Chave API > Copiar
//                     `,
//           }],
//   },
//   XMONEY: {
//     value: "utrust",
//     label: "Xmoney (Crypto)",
//     keys: [{
//             value: "api_key",
//             label: "API key",
//             helper:  `
// **API key**
// - Go to: [Xmoney API Keys](https://merchants.crypto.xmoney.com/integrations/api_keys)
// - Select: Type of integration: Custom (API)
// - Copy: API key
//                      `
//           },{
//             value: "webhook_secret",
//             label: "Webhook secret",
//             helper:  `
// **Webhook secret**
// - Go to: [Xmoney API Keys](https://merchants.crypto.xmoney.com/integrations/api_keys)
// - Select: Type of integration: Custom (API)
// - Copy: Webhook secret
//                      `
//           }],
//   }
};
