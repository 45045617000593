import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Card from 'ui/Card';
import Button from 'ui/Button';
import CardHeader from './CardHeader';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { t } from "i18n/index";
import Footer from './Footer';

const SingleCard = ({product, onAction, organizationSlug, organizationTags, showApplicationFee}) => {

  const { image_urls, title, description, end_date, issued_counter, supply_limit, extended_description, show_end_date, show_supply, show_view_all} = product;

  const history = useHistory();
  const handleRedirect = () => {
    history.push(`/${organizationSlug}/store`);
  };

  return (
    <div className='stores-content'>
      <div>
        <Card fullwidth
            image_urls={image_urls}
            header={ 
              <CardHeader
                title={title}
                end_date={show_end_date ? end_date : null}
                issued_counter={issued_counter}
                supply_limit={show_supply ? supply_limit: null}
              />}
            description={extended_description ? extended_description : description}
            footer={<Footer product={product} onAction={onAction} organizationTags={organizationTags} showApplicationFee={showApplicationFee} />}
        />
      </div>
      {show_view_all &&
        <Button secondary icon={ArrowBackIcon} minwidth onClick={handleRedirect}>
          {t('literal:viewAll')}
        </Button>
        }
    </div>
  );
};

SingleCard.propTypes = {
  product: PropTypes.array,
  onAction: PropTypes.func,
  organizationSlug: PropTypes.string,
  organizationTags: PropTypes.array,
  showApplicationFee: PropTypes.bool
};

export default SingleCard;
