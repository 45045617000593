import React from "react";
import { Tooltip } from '@mui/material';

export const newslettersColumns = [
    {
        field: "id",
        name: (
            <Tooltip title={'Tooltip for Post'}>
                <p>Post</p>
            </Tooltip>
        ),
        selector: (row) => row.post_title || row.post_body,
        width: "20%",
    },
    {
        field: "requests",
        name: (
            <Tooltip title={'The number of emails you requested to send via SendGrid'}>
                <p>Requests</p>
            </Tooltip>
        ),
        selector: (row) => row.requests,
        width: "120px",
    },
    {
        field: "clicks",
        name: (
            <Tooltip title={'The number of links that were clicked in your emails.'}>
                <p>Clicks</p>
            </Tooltip>
        ),
        selector: (row) => row.clicks,
        width: "100px",
    },
    {
        field: "unique_opens",
        name: (
            <Tooltip title={'Delete'}>
                <p>Unique Opens</p>
            </Tooltip>
        ),
        selector: (row) => row.unique_opens,
        width: "150px",
    },
    {
        field: "delivered",
        name: (
            <Tooltip title={'The number of emails SendGrid was able to confirm were actually delivered to a recipient.'}>
                <p>Delivered</p>
            </Tooltip>
        ),
        selector: (row) => row.delivered,
        width: "120px",
    },
    {
        field: "opens",
        name: (
            <Tooltip title={'The total number of times your emails were opened by recipients'}>
                <p>Opens</p>
            </Tooltip>
        ),
        selector: (row) => row.opens,
        width: "100px",
    },
    {
        field: "bounces",
        name: (
            <Tooltip title={'The number of emails that bounced instead of being delivered.'}>
                <p>Bounces</p>
            </Tooltip>
        ),
        selector: (row) => row.bounces,
        width: "100px",
    },
    {
        field: "spam_reports",
        name: (
            <Tooltip title={'The number of recipients who have marked your email as spam.'}>
                <p>Spam Reports</p>
            </Tooltip>
        ),
        selector: (row) => row.spam_reports,
        width: "150px",
    },
];
