const createFormData = (organization) => {
    const formData = new FormData();

    const {
        title,
        slug,
        admin_name,
        admin_email,
        currency,
        country,
    } = organization;

    const fields = [
        { key: "title", value: title },
        { key: "slug", value: slug },
        { key: "admin_email", value: admin_email },
        { key: "currency", value: currency },
        { key: "country", value: country },
    ];

    if (admin_name) {
        fields.push({ key: "admin_name", value: admin_name });
    }

    fields.forEach((field) => {
        formData.append(field.key, field.value);
    });

    return formData;
};

export default createFormData;
