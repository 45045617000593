import AuthenticatedResource from "resources/public/AuthenticatedResource";

export default class ProfileResource extends AuthenticatedResource {
    static urlRoot = '/profile' as const;

    readonly id: string | undefined = undefined;

    pk() {
        return this.id?.toString();
    }
}