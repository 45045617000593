import React from "react";
import PropTypes from "prop-types";

import TicketEditor from "./TicketEditor";
import CapacityEditor from "../CapacityEditor";

import FormSection from "ui/FormSection";
import Button from "ui/Button";

import AddCircleOutline from "@mui/icons-material/AddCircleOutline";

const SessionTicketsEditor = ({ event, onEventChange, onChange, setUnsavedChanges }) => {
  const { session_tickets, capacity } = event;

  const handleAddTicket = () => {
    setUnsavedChanges(true);
    let newEvent = { ...event, session_tickets: [...session_tickets, {}] };
    onEventChange(newEvent);
  };

  return (
    <FormSection title={"Tickets"}>
      <TicketEditor
        event={event}
        onEventChange={onEventChange}
        setUnsavedChanges={setUnsavedChanges} />

      <Button 
        onClick={handleAddTicket}
        secondary
        fullwidth
        icon={AddCircleOutline}>
          Add New Ticket
      </Button>

      <CapacityEditor
        capacity={capacity}
        onChange={onChange} />

    </FormSection>
  );
};

SessionTicketsEditor.propTypes = {
  event: PropTypes.object,
  onEventChange: PropTypes.func,
  onChange: PropTypes.func,
  setUnsavedChanges: PropTypes.func,
};

export default SessionTicketsEditor;
