import React from "react";
import PropTypes from "prop-types";

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import { currencies } from "utils/pricingsConsts";

const CurrencyField = ({currency, onCurrencyChange, isDisabled}) => {
  return (
    <FormControl>
        <InputLabel color="warning">Currency *</InputLabel>
        <Select
          label="Currency"
          name="currency"
          color="warning"
          size="small"
          fullWidth
          value={ currency }
          onChange={ onCurrencyChange }
          disabled={ isDisabled }
        >
          { Object.values(currencies).map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

CurrencyField.propTypes = {
  currency: PropTypes.string,
  onCurrencyChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default CurrencyField;