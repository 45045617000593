import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
// import { useSuspense } from '@rest-hooks/react';

// import OnboardingResource from 'resources/organization/OnboardingResource';


import Memberships from './Memberships';
// import Onboarding from './Onboarding';

const Redirect = () => {

    const params = useParams();
    const organizationId = params.organizationId;

    const history = useHistory();

    const currentPath = history.location.pathname;
    const normalizedPath = currentPath.endsWith('/') ? '' : organizationId + '/';

    // const {status} = useSuspense(OnboardingResource.detail(), {organization_id: organizationId});

    // const targetRoute = status === "started"
    //                             ? "start"
    //                             : "settings/details";

    // const TargetComponent = status === "started"
    //                             ? Onboarding
    //                             : Memberships;

    const targetRoute     = "settings/details";
    const TargetComponent = Memberships;

    history.push(`${normalizedPath}${targetRoute}`);
    return <TargetComponent />;
};

export default Redirect;
