import React, { useEffect, useState } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from "prop-types";

import ContentResource from 'resources/Profile/ContentResource';
import TagResource from 'resources/Profile/TagResource';

import PostCard from 'ui/PostCard';
import Button from 'ui/Button';

import { scrollToTop } from 'utils/scrollToTop';
import isMobileDevice from 'utils/mobileAndTabletCheck';
import { useFreeSubscription } from 'utils/freeSubscriptionController';
import AuthToken from 'utils/localStorage/AuthToken';
import { useSocialMetatags } from 'utils/socialMetatagsController';
import generateMetatags from 'utils/generateMetatags';
import { useError } from 'utils/useErrorController';

import './styles.css';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Fab } from '@mui/material';

import { t } from 'i18n/index';

const Content = ({organization_title, organization_logo}) => {

  const {organizationSlug, postSlug} = useParams();

  const searchParams = new URLSearchParams(window.location.search);
  const tagName = searchParams.get('tag');
  const organizationTags = useSuspense(TagResource.list(), {organization_slug: organizationSlug, tag_type: 'post'});
  const tagId = tagName ? organizationTags.find(tag => tag.name === tagName).id : null;

  const {fetch} = useController();
  const {handleError} = useError();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [posts, setPosts] = useState([]);

  const fetchPostsData = async () => {
    try {
      const updatedPosts = postSlug
                      ? [await fetch(ContentResource.detail(), {organization_slug: organizationSlug, id: postSlug})]
                      : await fetch(ContentResource.list(), {organization_slug: organizationSlug, page: 1, tag_id: tagId});

      setPosts(updatedPosts);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchPostsData();
    scrollToTop();
  }, [tagId]);

  useEffect(() => {
    const hasPosts = posts.length > 0;
    const hasMorePostsThanShown = hasPosts && posts.length >= posts[0].posts_count;
    if (hasMorePostsThanShown && !filteredPosts) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [posts, postSlug, tagId]);

  const {setSocialMetatags} = useSocialMetatags();

  useEffect(() => {
    const isSinglePost = postSlug && posts && posts.length > 0;
    const page = isSinglePost ? 'singlePost' : 'posts';
    const post = isSinglePost ? posts[0] : null;

    generateMetatags(page, setSocialMetatags, organization_title, organization_logo, post?.title, post?.short_description, post?.image_urls?.[0]);
  }, [postSlug, posts]);

  const filteredPosts = postSlug || tagId;

  const handlePageChange = async () => {
    if (filteredPosts) {
      history.replace(`/${organizationSlug}/content`);
    }
    const newPage = filteredPosts ? 1 : page + 1;
    const newPosts = await fetch(ContentResource.list(), {organization_slug: organizationSlug, page: newPage, tag_id: tagId});
    
    setPage(newPage);
    setPosts(filteredPosts ? newPosts : [...posts, ...newPosts]);
  };

  const showLoadMoreButton = posts.length > 0;
  const showArrowUp = page > 1 && isMobileDevice();

  const {openFreeSubscriptionModal} = useFreeSubscription();

  useEffect(() => {
    const authToken = AuthToken.read();
    const isLoggedIn = !!authToken;

    const timer = setTimeout(() => {
      if (!isLoggedIn) openFreeSubscriptionModal(t('profile:freeSubscriptionModal:promptToSubscribe') );
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
      <div className='posts-content'>

        {posts.map(( post ) => (
          <PostCard key={post.id} post={post} page={page} setPosts={setPosts} posts={posts}/>
        ))}

        <>
          { showLoadMoreButton &&
            <Button 
              onClick={() => handlePageChange()} 
              disabled={disabled}>
                {filteredPosts ? t('literal:seeLatest') : t('literal:loadMore')}
            </Button>
          }
          { showArrowUp &&
            <Fab variant="extended" className='floating-button' onClick={scrollToTop}>
              <ArrowUpwardIcon sx={{color: 'white'}}/>
            </Fab>
          }
        </>
        
      </div>
  );
};

Content.propTypes = {
  organization_title: PropTypes.string,
  organization_logo: PropTypes.string,
};

export default Content;