import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class SearchableFilterResource extends AuthenticatedResource {
  static urlRoot = '/checkin/searchable_filter'

  pk() {
    return 'searchable_filter';
  }
}