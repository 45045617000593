import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Description = ({children, secondary, bold, large, small, preWrap, className, padded}) => {

  const classes = ['ui-description'];
  if (secondary) classes.push('ui-secondary-text');
  if (bold) classes.push('ui-bold-text');
  if (large) classes.push('ui-large-text');
  if (small) classes.push('ui-small-text');
  if (preWrap) classes.push('ui-pre-wrap');
  if (padded) classes.push('ui-padded-text');
  if (className) classes.push(className);
    
  return (
    <p className={classes.join(' ')} >{children}</p>
  )
};

Description.propTypes = {
    children: PropTypes.string,
    secondary: PropTypes.bool,
    bold: PropTypes.bool,
    large: PropTypes.bool,
    small: PropTypes.bool,
    preWrap: PropTypes.bool,
    padded: PropTypes.bool,
    className: PropTypes.string
};

export default Description;