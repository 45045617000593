import React from "react";
import PropTypes from "prop-types";

import TableButton from "components/TableButton";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AdjustIcon from '@mui/icons-material/Adjust';

import { Chip, Tooltip } from "@mui/material";

const ManagmentTools = ({element, handleDeleteRequest, handleUpdateRequest}) => {

  const actionButtons = [
    {
      label: "Edit",
      icon: EditIcon,
      action: () => handleUpdateRequest(element)
    },
    {
      label: "Delete",
      icon: DeleteIcon,
      action: () => handleDeleteRequest(element.id)
    }
  ];

  const clickedCount = element.clicked_count < 999
                        ? element.clicked_count
                        : element.clicked_count < 2000
                        ? "1k+"
                        : element.clicked_count < 3000
                        ? "2k+"
                        : "3k+";
                        
  return (
    <div className="management-tools">
      {element.clicked_count > 0 && 
        <Tooltip title="Clicked Count">
          <Chip label={clickedCount} className="clicked-count" color="warning" variant="outlined" size="small" icon={<AdjustIcon />}/>
        </Tooltip>}
        
      {actionButtons.map((button) => (
        <TableButton 
          key={button.label}
          title={button.label}
          Icon={button.icon}
          action={button.action}
         /> 
      ))}

      <Tooltip title="Reorder">
        <DragIndicatorIcon color="warning" fontSize="small" />
      </Tooltip>
    </div>
  );
};

ManagmentTools.propTypes = {
  element: PropTypes.object,
  handleDeleteRequest: PropTypes.func,
  handleUpdateRequest: PropTypes.func,
};

export default ManagmentTools;
