import React, { useState } from 'react';
import ErrorPage from 'components/NetworkError/ErrorPage';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';
import AuthenticationResource from 'resources/checkin/AuthenticationResource';
import { useError } from 'utils/useErrorController';
import AuthToken from 'utils/localStorage/AuthToken';

const Unauthorized = () => {

    const [secretToken, setSecretToken] = useState('');
    const {eventUuid} = useParams();
    const {handleError} = useError();
    const {fetch} = useController();

    const handleChange = ({target: {value}}) => {
        setSecretToken(value);
    };

    const handleSubmit = async () => {
        try {
            const { auth_token } = await fetch(AuthenticationResource.create(), { id: eventUuid, secret_token: secretToken})
            AuthToken.write(auth_token);
            window.location.reload();
        } catch (error) {
            handleError(error);
        }
    };

    const page = {
        title: 'Check-in access',
        description: 'Please authenticate with the secret code you were provided below.',
        formFieldLabel: 'Secret Code',
        formFieldValue: secretToken,
        onFormFieldValueChange: handleChange,
        secondaryButtonAction: handleSubmit,
        secondaryButtonLabel: 'Submit',
      };

  return (
    <ErrorPage page={page}/>
  );
};

export default Unauthorized;
