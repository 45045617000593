import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from '@mui/icons-material/Link';
import PreviewIcon from '@mui/icons-material/Preview';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';

const getTagNames = (tagIds, organizationTags) => {
    const filteredTags = organizationTags.filter(tag => tagIds.includes(tag.id));
    const tagNames = filteredTags.map(tag => tag.name);

    return tagNames.join(', ');
  };

  export const getTableButtons = (row, handleDeleteRequest, handleUrlCopy, handlePreview, loading, organizationTags) => {
    const buttons = [
      {
        title: 'Copy URL',
        icon: LinkIcon,
        link: null,
        action: () => handleUrlCopy(row.external_url),
        disabled: loading,
      },
      {
        title: 'Preview',
        icon: PreviewIcon,
        link: null,
        action: () => handlePreview(row.external_url),
        disabled: loading,
      },
      {
        title: 'Edit',
        icon: EditIcon,
        link: `/organizations/${row.organization_id}/perks/${row.id}/edit`,
        disabled: loading,
      },
      {
        title: 'Delete',
        icon: DeleteIcon,
        link: null,
        action: () => row.redeemed > 0 ? null : handleDeleteRequest(row.id, row.title),
        disabled: row.redeemed > 0 || loading,
      },
    ];
  
    if (row.tag_ids && row.tag_ids.length > 0) {
      buttons.unshift({
        title: `Tags: ${getTagNames(row.tag_ids, organizationTags)}`,
        icon: SellOutlinedIcon,
      });
    }
  
    return buttons;
  };