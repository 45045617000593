import React from 'react';
import PropTypes from 'prop-types';
import { QrReader } from 'react-qr-reader';
import "./styles.css"

const QRCodeScanner = ({ onQRCodeRead }) => {
  const handleQRCodeResult = (result, error) => {
    if (result) {
      onQRCodeRead(result?.text);
    }
    if (error) {
      console.info("error", error);
    }
  }

  return (
      <QrReader
        constraints={ {facingMode: 'environment'} }
        onResult={handleQRCodeResult}
        style={{ width: '100%', overflow: 'hidden' }}
      />
  )
}

QRCodeScanner.propTypes = {
  onQRCodeRead: PropTypes.func
};

export default QRCodeScanner;
