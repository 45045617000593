import React from 'react';
import PropTypes from 'prop-types';

import { t } from "i18n/index";

import Button from 'ui/Button';

import CustomModal from 'components/CustomModal';

import DoneIcon from '@mui/icons-material/Done';

const ProductContentModal = ({productContent, isTicket, onClose}) => {

  const { content_text, content_link } = productContent;

  const title = isTicket ? t('profile:perks:ticketRedeemedSuccessfully') : t('profile:perks:productRedeemedSuccessfully');
  const content = content_text || (isTicket ? t('profile:perks:ticketRedeemedSuccessfullyDescription') : t('profile:perks:productRedeemedSuccessfullyDescription'));

  const customModalContent =
          { 
              title: <div>{title} <DoneIcon color='success' sx={{verticalAlign: 'text-top'}}/></div> ,
              content: content,
              button: content_link 
                          ? <Button highlighted onClick={ () => window.open(content_link, '_blank') }>Continue</Button>
                          : null,
              supportMarkdown: true
          }

  return (
    <CustomModal handleClose={onClose} customModalContent={customModalContent} />
  );
};

ProductContentModal.propTypes = {
  productContent: PropTypes.object,
  isTicket: PropTypes.bool,
  onClose: PropTypes.func
};

export default ProductContentModal;