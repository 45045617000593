import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';

const AddMemberModal = ({open, onSubmit, onClose}) => {

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const { email } = e.target;
        if (email.value) {
            onSubmit(email.value);
        }
    };

    return (
        <Dialog open={open}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",
                            },
                        },
                        }}
        >
            <DialogTitle  >
                Invite team member
                {onClose && (
                    <IconButton aria-label='close'
                                onClick={onClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500]
                                }}
                    >
                        <CloseIcon/>
                    </IconButton>
                )}
            </DialogTitle>
            <form onSubmit={ handleSubmit }>

            <DialogContent style={ { textAlign: 'center', border: '2px'} } dividers>
                <TextField
                    autoFocus
                    margin="dense"
                    name="email"
                    label="Email Address"
                    fullWidth
                    color="warning"
            />
            </DialogContent>
            <DialogActions>
                <Button color="warning" onClick={ onClose }>Cancel</Button>
                <Button variant="contained" color="primary" type="submit">Invite</Button>
            </DialogActions>
            </form>
        </Dialog>
    )
};

AddMemberModal.propTypes = {
    open: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
}

export default AddMemberModal;