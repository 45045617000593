import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useController } from '@rest-hooks/react';

import UserAccountResource from 'resources/user/UserAccountResource';
import ClaimResource from 'resources/public/ClaimResource';

import Layout from 'components/layouts/index';
import ShareRewardModal from 'components/ShareRewardModal';
import CrowdclassFallback from 'components/CrowdclassFallback';
import Ethereum from '../Settings/ConnectedAccounts/Accounts/Ethereum';
// import OnChainModal from './OnChainModal';

import MembershipsTabPanel from './MembershipsTabPanel';
// import CollectiblesTabPanel from './CollectiblesTabPanel';

import { claimState, rewardType } from 'utils/constants';
import { useConnectWalletModal, useShareModal} from 'utils/profileModals';

import { t } from 'i18n/index';
import { useError } from 'utils/useErrorController';

const pageInfo = {
  label: t('literal:profile')
};

const Profile = () => {
  const { connectIsOpen, openConnectModal, closeConnectModal } = useConnectWalletModal();
  const { shareIsOpen, openShareModal, closeShareModal } = useShareModal();


  const [loading, setLoading] = useState(true);
  const [celebration, setCelebration] = useState(false);
  const [claimableId, setClaimableId] = useState(null);
  const [account, setAccount] = useState({});
  const [rewards, setRewards] = useState([]);
  const [activeReward, setActiveReward] = useState({});

  const [passes, setPasses] = useState([]);
  // const [collectibles, setCollectibles] = useState([]);

  const { handleError } = useError();
  const { fetch } = useController();
  const history = useHistory();

  useEffect(() => fetchData(true), []);

  const fetchData = async (firstMount) => {
    try {
      const data = await fetch(UserAccountResource.detail(), {id: 1});

      setAccount(data);
      setRewards(data.rewards);
      customizePageInfo(data.name);

      const passes = data.rewards
        .filter(({ category }) => category === rewardType.PASS.value)
        .sort(({ created_at: a }, { created_at: b }) => b - a);
      setPasses(passes);

      // const collectibles = data.rewards
      //   .filter(({ category }) => category !== rewardType.PASS.value)

      // const unclaimedCollectibles = collectibles
      //   .filter( ({claim_state }) => claim_state === claimState.UNCLAIMED.value )
      //   .sort(({created_at: a}, {created_at: b} ) => {return a < b ? 1 : -1 })

      // const claimedCollectibles = collectibles
      //   .filter( ({claim_state}) => claim_state !== claimState.UNCLAIMED.value )
      //   .sort(({claimed_at: a}, {claimed_at: b} ) => {return a < b ? 1 : -1 })

      // setCollectibles(claimedCollectibles.concat(unclaimedCollectibles));

      if (firstMount) {
        const fromClaimPage = history.location.state && history.location.state.from === 'claim page';

        const unclaimed = data.rewards
          .filter(({ claim_state }) => claim_state === claimState.UNCLAIMED.value)
          .sort(({ created_at: a }, { created_at: b }) => b - a);

        const unclaimedRewardId = unclaimed.length > 0 && unclaimed[0].id;

        if (fromClaimPage && unclaimedRewardId) {
          setClaimableId(unclaimedRewardId);
          history.push('/profile');
        }
      }

      setLoading(false);
    } catch (error) {
      handleError(error)
    }
  };

  const customizePageInfo = (name) => {
    pageInfo['name'] = name;
  };

  const handleResetStates = () => {
    closeConnectModal();
    closeShareModal();
    setCelebration(false);
    setClaimableId(null);
    setActiveReward(null);
  };

  const handleRedirect = (organizationSlug) => {
    history.push(`/${organizationSlug}/memberships`);
  };

  const handleClaim = async (rewardId, rewards, force=false) => {
    const { has_wallet } = account;

    try {
      setClaimableId(rewardId);
      setActiveReward(rewards.find(reward => reward.id === rewardId ));

      if ( has_wallet || force ){
        const { claim_state } = await fetch(ClaimResource.update(), { id: rewardId });
        if (claim_state !== claimState.UNCLAIMED.value){
          openShareModal();
          setCelebration(true);
          fetchData(false);
        }
      } else {
        openConnectModal();
      }
    } catch (error) {
      handleError(error)
    }
  };

  const handleConnect = () => {
    handleClaim(claimableId, rewards, true);
    closeConnectModal();
  };

  const membershipsTabLabels = [t('literal:membershipPasses')];
  // const collectiblesTabLabels = [t('literal:digitalCollectibles')];

  return (
    <>{loading?
      <CrowdclassFallback />
    :
      <Layout context='student'
              pageInfo={ pageInfo }
              activeMenuItem='memberships' >

        {/* <OnChainModal open={ connectIsOpen }
                      onSubmit={ handleConnect }
                      onSkip={ handleConnectLater }
                      onClose={ claimStarted ? null : closeConnectModal }
                      /> */}

        <ShareRewardModal open={ shareIsOpen }
                          description={
                            <div className={`description-share-modal`} >
                              <p className={`share-modal-p`} >{t('literal:congratulations')}! 🎉</p>
                              <p className={`share-modal-label`} > {t('student:profile:shareModalMessage')} {activeReward && activeReward.title} </p>
                            </div>
                          }
                          reward={ activeReward }
                          showCelebration={ celebration }
                          onClose={ handleResetStates }
                          viewOnPublic={true}
                          />

        <MembershipsTabPanel labels={membershipsTabLabels}
                              passes={passes}
                              onRedirect={ handleRedirect }
                              onClaim={ (id) => handleClaim(id, rewards) } />

        <Ethereum open={ connectIsOpen }
                  onSuccess={ handleConnect }
                  onClose={ closeConnectModal }
                  />


        {/* <CollectiblesTabPanel labels={collectiblesTabLabels}
                              collectibles={collectibles}
                              onClaim={ (id) => handleClaim(id, rewards, walletAddress, connectLater) } /> */}

      </Layout>
    }</>
)
};

export default Profile;
