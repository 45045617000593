import React from 'react';
import PropTypes from 'prop-types';

import Supply from './Supply';
import Date from './Date';

const Settings = ({ end_date, issued_counter, supply_limit}) => {
  return (
    <div className='offer-card-settings'>
      {supply_limit && 
        <Supply issued_counter={issued_counter} supply_limit={supply_limit} />
      }
      {end_date &&
        <Date end_date={end_date} />
      }
    </div>
  );
};

Settings.propTypes = {
  end_date: PropTypes.string,
  issued_counter: PropTypes.number,
  supply_limit: PropTypes.number
};

export default Settings;