import React from "react";
import { useParams } from "react-router-dom";

import EventResource from "resources/organization/EventResource";

import EventFormWrapper from "./EventFormWrapper";

const NewEvent = () => {

  const pageInfo = {
    name: "Create new event",
  };

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const searchParams = new URLSearchParams(window.location.search);
  const singleEvent = !searchParams.has('recurring')

  const eventData = {
    title: '',
    desciption: '',
    start_date: null,
    end_date: null,
    location: '',
    status: 'draft',
    ticketing: true,
    external_url: '',
    ticket_price: '',
    slug: '',
    capacity: '',
    single: singleEvent,
    start_time: '',
    end_time: '',
    repeat_days: [],
    session_tickets: [],
  };

  const resource = EventResource.create();
  const resourceParams = { organization_id: organizationId };

  const toastMessage = singleEvent 
                          ? 'Event created successfully'
                          : 'Your request is being processed. We will email you once all sessions are created.';

  return (
    <EventFormWrapper
      pageInfo={pageInfo}
      organizationId={organizationId}
      eventData={eventData}
      resource={resource}
      resourceParams={resourceParams}
      toastMessage={toastMessage}
    />
  );
};

export default NewEvent;
