import React from 'react';
import PropTypes from 'prop-types';

import { capitalize } from 'utils/capitalize';

import moment from 'moment';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const PayoutGenerator = ({onSelect}) => {

  const generateMonthOptions = () => {
    return Array.from({ length: 3 }).map((_, i) => {
      const date = moment().locale('en').subtract(i, 'months').startOf('month');
      const label = capitalize(date.format('MMMM YY'));
      const value = date.format('YYYY-MM-DD');
      
      return { label, value };
    });
  };

  const monthOptions = generateMonthOptions();

  return (
    <FormControl size="small" sx={{ minWidth: 250 }}>
      <InputLabel color="warning">Request Payout Report</InputLabel>
      <Select
        color="warning"
        label="Request Payout Report"
        onChange={onSelect}
      >
        {monthOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

PayoutGenerator.propTypes = {
    onSelect: PropTypes.func
};

export default PayoutGenerator;