import React from 'react';
import PropTypes from 'prop-types';

import ProfileImages from 'ui/ProfileImages';
import Title from 'ui/text/Title';
import Description from 'ui/text/Description';

import './styles.css';

const Preview = ({organization, coverImageSrc, imageSrc}) => {

  const {slug, title, highlight_color, custom_cta, description} = organization;

  return (
    <div className='profile-preview'>
      <div className='browser-skeleton'>
        <span></span>
        <span></span>
        <span></span>
        <div className='url-bar'>
          membrz.club/{slug}
          <em className='preview'>preview</em>
        </div>
      </div>
      <ProfileImages coverImageSrc={coverImageSrc} profileImageSrc={imageSrc} />

      <Title bold>{title}</Title>
      <Description secondary>200 members</Description>

      <button style={{borderColor: highlight_color, backgroundColor: highlight_color}} className='ui-button' onClick={(e) => e.preventDefault()}>
          {custom_cta || 'Join the club'}
      </button>

      <Description>{description}</Description>
    </div>
  );
};

Preview.propTypes = {
  organization: PropTypes.object,
  imageSrc: PropTypes.string,
  coverImageSrc: PropTypes.string
};

export default Preview;
