import React from "react";
import PropTypes from "prop-types";
import ListItem from "ui/PageElement";

const Links = ({ links }) => {

  return (
    <>
      {links.map((pageElement, index) => (
        <ListItem key={index} pageElement={pageElement} />
      ))}
    </>
  );
};

Links.propTypes = {
  links: PropTypes.array,
};

export default Links;
