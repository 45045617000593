import React from "react";
import { useSuspense } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

import PostResource from "resources/organization/PostResource";

import PostFormWrapper from "./PostFormWrapper";

const UpdatePost = () => {

  const pageInfo = {
    name: "Update post",
  };

  const params = useParams();
  const organizationId = parseInt(params.organizationId);
  const postId = parseInt(params.postId);
  const postData = useSuspense(PostResource.detail(),{ organization_id: organizationId, id: postId })

  const resource = PostResource.update();
  const resourceParams = { id: postId, organization_id: organizationId };

  const toastMessage = 'Content updated successfully';

  return (
    <PostFormWrapper 
      pageInfo={pageInfo}
      organizationId={organizationId}
      postData={postData}
      resource={resource}
      resourceParams={resourceParams}
      toastMessage={toastMessage}
    />
  );
};

export default UpdatePost;
