import { rewardType } from "utils/constants";

const createFormData = (reward, templateVars, mysteryBox, mysteryBoxReward, published=false, isDisabled=false) => {

  const { title, description, category, template, public: isPublic, active_state, image_file, video_file,
          end_date, supply_limit, claim_limit, hide_details, pricings, metadata, points, external_contract_address } = reward;
  
  const collectible = category === rewardType.COLLECTIBLE.value;

  const ableToEditPrice = !published || (!collectible && !isDisabled);

  const formData = new FormData();
  //fields contains all that can be updated after issued on all rewards
  const fields = [
    { key: 'supply_limit', value: supply_limit },
    { key: 'active_state', value: active_state }
  ];
  //fields is added all that can be updated after issued on all rewards except mysteryBox collection's rewards
  if (!mysteryBox) {
    fields.push({ key: 'end_date', value: end_date },
                { key: 'claim_limit', value: claim_limit > 0  ? claim_limit : 1 },
                { key: 'hide_details', value: hide_details },);
  }
  if (image_file && !isDisabled) {
    fields.push({ key: 'image', value: image_file });
  }
  if (video_file && !isDisabled) {
    fields.push({ key: 'video', value: video_file },
                { key: 'media_type', value: 'video' },
    );
  } 
  if (image_file && !video_file && !isDisabled) {
    fields.push({ key: 'media_type', value: 'image' })
  }

  if (!isDisabled){
    fields.push(
                { key: 'title', value: title },
                { key: 'description', value: description },
                { key: 'category', value: mysteryBoxReward ? 'mysteryBox' : category },
                { key: 'template', value: template },
                { key: 'public', value: isPublic },
                { key: 'template_config', value: JSON.stringify(templateVars) },
                { key: 'points', value: points })
  }

  fields.forEach(field => {
    formData.append(field.key, field.value);
  });

  if (ableToEditPrice) {
    pricings.forEach( pricing => {
      formData.append('pricing_config[]', JSON.stringify(pricing));
    });
    if (external_contract_address) {
      formData.append('external_contract_address', external_contract_address)
    }
  }

  metadata.filter( ({key, value}) => key && value) // remove last one or empty rewards
            .forEach( property => {
              formData.append('metadata[]', JSON.stringify(property));
            });

  return formData;
};

export default createFormData;