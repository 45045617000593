import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import ProductResource from 'resources/Profile/ProductResource';

import ConfirmModal from 'components/ConfirmModal';

import Content from './Content';

import { useError } from 'utils/useErrorController';

import { t } from "i18n/index";

const ConfirmationModal = ({ open, product, organizationSlug, userPoints, eventTitle, onSuccessfulRedeem, onClose, loading }) => {

  const [redeemMotive, setRedeemMotive] = useState(null);

  const {setLoading, handleError} = useError();
  const {fetch} = useController();

  const isSL = organizationSlug === 'unicornfactorylisboa';

  const title = eventTitle 
      ? `${t('literal:confirm')} RSVP to ${eventTitle}?`
      : `${t('literal:redeem')} ${product.title}?`;

  const handleRedeemAttempt = async () => {
    try {
      setLoading(true);

      const params = {product_id: product.id, redeem_method: 'points'};
      if (isSL && redeemMotive) params['motive'] = redeemMotive;

      const {id, content_text, content_link} = await fetch(ProductResource.create(), {organization_slug: organizationSlug}, params);

      if (id) {
        onSuccessfulRedeem(content_text, content_link);
      }
      
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <ConfirmModal
      title={title}
      open={ open }
      onConfirm={ handleRedeemAttempt }
      setOpen={ () => { return } }
      handleClose={ onClose }
      loading={ loading }
    >
      <Content 
        product={product}
        onRedeemMotiveChange={isSL ? setRedeemMotive : null}
        eventTitle={eventTitle}
        userPoints={userPoints}
      />
    </ConfirmModal>
  );
};

ConfirmationModal.propTypes = {
  open: PropTypes.bool,
  product: PropTypes.bool,
  perk: PropTypes.object,
  userPoints: PropTypes.number,
  organizationSlug: PropTypes.string,
  eventTitle: PropTypes.string,
  onSuccessfulRedeem: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool
};

export default ConfirmationModal;