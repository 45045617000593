import React from 'react';
import PropTypes from 'prop-types';

import { AccordionDetails, AccordionSummary } from "@mui/material";
import MUIAccordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from '@mui/material/styles';

const CustomAccordion = styled(({className, ...props}) => (
    <MUIAccordion 
        className={className}
        disableGutters 
        {...props}/>
        ))(() => ({
            '&:before': { display: 'none' },
            borderRadius: 'var(--radius)',
            boxShadow: 'none',
            '.MuiAccordionSummary-root': {
                padding: 0,
                minHeight: 27,
            },
            '.MuiAccordionSummary-content': {
                margin: 0,
            },
            '.Mui-expanded': {
                marginBottom: '10px',
            },
            '.MuiAccordionDetails-root': {
                padding: 0
            },
            '.ui-padded-text': {
                marginTop: 10
            }   

    }));

const Accordion = ({summary, details, className}) => {
  return (
    <CustomAccordion className={className}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />} 
        >
          {summary}
        </AccordionSummary>
        <AccordionDetails> 
          {details}
        </AccordionDetails>
      </CustomAccordion>
  );
};

Accordion.propTypes = {
    summary: PropTypes.node,
    details: PropTypes.node,
    className: PropTypes.string
};

export default Accordion;