import { validateEmail } from "utils/validations/validateEmail";
import { validateStringField } from "utils/validations/validateStringField";

export const handleValidation = (newsletterSettings) => {

    const { reply_to_email, address, city, country, post_code } = newsletterSettings;

    validateEmail(reply_to_email, 'reply to email');
    validateStringField(address, 'address');
    validateStringField(city, 'city');
    validateStringField(country, 'country');
    validateStringField(post_code, 'post_code');
};