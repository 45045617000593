import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

export default class PaymentProviderResource extends AuthenticatedResource  {
    static urlRoot = 'profile/:organization_slug/payment_providers' as const;

    readonly id: number | undefined = undefined;

    pk() {
        return this.id?.toString();
    }
}