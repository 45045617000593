import React, { useEffect } from 'react';

import OrganizationUserResource from 'resources/admin/OrganizationUserResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import { organizationUsersColumns } from 'utils/tables/columns/admin/organizationUsers';
import SearchableFilter from 'ui/SearchableFilter';

const OrganizationUsersTable = () => {

  const {
      objectState,
      fetchData,
      handlePageChange,
      handleFilterChange
    } = usePaginationAndFilteringController({
        fetchDataResource: OrganizationUserResource,
        fetchDataParams: {},
    });

    useEffect(() => {
      fetchData();
    }, []);

  return (
    <DataSelectionTable records={objectState.filteredObjects}
                                  columns={organizationUsersColumns}
                                  serverPaginationProps={{
                                    paginationServer: true,
                                    paginationTotalRows: objectState.totalCount,
                                    page: objectState.page,
                                    onChangePage: handlePageChange
                                  }}
                                  filter={
                                      <SearchableFilter
                                        label={"organization"}
                                        onChange={handleFilterChange}
                                      />
                                    }
              />
  );
};

export default OrganizationUsersTable;
