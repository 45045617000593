import React from 'react';
import PropTypes from 'prop-types';

import BadgeCard from 'components/BadgeCard';

const BadgesGrid = ({rewards, onRedirect, onClaim}) => {

    return (
     <div className="badges-grid grid">
      { rewards.map( ({id, title, course, claim_state, category, media_type, image_url, external_url, video_url, subscription_status, subscription_end_date, organization_slug, organization_allow_minting, contract_address, organization_title}) => (
          <BadgeCard key={ id }
                     id={ id }
                     course={ course }
                     title={ title }
                     claim_state={ claim_state }
                     category={ category }
                     media_type={ media_type }
                     image_url={ image_url }
                     external_url={ external_url }
                     fixed={ true }
                     onRedirect={ onRedirect }
                     organization_slug={ organization_slug }
                     organization_title={ organization_title }
                     onClaim={ organization_allow_minting ? onClaim : null }
                     contract_address={ contract_address }
                     video_url={ video_url }
                     subscription_status={ subscription_status }
                     subscription_end_date={ subscription_end_date }
                     isInstance={ true }
          />
        ))
      }
    </div>
    )
  };

  BadgesGrid.propTypes = {
    rewards: PropTypes.array,
    onRedirect: PropTypes.func,
    onClaim: PropTypes.func
  };

  export default BadgesGrid;
