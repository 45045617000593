import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'ui/Button';
import Description from 'ui/text/Description';

import { useFreeSubscriptionController } from 'utils/freeSubscriptionController';

import { t } from 'i18n/index';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Paywall = ({ tier_titles, available_to_free }) => {

    const {organizationSlug} = useParams();
    const {openFreeSubscriptionModal} = useFreeSubscriptionController();

    const history = useHistory();

    const handleRedirect = () => {
        history.push(`/${organizationSlug}/memberships`); // TODO: scroll to top
    };

    const tierTitles = tier_titles.join(', ');

    const description = available_to_free || tier_titles.includes('private')
                            ? t('ui:postCard:privateOrFree')
                            : t('ui:postCard:exclusive', {tierTitles})

    const buttonLabel = available_to_free
                            ?  t('ui:postCard:becomeAFreeMember')
                            :  tier_titles.includes('private')
                                ? t('ui:postCard:becomeAMember')
                                : t('ui:postCard:becomeAPremiumMember');

    const isBecomeAFreeMemberButton = buttonLabel === t('ui:postCard:becomeAFreeMember');

    return (
    <div className='paywall' >
        <div>
            <ErrorOutlineIcon />
            <Description>{description}.</Description> 
        </div>
        <Button onClick={isBecomeAFreeMemberButton 
                            ? () => openFreeSubscriptionModal()  
                            : handleRedirect} >
                {buttonLabel}
        </Button>     
    </div>
  );
};

Paywall.propTypes = {
    tier_titles: PropTypes.array,
    available_to_free: PropTypes.bool,
};  

export default Paywall;