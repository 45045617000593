import React from 'react';
import PropTypes from 'prop-types';

import SendWelcomeEmail from './SendWelcomeEmail';


const EmailSelectionContent = ({subscription, onSubscriptionChange }) => {

  const handleCheckBox = ({target: {value}}) => {

    let newSubscription = { ...subscription, ["send_welcome_email"]: value };
    onSubscriptionChange(newSubscription);
  };

  return (
    <>
      <p>Please tell us if you would like us to send a welcome email.</p>

      <SendWelcomeEmail 
        send_welcome_email={subscription.send_welcome_email}
        onChange={handleCheckBox}
      />
    </>
  );
};

EmailSelectionContent.propTypes = {
  open: PropTypes.string,
  subscription: PropTypes.object,
  onSubscriptionChange: PropTypes.func,
  tiers: PropTypes.array,
  updatingSubscription: PropTypes.object,
};

export default EmailSelectionContent;