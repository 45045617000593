import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import OrganizationResource from 'resources/organization/SearchableFilterResource';

import { useErrorController } from 'utils/useErrorController';

import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, TextField } from '@mui/material';

import { debounce } from 'lodash';
import { useController } from '@rest-hooks/react';

const ProductSelector = ({organizationId, products, onChange}) => {

    const [options, setOptions] = useState([])

    const {loading, setLoading, handleError} = useErrorController();
    const {fetch} = useController();

    const renderOption = (props, option) => <li {...props}>{option.identifier}</li>;
    const renderInput = (params) => 
        <TextField 
        {...params} 
        label={"Applicable Products"} 
        color="warning"
        InputProps={{
            ...params.InputProps,
            endAdornment: (
            <>  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
            </>
            ),
        }}
    />;

const debouncedSearch = useCallback(
    debounce(async (query) => {
      try {
        const response = await fetch(OrganizationResource.list(), {query: query, entity: "product_and_ticket", organization_id: organizationId});
        const filteredResponse = response.filter((item) =>!products.some((product) => product.id === item.id));
        setOptions(filteredResponse);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    }, 1000),
    [fetch, products]
  );

  const handleOpen = () => {
    setLoading(true);
    debouncedSearch('');
  };

  const handleQueryChange = (event, newValue, reason) => {
    if (reason === 'input') {
      setLoading(true);
      debouncedSearch(newValue);
    }
  };

  const handleSelection = (event, newValue) => {
    onChange({target: {name: "products", value: newValue}});
  };

  return (
    <Autocomplete
      size="small"
      value={products}
      selectOnFocus
      blurOnSelect
      clearOnBlur
      clearOnEscape
      handleHomeEndKeys
      openOnFocus
      loading={loading}
      options={options}
      onChange={handleSelection}
      onOpen={handleOpen}
      onInputChange={handleQueryChange}
      renderOption={renderOption}
      renderInput={renderInput}
      multiple={true}
      getOptionLabel={(option) => option.identifier}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

ProductSelector.propTypes = {
    organizationId: PropTypes.number,
    products: PropTypes.array,
    onChange: PropTypes.func
};

export default ProductSelector;