import * as React from 'react';
import PropTypes from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { t } from 'i18n/index';

const CircularProgressWithLabel = ({value, activeStep, totalSteps}) => {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" 
                          value={value}
                          color="warning"
                          size={75}
                          />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
              {activeStep + 1} {t('literal:of_sc')} {totalSteps}
          </Typography>
        </Box>
      </Box>
    );
}
  
CircularProgressWithLabel.propTypes = {
    activeStep: PropTypes.number,
    totalSteps: PropTypes.number,
    value: PropTypes.number,
}; 

export default CircularProgressWithLabel;