import { validateEmail } from "utils/validations/validateEmail";

const handleValidation = (emailsArray) => {
    if (emailsArray === undefined || emailsArray === null || emailsArray.length === 0) {
        throw errorMessage;
    } else {
        emailsArray.forEach((email) => {
            validateEmail(email, null, errorMessage);
        });
    }
};

const errorMessage = "Please enter one or more valid email addresses, separated by commas.";

export default handleValidation;
