import moment from 'moment';
import SubscriptionResource from 'resources/organization/SubscriptionResource';

export const defaultEndDate = (tiers) => {
    tiers.length == 1 && !tiers[0].sellable ? null : moment().add(1, 'months'); 
};

export const getEmptySubscription = (tiers) => {
    return {
        email: "",
        end_date: defaultEndDate(tiers), 
        send_welcome_email: null,
    }
};

export const getTitle = (updatingSubscription, open) => {
    return updatingSubscription ? 'Update Subscription' : open === 'single' ? 'Add a new member' : 'Add multiple members';
};

export const getButtonLabel = (updatingSubscription, open, send_welcome_email, step) => {
    return updatingSubscription 
            ? 'Save changes' 
            : send_welcome_email === 'personalized' 
                ? 'Write Email' 
                : send_welcome_email === 'true'
                    ? open === 'single' 
                        ? 'Add & Send'
                        : 'Import & Send' 
                    : step >= 2
                        ? open === 'single'
                            ? 'Add Member'
                            : 'Import'
                        : 'Next';
};

export const getToastContent = (updatingSubscription, open) => {
    return updatingSubscription 
            ? 'Subscription updated' 
            : open === 'single' 
                ? 'Subscription and Member added' 
                : 'Subscriptions and Members added';
};

export const getResource = (updatingSubscription) => {
    return updatingSubscription ? SubscriptionResource.update()  : SubscriptionResource.create();
};