import React, { useState } from "react";
import PropTypes from "prop-types";
import { useController } from "@rest-hooks/react";
import {PaymentElement, useStripe, useElements, } from "@stripe/react-stripe-js";

import OrderSummary from "components/OrderSummary";
import Footer from "../Footer";

import { findOrderResult } from "utils/profile/claim/findOrderInstance";
import { t } from "i18n/index";
import { useError } from "utils/useErrorController";

/** CONSTANTS **/
const paymentElementOptions = { layout: "tabs" };

/** COMPONENT **/
const Form = ({
  object,
  orderPropertiesState,
  onStepChange,
  onContentChange,
  onShowTickets,
  pricingId
 }) => {
  const [isElementsReady, setIsElementsReady] = useState(false);

  const { handleError, setLoading } = useError();
  const { fetch }                   = useController();
  const stripe                      = useStripe();
  const elements                    = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements || !isElementsReady) {
      setLoading(false);
      handleError(t("errors:stripeNotReady"));
      return;
    }

    const response = await stripe.confirmPayment({ elements, confirmParams: {}, redirect: "if_required" });

    if (!response.error) {

      findOrderResult( response.paymentIntent.id, fetch, onStepChange, handleError, setLoading, onContentChange, onShowTickets );

    } else {
      handleError(response.error?.message || t("errors:default"));
    }
  };

  const { title, image_url, organization, application_fee, discount_price, net_discount, start_date, pricings } = object;
  const { organization_title, show_application_fee, currency } = organization;
  const { itemPrice, quantity } = orderPropertiesState;

  const frequency = pricings ? pricings.find((pricing) => pricing.id === pricingId).frequency : "lifetime";

  return (
    <form onSubmit={handleSubmit} className='stripe-payment-form'>
      <PaymentElement options={paymentElementOptions} onReady={() => setIsElementsReady(true)}/>

      <OrderSummary
        currency={currency}
        unit_price={itemPrice}
        discount_price={discount_price}
        net_discount={net_discount}
        quantity={quantity}
        frequency={frequency}
        imageUrl={image_url}
        organizationTitle={organization_title}
        title={title}
        applicationFee={application_fee}
        start_date={start_date}
        showApplicationFee={!!application_fee && show_application_fee}
       />

      <Footer
        itemPrice={orderPropertiesState.itemPrice}
        object={object}
        disabled={!stripe || !elements || !isElementsReady}
        caller="stripe"
        frequency={frequency}
        currency={currency}
        />
    </form>
  );
};

Form.propTypes = {
  object: PropTypes.object,
  orderPropertiesState: PropTypes.object,
  onStepChange: PropTypes.func,
  onShowTickets: PropTypes.func,
  onContentChange: PropTypes.func,
  pricingId: PropTypes.number
};

export default Form;
