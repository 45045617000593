import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class RewardResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/courses/:course_id/rewards' as const;

  readonly id: number | undefined = undefined;
  readonly title: string = '';
  readonly category: string = '';
  readonly media_type: string = '';
  readonly image_url: string = '';
  readonly video_url: string = '';
  readonly issued_counter: number | undefined = undefined;
  readonly tokens: number | undefined = undefined;
  readonly created_at: Date = new Date(0);
  readonly updated_at: Date = new Date(0);

  pk() {
    return this.id?.toString();
  }
}
