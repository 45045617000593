import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import CustomModal from 'components/CustomModal';

import Content from './Content';

const CheckinCredentialsModal = ({open, loading, onClose, onGenerate, checkinCredentials}) => {

  const customModalContent =
    {
      title: 'Check In secret link and code',
      content: <Content checkinCredentials={checkinCredentials} />,
      button: <Button loading={loading} fullwidth onClick={onGenerate}>{`${checkinCredentials?.secret_token ? 'Regenerate Credentials' : 'Generate'}`}</Button>,
    };

  return (
    <CustomModal
      open={ open }
      handleClose={ onClose }
      customModalContent={ customModalContent } />
  );
};

CheckinCredentialsModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onGenerate: PropTypes.func,
  checkinCredentials: PropTypes.object,
};

export default CheckinCredentialsModal;
