import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'ui/Button';
import Description from 'ui/text/Description';

import { t } from "i18n/index";

const TicketsSent = ({ setStep, handleClaimReset }) => {
  
  const {organizationSlug} = useParams();
  const history = useHistory()

  const handleRedirect = () => {
    handleClaimReset();
    history.push(`/${organizationSlug}`);
  };

  const handleBillingDetails = () => setStep(5);
  
  const description = t('profile:memberships:claimModal:ticketsSent');
  
  const backButtonLabel = t('literal:close');

  const nextButtonAction = handleBillingDetails;
  const nextButtonLabel = t('literal:billingDetails');

  return (
    <div className='payment-processed'>
      
      <Description>{description}</Description>

      <div className='button-group'>

        <Button onClick={handleRedirect} fullwidth secondary>{backButtonLabel}</Button>

        <Button onClick={nextButtonAction} fullwidth>{nextButtonLabel}</Button>

      </div>      
    </div>
  );
};

TicketsSent.propTypes = {
    setStep: PropTypes.func,
    handleClaimReset: PropTypes.func,
};

export default TicketsSent;

