import React, { useEffect } from 'react';

import TicketResource from 'resources/admin/TicketResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import Stats from './Stats';
import TicketFilter from './TicketFilter';

import { ticketsColumns } from 'utils/tables/columns/admin/ticketsColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import downloadCSV from 'utils/downloadCSV';

const pageInfo = {
  label: "Admin",
  name: "Tickets",
};

const AdminTickets = () => {
  
  const {
    objectState, 
    fetchData,
    handlePageChange,
    handleFilterChange
  } = usePaginationAndFilteringController({
      fetchDataResource: TicketResource,
      fetchDataParams: {},
  });

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownload = async () => {
    await downloadCSV('/admin/tickets', objectState.filteringParams);
  };

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem='tickets' >

      <TabPanels labels={[ "Tickets" ]} >
            <div>
              <Stats tickets={objectState.filteredObjects} />
              <DataSelectionTable records={objectState.filteredObjects}
                                  columns={ticketsColumns}
                                  onClick={handleDownload}
                                  serverPaginationProps={{
                                    paginationServer: true,
                                    paginationTotalRows: objectState.totalCount,
                                    page: objectState.page,
                                    onChangePage: handlePageChange
                                  }}
                                  filter={
                                    <TicketFilter
                                      onChange={handleFilterChange}
                                    />
                                  }  
              />
            </div>
      </TabPanels>

    </Layout>
)};

export default AdminTickets;
