import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';

import useAuthModalController from 'utils/useAuthModalController';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const AuthModal = ({open, onClose, done, organizationSlug=null, allowPartialSignIn=false}) => {
  
  const { logIn, Form, handleClose, handleLoginChange } = useAuthModalController(onClose);

  return (
    <Dialog open={open} onClose={ handleClose } fullWidth maxWidth='sm'>
        
      <Title logIn={logIn} />

      <DialogContent dividers>

        <Form done={done} 
              organizationSlug={organizationSlug} 
              onLoginChange={handleLoginChange} 
              showSignUp={true}
              allowPartialSignIn={allowPartialSignIn}
              />

      </DialogContent>
    </Dialog>
  );
};

AuthModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  done: PropTypes.func,
  organizationSlug: PropTypes.func,
  allowPartialSignIn: PropTypes.func
};

export default AuthModal;