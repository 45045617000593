import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Label from 'ui/text/Label';

import { Divider, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const CapacityEditor = ({capacity, onChange, showDivider=false}) => {
    const [open, setOpen] = useState(!!capacity);
  return (
    <>  
        {showDivider && <Divider light />}
        {!open ? (
            <Button 
                text
                small
                icon={AddCircleOutlineIcon}
                onClick={() => setOpen(true)}
            >
                Add Venue Capacity
            </Button>
        ) : ( 
            <>
                <TextField
                    fullWidth
                    size='small'
                    color='warning'
                    name={"capacity"}
                    label={"Venue Capacity"}
                    value={capacity}
                    onChange={onChange}
                />
                <Label secondary padded>
                    Use this field to limit the maximum amount of tickets sold. This will override their individual supply limit. 
                </Label>
            </>
        )}
    </>
  );
};

CapacityEditor.propTypes = {
    capacity: PropTypes.number,
    onChange: PropTypes.func,
    showDivider: PropTypes.bool
};

export default CapacityEditor;