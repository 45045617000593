import React, { useEffect, useState }  from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import OfferResource from 'resources/user/OfferResource';
import OfferInstanceResource from 'resources/user/OfferInstanceResource';

import OffersGrid from 'components/CardGrids/OffersGrid';
import Layout from 'components/layouts/index';
import TabPanels from 'components/TabPanels';
// import Filter from './Filter';
// import ShowAllFilterItemTitle from './ShowAllFilterItemTitle';

import { t } from 'i18n/index';

const pageInfo = {
    name: t('student:offers:pageTitle'),
    description: t('student:offers:pageDescription'),
  };

const Offers = () => {

    const { fetch } = useController();
    const params = useParams();
    const organizationId = params.organizationId;

    const [offers, setOffers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if (organizationId) {
                const offers = await fetch(OfferResource.list(), {organization_id: organizationId});
                setOffers(offers)
            } else {
                const offers = await fetch(OfferResource.list());
                setOffers(offers)
            }
        };
        fetchData();
    }, [])


    const reedeemedOffers = useSuspense(OfferInstanceResource.list());
    reedeemedOffers.sort(({created_at: a}, {created_at: b} ) => {return a < b ? 1 : -1 });

    // const uniqueArray = Array.from(
    //     new Set(offers.map((offer) => offer.organization_id))
    // );

    // const organizations = uniqueArray.map((organization_id) => {
    //     const offer = offers.find(
    //         (item) => item.organization_id === organization_id
    //     );
    //     return { id: offer.organization_id, title: offer.organization_title};
    // });

    // organizations.push({id: null, title: <ShowAllFilterItemTitle />})

    return (
        <Layout context='student'
                activeMenuItem='offers'
                pageInfo={ pageInfo } >
            <TabPanels labels={[ `${t('literal:available')} (${offers.length})`, `${t('literal:redeemed')} (${reedeemedOffers.length})` ]}>

                <div>
                    {/* <Filter organizations={organizations} onFilterChange={setOffers} currentOrganizationId={organizationId}/> */}
                    {offers.length > 0 ?
                        <OffersGrid data={offers} onRedeem={setOffers}/>
                        :
                        <p>{t('student:offers:noOffers')}</p>
                    }
                </div>

                { reedeemedOffers.length > 0 ?
                    <OffersGrid data={reedeemedOffers} /> :
                    <p>{t('student:offers:noOffersRedeemed')}</p>
                }

            </TabPanels>
        </Layout>
    );
};

export default Offers;
