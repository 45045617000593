import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Preview from '../Preview';

import Button from 'ui/Button';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { IconButton } from '@mui/material';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import { ChromePicker } from "react-color";
import FormSection from 'ui/FormSection';

const OrganizationForm = ({organization, onOrganizationChange, newOrganization, onUnsavedChanges, onSubmit, loading}) => {

  const [image, setImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const images = ["image", "cover_image"];

  const imageSrc = image ? window.URL.createObjectURL(image) : organization.logo_url;
  const coverImageSrc = coverImage ? window.URL.createObjectURL(coverImage) : organization.cover_image_url;

  const hiddenFileInputs = images.map(() => useRef(null));

  const handleChange = ({target: {name, value}}) => {
    onUnsavedChanges(true);

    let newOrg = {...organization, [name]: value};
    onOrganizationChange(newOrg);
  };

  const handleFileUpload = ({target: {files, name}}) => {
    if (files.length === 0) return;

    onUnsavedChanges(true);

    const file = files[0];
    let newOrganization = {...organization, [`${name}_file`]: file, [`${name}_filename`]: file.name};
    onOrganizationChange(newOrganization);

    if (name === 'image') {
      setImage(file)
    } else {
      setCoverImage(file);
    }
  };

  const handleClickShowColorPicker = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowColorPicker(!showColorPicker);
  };

  const handleColorChange = (color) => {
    onUnsavedChanges(true);
    let newOrganization = {...organization};
      newOrganization["highlight_color"] = color.hex;
      onOrganizationChange(newOrganization);
  };

  const isDisabled = organization.newsletter_sender_created;

  return (
    <div className='organization-form-and-preview' onSubmit={onSubmit}>
      <form className='organization-form'>
        {newOrganization &&
          <div>
            <h3 className="new-form-section">Community Details</h3>
            <p>To create your first collection, we need more details about your community to set up an account!</p>
          </div>
        }
        <FormSection>
          <TextField
            fullWidth
            size="small"
            name="title"
            label="Display Name *"
            color="warning"
            value={ organization.title || ''}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            size="small"
            name="slug"
            label="Username *"
            color="warning"
            value={ organization.slug || '' }
            disabled={ isDisabled }
            helperText={isDisabled && "Please contact us if you would like to update your Username."}
            InputProps={{
              startAdornment: <InputAdornment position="start">membrz.club/</InputAdornment>,
            }}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            size="small"
            name="description"
            label="Description"
            multiline
            rows={3}
            color="warning"
            value={ organization.description || '' }
            onChange={handleChange}
          />

          <TextField
            fullWidth
            size="small"
            name="custom_cta"
            label='Subscribe button'
            placeholder='Join the Club'
            color="warning"
            value={ organization.custom_cta || ''}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            size="small"
            name="highlight_color"
            label="Highlight Color"
            color="warning"
            value={ organization.highlight_color || '' }
            InputProps={{endAdornment:
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle color picker visibility"
                  onClick={()=> handleChange({target: {name: 'highlight_color', value: '#F75201'}})}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  <RotateLeftIcon />
                </IconButton>
                <IconButton
                  aria-label="toggle color picker visibility"
                  onClick={handleClickShowColorPicker}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  <ColorLensIcon />
                </IconButton>
              </InputAdornment>
            }}
          />

          {showColorPicker &&
            <div className='color-picker'>
              <ChromePicker
                disableAlpha={true}
                color={organization.highlight_color}
                onChangeComplete={ handleColorChange }
              />
            </div>}

          {images.map ((image, index) => (
            <div key={index} >
              <Button
                fullwidth
                secondary
                icon={UploadFileIcon}
                onClick={(event) => {
                  event.preventDefault()
                  hiddenFileInputs[index].current.click();
                }}
                >
                {organization[`${image}_filename`] ? `Uploaded ${organization[`${image}_filename`]}` : `Upload ${image}`}
              </Button>
              <input ref={hiddenFileInputs[index]} type="file" accept="image/*" name={image} hidden onChange={handleFileUpload} style={{display:'none'}}/>
            </div>
          ))}
        </FormSection>

        <div className={ `new-form-field-footer` }>
          <Button loading={loading} fullwidth>
            {newOrganization ? 'Create' : 'Update'}
          </Button>
        </div>
      </form>

      <Preview organization={organization} coverImageSrc={coverImageSrc} imageSrc={imageSrc} />
    </div>
  )
};

OrganizationForm.propTypes = {
    organization: PropTypes.object,
    onOrganizationChange: PropTypes.func,
    newOrganization: PropTypes.bool,
    onUnsavedChanges: PropTypes.func,
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
};

export default OrganizationForm;
