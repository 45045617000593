import React from "react";
import PropTypes from "prop-types";

import { Checkbox, FormControlLabel } from "@mui/material";

const TierSelector = ({ allTiers, accessTiers, setGroupTiers }) => {
  const handlePassToggle = (tierId) => () => {
    const updateTierIds = accessTiers.includes(tierId)
      ? accessTiers.filter((id) => id !== tierId)
      : [...accessTiers, tierId];

    setGroupTiers( updateTierIds);
  };

  return (
    <div className={`new-form-field`}>
      {allTiers.map((tier) => (
        <FormControlLabel
          key={tier.id}
          control={
            <Checkbox
              checked={accessTiers && accessTiers.includes(tier.id)}
              onChange={handlePassToggle(tier.id)}
            />
          }
          label={tier.title}
        />
      ))}
    </div>
  );
};

TierSelector.propTypes = {
  allTiers: PropTypes.array,
  accessTiers: PropTypes.array,
  setGroupTiers: PropTypes.func,
};

export default TierSelector;
