import { convertPriceFromCents, currencySymbol } from "utils/rewards";
import { getDateFormat } from "utils/tables/getDateFormat";
import { getDateSelector } from "utils/tables/getDateSelector";

export const soldTicketsColumns = [
  {
    field: "event_title",
    name: "Event",
    selector: (row) => `${row.event.identifier}`,
    sortable: true,
    width: "200px",
  },
  {
    field: "title",
    name: "Title",
    selector: (row) => `${row.title}`,
    sortable: true,
    width: "200px",
  },
  {
    field: "reference",
    name: "Reference",
    selector: (row) => `${row.uuid}`,
    sortable: true,
    width: "350px",
  },
  { 
    field: "order_id",
    name: "Order ID",
    selector: (row) => row.order_id,
    sortable: true,
    width: "125px",
  },
  {
    field: "price",
    name: "Price",
    selector: (row) => !row.unit_price || row.unit_price === 0 ? 0 : `${convertPriceFromCents(row.unit_price)} ${currencySymbol[row.currency]}`,
    sortable: true,
    width: "125px",
  },
  {
    field: "user",
    name: "User",
    selector: (row) => `${row.user_name} #${row.user_id}`,
    sortable: true,
    width: "200px",
  },
  {
    field: "createdAt",
    name: "Order Date",
    selector: (row) => getDateSelector(row.created_at),
    format: (row) => getDateFormat(row.created_at),
    sortable: true,
    width: "150px",
  },
];
