import React from 'react';
import PropTypes from 'prop-types';



import AccordionDetails from '@mui/material/AccordionDetails';
import ExistingComments from './ExistingComments';
import NewComment from './NewComment';

const CommentsList = ({comments, loading, OnCommentCreate, onCommentDelete, onCommentChange}) => {
  return (
    <AccordionDetails>
        <ExistingComments comments={comments} loading={loading} onCommentDelete={onCommentDelete}/>
        <NewComment comments={comments} loading={loading} OnCommentCreate={OnCommentCreate} onCommentChange={onCommentChange}/>
    </AccordionDetails>
  );
};

CommentsList.propTypes = {
    comments: PropTypes.array,
    loading: PropTypes.bool,
    OnCommentCreate: PropTypes.func,
    onCommentDelete: PropTypes.func,
    onCommentChange: PropTypes.func,
};

export default CommentsList;