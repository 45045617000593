import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import OrderResource from 'resources/user/OrderResource';

import Button from 'ui/Button';

import formattedDate from 'utils/formattedDate';
import { convertPriceFromCents, currencySymbol } from 'utils/rewards';
import { useError } from 'utils/useErrorController';
import { offerCategory } from 'utils/offersFormHelper';

import { t } from 'i18n/index';

const OrderCardContent = ({item}) => {

  const {invoice, created_at, amount, total_price, currency, object_category} = item;

  const defaultButtonLabel = object_category === offerCategory.ticket.value ? t('literal:sendInvoice') : t('literal:resendInvoice');
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
  const [message, setMessage] = useState(defaultButtonLabel)
  const {loading, setLoading, handleError} = useError();
  const {fetch} = useController();

  const handleResendInvoice = async () => {
    try {
      setLoading(true);
      const {id} = await fetch(OrderResource.detail(), {id: item.id});
      if (id) {
        setMessage(t('literal:invoiceSent'))
        setLoading(false);
      }
    } catch (error) {
      handleError(error)
    }
  };

  return (
    <>
      <div className='fullwidth-card-content'>
        {showInvoiceDetails ?
        <>
          <p>{t('literal:address')}: {invoice.address}</p>
          <p>{t('literal:vat')}: {invoice.vat}</p>
          <p>{t('literal:email')}: {invoice.email}</p>
        </> :
        <>
          <p>{t('literal:createdAt')}: {formattedDate(created_at)}</p>
          <p>{t('literal:quantity')}: {amount}</p>
          <p>{t('literal:paid')}: {convertPriceFromCents(total_price)} {currencySymbol[currency]}</p>
        </>}
        {invoice &&
          <>
            <div className='button-group'>
              <Button
                    loading={loading}
                    minwidth
                    onClick={ () => setShowInvoiceDetails(!showInvoiceDetails) }
                    >
                    {t('literal:seeDetails')}
              </Button>
              <Button
                    loading={loading}
                    minwidth
                    secondary
                    onClick={ handleResendInvoice }
                    disabled={message === t('literal:invoiceSent')}
                    >
                    {message}
              </Button>
            </div>
          </>}
      </div>
    </>

  )
}

OrderCardContent.propTypes = {
    item: PropTypes.object,
};

export default OrderCardContent;
