import React from 'react';
import PropTypes from 'prop-types';


import TierSelector from './TierSelector';
import DateSelector from './DateSelector';
import Import from './Import';

import TextField from "@mui/material/TextField";
import FormSection from 'ui/FormSection';

const Content = ({open, subscription, onSubscriptionChange, tiers, updatingSubscription }) => {

  const handleChange = ({target: {name, value}}) => {
    let newSubscription = { ...subscription, [name]: value };
    onSubscriptionChange(newSubscription);
  };

  return (
    <>
      <FormSection slim>
        {open === 'single' && 
          <TextField
            fullWidth
            color='warning'
            name={'email'}
            size={'small'}
            label={'User Email'}
            value={ subscription.email}
            onChange={handleChange}
            disabled={ updatingSubscription }
          />
        }

        {open === 'import' &&
          <Import
            subscription={subscription}
            onSubscriptionChange={onSubscriptionChange}
          />
        }

        <TierSelector
          tiers={tiers} 
          subscription={subscription} 
          onSubscriptionChange={onSubscriptionChange}
        />

        <DateSelector
          tiers={tiers} 
          subscription={subscription} 
          onSubscriptionChange={onSubscriptionChange}
        />
      </FormSection>
      

    </>
  );
};

Content.propTypes = {
  open: PropTypes.string,
  subscription: PropTypes.object,
  onSubscriptionChange: PropTypes.func,
  tiers: PropTypes.array,
  updatingSubscription: PropTypes.object,
};

export default Content;