import React from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CurrencyField from "components/CurrencyField";
import FormSection from "ui/FormSection";
import CountrySelector from "components/AddressForm/CountrySelector";

const CreateForm = ({ onSubmit, onClose, onChange, organization, onOrganizationChange }) => {
  const { title, slug, admin_name, admin_email, currency } = organization;
  return (
    <>
      <DialogTitle>Add Organization</DialogTitle>
      <DialogContent dividers>
        <FormSection slim>
          <TextField
            size="small"
            name="title"
            label="Organization Title *"
            fullWidth
            color="warning"
            value={title}
            onChange={onChange}
          />

          <TextField
            size="small"
            name="slug"
            label="Organization Slug *"
            fullWidth
            color="warning"
            value={slug}
            onChange={onChange}
          />

          <TextField
            size="small"
            name="admin_name"
            label="Admin name"
            fullWidth
            color="warning"
            value={admin_name}
            onChange={onChange}
          />

          <TextField
            size="small"
            name="admin_email"
            label="Admin email *"
            fullWidth
            color="warning"
            value={admin_email}
            onChange={onChange}
          />

          <CurrencyField
            currency={currency}
            onCurrencyChange={onChange}
          />

          <CountrySelector data={organization} onDataChange={onOrganizationChange}/>
  
        </FormSection>
      </DialogContent>
      <DialogActions>
        <Button color="warning" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          Create Organization
        </Button>
      </DialogActions>

</>
  );
};

CreateForm.propTypes = {
  organization: PropTypes.object,
  onOrganizationChange: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateForm;
