import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';

import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Button from 'ui/Button';

const NewGroupModal = ({ organization, open, onClose }) => {

  return (
    <Dialog open={open} fullWidth={ true } maxWidth='sm'>
        <Title
            onClose={onClose}
        />

        <DialogContent dividers>
          <p>You are about to create a exclusive-access Telegram Group. Open your Telegram app and follow these steps.</p>
          <ol>
            <li>Create a Private Telegram Group</li>
            <li>Invite our own Telegram bot (search by @MembrzClubBot) to the group</li>
            <li>Go to Edit &gt; Administrators &gt; Add Admin, and select the bot</li>
            <li>Give the bot the following permissions
              <ul>
                <li>Ban Users</li>
                <li>Invite Users via Link</li>
              </ul>
            </li>
            <li>Type the following command in the group chat <br /><br />
              <code>
                  /register { `${organization.slug}_${organization.id}` }
              </code>
              <br />
            </li>
          </ol>

          <p><br />
            If everything goes correctly you will receive a success message.<br />
            Click on the next button to setup access permissions.
          </p>
        </DialogContent>



        <DialogActions>
          <Button secondary onClick={onClose}> Cancel </Button>
          <Button onClick={ () => window.location.reload(false) }>
            Next
          </Button>
        </DialogActions>


    </Dialog>
  );
};

NewGroupModal.propTypes = {
    organization: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func
};

export default NewGroupModal;
