import { useState } from 'react';

function useConnectWalletModal() {
  const [connectIsOpen, setConnectIsOpen] = useState(false);

  const openConnectModal = () => setConnectIsOpen(true);
  const closeConnectModal = () => setConnectIsOpen(false);

  return { connectIsOpen, openConnectModal, closeConnectModal };
}

export { useConnectWalletModal };

function useStatusModal() {
  const [statusIsOpen, setStatusIsOpen] = useState(false);

  const openStatusModal = () => setStatusIsOpen(true);
  const closeStatusModal = () => setStatusIsOpen(false);

  return { statusIsOpen, openStatusModal, closeStatusModal };
}

export { useStatusModal };

function useShareModal() {
  const [shareIsOpen, setShareIsOpen] = useState(false);

  const openShareModal = () => setShareIsOpen(true);
  const closeShareModal = () => setShareIsOpen(false);

  return { shareIsOpen, openShareModal, closeShareModal };
}

export { useShareModal };

