import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import { useController } from "@rest-hooks/react";

import PageElementResource from 'resources/Profile/PageElementResource';

import Embeds from "./Embeds";
import Calendar from "./Calendar";

import Provider from "containers/student/Settings/ConnectedAccounts/Accounts/Provider";

import { isEmbeddedElement, isSocialElement, socialTypes } from "utils/pageElementsHelper";
import useElementStatsController from "utils/profile/useElementStatController";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from "@mui/material/ListItemText";

const Content = ({ element, profile }) => {

    const [openConnectAccount, setOpenConnectAccount] = useState(false);

    const {fetch} = useController();    
    const {organizationSlug} = useParams();
    const {createStat} = useElementStatsController(organizationSlug);

    const history = useHistory();

    const {content, element_type, image_url, link_title, require_telegram, require_whatsapp, id} = element;
    const isTitle = element_type === "title";
    const isLink = element_type === "link";
    const isEmail = element_type === "email";
    const isSocial = isSocialElement(element_type);
    const isEmbed = isEmbeddedElement(element_type);
    const isCalendar = element_type === "calendar";

    const action = () => {
      if (profile) createStat(id);
      if (require_telegram || require_whatsapp) {
        setOpenConnectAccount(true);
      } else if (isLink || isSocial) {
        const url = isEmail ? null : new URL(content);
        const isInternalLink = isEmail ? false : url.hostname === window.location.hostname;
        if (isInternalLink){
          history.push(url.pathname);
        } else {
          const link = isEmail ? `mailto:${content}` : content;
          window.open(link, "_blank");
        }
      }
    };

    const socialElementIcon = isSocial && socialTypes.find(type => type.value === element_type).logo;

    const handleConnectAccount = async () => {
      window.open(content, "_blank");
      await fetch(PageElementResource.detail(), {organization_slug: organizationSlug, id: 1})

      setOpenConnectAccount(false);
    };

    return (
    <>
      {isTitle && (
        <ListItemText sx={{wordWrap: "break-word"}} primary={content} className="title"/>
      )}

      {isCalendar && !profile && (
        <ListItemText sx={{wordWrap: "break-word"}} primary={`Calendar showing next ${content} events`}/>
      )}

      {isCalendar && profile && (
        <Calendar />
      )}

      {isLink && (
        <ListItemButton
          onClick={action}
          disableRipple
          disableGutters
          className='list-item-button'
        >
          {image_url && (
            <ListItemAvatar className="image-container">
              <img className="image" src={image_url} />
            </ListItemAvatar>
          )}
          <ListItemText sx={{wordWrap: "break-word"}}  primary={link_title} />
        </ListItemButton>
      )}

      {isSocial && (
        <ListItemButton
          onClick={action}
          disableRipple
          disableGutters
          className='list-item-button'
        >
          <ListItemAvatar className="image-container">
            <img className="icon" src={socialElementIcon} />
          </ListItemAvatar>
          <ListItemText sx={{wordWrap: "break-word"}} primary={content} />
        </ListItemButton>
      )}

      <Provider open={openConnectAccount && (require_telegram || require_whatsapp)}                
                onClose={() => setOpenConnectAccount(false)}
                onSuccess={handleConnectAccount}
                provider={ require_telegram ? "telegram" : "whatsapp"}
                showJoin={require_telegram} />


      {isEmbed && (
        <Embeds profile={profile} content={content} element_type={element_type}/>
      )}

    </>
  );
};

Content.propTypes = {
  element: PropTypes.object,
  profile: PropTypes.bool
};

export default Content;
