import React from 'react';
import PropTypes from 'prop-types';

import TableButton from 'components/TableButton';

import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationOffOutlinedIcon from '@mui/icons-material/LocationOffOutlined';

const ActionButtons = ({post, loading, organizationId, history, handleConfirmDeleteModal, handleUrlCopy, handlePreview, handleNewsletter, handlePin}) => {

    const draft = post.status === 'draft';
    const pinned = !!post.pinned_at;

    const actionButtonsData = [
        {
          label: 'Copy URL',
          icon: ContentCopyIcon,
          action: () => handleUrlCopy(post),
          disabled: loading || draft,
        },
        {
          label: 'Preview',
          icon: PreviewIcon,
          action: () => handlePreview(post),
          disabled: loading,
        },
        {
          label: 'See as Newsletter',
          icon: EmailIcon,
          action: () => handleNewsletter(post),
          disabled: loading,
        },
        {
          label: 'Edit/Publish',
          icon: EditIcon,
          action: () => history.push(`/organizations/${organizationId}/content/${post.id}/edit`),
          disabled: loading,
        },
        {
          label: pinned ? 'Unpin' : 'Pin to top',
          icon: pinned ? LocationOnOutlinedIcon : LocationOffOutlinedIcon,
          action: () => handlePin(post),
          disabled: loading,
        },
        {
          label: 'Delete',
          icon: DeleteIcon,
          action: () => handleConfirmDeleteModal(post),
          disabled: loading,
        },
      ];

  return (
    <div>
        {actionButtonsData.map((data) => (
            <TableButton
                key={data.label}
                title={data.label}
                Icon={data.icon}
                action={data.action}
                disabled={data.disabled}
            />
        ))}
    </div>
  );
};

ActionButtons.propTypes = {
    post: PropTypes.object,
    loading: PropTypes.bool,
    organizationId: PropTypes.number,
    history: PropTypes.object,
    handleConfirmDeleteModal: PropTypes.func,
    handleUrlCopy: PropTypes.func,
    handlePreview: PropTypes.func,
    handleNewsletter: PropTypes.func,
    handlePin: PropTypes.func
};

export default ActionButtons;