import React, { useState } from "react";
import { useController } from "@rest-hooks/react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import EventResource from "resources/organization/EventResource";
import PageElementResource from "resources/organization/PageElementResource";

import Layout from "components/layouts";
import CustomModal from "components/CustomModal";

import Form from "./Form";
import createFormData from "./createFormData";
import handleValidation from "./handleValidation";

import Button from "ui/Button";

import { useToast } from "utils/context/ToastContext";
import customToast from "utils/customToast";
import { useError } from "utils/useErrorController";

const EventFormWrapper = ({
  pageInfo,
  organizationId,
  eventData,
  resource,
  resourceParams,
  toastMessage,
}) => {

  const { fetch } = useController();
  const history = useHistory();

  const { setOpen, setMessage, setSeverity } = useToast();
  const { handleError, validate, loading, setLoading } = useError();

  const [event, setEvent] = useState(eventData);
  const [customModalContent, setCustomModalContent] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const handleRedirect = (id) => {
    history.push(`/organizations/${organizationId}/perks/new?eventId=${id}`)
  };

  const handleCloseCustomModal = (status) => {
    setCustomModalContent(null);
    setLoading(false);
    handleSuccessfulResponse(status);
  };

  const getModalContent = (title, id) => {
    return {
      title: "Create Tickets?",
      content: `${title} saved successfully! Would you like to create tickets fot this event now?`,
      button: <Button fullwidth secondary onClick={() => handleCloseCustomModal('published')}>{"No, see events"}</Button>,
      secondaryButton: <Button fullwidth onClick={() => handleRedirect(id)}>{"Yes, create"}</Button>
    }
  };

  const handleSubmit = async (e, status) => {
    try {
      e.preventDefault();
      validate(event, handleValidation);
      const formData = createFormData(event, status);

      const {id, has_ticket, title} = await fetch(resource, resourceParams, formData);

      if (id) {
        await fetch(EventResource.list(), { organization_id: organizationId, upcoming: true });
        await fetch(EventResource.list(), { organization_id: organizationId });
        await fetch(PageElementResource.detail(), { organization_id: organizationId });
      }
     
      setLoading(false);
      setUnsavedChanges(false);

      if (event.ticketing && !has_ticket && id) {
        setCustomModalContent(getModalContent(title, id, "createTickets", status));

      } else {
        handleSuccessfulResponse(status, id);
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleSuccessfulResponse = async (status, id) => {    
    customToast("success", toastMessage, setOpen, setSeverity, setMessage);
    if (status === 'published' || !id) {
      history.push(`/organizations/${organizationId}/events`);
    }
  };

  const handleChange = ({target: {name, value}}) => {
    setUnsavedChanges(true);
    let newEvent = { ...event, [name]: value };
    setEvent(newEvent);
  };

  return (
    <Layout
      context="teacher"
      back={`/organizations/${organizationId}/events`}
      pageInfo={pageInfo}
      activeMenuItem="events"
    >
      <Form
        event={event}
        setEvent={setEvent}
        onSubmit={handleSubmit}
        loading={loading}
        onChange={handleChange}
        onError={handleError}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />

      {customModalContent &&
        <CustomModal open={ !!customModalContent }
                     handleClose={ () => handleCloseCustomModal('published') }
                     customModalContent={ customModalContent } /> }

    </Layout>
  );
};

EventFormWrapper.propTypes = {
  pageInfo: PropTypes.object,
  eventData: PropTypes.object,
  resource: PropTypes.func,
  resourceParams: PropTypes.object,
  toastMessage: PropTypes.string,
  organizationId: PropTypes.number,
};

export default EventFormWrapper;
