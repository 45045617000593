import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Link } from '@mui/material';

const Location = ({ location }) => {

  return (
    <div className="location">
      <div className="icon">
        <LocationOnIcon />
      </div>

      <Description>
        <Link href={`http://maps.google.com/?q=${encodeURIComponent(location)}`} target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'inherit' }}>
            {location}
        </Link>
      </Description>

    </div>
  );
};

Location.propTypes = {
  location: PropTypes.string
};

export default Location;