import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

const RedeemLimit = ({redeem_limit, onChange}) => {

  const label = 'Max amount per user. Leave empty for unlimited'
  return (
        <TextField
            fullWidth
            size='small'
            color="warning"
            name='redeem_limit'
            type='number'
            value={redeem_limit || ''}
            onChange={onChange}
            label={label}
            inputProps={{ inputMode: 'numeric'}} />
  );
};

RedeemLimit.propTypes = {
  redeem_limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default RedeemLimit;