import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import SearchIcon from '@mui/icons-material/Search';

import { t } from "i18n/index";

const ViewMore = ({handleRedirect}) => {
  return (
    <Button text icon={SearchIcon} fullwidth onClick={ handleRedirect }>
        {t('literal:seeDetails')}
    </Button>
  );
};

ViewMore.propTypes = {
  handleRedirect: PropTypes.func,
};

export default ViewMore;