import React from "react";
import PropTypes from "prop-types";

import Label from "ui/text/Label";

import TicketCard from "./TicketCard";

import { getServiceFeeLabel } from "utils/profile/getServiceFeeLabel";

const TicketList = ({ tickets, showApplicationFee, onAction }) => {
  const serviceFeesLabel = getServiceFeeLabel(showApplicationFee);
  const showApplicationFeeLabel = tickets.find((ticket) => ticket.price > 0);

  return (
    <div className="tickets">
      {tickets.map((ticket) => (
        <TicketCard key={ticket.id} ticket={ticket} onAction={onAction} />
      ))}
      {showApplicationFeeLabel && (
        <Label padded secondary small className={"application-fee-disclaimer"}>
          {serviceFeesLabel}
        </Label>
      )}
    </div>
  );
};

TicketList.propTypes = {
  tickets: PropTypes.array,
  showApplicationFee: PropTypes.bool,
  onAction: PropTypes.func,
};

export default TicketList;
