import React from "react";
import PropTypes from "prop-types";

import DialogComponent from "./DialogComponent";
import BackdropComponent from "./BackdropComponent";

import "./styles.css";

const Slider = ({ open, handleClose, objects, selectedIndex, title, description }) => {

  const commonProps = { open, handleClose, objects, selectedIndex };

  if (title && description) return (
    <DialogComponent {...commonProps} title={title} description={description} />
  )
  
  return (
    <BackdropComponent {...commonProps} />
  );
};

Slider.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  objects: PropTypes.array,
  selectedIndex: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string
};

export default Slider;
