import React from 'react';
import PropTypes from 'prop-types';

import QRCodeGenerator from 'components/QRCodeGenerator';

import { useToast } from 'utils/context/ToastContext';
import copyToClipboard from 'utils/copyToClipboard';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { Button } from '@mui/material';

const ClaimLinkModal = ({open, url, onClose}) => {

    const { setOpen, setMessage, setSeverity } = useToast();
  
    const downloadQRCode = () => {  
        const canvas = document.getElementById("qrCodeId");
        const pngUrl = canvas.toDataURL('image/png')
                            .replace("image/png" , "image/octet-stream");
        let downloadButton = document.createElement("a");
        downloadButton.href = pngUrl;
        downloadButton.download = `qrcode.png`;
        document.body.appendChild(downloadButton);
        downloadButton.click();
        document.body.removeChild(downloadButton);
    };

    return (
        <div >
            <Dialog open={open}
                        sx={{
                            "& .MuiDialog-container": {
                              "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "500px",
                              },
                            },
                          }}
            >
                <DialogTitle  >
                    Claim link & QR code
                    {onClose && (
                        <IconButton aria-label='close'
                                    onClick={onClose}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500]
                                    }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent style={ { textAlign: 'center', border: '2px'} } dividers>
                    <QRCodeGenerator url={url} />
                    <p>Copy this claim link to share with anyone or download the QR code image to print it.</p>

                    <p>
                    <Button onClick={() => copyToClipboard(url, setOpen, setSeverity, setMessage, onClose)} variant="contained">
                        <ContentCopyIcon/>
                        Copy claim link
                    </Button>
                    <span> or </span>
                    <Button onClick={downloadQRCode} variant="contained">
                        <DownloadIcon/>
                        Download QR Code
                    </Button>
                    </p>
                </DialogContent>
            </Dialog>
        </div>
    )
};

ClaimLinkModal.propTypes = {
    open: PropTypes.bool,
    url: PropTypes.string,
    onClose: PropTypes.func,
}

export default ClaimLinkModal;