import React from "react";
import PropTypes from "prop-types";

import { ListItemText } from "@mui/material";

const ListItemTextComponent = ({ text }) => {
    
  const listItemText = (text) =>
    `${text.slice(0, 40)}${text.length > 40 ? "..." : ""}`;

  return (
    <ListItemText
      sx={{ padding: "8px 0px", margin: "0px" }}
      primary={listItemText(text)}
    />
  );
};

ListItemTextComponent.propTypes = {
  text: PropTypes.string,
};

export default ListItemTextComponent;
