import React from 'react';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';

import UsersTable from './UsersTable';
import OrganizationUsersTable from './OrganizationUsersTable';

const pageInfo = {
  label: "Admin",
  name: "Users",
};

const AdminUsers = () => {
  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem='users' >

      <TabPanels labels={[ "Users", "Organization Users" ]} >
            <div>
              <UsersTable />
            </div>
            <div>
              <OrganizationUsersTable />
            </div>
      </TabPanels>

    </Layout>
)};

export default AdminUsers;
