import React, { useState } from 'react'
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from 'react-router-dom';

import PostResource from 'resources/organization/PostResource';
import OrganizationResource from 'resources/organization/OrganizationResource';

import ConfirmModal from 'components/ConfirmModal';

import NewsletterModal from '../NewsletterModal';
import ActionButtons from './ActionButtons';

import Button from 'ui/Button';
import CustomChip from 'ui/CustomChip';

import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import copyToClipboard from 'utils/copyToClipboard';
import { useError } from 'utils/useErrorController';
import isMobileDevice from 'utils/mobileAndTabletCheck';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// import IconButton from '@mui/material/IconButton';
import { List, ListItem, ListItemText } from '@mui/material';

const PostsList = () => {
    const [newsletterModal, setNewsletterModal] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);

    const { fetch } = useController();
    const history = useHistory();
    const params  = useParams();

    const organizationId = params.organizationId;

    const data = useSuspense(PostResource.list(), {organization_id: organizationId});
    const { setOpen, setMessage, setSeverity } = useToast();
    const { handleError, loading, setLoading } = useError();

    const handlePreview = (post) => {
        window.open(post.external_url, '_blank');
    };

    const handleNewsletterModalClose = () => {
      setNewsletterModal(false);
      setSelectedPost(null);
      setLoading(false);
    };

    const handleDelete = async () => {
        try {
            setLoading(true);
            await fetch(PostResource.delete(), {organization_id: organizationId, id: selectedPost.id});
            await fetch(PostResource.list(), {organization_id: organizationId});
            await fetch(OrganizationResource.detail(), { id: organizationId })
            customToast('success', `Post Deleted`, setOpen, setSeverity, setMessage);
            setSelectedPost(null);
            setLoading(false);
        } catch (error) {
            handleError(error)
        }
    };

    const handlePin = async (post) => {
        try {
            setLoading(true);
            await fetch(PostResource.update(), {organization_id: organizationId, id: post.id}, {pinned: true});
            await fetch(PostResource.list(), {organization_id: organizationId});
            setLoading(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleConfirmDeleteModal = (post) => {
        setSelectedPost(post);
        setConfirmDelete(true);
    };

    const handleUrlCopy = (post) => {
        copyToClipboard(post.external_url, setOpen, setSeverity, setMessage)
    };

    const handleNewsletter = (post) => {
        setNewsletterModal(true);
        setSelectedPost(post);
    };

    const isMobile = isMobileDevice();
    const listFlexDirection = isMobile ? 'column' : 'row';
    const listGap = isMobile ? '0px' : '20px';
    const borderTop = pinned => pinned ? '1px solid var(--highlight-color)' : '';
    const listItemStyle = (pinned) => {
        return {
            backgroundColor: "#ffff", 
            margin: '16px 0px', 
            display: 'flex', 
            flexDirection: listFlexDirection, 
            borderRadius: 'var(--radius)',  
            padding: '20px', 
            gap: listGap, 
            borderTop: borderTop(pinned)}
        }
    
    const listItemText = (post) => (post.title ? `${post.title.slice(0, 40)}${post.title.length > 40 ? '...' : ""}` : `${post.body.slice(0, 40)}${post.body.length > 40 ? '...' : ""}`);
    
    return (

    <div>
        <List sx={{padding: 'var(--mobilePadding)'}}>

            <Button icon={AddCircleOutlineIcon} highlighted fullwidth onClick={() => history.push(`/organizations/${organizationId}/content/new`)} loading={loading}>
                Add new post
            </Button>

            {data.map((post, index) => (
                <ListItem 
                    key={index + 1}
                    sx={listItemStyle(post.pinned_at)}>

                    <ListItemText 
                        sx={{padding: '8px 0px', margin: '0px'}}
                        primary={listItemText(post)} />
                    
                    {post.status && <CustomChip label={post.status} minwidth padded/>}

                    <ActionButtons
                        post={post} 
                        loading={loading} 
                        organizationId={organizationId} 
                        history={history} 
                        handleConfirmDeleteModal={handleConfirmDeleteModal} 
                        handleUrlCopy={handleUrlCopy} 
                        handlePreview={handlePreview}
                        handleNewsletter={handleNewsletter}
                        handlePin={handlePin}
                        />

                </ListItem>
            ))}
        </List>

        <ConfirmModal
                title={`Deleting post`}
                open={confirmDelete}
                setOpen={setConfirmDelete}
                onConfirm={handleDelete}
            >
                You are about to delete a post. Would you like to continue?
        </ConfirmModal>

        { newsletterModal && 
            <NewsletterModal 
                open={newsletterModal}
                onClose={handleNewsletterModalClose}
                postId={selectedPost.id}
                organizationId={organizationId} />}

    </div>
  )
};

export default PostsList;