import { validateEmail } from "utils/validations/validateEmail";
import { validateStringField } from "utils/validations/validateStringField";

export const handleValidation = (organization) => {

    const {title, slug, admin_email, country} = organization;
    
    validateStringField(title, "the organization title");
    validateStringField(slug, "the organization slug");
    validateStringField(country, "the organization country");
    validateEmail(admin_email, 'admin email');
};