import React from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";

import { DialogActions } from "@mui/material";

const Actions = ({onSubmit, loading, pageElementId}) => {
  return (
    <DialogActions>
      <Button onClick={onSubmit} highlighted fullwidth loading={loading}>
        {pageElementId ? "Update" : "Create"}
      </Button>
    </DialogActions>
  );
};

Actions.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  pageElementId: PropTypes.number
};

export default Actions;
