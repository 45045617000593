import React from 'react';

import TableButton from 'components/TableButton';
import { getDateSelector } from 'utils/tables/getDateSelector';
import { getDateFormat } from 'utils/tables/getDateFormat';

import EditIcon from '@mui/icons-material/Edit';
import OpenInNew from '@mui/icons-material/OpenInNew';

const getActionButtons = (row) => {
  return [
    {
      title: "See Event",
      Icon: OpenInNew,
      action: () => window.location.href = row.external_url
    },
    {
      title: "Edit",
      Icon: EditIcon,
      action: () => window.location.href = '/organizations/' + row.organization_id + '/events/' + row.id + '/edit'
    }
  ];
};

export const eventsColumns = [
  {
    field: "organization",
    name: "Organization",
    selector: (row) => row.organization_title,
    sortable: true,
    width: "25%",
  },
  {
    field: "id",
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "15%",
  },
  {
    field: "title",
    name: "Title",
    selector: (row) => row.title,
    sortable: true,
    width: "35%",
  },
  {
    field: "sold_tickets_count",
    name: "Sold Tickets",
    selector: (row) => row.sold_tickets_count,
    sortable: true,
    width: "20%",
  },
  {
    field: "total_revenue",
    name: "Total Revenue",
    selector: (row) => row.event_revenue,
    sortable: true,
    width: "20%",
  },
  {
    field: "start_date",
    name: "Start Date",
    selector: (row) => getDateSelector(row.start_date),
    format: (row) => getDateFormat(row.start_date, 'lll'),
    sortable: true,
    width: "25%",
  },
  {
    selector : (row) =>  
      <div>
        { getActionButtons(row).map((button, index) => (
          <TableButton 
            key={index}
            title={button.title}
            Icon={button.Icon}
            action={button.action}
          />
        ))}
      </div>,
    sortable: false,
    width: '15%'
  },
];
