import React from 'react';
import PropTypes from 'prop-types';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import ProfileResource from 'resources/Profile/ProfileResource';

import Title from './Title';
import OrganizationDetails from './OrganizationDetails';
import Footer from './Footer';

import ExpressForm from 'components/authForms/ExpressForm';

import { checkCustomFields } from 'utils/signUpCustomFields';
import useAuthModalController from 'utils/useAuthModalController';

import Dialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';

import "./styles.css";

const FreeSubscriptionModal = ({ open, onClose, customDescription }) => {

  //prevents data fetching before component is needed
  if (!open) return null; 

  const {organizationSlug} = useParams();

  const organization = useSuspense(ProfileResource.detail(), {id: organizationSlug})

  const {description, title, image, followers, free_pass_claim_token} = organization;

  const hasCustomFields = checkCustomFields(organizationSlug);
  
  const { Form, handleLoginChange, handleClose } = useAuthModalController(onClose);

  return (
    <Dialog open={open} fullScreen className='free-subscription-modal'>

      <Title onClose={handleClose} />

      <DialogContent className='content'>
        <OrganizationDetails 
          orgTitle={title}
          orgDescription={customDescription ? customDescription : description}
          orgImage={image}
          orgFollowers={followers}
        />

        {hasCustomFields 
          ? <Form 
              done={handleClose} 
              organizationSlug={organizationSlug}
              onLoginChange={handleLoginChange} 
            />
            
          : <ExpressForm
              freePassClaimToken={free_pass_claim_token}
              organizationSlug={organizationSlug} 
              onClose={onClose}
            />
        }
      </DialogContent>

      {!hasCustomFields && <Footer /> }

    </Dialog>
  );
};

FreeSubscriptionModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  customDescription: PropTypes.string
};

export default FreeSubscriptionModal;