import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import NewsletterResource from 'resources/organization/NewsletterResource';

import handleValidation from './handleValidation';

import Button from 'ui/Button';

import { useError } from 'utils/useErrorController';

import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';

const NewsletterModal = ({open, onClose, postId, organizationId}) => {

    const [ destinationEmails, setDestinationEmails ] = useState('');
    const { handleError, validate, loading } = useError();
    const { fetch } = useController();
  
    const handleSubmit = async () => {
      try {
        const emailsArray = destinationEmails.replace(/\s+/g, '').split(',');
        validate(emailsArray, handleValidation);
        await fetch(NewsletterResource.create(), {organization_id: organizationId}, {emails: emailsArray, post_id: postId});
        onClose();
      } catch (error) {
        handleError(error);
      }
    };

    const handleChange = ({target: {value}}) => {
        setDestinationEmails(value);
    };
    
    return (
    <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={ onClose }>
      <DialogTitle>
        Send Test Newsletter
      </DialogTitle>

      <DialogContent>
        <form onSubmit={ (e) => handleSubmit(e) }>
          <div className={ `new-form-field` }>
             <TextField
              fullWidth
              name={ destinationEmails }
              label="Destination Emails"
              color="warning"
              value={ destinationEmails }
              onChange={ handleChange }
              helperText={ "Enter one or more valid email addresses, separated by commas." }
            />
          </div>  
        </form>
      </DialogContent>

      <DialogActions>
        <Button 
          icon={SendIcon} 
          onClick={handleSubmit}
          loading={loading}
          minwidth >
            Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewsletterModal.propTypes = {
  open: PropTypes.true,
  onClose: PropTypes.func,
  handleError: PropTypes.func,
  postId: PropTypes.object,
  organizationId: PropTypes.number,
};

export default NewsletterModal;