import React from 'react';
import PropTypes from 'prop-types';

import MarkdownEditor from 'components/MarkdownEditor';

import FormSection from 'ui/FormSection';

import { DialogContent, TextField } from '@mui/material';

const Content = ({object, onObjectChange}) => {

  const handleChange = ({target: {name, value}}) => {
    let newSubscription = { ...object, [name]: value };
    onObjectChange(newSubscription);
  };

  return (
    <DialogContent>
      <FormSection slim>
        <TextField
            fullWidth
            color='warning'
            name={'subject'}
            size={'small'}
            label={'Email Subject *'}
            value={ object.subject}
            onChange={handleChange}
          />
        {<MarkdownEditor 
            object={object}
            setObject={onObjectChange}
            attribute={"content"}
            placeholder={"Email Body *"}
            height={"200px"} />}
      </FormSection>
    </DialogContent>
  );
};

Content.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func
};

export default Content;