const createFormData = (organization) => {
    const {profile_tabs, preferences} = organization;
    const formData = new FormData();

    Object.keys(preferences).forEach(preference => {
      if (preference === "hide_description" && preferences[preference]) {
        formData.append('preferences[]', 'hide_description');
      }
      if (preference === "show_application_fee" && preferences[preference]) {
        formData.append('preferences[]', 'show_application_fee');
      }
    });

    profile_tabs.forEach(tab => {
      formData.append('profile_tabs[]', tab);
    })

    return formData;
  };

  export default createFormData;
