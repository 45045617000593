import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Viewer } from '@toast-ui/react-editor';

const CustomModal = ({handleClose, customModalContent}) => {
  
  const {title, content, button, secondaryButton, supportMarkdown} = customModalContent;
  
  return (
    <Dialog open={!!customModalContent} fullWidth={ true } maxWidth='sm'>
      <DialogTitle>
        {title}
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: 'black',
          }}
          >
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {supportMarkdown ?
          <Viewer
              initialValue={content}
              extendedAutolinks={true}
              linkAttributes={{
                  target: '_blank',
                  rel: 'noopener noreferrer',
              }}
          />
        :
        <DialogContentText>
          {content}
        </DialogContentText>}
      </DialogContent>
      {button &&
        <DialogActions sx={{display: 'flex'}}>
          {button}
          {secondaryButton}
        </DialogActions>}
    </Dialog>
  );
};

CustomModal.propTypes = {
  handleClose: PropTypes.func,
  customModalContent: PropTypes.object,
};

export default CustomModal;