import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'components/StatCard';
import { convertPriceFromCents } from 'utils/rewards';
import { currencySymbol } from 'utils/rewards';


const Stats = ({ tickets, events_count }) => {
    
    const ticket = tickets?.[0];

    return (
        <div className="event-stats-grid grid custom-margin">

            <StatCard type='number'
                color='rgb(255, 99, 132)'
                highlight={ ticket ? ticket.total_count : 0 }
                title='Sold Tickets' />

            <StatCard type='number'
                color='rgb(54, 162, 235)'
                highlight={  ticket ? ticket.total_revenue === 0 ? 0 : convertPriceFromCents(ticket.total_revenue) : 0 }
                title={`Total Revenue (${ ticket && ticket.currency ? currencySymbol[ticket.currency] : '$'})`} />

            <StatCard type='number'
                    color='rgb(255, 205, 86)'
                    highlight={ events_count }
                    title='Total Events' />

        </div>
    )};

Stats.propTypes = {
    tickets: PropTypes.array,
    events_count: PropTypes.number
};

export default Stats;