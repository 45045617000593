import { useState } from 'react';

import SignUpForm from 'components/authForms/SignUpForm';
import LogInForm from 'components/authForms/LogInForm';

const useAuthModalController = (onClose) => {
  const [logIn, setLogIn] = useState(false);

  const Form = logIn ? LogInForm : SignUpForm;

  const handleLoginChange = () => {
    setLogIn((prevLogIn) => !prevLogIn);
  };

  const handleClose = () => {
    setLogIn(false);
    onClose();
  };

  return {
    logIn,
    Form,
    handleLoginChange,
    handleClose
  };
};

export default useAuthModalController;