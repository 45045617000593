import React from "react";
import PropTypes from 'prop-types';

import Details from "./Details";
import PassSelector from "./PassSelector";
import Attachments from "./Attachments";
import ActionButtons from "./ActionButtons";
import Banner from "./Banner";

import SeoOptions from "components/SeoOptions";
import TagSelector from "components/TagSelector";

import FormSection from "ui/FormSection";

const PostForm = ({post, setPost, organizationId, onSubmit, onPublish, loading, setLoading, handleError, onChange, onSendNewsletter, onSendTestNewsletter}) => {

    const {slug, reward_ids, title, short_description} = post;

    return (
            <>
                <Details
                    post={post}
                    setPost={setPost}
                    title={title}
                    onChange={onChange} />

                <Banner
                    object={post}
                    onObjectChange={setPost}
                    loading={loading} 
                    onError={handleError} />

                <Attachments 
                    object={post}
                    onObjectChange={setPost} 
                    loading={loading}
                    setLoading={setLoading}
                    onError={handleError} />

                <FormSection title={"Tags"}>
                    <TagSelector
                        object={post}
                        onObjectChange={setPost}
                        objectType={"post"}
                        organizationId={organizationId} />
                </FormSection>

                <SeoOptions
                    slug={slug}
                    onChange={onChange}
                    showShortDescription={true}
                    short_description={short_description} />

                <PassSelector
                    reward_ids={reward_ids}
                    organizationId={organizationId}
                    onChange={onChange} />

                <ActionButtons
                    post={post}
                    organizationId={organizationId}
                    loading={loading}
                    onSubmit={onSubmit}
                    onPublish={onPublish}
                    onSendNewsletter={onSendNewsletter}
                    onSendTestNewsletter={onSendTestNewsletter} />
            </>
    );
};

PostForm.propTypes = {
    post: PropTypes.object,
    setPost: PropTypes.func,
    organizationId: PropTypes.number,
    onSubmit: PropTypes.func,
    onPublish: PropTypes.func,
    loading: PropTypes.bool,
    setLoading: PropTypes.func,
    handleError: PropTypes.func,
    onChange: PropTypes.func,
    onSendNewsletter: PropTypes.func,
    onSendTestNewsletter: PropTypes.func
};

export default PostForm;
