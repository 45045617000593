import React from 'react';
import PropTypes from 'prop-types';

const EmbeddedSoundcloud = ({ id }) => {

    const slashCount = (id && id.match(/\//g) || []).length;
    const height = slashCount === 1 ? "164px" : "400px";

    return (
        <div className='embed'>
            <iframe 
                className='soundcloud-embed-iframe' 
                width="100%" 
                height={height} 
                allow="autoplay" 
                loading="lazy" 
                src={`https://w.soundcloud.com/player/?url=https://soundcloud.com/${id}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=false&visual=false`}>
            </iframe>
        </div> 
    );
};

EmbeddedSoundcloud.propTypes = {
    id: PropTypes.string,
};

export default EmbeddedSoundcloud;