import React, {useState} from 'react';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import RegistrationResource from 'resources/auth/RegistrationResource';

import signUpValidation from './Validations/signUpForm';

import { StyledTextField } from 'components/StyledTextField';

import Button from 'ui/Button';
import Label from "ui/text/Label";
import FormSection from 'ui/FormSection';

import AuthToken    from 'utils/localStorage/AuthToken';
import CoursesList    from 'utils/localStorage/CoursesList';
import UserName    from 'utils/localStorage/UserName';
import { useError } from 'utils/useErrorController';
import { termsOfUseLink, privacyPolicyLink } from "utils/urls";
import { checkCustomFields, signUpCustomFields, transformCustomField } from 'utils/signUpCustomFields';

import { t } from 'i18n/index';
import { Link } from '@mui/material';

const SignUpForm = ({done, organizationSlug, onLoginChange, allowPartialSignIn=false}) => {

  const { fetch } = useController();
  const { loading, setLoading, validate, handleError } = useError();//#endregion
  const [data, setData] = useState(organizationSlug ? {organization_slug: organizationSlug} : {});

  const hasCustomFields = checkCustomFields(organizationSlug);
  const customFields = hasCustomFields && signUpCustomFields[organizationSlug];

  const handleChange = ({ target: {name, value}}) => {
    setData( {
      ...data,
      [name]: value
    });
  };

  const handleEmailSignup = async () => {
    try {
      validate(data, signUpValidation);

      const formData = new FormData();
      Object.entries(data).forEach(([name, value]) => formData.append(name, value));
      if (allowPartialSignIn) formData.append('lite_auth', allowPartialSignIn);

      await handleSignUp(formData);
    } catch (error) {
      handleError(error);
    }
  };

  const handleSignUp = async (data) => {
    const { auth_token, user } = await fetch(RegistrationResource.create(), data);
    if (auth_token){
      AuthToken.write(auth_token);
      CoursesList.write([]);
      UserName.write(user.name);

      if (done){
        done();
      }
      setLoading(false);
    }
  };

  return (
    <FormSection slim>
      <StyledTextField
                  label={t('literal:name')}
                  name="name"
                  value={ data.name || '' }
                  onChange={ handleChange }
                  fullWidth>
      </StyledTextField>

      <StyledTextField
                  label={t('literal:email')}
                  name="email"
                  value={ data.email || '' }
                  onChange={ handleChange }
                  fullWidth>
      </StyledTextField>

      {hasCustomFields &&
        customFields.map((name, index) => (
          <StyledTextField
            key={index}
            label={transformCustomField(name, organizationSlug)}
            name={name}
            value={ data[name] || '' }
            onChange={ handleChange }
            fullWidth>
          </StyledTextField>
      ))}

      <Label secondary padded>
        {t("authForms:signUpForm:instruction")}<br/>
        {t("authForms:signUpForm:alreadyRegisteredQuestion")}
        <Link component="button" onClick={ onLoginChange }>
          {t("authForms:signUpForm:signInCall")}!
        </Link>
      </Label>

      <Button fullwidth loading={loading} onClick={ handleEmailSignup }>
        {t('literal:continue')}
      </Button>

      <Label secondary padded>
        {t("profile:freeSubscriptionModal:agreement")}&nbsp;
        <Link href={termsOfUseLink} target="_blank">
          {t("literal:termsAndConditions")}
        </Link>
        &nbsp;{t("literal:and_sc")}&nbsp;
        <Link href={privacyPolicyLink} target="_blank">
          {t("literal:privacyPolicy")}
        </Link>
      </Label>

    </FormSection>
  )
};

SignUpForm.propTypes = {
  done: PropTypes.func,
  organizationSlug: PropTypes.string,
  onLoginChange: PropTypes.func,
  allowPartialSignIn: PropTypes.bool
};

export default SignUpForm;
