import React from 'react';
import PropTypes from 'prop-types';

import Attachments from './Attachments';

import "./styles.css";

const MediaGallery = ({ image_urls, video_urls }) => {

  const hasImages = image_urls && image_urls.length > 0;
  const hasVideos = video_urls && video_urls.length > 0;

  if (!hasImages && !hasVideos) return null;
      
  return (
    <div className="ui-media-gallery">
        <Attachments image_urls={image_urls} video_urls={video_urls}/>
    </div>
  )
};

MediaGallery.propTypes = {
    embeds: PropTypes.array,
    image_urls: PropTypes.array,
    video_urls: PropTypes.array,
};

export default MediaGallery;