import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

const Title = ({title, onChange}) => {
  return (
    <TextField
        name="title"
        size='small'
        label="Change Name to *"
        fullWidth
        color="warning"
        value={title}
        onChange={onChange}
        />
  );
};

Title.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func
};

export default Title;