import { React } from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import OnboardingResource from 'resources/organization/OnboardingResource';

import Layout from 'components/layouts/index';

import Checklist from './Checklist';

const pageInfo = {
  name: "Welcome to your Membrz Club",
  description: "Let's get your Club page ready! Here are some steps you can take to get started.",
}


const Onboarding = () => {

  const params            = useParams();
  const organizationId    = parseInt(params.organizationId);
  const onboardingDetails = useSuspense(OnboardingResource.detail(), {organization_id: organizationId});

  return (
    <Layout context='teacher'
            activeMenuItem='onboarding'
            pageInfo={pageInfo}>

      <Checklist onboardingDetails={onboardingDetails}/>

    </Layout>
  );
};

export default Onboarding;
