import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import NewsletterResource from 'resources/organization/NewsletterResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import { newslettersColumns } from 'utils/tables/columns/newslettersColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';

const NewslettersTabContent = ({ organizationId }) => {

    const {
      objectState, 
      fetchData,
      handlePageChange
    } = usePaginationAndFilteringController({
        fetchDataResource: NewsletterResource,
        fetchDataParams: { organization_id: organizationId },
    })

    useEffect(() => {
      fetchData();
    }, []);

  return (
    <div style={{ flexGrow: 1 }}>
        <DataSelectionTable records={objectState.filteredObjects}
                            columns={newslettersColumns}
                            serverPaginationProps={{
                              paginationServer: true,
                              paginationTotalRows: objectState.totalCount,
                              page: objectState.page,
                              onChangePage: handlePageChange
                            }}
                            subHeader={false}
        />
    </div>
  );
};

NewslettersTabContent.propTypes = {
    organizationId: PropTypes.number
};

export default NewslettersTabContent;