export const createFormData = (org) => {
    const formData = new FormData();

    const {title, status, hasMinting, hasProducts, hasSales, hasWhitelabel, hasMysterybox} = org;

    const fields = [
      {key: "title", value: title},
      {key: "status", value: status},
    ]

    if (hasMinting) fields.push({key: "modules[]", value: "minting"});
    if (hasProducts) fields.push({key: "modules[]", value: "products"});
    if (hasSales) fields.push({key: "modules[]", value: "sales"});
    if (hasWhitelabel) fields.push({key: "modules[]", value: "whitelabel"});
    if (hasMysterybox) fields.push({key: "modules[]", value: "mysterybox"});

    fields.forEach(field => {
      formData.append(field.key, field.value);
    });
    return formData;
  }