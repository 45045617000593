import React, { useRef } from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useError } from "utils/useErrorController";

const ImageUploadButton = ({ object, onObjectChange, onImageChange, required=false, onUnsavedChanges }) => {
  
  const hiddenFileInput = useRef(null);

  const {filename} = object;
  const {loading, setLoading} = useError();

  const handleFileUpload = (event) => {
    setLoading(true);
    if (!event.target.files) {
      setLoading(false);
      return;
    }
    const file = event.target.files[0];

    let newObject = {...object};
    newObject['file'] = file;
    newObject['filename'] = file && file.name;
    onObjectChange(newObject);
    if (onImageChange) onImageChange(event.target.files[0]);
    if (onUnsavedChanges) onUnsavedChanges(true);

    setLoading(false);
  };

  return (
    <>
      <Button
        fullwidth
        secondary
        icon={UploadFileIcon}
        loading={loading}
        onClick={(event) => {
          event.preventDefault()
          hiddenFileInput.current.click(); 
        }}
        >
        {filename ? `Uploaded ${filename}` : `Upload image ${required ? '*' : ''}`}
      </Button>
      <input ref={hiddenFileInput} type="file" accept="image/*" name="image" hidden onChange={handleFileUpload} style={{display:'none'}}/>
    </>
  );
};

ImageUploadButton.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  onImageChange: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  required: PropTypes.bool,
  onUnsavedChanges: PropTypes.func
};

export default ImageUploadButton;
