export const slugify = (object, onObjectChange) => {
    if (!object.title || object.slug) {
        return;
    } else {
        editSlug(object, onObjectChange);
    }
};

const editSlug = (object, onObjectChange) => {
    let newSlug;
    if (object.title.trim() === "") {
        newSlug = "";
    } else {
        newSlug = object.title.toLowerCase()
                     .trim()
                     .normalize('NFD')
                     .replace(/[\u0300-\u036f]/g, '')
                     .replace(/[^a-z0-9\s-_]/g, ' ')
                     .trim()
                     .replace(/[\s-]+/g, '-')
                     .replace(/[\s_]+/g, '_')
    }

    let newObject = { ...object, slug: newSlug };
    onObjectChange(newObject);
};