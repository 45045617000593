import React from 'react';
import PropTypes from 'prop-types';

import { DialogTitle } from '@mui/material';

import { t } from 'i18n/index';

const Title = ({logIn}) => {

  const title = logIn ? `${t('authForms:authModal:welcomeBack')}` : t('authForms:authModal:getStarted');

  return (
    <DialogTitle>{title}</DialogTitle>
  )
};

Title.propTypes = {
    resetToken: PropTypes.string,
    preResetDescription: PropTypes.bool,
    logIn: PropTypes.bool,
};

export default Title;