import React, { useCallback } from 'react';
import { useSuspense, useController } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";

import RewardResource from 'resources/organization/RewardResource';
import CourseResource from 'resources/organization/CourseResource';
import OrganizationResource from 'resources/organization/OrganizationResource';

import Layout from 'components/layouts/index';

import Form from './form';
import handleValidation from './handleValidation';
import createFormData from './createFormData';

import { courseType } from 'utils/constants';
import { useError } from 'utils/useErrorController';

const NewReward = () => {
  const { fetch } = useController();
  const history = useHistory();

  const params = useParams();
  const courseId = parseInt(params.courseId);
  const rewardId = parseInt(params.rewardId);
  const organizationId = parseInt(params.organizationId);

  const reward = useSuspense(RewardResource.detail(), { id: rewardId, course_id: courseId, organization_id: organizationId });
  const course = useSuspense(CourseResource.detail(), {id: courseId, organization_id: organizationId });
  const organization = useSuspense(OrganizationResource.detail(), {id: organizationId});

  const { handleError, loading, setLoading, validate } = useError();

  const searchParams = new URLSearchParams(window.location.search);
  const mysteryBoxReward = searchParams.has('mysteryBox') && searchParams.get('mysteryBox') === 'true';

  const mysteryBox = course.category === "mysteryBox";
  const published = mysteryBox && course.published;
  const isDisabled = published ? true : false;
  const hasBeenIssued = reward.stats['issued'] > 0;
  const membership = course.category === courseType.MEMBERSHIP.value;

  const title = membership ?
                `Edit membership pass` :
                `Edit credential`;

  const pageInfo = {
    // label: "Web3, Blockchain and Digital Asset Expert Track",
    name: title
  };

  const handleOnSubmit = useCallback(
    async (e, reward, templateVars) => {
      try {
        e.preventDefault();
        validate({reward, course}, handleValidation);
        const formData = createFormData(reward, templateVars, mysteryBox, mysteryBoxReward, published, isDisabled);
        const { id } = await fetch(RewardResource.update(), { id: rewardId, course_id: courseId, organization_id: organizationId }, formData);

        // success!
        if (id){
          fetch(RewardResource.list(), {course_id: courseId, organization_id: organizationId});
          history.goBack();
          setLoading(false);
        }
      }  catch (error) {
        handleError(error)
      }
    },
    [fetch],
  );

  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >

      <Form record={ reward }
            course={ course }
            isDisabled={ isDisabled }
            hasBeenIssued={ hasBeenIssued }
            loading={ loading }
            mysteryBoxReward={ mysteryBoxReward }
            published= { published }
            onSubmit= { handleOnSubmit }
            handleError={ handleError }
            currency={ organization.currency } />
            
    </Layout>
  )
};

export default NewReward;
