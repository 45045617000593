import React from 'react';
import PropTypes from 'prop-types';

import TextField from "@mui/material/TextField";

import { LocalizationProvider,  } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const DateInputField = ({ attribute, onChange, label, object, disablePast=false, setUnsavedChanges, dateOnly=false, timeOnly=false, disabled }) => {

    const handleDateTimeChange = (newValue) => {
        if (setUnsavedChanges) setUnsavedChanges(true);
        let newOffer = { ...object, [attribute]: newValue };
        onChange(newOffer);
    };

    const Picker = timeOnly ? TimePicker : dateOnly ? DatePicker : DateTimePicker;
    
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Picker label={ label }
                    value={ object[attribute] ? object[attribute] : null }
                    onChange={ handleDateTimeChange }
                    disablePast={ disablePast}
                    disabled={ disabled }
                    renderInput={ (params) => <TextField {...params} color='warning' fullWidth size='small'/> }
            />
        </LocalizationProvider>
    );
};

DateInputField.propTypes = {
    attribute: PropTypes.string,
    onChange: PropTypes.func,
    label: PropTypes.string,
    object: PropTypes.object,
    disablePast: PropTypes.bool,
    setUnsavedChanges: PropTypes.func,
    dateOnly: PropTypes.bool,
    timeOnly: PropTypes.bool,
    disabled: PropTypes.bool
};

export default DateInputField;