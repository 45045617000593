import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class IntegrationResource extends AuthenticatedResource {
  static urlRoot = '/profile/:organization_slug/integrations' as const;

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}
