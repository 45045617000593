import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import SettingsIcon from '@mui/icons-material/Settings';
// import DownloadIcon from '@mui/icons-material/Download';
// import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import ShareIcon from '@mui/icons-material/Share';
import LinkIcon from '@mui/icons-material/Link';

import { t } from 'i18n/index';
import { ListItemIcon, ListItemText } from '@mui/material';

const Student = ({ showManageSubscription, onRedirectToMemberships, claimedOnChain, onRedirect, onClaim, handleOpenShareModal }) => {

    const menuItems = [
        {
          icon: <SettingsIcon />,
          description: t('literal:manageSubscription'),
          action: onRedirectToMemberships,
          show: showManageSubscription,
        },
        {
          icon: <LinkIcon />,
          description: claimedOnChain ? t('literal:showOnChain') : t('literal:claimOnChain'),
          action: claimedOnChain ? onRedirect : onClaim,
          show: !!onClaim
        },
        {
          icon: <ShareIcon />,
          description: t('literal:share'),
          action: handleOpenShareModal
        },
        // {
        //   icon: <DownloadIcon />,
        //   description: t('literal:downloadImage'),
        //   action: onImageDowload
        // },
        // {
        //   icon: <SimCardDownloadIcon />,
        //   description: t('literal:downloadPDF'),
        //   action: onPDFDowload
        // }
    ];

  return (
    <div>
        {menuItems.map((menuItem, index) => {
          if (menuItem.show !== undefined && !menuItem.show) {
            return null;
          }

          return (
            <MenuItem key={index} onClick={menuItem.action}>
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText>{menuItem.description}</ListItemText>
            </MenuItem>
          );
      })}
    </div>
  )
};

Student.propTypes = {
  showManageSubscription: PropTypes.bool,
  onRedirectToMemberships: PropTypes.func,
  claimedOnChain: PropTypes.bool,
  onRedirect: PropTypes.func,
  onClaim: PropTypes.func,
  handleOpenShareModal: PropTypes.func,
  onImageDowload: PropTypes.func,
  onPDFDowload: PropTypes.func
};

export default Student;
