import AuthenticatedResource from "resources/public/AuthenticatedResource";

export default class UserLanguageResource extends AuthenticatedResource {
    static urlRoot = '/user_language' as const;

    readonly id: number | undefined = undefined;

    pk() {
        return this.id?.toString();
    }
}
