import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormSection from 'ui/FormSection';

import { courseType } from 'utils/constants';

import Title from './Form/Title';
import Pricing from './Form/Pricing';
import Points from './Form/Points';
import Media from './Form/Media';
import Metadata from './Form/Metadata';
import Footer from './Form/Footer';
import Description from './Form/Description';
import Advanced from './Form/Advanced';

import { emptyReward, booleanAttrs } from './Form/consts';

function initReward(record, course) {
  return {
    ...emptyReward[course.category],
    ...record,
  };
}

const RewardForm = ({record={}, course, isDisabled=false, hasBeenIssued=false, loading, mysteryBoxReward, published=false, onSubmit, handleError, currency}) => {

  const [reward, setReward] = useState(initReward(record, course));

  const mysteryBox = course.category === courseType.MYSTERYBOX.value;

  const handleChange = ({target: {name, value, checked}}) => {
    let newReward = {...reward};

    if (name === 'pricings' && value && value.length === 0) {
      newReward["external_contract_address"] = '';
    }

    newReward[name] = booleanAttrs.includes(name) ? checked : value;
    setReward(newReward);
  };

  return (
      <form onSubmit={ (e) => onSubmit(e, reward) } >
        <FormSection title="Product Details">
          <Title
              reward={reward}
              isDisabled={isDisabled}
              onChange={handleChange} />

          <Description
              reward={reward}
              setReward={setReward}
              isMembershipCollection={course.category === courseType.MEMBERSHIP.value} />
        </FormSection>

        <FormSection >
          <Pricing
              pricings={reward.pricings}
              collectionType={course.category}
              onChange={handleChange}
              mysteryBoxReward={mysteryBoxReward}
              isDisabled={isDisabled}
              hasBeenIssued={hasBeenIssued}
              currency={currency} />
        </FormSection>


          { course.category === courseType.MEMBERSHIP.value &&
            <FormSection title="Points">
              <Points
                reward={reward}
                isDisabled={isDisabled}
                onChange={handleChange} />
            </FormSection>
          }

          <FormSection title="Media">
            <Media
              object={reward}
              onObjectChange={setReward}
              isDisabled={isDisabled}
              loading={loading}
              onError={handleError}
              />
          </FormSection>


          { !mysteryBoxReward &&
            <Metadata
              reward={reward}
              course={course}
              isDisabled={isDisabled}
              onChange={handleChange} />
          }

          <FormSection title={"Advanced Settings"} expandable={true}>
            <Advanced
              reward={reward}
              course={course}
              mysteryBoxReward={mysteryBoxReward}
              isDisabled={isDisabled}
              published={published}
              onChange={handleChange} />
          </FormSection>

          <Footer
              loading={loading}
              published={published}
              record={record}
              mysteryBox={mysteryBox}
              reward={reward}
              onChange={handleChange} />
      </form>
  );
};

RewardForm.propTypes = {
  record: PropTypes.object,
  course: PropTypes.object,
  isDisabled: PropTypes.bool,
  hasBeenIssued: PropTypes.bool,
  loading: PropTypes.bool,
  mysteryBoxReward: PropTypes.bool,
  published: PropTypes.bool,
  onSubmit: PropTypes.func,
  handleError: PropTypes.func,
  currency: PropTypes.string
};

export default RewardForm;
