import moment from 'moment';
import { courseType } from './constants';

/*REWARD HELPERS*/
export const currencySymbol = {"EUR" : "€", "USD" : "$", "GBP" : "£", "BRL" : "R$"};

export const extractPrice = (pricings, priceFrequency) => {
    return priceFrequency ? pricings.find(element => element.frequency === priceFrequency ).price : pricings[0].price
  };

export const extractCurrency = (pricings, priceFrequency) => {
    return priceFrequency ? pricings.find(element => element.frequency === priceFrequency ).currency : pricings[0].currency
  };

export const extractPricingFrequencies = (pricings) => {
    return pricings.map(pricing => {return {frequency: pricing.frequency, id: pricing.id}})
  };

export const extractPricingId = (frequency, pricings) => {
    return pricings.find(pricing => pricing.frequency === frequency).id
  };

export  const convertPriceFromCents = (price) => {
    if (!price) {
      return null;
    }

    const convertedPrice = price / 100;
    const decimalPart = convertedPrice % 1;

    if (decimalPart === 0) {
      return convertedPrice.toFixed(0); // Display as an integer
    } else {
      return convertedPrice.toFixed(2); // Display with two decimal places
    }
  };

export const convertPriceToCents = (price) => {
    if (!price) {
      return null;
    }

    const num = price * 100
    return Math.floor(num.toFixed(2));
  };

export function priceIndexFromPricings(pricings) {
  if (pricings == null) { return {}; }

  const index = {};
  pricings.forEach( ({frequency, price}) => {
    index[frequency] = convertPriceFromCents(price);
  });
  return index;
}

const priceBuilder = (frequency, price, currency) => {
   return { frequency, price: convertPriceToCents(price), currency: currency }
};

export function pricingsFromPriceIndex(prices, currency) {
  const pricings = Object.entries(prices)
                         .map( ([frequency, price]) => ( price !== "" ? priceBuilder(frequency, price, currency) : null )) 
                         .filter( (p) => p != null);
  return pricings.length > 0 ? pricings : null;
}

export const isRewardUnavailable = (reward) => {
    const atSupplyLimit = reward.supply_limit !== null && reward.supply_limit <= reward.stats["issued"];
    const expiredDate = reward.end_date !== null && moment(reward.end_date) < moment(Date.now());
    return !reward.active_state || atSupplyLimit || expiredDate;
  };

export const getMaxAmount = (reward, issued_count) => {
  if (reward.course_category === courseType.MYSTERYBOX.value) {
    return 1
  } else {
    return Math.min(reward.supply_limit ? reward.supply_limit - reward.stats.issued
      : Infinity, reward.claim_limit - issued_count)
  }
};

import { t } from 'i18n/index';

const helpLabels =  {
  already_issued: t('errors:alreadyIssuedMax'),
  not_eligible: t('errors:notEligible'),
  pre_approved: t('errors:privateClaim'),
  unavailable: t('errors:unavailable'),
};

export const getHelpText = (logged_in, has_been_issued, is_enrolled, is_public, unavailable) => {
  if (unavailable) {
    return helpLabels.unavailable;
  } else if (logged_in && has_been_issued) {
    return helpLabels.already_issued;
  } else if (logged_in && !has_been_issued && !is_public && !is_enrolled) {
    return helpLabels.not_eligible;
  } else if (!logged_in && !is_public) {
    return helpLabels.pre_approved;
  }
}

export const computeRewardVariables = (reward, errorMessage, frequency, claimingGift) => {
  
  const unavailable = isRewardUnavailable(reward);
  
  const { logged_in, name, email, is_enrolled, can_claim, has_been_issued, issued_count } = reward.participant;
  const { pricings, is_public } = reward;

  const showActionButtons = !unavailable && can_claim && !errorMessage;

  const sellable = reward.sellable && !claimingGift;

  const helpText = getHelpText(logged_in, has_been_issued, is_enrolled, is_public, unavailable);
  const maxAmount = getMaxAmount(reward, issued_count);

  const hasPricings = pricings.length > 0
  const pricingId = hasPricings && extractPricingId(frequency, pricings);
  const frequencies = hasPricings && extractPricingFrequencies(pricings);
  const price = hasPricings && extractPrice(pricings, frequency);
  const currency = hasPricings && extractCurrency(pricings, frequency);

  return {
    logged_in,
    name,
    email,
    maxAmount,
    helpText,
    sellable,
    showActionButtons,
    pricingId,
    frequencies,
    price,
    currency,
  };
};