import React from 'react';
import PropTypes from 'prop-types';

import NotFound from 'components/NetworkError/NotFound';
import Internal from 'components/NetworkError/Internal';
import Unauthorized from './Unauthorized';

const fallbackComponents = {
  404: NotFound,
  500: Internal,
  401: Unauthorized,
};

const NetworkError = ({error}) => {

  const FallbackComponent = fallbackComponents[error.status];
  return (
      <FallbackComponent error={error} />
  );
};

NetworkError.propTypes = {
    error: PropTypes.object,
};

export default NetworkError;