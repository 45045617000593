import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

const ExternalEventAttributes = ({external_url, ticket_price, onChange}) => {
  return (
    <>
        <TextField
                fullWidth
                size='small'
                color='warning'
                name={"external_url"}
                label={"External Url"}
                value={external_url}
                onChange={onChange}
            />

        <TextField
                fullWidth
                size='small'
                color='warning'
                name={"ticket_price"}
                label={"Ticket Price"}
                value={ticket_price}
                onChange={onChange}
            />

    </>
  );
};

ExternalEventAttributes.propTypes = {
  external_url: PropTypes.string,
  ticket_price: PropTypes.string,
  onChange: PropTypes.func
};

export default ExternalEventAttributes;