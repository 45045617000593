
export const handleValidation = (promoCode) => {
    const { code, percent_off, category, products } = promoCode;

    validatePromoCode(code);
    validatePercentOff(percent_off);
    if (category === "specific") validateProducts(products);
};

const validatePromoCode = (code) => {
    const promoCodeRegex = /^[A-Z0-9]{4,16}$/;
    if (!promoCodeRegex.test(code)) {
        throw `Promo code must be 4-16 characters and contain only numbers or capital letters`;
    }
};

const validatePercentOff = (percent_off) => {
    if (percent_off < 0 || percent_off > 100) {
        throw "Percent off must be between 0 and 100";
    }
};

const validateProducts = (products) => {
    if (products.length === 0) {
        throw "To create a specific promo code, you must select at least one product.";
    }
};