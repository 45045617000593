import React from 'react';

import { DialogTitle } from '@mui/material';

const Title = () => {
  return (
    <DialogTitle>
        Import preview & confirmation
    </DialogTitle>
  );
};

export default Title;