import React from 'react';
import PropTypes from 'prop-types';

import Videos from './Videos';
import Images from './Images';

const Attachments = ({ image_urls, video_urls }) => {

  return (
    <div className='attachments'>
      <Images image_urls={image_urls} />
      <Videos video_urls={video_urls} />
    </div>
  );
};

Attachments.propTypes = {
    image_urls: PropTypes.array,
    video_urls: PropTypes.array,
};

export default Attachments;