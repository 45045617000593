import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Title = ({children, bold}) => {
  return (
    <h1 className={`ui-title ${bold ? 'bold' : ''}`}>{children}</h1>
  )
};

Title.propTypes = {
  children: PropTypes.node,
  bold: PropTypes.bool,
};

export default Title;
