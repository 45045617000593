import React from "react";
import PropTypes from "prop-types";

import prepareChartData from "./prepareChartData";

import { Line } from "react-chartjs-2";

const LineChart = ({ data, labelKey, dataKey }) => {

  const chartData = prepareChartData(data, labelKey, dataKey);

  return (
    <div style={{ padding: "40px" }}>
      <Line data={chartData} />
    </div>
  );
};

LineChart.propTypes = {
  data: PropTypes.object,
  labelKey: PropTypes.string,
  dataKey: PropTypes.string,
};

export default LineChart;
