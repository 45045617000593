import { getDateFormat } from 'utils/tables/getDateFormat';
import { getDateSelector } from 'utils/tables/getDateSelector';

import { convertPriceFromCents , currencySymbol } from 'utils/rewards';
import { capitalize } from 'utils/capitalize';

export const payoutColumns = [
    {
      field: 'id',
      name: 'Payout ID',
      sortable: true,
      width: "300px",
      selector: (row) => row.id,
    },
    {
      field: 'status',
      name: 'Status',
      sortable: true,
      width: "100px",
      selector: (row) => capitalize(row.status)
    },
    {
      field: 'amount',
      name: 'Amount',
      sortable: true,
      width: "150px",
      selector: (row) => `${convertPriceFromCents(row.amount)} ${currencySymbol[row.currency]}`
    },
    {
      field: 'arrival_date',
      name: 'Arrival Date',
      sortable: true,
      width: "225px",
      selector: (row) => getDateSelector(row.arrival_date),
      format: (row) => getDateFormat(row.arrival_date, 'lll'),
    },
  ];
