import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import FormSection from 'ui/FormSection';
import MediaUploader from 'components/MediaUploader';

const Media = ({object, onObjectChange, onError, setUnsavedChanges}) => {

  const hiddenFileInputs = {
    image: useRef(null),
  };

  const label = "To ensure a smoother user experience any image you upload should be 1600x900.";

  return (
    <FormSection title={"Media"}>
      <MediaUploader
        hiddenFileInputs={hiddenFileInputs}
        object={object}
        onObjectChange={onObjectChange}
        onError={onError}
        setUnsavedChanges={setUnsavedChanges}
        label={label}
      />
    </FormSection>
  );
};

Media.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  onError: PropTypes.func,
  setUnsavedChanges: PropTypes.func
};

export default Media;