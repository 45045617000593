import React from 'react';
import PropTypes from 'prop-types';

import { ListItem } from '@mui/material';
import isMobileDevice from 'utils/mobileAndTabletCheck';

const ListItemComponent = ({children}) => {

    const isMobile = isMobileDevice();
    const listFlexDirection = isMobile ? 'column' : 'row';
    const listGap = isMobile ? '0px' : '20px';
    const listItemStyle = {backgroundColor: "#ffff", margin: '16px 0px', display: 'flex', flexDirection: listFlexDirection, borderRadius: 'var(--radius)',  padding: '20px', gap: listGap}
    
  return (
    <ListItem sx={listItemStyle}>{children}</ListItem>
  );
};

ListItemComponent.propTypes = {
    children: PropTypes.node
};

export default ListItemComponent;