import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";

import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';

const TableButton = ({title, Icon, link, action, disabled}) => {
  
const history = useHistory();

  const onClickAction = () => {
    if (action) {
      action();
    } else {
      history.push(link);
    }
  };

  return (
    <Tooltip title={ title } >
      <span>
        <IconButton onClick={onClickAction} disabled={disabled}>
            <Icon color={disabled ? "gray" : "warning"} fontSize='small'/>
        </IconButton>
      </span>  
    </Tooltip>
  );
};

TableButton.propTypes = {
    title: PropTypes.string,
    Icon: PropTypes.object,
    link: PropTypes.string,
    action: PropTypes.func,
    disabled: PropTypes.bool,
};

export default TableButton;