import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from "react-router-dom";

import { getNavLinks } from 'utils/navLinks.js';

import Box from '@mui/material/Box';
import MUISwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import LaunchIcon from '@mui/icons-material/Launch';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import MenuIcon from '@mui/icons-material/Menu';
import { Avatar } from '@mui/material';

const SwipeableDrawer = ( {handleLogout, activeMenuItem = 0, context="student", organizationsList} ) => {

  const [state, setState] = useState({right: false});

  const history = useHistory();
  const params = useParams();
  const organizationId = params.organizationId ? parseInt(params.organizationId) : null;
  const organizationSlug = organizationsList && organizationsList.find(org => org.id === organizationId)?.slug;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleOrganizationChange = (newOrganizationId) => {
    history.push(`/organizations/${newOrganizationId}`);
  }

  const handleViewSwitch = () =>  {
    history.push(`/profile`);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {getNavLinks(context).map(({text, Icon, link, external_link, id}) => {
          const listComponent = link ? Link : "a";
          return (
            <ListItemButton key={text}
                            component={ listComponent }
                            to={ context == "student" ? link : `/organizations/${organizationId}/${link}`}
                            href={ external_link }
                            target={ external_link && "_blank"}
                            selected={ id == activeMenuItem }
                            >
              <ListItemIcon>
                <Icon color='warning'/>
              </ListItemIcon>
              <ListItemText primary={ text } />
            </ListItemButton>
        )})}
        {context === "teacher" && organizationsList.length > 0 &&
          <ListItemButton key={'preview'}
                          component={ Link }
                          to={ `/${organizationSlug}`}
                          >
            <ListItemIcon>
              <LaunchIcon color='warning'/>
            </ListItemIcon>
            <ListItemText primary={ 'Preview Page' } />
          </ListItemButton>}
      </List>
      {organizationsList.length > 0 &&
        <>
        <Divider />
        <List>
        {organizationsList.map((item, key) =>
          <ListItemButton key={key}
                          fullWidth
                          onClick={() => handleOrganizationChange(item.id)}
                          className='switcher-menu-item'
                          >
                          <Avatar sx={{ width: 32, height: 32, border: '2px solid #e76d3e'}} src={item.logo_url} />
                          <p className={`organization-name ${organizationId === item.id ? 'selected' : ''}`}>
                              {item.title}
                          </p>
            </ListItemButton>
          )}
        </List>
        </>
      }
      <Divider />
      <List>
        {context === "teacher" &&
          <ListItem key={'User Profile'} disablePadding>
            <ListItemButton onClick={handleViewSwitch} >
              <ListItemIcon>
                <PersonIcon color='warning' />
              </ListItemIcon>
              <ListItemText primary={'User Profile'} />
            </ListItemButton>
          </ListItem>
        }
        <ListItem key={'Log out'} disablePadding>
          <ListItemButton onClick={ handleLogout }
                  component={Link}
                  to="/">
            <ListItemIcon>
              <PowerSettingsNewIcon color='warning' />
            </ListItemIcon>
            <ListItemText primary={'Log out'} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
        <React.Fragment key={"right"}>
        <IconButton color='warning' onClick={toggleDrawer("right", true)}><MenuIcon /> </IconButton>

        <MUISwipeableDrawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </MUISwipeableDrawer>
        </React.Fragment>
  );
}

SwipeableDrawer.propTypes = {
    activeMenuItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleLogout: PropTypes.func,
    context: PropTypes.string,
    organizationsList: PropTypes.array
  };

export default SwipeableDrawer;
