import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class CheckinTokenResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/checkin_tokens' as const;

  readonly id: number | undefined = undefined;
  
  pk() {
    return this.id?.toString();
  }

}
