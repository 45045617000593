import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'utils/capitalize';

import "./styles.css";

const StatCard = ({title, value}) => {

  const transformedTitle = capitalize(title).replace('_', ' ')

  return (
    <div className="ui-stat-card">
      <p className="value">{value}</p>
      <p className="title">{transformedTitle}</p>
    </div>
  );
};

StatCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number
};

export default StatCard;