import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

export default class ChallengeResource extends AuthenticatedResource {
    static urlRoot = '/auth/challenge' as const;

    readonly wallet_address: string | undefined = undefined;
    readonly challenge: string | undefined = undefined;

    pk() {
        return this.wallet_address?.toString();
    }
}
