import React from 'react';
import PropTypes from 'prop-types';

import ConfirmModal from './ConfirmModal';

const DeleteModal = ({deletingObject, objectCategory, setOpen, onConfirm}) => {
  return (
    <ConfirmModal
        title={deletingObject ? `Delete ${deletingObject.title}?` : ''}
        open={deletingObject}
        onConfirm={onConfirm}
        setOpen={setOpen}
    >
        Are you sure you want to delete this {objectCategory}?
    </ConfirmModal>
  );
};

DeleteModal.propTypes = {
    deletingObject: PropTypes.object,
    objectCategory: PropTypes.string,
    setOpen: PropTypes.func,
    onConfirm: PropTypes.func
};

export default DeleteModal;