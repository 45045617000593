import { TwitterShareButton, TwitterIcon } from "react-share";
import React from "react";

/* eslint-disable react/prop-types */
const TwitterShare = ({ url, title }) => {
    return (
        <TwitterShareButton
            url={url}
            title={title}
        >
            <TwitterIcon
                size={40}
                round
            />
        </TwitterShareButton>
    )
}

export default TwitterShare;