import React from "react";
import PropTypes from "prop-types";

import { TextField } from "@mui/material";
import Label from "ui/text/Label";

const NotificationList = ({notification_list, onChange}) => {

  const label = "Notification List";
  const helperText = "Enter one or more valid email addresses, separated by commas and we will notify them when a product is purchased.";

  return (
    <>
      <TextField
        fullWidth
        size="small"
        color="warning"
        name="notification_list"
        value={notification_list}
        onChange={onChange}
        label={label}/>

        <Label secondary padded>
            {helperText}
        </Label>
    </>

  );
};

NotificationList.propTypes = {
  notification_list: PropTypes.string,
  onChange: PropTypes.func,
};

export default NotificationList;
