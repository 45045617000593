import "./instrument";
import React from 'react';
import { CacheProvider } from '@rest-hooks/react';
import ReactDOM from 'react-dom';

import App from './App';

import 'assets/css/main.css';
import 'assets/css/form.css';
import "./i18n/index";


ReactDOM.render(
  <CacheProvider>
    <App />
  </CacheProvider>,
  document.getElementById('root')
);


