import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

const Actions = ({ loading, onClose, onClick, action, secondaryAction, disabled }) => {
  return (
    <div className="new-form-field-footer">
        <Button
          loading={loading}
          secondary
          fullwidth
          onClick={onClose}
          disabled={loading}
        >
          {secondaryAction}
        </Button>
        <Button
          loading={loading}
          fullwidth
          onClick={onClick}
          disabled={disabled || loading}
        >
          {action}
        </Button>
    </div>
  );
};

Actions.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  action: PropTypes.string,
  secondaryAction: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Actions;