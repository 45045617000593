import React from 'react';
import PropTypes from 'prop-types';
import Button from 'ui/Button';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const PageTop = ({ pageInfo, cta, onGoBack }) => {
  return(
    <>
      { pageInfo &&
        <div className="page-top">

          <div>

            {onGoBack && 
              <Button text small grey onClick={ onGoBack } icon={ArrowBackOutlinedIcon}>
                Back
              </Button>}
            
            <div className="page-label">{ pageInfo["label"] }</div>
            <div className="page-name">{ pageInfo["name"] }</div>
          </div>

          { pageInfo["description"] &&
            <div className="page-description">{ pageInfo["description"] }</div>
          }

          { pageInfo["action"] && cta }
        </div>
      }
    </>
  )
};

PageTop.propTypes = {
  pageInfo: PropTypes.object,
  cta: PropTypes.node,
  onGoBack: PropTypes.func,
};

export default PageTop;