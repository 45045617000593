import React from 'react';
import PropTypes from 'prop-types';

import TollIcon from '@mui/icons-material/Toll';

import { t } from 'i18n/index';

const PointsChip = ({points, frequency=false, inCard=false}) => {
  return (
    <div className='points-chip'>
      <TollIcon color='warning'/>
      <p>{inCard && `${t('literal:receive')}:`} {points} {frequency && `${t('literal:points_sc')} / ${frequency}`}</p>
    </div>
  )
};

PointsChip.propTypes = {
  points: PropTypes.number,
  frequency: PropTypes.string,
  inCard: PropTypes.bool,
};

export default PointsChip;
