const storage = localStorage;
const key     = 'cc.courses_list';

// -----------------------------------------------------
// Util Definition
// -----------------------------------------------------

const CoursesToken = {
  read:   () => JSON.parse(storage.getItem(key)),
  write:  (coursesList) => {
    const coursesString = JSON.stringify(coursesList);
    storage.setItem(key, coursesString);
  },
  remove: () => storage.removeItem(key)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default CoursesToken;