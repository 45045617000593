import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';

import { Checkbox } from '@mui/material';
import FormSection from 'ui/FormSection';

const ShowPriceWithApplicationFee = ({ show_application_fee, onChange }) => {

    const label = "By default, the price is shown to the user without application fee. By selecting this checkbox you can choose to show the price including it.";

    return (

        <FormSection title={'Show application fee'}>
            <FormControlLabel
                control={
                    <Checkbox
                        name="show_application_fee"
                        checked={show_application_fee}
                        onChange={onChange}
                        size='small'
                        />
                        }
                        label={label}
                    />
        </FormSection>
  );
};

ShowPriceWithApplicationFee.propTypes = {
    show_application_fee: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ShowPriceWithApplicationFee;