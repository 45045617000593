import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class ContentResource extends AuthenticatedResource {
  static urlRoot = 'profile/:organization_slug/contents' as const;

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}
