import { convertPriceToCents } from "utils/rewards";

const createFormData = (event, status) => {
    const formData = new FormData();

    const {
        title,
        description,
        start_date,
        end_date,
        location,
        ticketing,
        external_url,
        ticket_price,
        image_file,
        slug,
        short_description,
        image_filename,
        capacity,
        repeat_days,
        session_tickets,
    } = event;

    const fields = [
        { key: "title", value: title },
        { key: "start_date", value: start_date },
        { key: "end_date", value: end_date },
        { key: "location", value: location },
        { key: "status", value: status },
        { key: "ticketing", value: ticketing },
        { key: "image_filename", value: image_filename },
    ];

    if (capacity) {
        fields.push({ key: "capacity", value: capacity });
    }

    if (repeat_days && repeat_days.length > 0) {
        fields.push({ key: "repeat_days", value: JSON.stringify(repeat_days) });
    }

    if (short_description) {
        fields.push({ key: "short_description", value: short_description });
    }

    if (session_tickets) {
        const session_tickets_with_transformed_price = session_tickets.map((ticket) => {
            if (ticket.price) {
                return { ...ticket, price: convertPriceToCents(ticket.price) };
            } else {
                return ticket;
            }
        });
        fields.push({ key: "session_tickets", value: JSON.stringify(session_tickets_with_transformed_price) });
    }

    if (!ticketing) {
        fields.push({ key: "external_url", value: external_url });
        fields.push({ key: "ticket_price", value: ticket_price ? ticket_price : '' });
    } else if (description) {
        fields.push({ key: "description", value: description });
    }

    if (slug) {
        fields.push({ key: "slug", value: slug });
    }

    if (image_file) {
        fields.push({ key: 'image', value: image_file });
    }

    fields.forEach((field) => {
        formData.append(field.key, field.value);
    });

    return formData;
};

export default createFormData;
