import React from 'react';

import NewOrganization from './new';

import Layout from 'components/layouts/index';

const OrganizationWizzard = () => {

  const pageInfo = {
    name: 'Create a new Community'
  };

  //This page is only used to create organizations at present
  //All updates are done through the Organization Settings
    
  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo } >
  
      <NewOrganization />

    </Layout>
  )
};

export default OrganizationWizzard;