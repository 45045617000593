import React from 'react';
import PropTypes from 'prop-types';

import LinkSettings from './LinkSettings';
import { courseType } from 'utils/constants';
import SupplyLimit from './SupplyLimit';
import ClaimLimit from './ClaimLimit';
import EndDate from './EndDate';
import HideDetails from './HideDetails';
import CollectionContractAddress from './CollectionContractAddress';

const Advanced = ({ reward, course, mysteryBoxReward, isDisabled, published, onChange }) => {
    
    const mysteryBox = course.category === courseType.MYSTERYBOX.value;
    const pass = course.category === courseType.MEMBERSHIP.value
  
    const showLinkSettingsAndHideDetails = !mysteryBox && !pass;
    const showExpirationDateAndClaimLimit = (!mysteryBox || mysteryBoxReward) && !pass

    const { public: publicReward, category, supply_limit, claim_limit, end_date, hide_details, external_contract_address, pricings } = reward;

    return (
        <>
            {showLinkSettingsAndHideDetails &&
                <LinkSettings
                    publicReward={publicReward}
                    category={category}
                    isDisabled={isDisabled}
                    onChange={onChange} />}

            <SupplyLimit
                supply_limit={supply_limit}
                published={published}
                mysteryBoxReward={mysteryBoxReward}
                mysteryBox={mysteryBox}
                onChange={onChange} />    
            

            { showExpirationDateAndClaimLimit &&
            <>
                <ClaimLimit
                    claim_limit={claim_limit}
                    published={published}
                    onChange={onChange} />

                <EndDate
                    end_date={end_date}
                    isDisabled={published}
                    onChange={onChange} />
            </>  
            }

            { showLinkSettingsAndHideDetails &&
                <HideDetails 
                    hide_details={hide_details}
                    onChange={onChange} />
            }

            {pass && 
                <CollectionContractAddress
                    pricings={pricings}
                    external_contract_address={external_contract_address}
                    onChange={onChange} />}
        </>             
    );
};

Advanced.propTypes = {
  reward: PropTypes.object,
  course: PropTypes.object,
  mysteryBoxReward: PropTypes.bool,
  isDisabled: PropTypes.bool,
  published: PropTypes.bool,
  onChange: PropTypes.func
};

export default Advanced;