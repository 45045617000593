import React from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Alert, TextField } from '@mui/material';
import Button from 'ui/Button';
import { useError } from 'utils/useErrorController';
import ConnectedAccountsResource from 'resources/user/ConnectedAccountsResource';

const Ethereum = ({ open, onClose, onSuccess }) => {

  const { fetch } = useController();
  const [username, setUsername] = React.useState('');
  const {loading, setLoading, handleError} = useError();

  const handleSave = async () => {
    if (!username) {
      return;
    }

    try {
        setLoading(true);
        await fetch(ConnectedAccountsResource.update(), {id: 'ethereum'}, { username: username });
        await fetch(ConnectedAccountsResource.list());

        setLoading(false);
        if(onSuccess) { onSuccess(); }
        onClose();

    } catch (error) {
        setLoading(false);
        handleError(error);
    }
 };


  return (
    <Dialog open={ open }
            onClose={ onClose }>

      <DialogTitle>
          Set up Ethereum / Polygon wallet address
      </DialogTitle>

      <DialogContent dividers>

        <Alert severity="info">
          This will allow you to mint your membership passes as NFTs.
          You can add either a wallet address or a ENS.
        </Alert>

        <TextField
          label="Wallet address or ENS"
          color="warning"
          fullWidth
          margin="normal"
          value={username}
          onChange={( e ) => setUsername(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button secondary onClick={onClose}> Cancel </Button>
        <Button loading={loading} onClick={ handleSave }>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Ethereum.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
};

export default Ethereum;
