import { capitalize } from "./capitalize";
import { t } from 'i18n/index';

export const signUpCustomFields = {
  'asa-perafita': ['matricula'],
  'unicornfactorylisboa': ['startup']
};

export const checkCustomFields = (organizationSlug) => {
  return Object.keys(signUpCustomFields).includes(organizationSlug)
}

export const transformCustomField = (customField, organizationSlug=null) => {
  if (organizationSlug) {
    return t(`customFields:${organizationSlug}:${customField}`);
  }
  return capitalize(customField.replace(/_/g, ' '));
}
