import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';
import { ReactSVG } from 'react-svg';

import PageElementResource from 'resources/Profile/PageElementResource';

import Provider from "containers/student/Settings/ConnectedAccounts/Accounts/Provider";

import { socialTypes } from 'utils/pageElementsHelper';
import useElementStatsController from 'utils/profile/useElementStatController';


const Social = ({pageElement, organizationSlug = ""}) => {

  const [openConnectAccount, setOpenConnectAccount] = useState(false);

  const {fetch} = useController();    
  const {createStat} = useElementStatsController(organizationSlug);

  const {element_type, content, require_telegram, require_whatsapp, id } = pageElement;
  const logo = socialTypes.find(type => type.value === element_type).logo;
  const isEmail = element_type === "email";

  const handleClick = async () => {
    if (require_telegram || require_whatsapp) {
      setOpenConnectAccount(true);
    } else {
      handleRedirect();
    }
  };

  const handleRedirect = () => {
    createStat(id);
    window.open(isEmail ? `mailto:${content}` : content, "_blank");
  }

  const handleConnectAccount = async () => {
    await fetch(PageElementResource.detail(), {organization_slug: organizationSlug, id: 1})
    setOpenConnectAccount(false);
    handleRedirect();
  };

  return (
    <>
      <ReactSVG
        src={logo}
        className='ui-social-element'
        onClick={handleClick}
      />

      {organizationSlug && 
        <Provider open={openConnectAccount && (require_telegram || require_whatsapp)}                
                onClose={() => setOpenConnectAccount(false)}
                onSuccess={handleConnectAccount}
                provider={ require_telegram ? "telegram" : "whatsapp"}
                showJoin={require_telegram} />}
    </>
  );
};

Social.propTypes = {
  pageElement: PropTypes.object,
  organizationSlug: PropTypes.string
};

export default Social;