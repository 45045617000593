import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";


import Dialog from "@mui/material/Dialog";

import Title from "./Title";
import Content from "./Content";
import Actions from "./Actions";

import { validEmailRegex } from "utils/validations/validateEmail";

const CsvModal = ({ open, onClose, onConfirm, rows, fullNameTable }) => {
  const [error, setError] = useState(false);

  const validateEmails = () => {
    const invalidEmailRows = rows.filter(row => row.email === undefined || !row.email.trim().match(validEmailRegex));

    if (invalidEmailRows.length > 0) setError("invalid_email");
    if (rows.length > 300) setError("rows_count");
  };

  useEffect(() => {
    validateEmails();
  }, [rows]);


  const message = {
    invalid_email: "We have detected at least one invalid email. Please address this issue before proceeding.",
    rows_count: "You can only import 300 contacts at a time. Please address this issue before proceeding.",
    default: `You're about to import ${rows.length} contacts. Click Next to proceed.`,
  };

  return (
    <Dialog open={open} fullScreen>
      <Title />

      <Content
        rows={rows}
        message={message[error] || message.default}
        fullNameTable={fullNameTable} />

      <Actions
        error={error}
        onClose={onClose}
        onConfirm={onConfirm} />
    </Dialog>
  );
};

CsvModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  rows: PropTypes.array,
  fullNameTable: PropTypes.bool,
};

export default CsvModal;
