import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar } from '@mui/material';
import Details from './Details';

const ExistingComments = ({comments, onCommentDelete, loading}) => {
  return (
    comments.map ((comment, index) => (
        <div key={index} className='existing-comment'>
            <Avatar src={comment.user_profile_image} />

            <Details comment={comment} />
            
            {comment.user_is_owner && 
                <Button className={'delete-button'} loading={loading} icon={DeleteIcon} text onClick={()=> onCommentDelete(comment.id)}></Button>}
        </div>
    ))
  );
};

ExistingComments.propTypes = {
    comments: PropTypes.array
};

export default ExistingComments;