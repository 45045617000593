// 'import React, { useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types';

import Label from 'ui/text/Label';
// import Button from 'ui/Button';

import { Divider, IconButton, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const TicketEditor = ({event, onEventChange, setUnsavedChanges}) => {

  // const [open, setOpen] = useState(false);
  const { session_tickets } = event;

  const handleChange = (e, index) => {
    setUnsavedChanges(true)
    const { name, value } = e.target
    const newTickets = [...session_tickets]
    newTickets[index][name] = value
    let newEvent = { ...event, session_tickets: newTickets };
    onEventChange(newEvent)
  };

  const handleDelete = (index) => {
    setUnsavedChanges(true)
    const newTickets = [...session_tickets]
    newTickets.splice(index, 1)
    let newEvent = { ...event, session_tickets: newTickets }
    onEventChange(newEvent)
  };

  const showDivider = (index) => (session_tickets.length > 1 && index !== session_tickets.length - 1)

  return (
    <div className="event-form-ticket_editor">
      
      {session_tickets.map((ticket, index) => (
        <div key={index} className='event-form-ticket'>
          {/* <div className='row'> */}
            <TextField
              name="title"
              label="Title *"
              value={ticket.title}
              onChange={(e) => handleChange(e, index)}
              fullWidth
              size="small"
              color="warning"
              InputProps={{
                endAdornment: (
                  <IconButton highlighted onClick={() => handleDelete(index)}>
                    <DeleteIcon color='warning'/>
                  </ IconButton>
                )
              }}
            />
            {/* {!open ? (
              <Button 
                  text
                  small
                  icon={AddCircleOutlineIcon}
                  onClick={() => setOpen(true)}
              >
                  Add description (optional)
              </Button>
            ) : (
              <TextField
                name="description"
                label="Description"
                value={ticket.description}
                onChange={(e) => handleChange(e, index)}
                fullWidth
                size="small"
                inputProps={{ maxLength: 300 }}
                color="warning"
              />
            )} 
          </div> */}

          <TextField
            name="description"
            label="Description"
            value={ticket.description}
            onChange={(e) => handleChange(e, index)}
            fullWidth
            size="small"  
            multiline
            rows={3}
            inputProps={{ maxLength: 300 }}
            color="warning"
          />

          <div key={index} className='row'>
            <TextField
              name="supply_limit"
              label="Supply Limit"
              value={ticket.supply_limit}
              onChange={(e) => handleChange(e, index)}
              fullWidth
              size="small"
              color="warning"
            />
            <TextField
              name="price"
              label="Price"
              value={ticket.price}
              onChange={(e) => handleChange(e, index)}
              fullWidth
              size="small"
              color="warning"
            />
            <Label secondary padded>Leave empty for unlimited or free</Label>
          </div>

          {showDivider(index) && 
            <Divider light sx={{marginBottom: 2}} />
          }
        </div>
      ))}
    </div>
  );
};

TicketEditor.propTypes = {
    event: PropTypes.object,
    onEventChange: PropTypes.func,
    setUnsavedChanges: PropTypes.func
};

export default TicketEditor;