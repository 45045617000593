import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import InvoiceResource from 'resources/Profile/InvoiceResource';

import AddressForm from 'components/AddressForm';

import { handleValidation } from './handleValidation';

import Button from 'ui/Button';
import FormSection from 'ui/FormSection';

import { useError } from 'utils/useErrorController';

import TextField from '@mui/material/TextField';

import { t } from 'i18n/index';

const InvoiceForm = ({ setStep, senderName, senderEmail, paymentIntentId}) => {

    const emptyInvoice = {name: '', email: ''};
    const [invoiceData, setInvoiceData] = useState({...emptyInvoice, name: senderName, email: senderEmail });
    const {loading, setLoading, handleError, validate} = useError();
    const {organizationSlug} = useParams();
    const { fetch } = useController();

    const handleChange = ({ target: {name, value}}) => {
        const newInvoice = { ...invoiceData, [name]: value };
        setInvoiceData(newInvoice);
    };

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            validate(invoiceData, handleValidation);
            await fetch(InvoiceResource.create(), {organization_slug: organizationSlug, payment_intent_id: paymentIntentId}, invoiceData)
            setLoading(false);
            setStep(6);
        } catch (error) {
            handleError(error);
        }
    };

    const textFieldsContent = {
        name : {
            label: t('literal:name'),
            name: 'name',
            value: invoiceData.name,
            onChange: handleChange
        },
        vat : {
            label: t('literal:vat'),
            name: 'vat',
            value: invoiceData.vat,
            onChange: handleChange,
        },
        email : {
            label: t('literal:email'),
            name: 'email',
            value: invoiceData.email,
            onChange: handleChange,
        },
    };

    const renderTextField = ({label, name, value, onChange}) => {
        return (
            <TextField label={label}
                        name={name}
                        value={value || ''}
                        onChange={onChange}
                        fullWidth
                        size='small'
                        color="warning">
            </TextField>
        );
    };

    return (
        <>
            <form style={{display: 'flex', flexDirection: 'column', gap: 15}}>
                <FormSection title={t('literal:personalDetails')} slim>
                    {renderTextField(textFieldsContent["name"])}
                    {renderTextField(textFieldsContent["vat"])}
                    </FormSection>

                <FormSection title={t('literal:contactDetails')} slim>
                    {renderTextField(textFieldsContent["email"])}
                </FormSection>

                <AddressForm 
                    onChange={handleChange}
                    data={invoiceData}
                    onDataChange={setInvoiceData}
                    end_user={true} />

                <Button fullwidth
                        loading={loading}
                        onClick={(e) => handleSubmit(e)}>
                   {t('literal:submit')}
                </Button>
            </form>
        </>
    )
};

InvoiceForm.propTypes = {
    setStep: PropTypes.func,
    senderName: PropTypes.string,
    senderEmail: PropTypes.string,
    setLoading: PropTypes.func,
    loading: PropTypes.bool,
    handleError: PropTypes.func,
    validate: PropTypes.func,
    paymentIntentId: PropTypes.string
};

export default InvoiceForm;
