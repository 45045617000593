import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class OrganizationResource extends AuthenticatedResource {
  static urlRoot = '/admin/organizations'

  readonly id: number | undefined = undefined;
  readonly title: string = '';

  pk() {
    return this.id?.toString();
  }
}