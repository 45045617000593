import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class ProductInstanceResource extends AuthenticatedResource {
  static urlRoot = 'organizations/:organization_id/product_instances' as const;

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}
