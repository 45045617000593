import { useState } from "react";
import { useController } from "@rest-hooks/react";

import EmailResource from "resources/organization/EmailResource";

import { useError } from "./useErrorController";
import { useToast } from "./context/ToastContext";

import customToast from "utils/customToast";
import { validateStringField } from "utils/validations/validateStringField";

export const useEmailController = (organizationId, setConfirmModal, eventId=null, sessionId=null, selectedRows=null, setSelectedRows=null, onEditEmailClick=null) => {
  
    const { fetch } = useController();
    const { handleError, setLoading } = useError();
    const { setOpen, setMessage, setSeverity } = useToast();
    const [ email, setEmail ] = useState({ content: "" });
    const [ editingEmail, setEditingEmail ] = useState(false);
  
    const handleEmailSendRequest = () => {
      try {
        validateStringField(email.subject, "the subject of the email");
        validateStringField(email.content, "the body of the email");
        setConfirmModal(true);
      } catch (error) {
        handleError(error);
      }
    };

    const createFormData = () => {
      const formData = new FormData();
      formData.append("content", email.content);
      formData.append("subject", email.subject);

      const userIds = selectedRows ? selectedRows.map(ticket => ticket.user_id) : null;
      if (userIds) formData.append("user_ids", userIds);
      if (eventId) formData.append("event_id", eventId);
      if (sessionId) formData.append("session_id", sessionId);

      return formData;
    };
  
    const handleEmailSend = async () => {
      try {
        setLoading(true);
        const formData = createFormData();

        const {count} = await fetch(EmailResource.create(), { organization_id: organizationId }, formData);
        customToast("success", `${count} Email${count > 1 ? "s" : ""} Sent` , setOpen, setSeverity, setMessage);
        
        setLoading(false);
        handleEmailCancel();
      } catch (error) {
        handleError(error);
      }
    };
  
    const handleEditEmailClick = () => {
      setEditingEmail(true);
      if (onEditEmailClick) onEditEmailClick();
    };

    const handleEmailCancel = () => {
      setEditingEmail(false);
      setEmail({ content: "" });
      if (setSelectedRows) setSelectedRows([]);
    };
  
    return {
      email,
      setEmail,
      editingEmail,
      handleEmailSendRequest,
      handleEmailSend,
      handleEditEmailClick,
      handleEmailCancel
    };
  };