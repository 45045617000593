
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import EmbeddedSpotify from 'components/MarkdownEmbeds/EmbeddedSpotify';
import EmbeddedVideo from 'components/MarkdownEmbeds/EmbeddedVideo';
import EmbeddedSoundcloud from 'components/MarkdownEmbeds/EmbeddedSoundcloud';

const createMarkdownEmbed = (type, id) => {
  let component
  switch (type) {
    case 'spotify':
      component = <EmbeddedSpotify id={id} />;
      break;
    case 'soundcloud':  
      component = <EmbeddedSoundcloud id={id} />;
      break;
    default:
      component = <EmbeddedVideo type={type} id={id} />;
      break;
  }

  const html = renderToStaticMarkup(component);
  return [
    { type: 'openTag', tagName: 'div', outerNewLine: true },
    { type: 'html', content: html },
    { type: 'closeTag', tagName: 'div', outerNewLine: true }
  ];
};

const nodeHandler = {
  youtube: (node) => createMarkdownEmbed('youtube', node.literal),
  vimeo: (node) => createMarkdownEmbed('vimeo', node.literal),
  spotify: (node) => createMarkdownEmbed('spotify', node.literal),
  soundcloud: (node) => createMarkdownEmbed('soundcloud', node.literal),
};

export const markdownCustomHTML = {
  htmlBlock: {
      iframe(node) {
      return [
          { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
          { type: 'html', content: node.childrenHTML },
          { type: 'closeTag', tagName: 'iframe', outerNewLine: true },
      ];
      }}, ...nodeHandler
  }