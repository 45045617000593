import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const AttachmentChip = ({filenames, type, onDelete, onClick, object, onObjectChange, hiddenFileInputs, multiple=false, setUnsavedChanges}) => {
    
    return (
        filenames.map((filename, index) => (
            <Chip key={`${type}-chip-${index}`} 
                label={filename}
                variant="outlined"
                onDelete={onDelete(type, index, object, onObjectChange, hiddenFileInputs, multiple, setUnsavedChanges)}
                className="attachment-chip"
                clickable={onClick}
                icon={onClick ? onClick.icon : null}
                onClick={onClick ? onClick.action : null} />
        ))
    );
};

AttachmentChip.propTypes = {
    filenames: PropTypes.array,
    type: PropTypes.string,
    onDelete: PropTypes.func,
    onClick: PropTypes.object,
    object: PropTypes.object,
    onObjectChange: PropTypes.func,
    hiddenFileInputs: PropTypes.object,
    multiple: PropTypes.bool,
    setUnsavedChanges: PropTypes.func
};

export default AttachmentChip;