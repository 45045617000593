import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSuspense } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

import IntegrationsResource from 'resources/organization/IntegrationsResource';

import DraggableList from "./DraggableList";

import { useError } from "utils/useErrorController";

import Label from "ui/text/Label";
import FormSection from "ui/FormSection";

import { DragDropContext } from "react-beautiful-dnd";

import { Alert, Skeleton } from "@mui/material";

import './styles.css';

const STANDARD_TABS = ["about", "memberships", "store", "content", "events"];
const ALWAYS_SHOWN_TABS = ["about"];

const TabsOrganizer = ({ loading, profile_tabs, onChange, onUnsavedChanges }) => {

  const { handleError } = useError();
  const { organizationId } = useParams();
  const { settings } = useSuspense(IntegrationsResource.detail(), { organization_id: organizationId, id: 'notion' });
    
  const possibleTabs = useMemo(() => {
    return settings?.profile_tab ? [...STANDARD_TABS, `notion:${settings.profile_tab}`] : STANDARD_TABS;
  }, [settings?.profile_tab]);

  const [removedTabs, setRemovedTabs] = useState(
    possibleTabs.filter((item) => !profile_tabs.includes(item))
  );

  const reorderTabsShownTabs = (list, startIndex, endIndex) => {
    const reorderedTabs = Array.from(list);
    const [removed] = reorderedTabs.splice(startIndex, 1);
    reorderedTabs.splice(endIndex, 0, removed);

    updateTabs(reorderedTabs);
  };

  const checkAbleToMove = (movedTab) => {
    if (ALWAYS_SHOWN_TABS.includes(movedTab)) {
      handleError(`Cannot move ${movedTab} section from your profile`);
      return false;
    } else {
      return true;
    }
  };

  const removeTab = (startIndex, endIndex) => {
    const movedTab = profile_tabs[startIndex];
    if (checkAbleToMove(movedTab)) {
      const updatedProfileTabs = profile_tabs.filter((_, index) => index !== startIndex);
      const updatedRemovedTabs = [...removedTabs];
      updatedRemovedTabs.splice(endIndex, 0, movedTab);
      updateTabs(updatedProfileTabs, updatedRemovedTabs);
    }
  };

  const addTab = (startIndex, endIndex) => {
    const movedTab = removedTabs[startIndex];
    const updatedRemovedTabs = removedTabs.filter((_, index) => index !== startIndex);
    const updatedProfileTabs = [...profile_tabs];
    updatedProfileTabs.splice(endIndex, 0, movedTab);
    updateTabs(updatedProfileTabs, updatedRemovedTabs);
  };
  
  const updateTabs = (updatedProfileTabs, updatedRemovedTabs=removedTabs) => {
    setRemovedTabs(updatedRemovedTabs);
    onChange({ target: { name: 'profile_tabs', value: updatedProfileTabs } });
    onUnsavedChanges(true);
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const { droppableId: sourceList, index: startIndex } = source;
    const { droppableId: destinationList, index: endIndex } = destination;

    if (sourceList === destinationList) {
      if (sourceList === "shownTabs") {
        reorderTabsShownTabs(profile_tabs, startIndex, endIndex);
      }
    } else {
      if (sourceList === "shownTabs") {
        removeTab(startIndex, endIndex);
      } else {
        addTab(startIndex, endIndex);
      }
    }
  };

  return (
      <FormSection>
        {loading ? 
          <Skeleton variant="rectangular" width="100%" height="350px" />
        : <div className="drag-drop-container">
            <DragDropContext onDragEnd={handleDragEnd} >
                <DraggableList
                  items={profile_tabs}
                  droppableId={'shownTabs'}
                  title="Shown Sections in Profile"
                />
                <DraggableList
                  items={removedTabs}
                  droppableId={'removedTabs'}
                  title="Hidden Sections in Profile"
                />
            </DragDropContext>
          </div>}

      <Label secondary padded>
        Drag and drop the items to adjust the order of the tabs shown in your profile. <br />
        Additionally, you can hide sections from your profile by moving the items to the right column.
      </Label>

      {profile_tabs.length > 5 && 
        <Alert severity="warning"
          sx={{marginBottom: "20px"}}>
          Our menu layout is designed to display up to 5 tabs simultaneously on all screens. If more than 5 tabs are selected, they may overlap. We recommend testing your layout to ensure it meets your needs while we work on a solution.
        </Alert>}
      
    </FormSection> 
  );
};

TabsOrganizer.propTypes = {
  loading: PropTypes.bool,
  profile_tabs: PropTypes.object,
  onChange: PropTypes.func,
  onUnsavedChanges: PropTypes.func
};

export default TabsOrganizer;
