import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, Link } from 'react-router-dom';

import Button from 'ui/Button';

import { t } from "i18n/index";

const InvoiceCreated = ({ handleClaimReset, isTicket }) => {
  
  const {organizationSlug} = useParams();
  const history = useHistory()

  const handleRedirect = () => {
    handleClaimReset();
    history.push(`/${organizationSlug}`);
  };

  const text = isTicket 
                ? <>{t('profile:memberships:claimModal:ticketInvoiceCreated')} <Link to={`/orders`}>{t('literal:here')}</Link></> 
                : t('profile:memberships:claimModal:invoiceCreated');


  return (
    <div className='payment-processed'>
      <p>{text}</p>
      <div className='button-group'>
        <Button onClick={handleRedirect} fullwidth>{t('literal:close')}</Button>
      </div>      
    </div>
  );
};

InvoiceCreated.propTypes = {
    handleClaimReset: PropTypes.func,
    isTicket: PropTypes.bool
};

export default InvoiceCreated;

