const createFormData = (post) => {
    const formData = new FormData();

    const {
        body,
        reward_ids,
        tag_ids,
        status,
        title,
        slug,
        send_newsletter,
        image_files,
        video_files,
        image_file,
        image_filename,
        short_description,
    } = post;
    
    const fields = [
        { key: "body", value: body },
        { key: "reward_ids", value: reward_ids },
        { key: "tag_ids", value: JSON.stringify(tag_ids) },
        { key: "status", value: status ? status : "draft" },
        { key: "title", value: title },
        { key: "short_description", value: short_description }
    ];

    if (slug) {
        fields.push({ key: "slug", value: slug });
    }
    if (send_newsletter) {
        fields.push({ key: "send_newsletter", value: send_newsletter });
    }
    
    fields.forEach((field) => {
        formData.append(field.key, field.value);
    });
  
    if (image_files && image_files.length > 0) {
        image_files.forEach((file) => {
            formData.append("images[]", file);
        });
    }
    if (video_files && video_files.length > 0) {
        video_files.forEach((file) => {
            formData.append("videos[]", file);
        });
    }
    if (image_file) {
        formData.append("banner_image", image_file);
    }
    if (image_filename) {
        formData.append("banner_image_filename", image_filename);
    }
  
    return formData;
};
    
export default createFormData;