import React from 'react';

import TableButton from 'components/TableButton';
import { getDateSelector } from 'utils/tables/getDateSelector';
import { getDateFormat } from 'utils/tables/getDateFormat';

import EditIcon from '@mui/icons-material/Edit';
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import OpenInNew from '@mui/icons-material/OpenInNew';

const getTitle = (title, body) => {
  return (title ? `${title.slice(0, 40)}${title.length > 40 ? '...' : ""}` : `${body.slice(0, 40)}${body.length > 40 ? '...' : ""}`);
};

const getActionButtons = (row) => {
  return [
    {
      title: "See All",
      Icon: ZoomInIcon,
      action: () => window.location.href = '/organizations/' + row.organization_id + '/content/'
    },
    {
      title: "See Post",
      Icon: OpenInNew,
      action: () => window.location.href = row.external_url
    },
    {
      title: "Edit",
      Icon: EditIcon,
      action: () => window.location.href = '/organizations/' + row.organization_id + '/content/' + row.id + '/edit'
    }
  ];
};

export const postsColumns = [
  {
    field: "id",
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "15%",
  },
  {
    field: "organization",
    name: "Organization",
    selector: (row) => row.organization_title,
    sortable: true,
    width: "25%",
  },
  {
    field: "title",
    name: "Identifier",
    selector: (row) => getTitle(row.title, row.body),
    sortable: true,
    width: "35%",
  },
  {
    field: "status",
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    width: "15%",
  },
  {
    field: "created_at",
    name: "Created At",
    selector: (row) => getDateSelector(row.created_at),
    format: (row) => getDateFormat(row.created_at, 'lll'),
    sortable: true,
    width: "25%",
  },
  {
    selector : (row) =>  
      <div>
        { getActionButtons(row).map((button, index) => (
          <TableButton 
            key={index}
            title={button.title}
            Icon={button.Icon}
            action={button.action}
          />
        ))}
      </div>,
    sortable: false,
    width: '15%'
  },
];
