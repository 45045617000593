import React from 'react';
import PropTypes from 'prop-types';

import { rewardMediaDownload } from 'utils/urls';
import { rewardPDFDownload } from 'utils/urls';
import { downloadFileFromUrl } from 'utils/downloadFile';

import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from '@mui/material';
import { IconButton } from '@mui/material';

import { t } from 'i18n/index';

const DownloadButtons = ({id}) => {

    const handlePDFDownload = () => {
        downloadFileFromUrl(rewardPDFDownload(id), `certificate_${id}.pdf`, 'application/pdf');
    };

    const handleMediaDownload = () => {
        window.open(rewardMediaDownload(id), '_blank');
    };

    return (
            <div className='backdrop-buttons-container' >
                <Tooltip title={t('literal:downloadPDF')} >
                    <IconButton onClick={() => handlePDFDownload()}>
                        <SimCardDownloadIcon fontSize='large' sx={{ color: "#eee" }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={t('literal:downloadImage')} >
                    <IconButton onClick={() => handleMediaDownload()}>
                        <DownloadIcon fontSize='large' sx={{ color: "#eee" }} />
                    </IconButton>
                </Tooltip>
            </div>
    );
};

DownloadButtons.propTypes = {
    id: PropTypes.number
};

export default DownloadButtons;