import moment from "moment";

export const getNextChargeDateAndDay = (frequency, targetLanguage) => {
    const day = moment().locale(targetLanguage).format('Do');
    
    let date;
    if (frequency === 'monthly') {
      date = moment().add(1, 'months').format('D MMM');
    } else if (frequency === 'quarterly') {
      date = moment().add(3, 'months').format('D MMM') ;
    } else if (frequency === 'yearly') {
      date = moment().add(1, 'years').format('D MMM YYYY');
    }

    return { day, date };
};