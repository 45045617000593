import React from 'react';
import PropTypes from 'prop-types';

import TextField from "@mui/material/TextField";
import Label from 'ui/text/Label';

const Link = ({content_text_link, onChange}) => {

    const textfieldLabel = "Link"
    const textfieldHelperText = "Add a link for a 'Continue' button. Enter the URL for redirection on click. Only applicable on free tickets/products."
  
  return (
    <>
        <TextField
          fullWidth
          color="warning"
          name="content_text_link"
          value={content_text_link}
          onChange={onChange}
          label={textfieldLabel}
          size='small' />
        <Label secondary padded>
            {textfieldHelperText}
        </Label>
    </>
  );
};

Link.propTypes = {
  content_text_link: PropTypes.string,
  onChange: PropTypes.func
};

export default Link;