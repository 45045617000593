import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';
import Label from 'ui/text/Label';

import formattedDate from 'utils/formattedDate';

const Details = ({comment}) => {
  return (
    <div className='details'>
        <div className='header'>
            <Description>{comment.owner_name}</Description>
            <Label secondary>{formattedDate(comment.created_at, 'fromNow')}</Label>
        </div>
        <Description className={'content'}>{comment.content}</Description>
    </div>
  );
};

Details.propTypes = {
    comment: PropTypes.object
};

export default Details;