import React from 'react';
import PropTypes from 'prop-types';

import Subtitle from "ui/text/Subtitle";

import Settings from 'components/OfferCardSettings';

const CardHeader = ({title, end_date, issued_counter, supply_limit, onClick}) => {
  
  const show_settings = end_date || supply_limit;

  return (
    <div className="perk-header">
      {onClick ? (
        <div className="pointer" onClick={onClick}>
          <Subtitle>{title}</Subtitle>
        </div>
      ) : (
        <Subtitle>{title}</Subtitle>
      )}

      {show_settings &&
        <Settings
          end_date={end_date}
          issued_counter={issued_counter}
          supply_limit={supply_limit} />
      }

    </div>
  );
};

CardHeader.propTypes = {
    title: PropTypes.string,
    end_date: PropTypes.string,
    issued_counter: PropTypes.number,
    supply_limit: PropTypes.number,
    onClick: PropTypes.func
};

export default CardHeader;
