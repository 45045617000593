import React from "react";

import { getDateSelector } from "utils/tables/getDateSelector";
import { getDateFormat } from "utils/tables/getDateFormat";
import { transformCustomField } from "utils/signUpCustomFields";

import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";

const renderPointsCell = (row, handlePointsClick) => (
  <p onClick={() => handlePointsClick(row)}>{row.points}</p>
);

const renderEditCell = (
  row,
  handleClick,
  allowUserSelection,
  selectedUsers,
  handleSelectionChange
) =>
  allowUserSelection ? (
    <Checkbox
      checked={selectedUsers.includes(row)}
      onChange={() => handleSelectionChange(row)}
      color="warning"
    />
  ) : (
    <Tooltip title="Edit Subscription">
      <EditIcon onClick={() => handleClick(row)} color="warning" />
    </Tooltip>
  );

export const subscriptionsColumns = ( handleClick, allowUserSelection, selectedUsers, handleSelectionChange, handlePointsClick, data) => {

  const customFieldKeys = [...new Set(data.flatMap((row) => Object.keys(row.custom_fields)))];
  
  const customFieldColumns = customFieldKeys.map((key) => ({
    field: key,
    name: transformCustomField(key),
    sortable: true,
    width: "15%",
    style: {
      cursor: "default",
    },
    selector: (row) => row.custom_fields[key] || "",
  }));

  return [
    {
      field: "name",
      name: "Name",
      sortable: true,
      width: "15%",
      style: {
        cursor: "default",
      },
      selector: (row) => row.name,
    },
    {
      field: "email",
      name: "Email Address",
      sortable: true,
      width: "25%",
      style: {
        cursor: "default",
      },
      selector: (row) => row.email,
    },
    ...customFieldColumns,
    {
      field: "points",
      name: "Points",
      sortable: true,
      width: "10%",
      selector: (row) => row.points,
      cell: (row) => renderPointsCell(row, handlePointsClick),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      field: "tier",
      name: "Tier",
      sortable: true,
      width: "15%",
      style: {
        cursor: "default",
      },
      selector: (row) => row.tier,
    },
    {
      field: "status",
      name: "Status",
      sortable: true,
      width: "10%",
      style: {
        cursor: "default",
      },
      selector: (row) => row.status,
    },
    {
      field: "end_date",
      name: "End Date",
      sortable: true,
      width: "15%",
      style: {
        cursor: "default",
      },
      selector: (row) => getDateSelector(row.end_date),
      format: (row) => getDateFormat(row.end_date),
    },
    {
      cell: (row) =>
        renderEditCell( row, handleClick, allowUserSelection, selectedUsers, handleSelectionChange ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      style: {
        cursor: "pointer",
      },
      width: "10%",
    },
  ];
};
