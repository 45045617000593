import React, { useState } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import PasswordResetResource from 'resources/user/PasswordResetResource';

import resetValidation from './Validations/resetForm';

import { useError } from 'utils/useErrorController';
import AuthToken from 'utils/localStorage/AuthToken';
import UserName    from 'utils/localStorage/UserName';
import CoursesList from 'utils/localStorage/CoursesList';
import { TCLabel } from 'utils/TCLabel';
import redirectToView from 'utils/redirectToView';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel } from '@mui/material';

import { t } from 'i18n/index';

const ResetForm = ({ resetToken, handleDescription, done, buttonDescription }) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});

  const { fetch } = useController();
  const history = useHistory();

  const account = useSuspense(PasswordResetResource.detail(), {id: resetToken}); // eslint-disable-line

  const { handleError, validate, setLoading } = useError();

  const handleReset = async (e) => {
    try {
      e.preventDefault();
      validate(e, resetValidation);
      const { auth_token, user, courses } = await fetch(PasswordResetResource.update(), { id: resetToken }, new FormData(e.target) );
      // success!
      if (auth_token){
        handleDescription(false);
        setStep(2);
        AuthToken.write(auth_token);
        const { role, name } = user;
        UserName.write(name);
        CoursesList.write(courses);
        setTimeout(() => {
          if (done) {
            done();
          }
          if (buttonDescription) {
            redirectToView(history, role, courses);
          }
          setLoading(false);
        }, 3500);
      }
    }  catch (error) {
      handleError(error);
    }
  };

  const handleCheckBox = (event) => {
    setData({
      ...data,
      agreement: event.target.checked
    });
  };

  return (
    <>
      <form onSubmit={(e) => handleReset(e, data) } >
        { step === 1 &&
          <>
            <TextField label={t('literal:newPassword')} name="password" type={'password'}
                        fullWidth margin="normal" color="warning"></TextField>
            <TextField label={t('literal:confirmPassword')} name="password_confirmation" type={'password'}
                        fullWidth margin="normal" color="warning"></TextField>
            <FormControlLabel
              control={
                <Checkbox checked={ data.agreement || false }
                          onChange={handleCheckBox}
                          label={t('authForms:common:agreementDeclaration')}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={TCLabel}
            />
            { account.is_new }
            <Button fullWidth
                    type="submit"
                    variant="contained"
                    color="primary">
              {buttonDescription ? buttonDescription : t('authForms:resetForm:requestInstructions')}
            </Button>
            </>
        }
        { step === 2 &&
          <>
            <p>{t('authForms:resetForm:successMessage')}</p>
            <p>{buttonDescription ? t('authForms:resetForm:redirectMessage') : t('authForms:resetForm:claimingMessage')}</p>
          </>
        }
      </form>
    </>


  )
}

ResetForm.propTypes = {
  handleDescription: PropTypes.func,
  resetToken: PropTypes.string,
  done: PropTypes.func,
  buttonDescription: PropTypes.string,
};

export default ResetForm;
