import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, FormControlLabel, FormHelperText, Link } from '@mui/material';

const NewsletterModal = ({post, setPost, open, onClick, onClose, loading, organizationId}) => {

  const disableSend = !post.send_newsletter || post.disable_newsletter;

  const handleCheckBox = () => {
    setPost({ ...post, send_newsletter: !post.send_newsletter });
  };

  const handleCancel = () => {
    setPost({ ...post, send_newsletter: false });
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={ true } maxWidth='sm' >
      <DialogTitle sx={{ alignItems: 'center', display: 'flex', gap: 1 }}>
        Send newsletter
        <IconButton
          onClick={handleCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
          }}
          >
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
            You are about to send this post as a newsletter to your members. Please confirm the checkbox below to proceed.
            <Box>
              <FormControlLabel
                label="Send Newsletter on Publish"
                control={
                  <Checkbox
                    checked={post.send_newsletter}
                    onChange={handleCheckBox}
                    color="warning"
                    disabled={post.disable_newsletter}
                  />
                }
              />
              <FormHelperText>
                {post.disable_newsletter &&
                  <>
                    To send your Post as Newsletter you must first finish your Newsletter setup
                    <Link underline="hover" color="inherit" href={`/organizations/${organizationId}/settings/email`} target='_blank'> here</Link>.
                  </>}
              </FormHelperText>
            </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} fullwidth secondary>Cancel</Button>
        <Button onClick={onClick} disabled={disableSend} loading={loading} fullwidth >Send Newsletter</Button>
      </DialogActions>
    </Dialog>
  );
};

NewsletterModal.propTypes = {
  post: PropTypes.object,
  setPost: PropTypes.func,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  organizationId: PropTypes.number,
};

export default NewsletterModal;
