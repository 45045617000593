import React from 'react';
import PropTypes from 'prop-types';

import UniversalTimeSelector from './UniversalTimeSelector';
import DateTimeFields from './DateTimeFields';

const DateTimeSelector = ({ event, onEventChange, setUnsavedChanges }) => {
  return (
    <div className="date-fields double-row">
        <DateTimeFields event={event} onEventChange={onEventChange} setUnsavedChanges={setUnsavedChanges} />
        <UniversalTimeSelector event={event} onEventChange={onEventChange} setUnsavedChanges={setUnsavedChanges} />
    </div>
  );
};

DateTimeSelector.propTypes = {
  event: PropTypes.object,
  onEventChange: PropTypes.func,
  setUnsavedChanges: PropTypes.func,
};

export default DateTimeSelector;