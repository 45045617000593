import React from "react";
import PropTypes from "prop-types";

import SearchableFilter from "ui/SearchableFilter";

const TicketFilter = ({ onChange }) => {
  const organizationFilter = <SearchableFilter label={"organization"} onChange={onChange} />;

  const eventFilter = <SearchableFilter label={"event"} onChange={onChange} />;

  const sessionFilter = <SearchableFilter label={"session"} onChange={onChange} />;

  const ticketFilter = <SearchableFilter label={"ticket"} onChange={onChange} />;

  return (
    [organizationFilter, eventFilter, sessionFilter, ticketFilter]
  );
};

TicketFilter.propTypes = {
  onChange: PropTypes.func,
};

export default TicketFilter;
