import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

const SettingsVisibility = ({show_end_date, show_supply, onChange}) => {
  return (
    <FormControl className='row'>
      <FormControlLabel
        control={
            <Checkbox checked={ show_supply || false }
                name='show_supply'
                onChange={onChange}
                size='small'
            />
        }
      label={"Show supply limit"}
      />
      <FormControlLabel
        control={
            <Checkbox checked={ show_end_date || false }
                name='show_end_date'
                onChange={onChange}
                size='small'
            />
        }
      label={"Show expiry date"}
      />
    </FormControl>
  );
};

SettingsVisibility.propTypes = {
    show_end_date: PropTypes.bool,
    show_supply: PropTypes.bool,
    onChange: PropTypes.func
};

export default SettingsVisibility;
