import React from 'react';
import { useParams } from 'react-router-dom';
import { useSuspense } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import OrganizationResource from 'resources/Profile/ProfileResource';
import PageElementResource from 'resources/Profile/PageElementResource';

import Links from './Links';
import Socials from './Socials';

import Button from 'ui/Button';

import { scrollToTop } from 'utils/scrollToTop';
import isMobileDevice from 'utils/mobileAndTabletCheck';
import { externalUrl } from 'utils/urls';

import './styles.css';

import { t } from "i18n/index";

const linkifyDescription = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return parts.map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    } else {
      return part;
    }
  });
};


const About = ({ inSidebar }) => {

  const {organizationSlug} = useParams();
  const organization = useSuspense(OrganizationResource.detail(), {id: organizationSlug})
  const {socials, links} = useSuspense(PageElementResource.detail(), {organization_slug: organizationSlug, id: 1})

  const {description} = organization;
  const linkifiedDescription = linkifyDescription(description || '');

  scrollToTop();

  const showDescription = inSidebar || (isMobileDevice() && !organization?.preferences?.hide_description);

  const showLinks = !inSidebar || isMobileDevice();
  const showSocials = inSidebar || isMobileDevice();
  const showBrandingButton = isMobileDevice();

  const handleCreateRequest = () => {
    window.open(externalUrl, '_blank');
  };

  return (
    <div className='about-content'>

      {showDescription &&
        <div className='description'>
          {linkifiedDescription}
        </div>
      }

      {showSocials &&
        <Socials socials={socials} organizationSlug={organization.slug} />
      }

      {showLinks &&
        <Links links={links} />
      }

      {showBrandingButton &&
        <Button className={'create-free-account'} onClick={handleCreateRequest} rounded>{t('profile:about:brandingButton')} <span>→</span></Button>
      }

    </div>
  );
};

About.propTypes = {
  inSidebar: PropTypes.bool,
};

export default About;
