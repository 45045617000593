import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

const Points = ({reward, isDisabled, onChange}) => {

  return (
    <TextField
        fullWidth
        size='small'
        name="points"
        label={`Points earned per month`}
        color="warning"
        value={reward.points}
        onChange={onChange}
        disabled={isDisabled} 
        type="number"
        />
  );
};

Points.propTypes = {
  reward: PropTypes.object,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default Points;


