import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from '@mui/material';

const OrgImage = ({image}) => {
  return (
    <Avatar src={image} sx={{ width: 32, height: 32 }} className='mobile-logo' />
  );
};

OrgImage.propTypes = {
  image: PropTypes.string
};

export default OrgImage;