import React from 'react';
import PropTypes from 'prop-types';

import Filter from 'ui/Filter';
import SearchableFilter from 'ui/SearchableFilter';

const TicketFilter = ({ onChange }) => {

  const ticketFilter =  <SearchableFilter onChange={onChange} label="ticket" />;

  const statusFilter = <Filter onChange={onChange} label="status" table={'tickets'} />;
  
  return (
    [ticketFilter, statusFilter]
  );
};

TicketFilter.propTypes = {
  onChange: PropTypes.func,
};

export default TicketFilter;
