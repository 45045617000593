import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormSection from 'ui/FormSection';
import Label from 'ui/text/Label';
import Button from "ui/Button";

import { TextField } from '@mui/material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';

const SeoOptions = ({slug, onChange, showShortDescription, short_description}) => {

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(short_description);
  }, [short_description]);

  return (
    <FormSection title={'SEO options'}>  
        <TextField
            fullWidth
            color='warning'
            size='small'
            label={'Url'}
            name="slug"
            value={slug}
            onChange={onChange}
        />
        <Label secondary padded>Optional: An Url will be generated if left empty</Label>

        {showShortDescription && (
            <>
                {show ? (
                    <TextField
                        fullWidth
                        color='warning'
                        size='small'
                        label={'Short Description'}
                        name="short_description"
                        value={short_description}
                        onChange={onChange}
                        multiline
                        rows={2}
                        inputProps={{ maxLength: 150 }}
                    />
                ) : (
                    <Button text small icon={AddCircleOutlineOutlinedIcon} onClick={() => setShow(!show)}>Add a short description</Button>
                )}
            </>
        )}
    </FormSection>
  );
};

SeoOptions.propTypes = {
    slug: PropTypes.string,
    onChange: PropTypes.func,
    showShortDescription: PropTypes.bool,
    short_description: PropTypes.string
};

export default SeoOptions;