import React from "react";
import PropTypes from "prop-types";

import CustomModal from "components/CustomModal";

const RequestConfirmedModal = ({ open, onClose }) => {

  const content = open
    ? {title: "Request Submitted", content: "Your request has been submitted. As soon as your report is ready, it will be emailed to you. Thank you."} 
    : null;

  return (
    <>
        {open &&
            <CustomModal
                open={open}
                handleClose={onClose}
                customModalContent={content}
                />
        }
    </>
  );
};

RequestConfirmedModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default RequestConfirmedModal;
