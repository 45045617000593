import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class TagResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/tags' as const

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

}