import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';

import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

const Supply = ({issued_counter, supply_limit}) => {
  return (
    <div>
        <ProductionQuantityLimitsIcon color="warning" fontSize='14px'/>
        <Description secondary>{issued_counter}/{supply_limit}</Description>
    </div>
  );
};

Supply.propTypes = {
  issued_counter: PropTypes.number,
  supply_limit: PropTypes.number
};

export default Supply;