import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';

const Teacher = ({ organizationId, courseId, id, showDeleteButton, published, onConfirmDelete }) => {
  
  return (
    <div>
        {published ?
          <MenuItem component={Link} to={ `/organizations/${organizationId}/collections/${courseId}/collectible/${id}/edit` } disableRipple>
            <RemoveRedEyeIcon fontSize='large'/> Details 
          </MenuItem>
          :         
          <MenuItem component={Link} to={ `/organizations/${organizationId}/collections/${courseId}/collectible/${id}/edit` } disableRipple>
            <EditIcon fontSize='large'/> Edit
          </MenuItem>
        }

        { showDeleteButton &&
        <MenuItem onClick={onConfirmDelete} disableRipple>
            <DeleteIcon fontSize='large'/>
            Delete
        </MenuItem>
        }
    </div>
  )
}

Teacher.propTypes = {
  organizationId: PropTypes.string,
  courseId: PropTypes.string,
  id: PropTypes.string,
  showDeleteButton: PropTypes.bool,
  published: PropTypes.bool,
  onConfirmDelete: PropTypes.func
};

export default Teacher;