import React from 'react';
import PropTypes from 'prop-types';
import Content from './Content';
import ManagmentTools from './ManagementTools';

const index = ({pageElement, handleDeleteRequest, handleUpdateRequest}) => {

  const showManagementTools = !!handleDeleteRequest && !!handleUpdateRequest;

  return (
    <>
        <Content element={pageElement} profile={!showManagementTools}/>
        {showManagementTools && 
          <ManagmentTools
            element={pageElement}
            handleDeleteRequest={handleDeleteRequest}
            handleUpdateRequest={handleUpdateRequest}
          />
        }
    </>
  );
};

index.propTypes = {
    pageElement: PropTypes.object,
    handleDeleteRequest: PropTypes.func,
    handleUpdateRequest: PropTypes.func
};

export default index;