import { validateCustomFields } from 'utils/validations/validateCustomFields';
import { validateEmail } from 'utils/validations/validateEmail';
import { validateStringField } from 'utils/validations/validateStringField';
import { checkCustomFields } from 'utils/signUpCustomFields';

import { t } from 'i18n/index';

const signUpValidation = (data) => { 
  const {email, name, organization_slug} = data;

  validateStringField(name, null, t('errors:nameMissing'));
  validateEmail(email, null, t('errors:emailMissing'));
  
  if (checkCustomFields(organization_slug)) validateCustomFields(organization_slug, data);
};

export default signUpValidation;