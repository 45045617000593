import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useParams } from 'react-router-dom';

import Button from 'ui/Button';
import Description from 'ui/text/Description';

import { t } from "i18n/index";
import { Viewer } from '@toast-ui/react-editor';

const PaymentProcessed = ({ setStep, handleClaimReset, stripe_purchase, isTicket, amount, content, organizationTitle, atTheDoor }) => {
  
  const {organizationSlug} = useParams();
  const history = useHistory()

  const handleRedirect = () => {
    handleClaimReset();
    history.push(`/${organizationSlug}`);
  };

  const handleRedirectToBillingDetails = () => {
    handleBillingDetails()
  };

  const handleTicketDetails = () => setStep(3);

  const handleBillingDetails = () => setStep(5);
  
  const description = stripe_purchase ? t('profile:memberships:claimModal:thankYouForPayment') : t('profile:memberships:claimModal:welcomeToTheClub');

  const singleTicketDescription = atTheDoor ? t('profile:memberships:claimModal:singleTicketAtTheDoorDescription') : t('profile:memberships:claimModal:singleTicketDescription');
  const multipleTicketDescription = atTheDoor ? t('profile:memberships:claimModal:multipleAtTheDoorTicketDescription') : t('profile:memberships:claimModal:multipleTicketDescription');
  const ticketDescription = amount === 1 ? singleTicketDescription : multipleTicketDescription;
  const suffix = amount === 1 ? "" : "s";

  const backButtonLabel = stripe_purchase ?  t('literal:close') : t('profile:memberships:claimModal:back');

  const nextButtonAction = isTicket && amount > 1 ? handleTicketDetails : handleBillingDetails;
  const nextButtonLabel = isTicket && amount > 1 ? t('profile:memberships:claimModal:ticketDetailsCTA') : t('profile:memberships:claimModal:billingDetailsCTA');

  const showMessageFrom = isTicket && stripe_purchase; //could/should be shown for products and tickets
  const showContentText = isTicket && stripe_purchase && content; //could/should be shown for products and tickets
  const showTicketDescriptions = isTicket;
  const showNextButton = stripe_purchase;

  return (
    <div className='payment-processed'>
      
      {showMessageFrom && 
        <Description bold>{t('literal:messageFrom', {organizationTitle})}:</Description>}

      {showContentText
        ? <Viewer
              initialValue={content}
              extendedAutolinks={true}
              linkAttributes={{
                  target: '_blank',
                  rel: 'noopener noreferrer',
              }}
          />
        : <Description>{description}</Description>}
      
      {showTicketDescriptions && 
        <>
          <Description bold>{t('profile:memberships:claimModal:ticketDescription', {suffix})}:</Description>
          <Description preWrap>{ticketDescription}{amount > 1 && <Link onClick={handleRedirectToBillingDetails}>{t('literal:here')}</Link>}.</Description>
        </>}

      <div className='button-group'>

        <Button onClick={handleRedirect} fullwidth secondary>{backButtonLabel}</Button>

        {showNextButton && 
          <Button onClick={nextButtonAction} fullwidth>{nextButtonLabel}</Button>}

      </div>      
    </div>
  );
};

PaymentProcessed.propTypes = {
    setStep: PropTypes.func,
    handleClaimReset: PropTypes.func,
    stripe_purchase: PropTypes.bool,
    isTicket: PropTypes.bool,
    amount: PropTypes.number,
    content: PropTypes.object,
    organizationTitle: PropTypes.string,
    atTheDoor: PropTypes.bool
};

export default PaymentProcessed;

