import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';

import { Checkbox } from '@mui/material';
import FormSection from 'ui/FormSection';

const HideDescription = ({ hide_description, onChange }) => {

    const label = "Hide organization's description in the About section when user is on mobile.";

    return (

        <FormSection title={'Hide Description in Mobile'}>
            <FormControlLabel
                control={
                    <Checkbox
                        name="hide_description"
                        checked={hide_description}
                        onChange={onChange}
                        size='small'
                        />
                        }
                        label={label}
                    />
        </FormSection>
  );
};

HideDescription.propTypes = {
    hide_description: PropTypes.bool,
    onChange: PropTypes.func,
};

export default HideDescription;