import React from 'react';
import { useParams } from 'react-router-dom';
import { useSuspense } from '@rest-hooks/react';

import PromoCodeResource from 'resources/organization/PromoCodeResource';

import Layout from 'components/layouts';

import Form from './form'

const pageInfo = {
  name: "Edit Promo Code"
};

const UpdatePromoCode = () => {

  const params = useParams();
  const organizationId = parseInt(params.organizationId);
  const promoCodeId = parseInt(params.promoCodeId);

  const promoCode = useSuspense(PromoCodeResource.detail(), { id: promoCodeId, organization_id: organizationId });

  pageInfo.name = "Edit Promo Code"

  return (
    <Layout context='teacher'
            back={ true }
            pageInfo={ pageInfo }
            activeMenuItem='perks'>

      <Form record={ promoCode } />

    </Layout>
  );
};

export default UpdatePromoCode;
