import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ActionCard from 'components/ActionCard';
import ClaimLinkModal from './ClaimLinkModal';

import { rewardClaimUrl } from 'utils/urls';
import { courseType, rewardType } from 'utils/constants';
import { isRewardUnavailable } from 'utils/rewards';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';

const claimLinkLabels = {
  private: {
    title: "Private Claim Link",
    description: "Copy a link that whitelisted contacts can use to claim"
  },
  public: {
    title: "Public Claim Link",
    description: "Copy a link that anyone can use to claim"
  }
};

const EmptyDashboard = ({ reward, onSelect }) => {

  const isPublic = reward.public && reward.claim_token;
  const [openClaimLinkModal, setOpenClaimLinkModal] = useState(false);
  const claimLabels = isPublic ? claimLinkLabels.public : claimLinkLabels.private;
  const [ ableToSend, setAbleToSend ] = useState(true);

  const mysteryBox = reward.course_category === courseType.MYSTERYBOX.value;
  const sellableCollectibles = reward.course_category === "sellableCollectibles";

  const showSendViaEmail = !mysteryBox && !sellableCollectibles;

  const handleOpenClaimLinkModal = () => {
    setOpenClaimLinkModal(true);
  };

  const handleCloseClaimLinkModal = () => {
    setOpenClaimLinkModal(false);
  };

  useEffect(() => {
    const handleRewardState = () => {
      const unavailable = isRewardUnavailable(reward)
      if (unavailable) {
        setAbleToSend(false);
      }
    };
    handleRewardState();
  }, []);

  return (
    <>
      <p style={{ textAlign: 'center' }}>{ ableToSend ? `You are about to send this ${rewardType[reward.category.toUpperCase()].label}. Please pick one of the delivery options bellow.` : `You can no longer send this reward.`}</p>
      
      {ableToSend &&
        <div className={ `grid stats-grid`}>

          { showSendViaEmail &&
            <>
              <ActionCard onClick={() => onSelect('list')}
                          Icon={ PeopleAltIcon } 
                          title="Participants List" 
                          description="Choose from participants list" />

              <ActionCard onClick={() => onSelect('new')}
                          Icon={ EmailIcon } 
                          title="Send Email" 
                          description="Send to participant via email" />
            </>
          }

          <ActionCard onClick={handleOpenClaimLinkModal}
                      Icon={ ContentCopyIcon } 
                      title={claimLabels.title}
                      description={claimLabels.description} />

          <ClaimLinkModal open={openClaimLinkModal}
                          url={rewardClaimUrl(reward.claim_token)}
                          onClose={handleCloseClaimLinkModal}
          />
        </div>
      }
    </>
  )
};

EmptyDashboard.propTypes = {
  reward: PropTypes.object,
  onSelect: PropTypes.func,
  active_status: PropTypes.boolean,
};

export default EmptyDashboard;
