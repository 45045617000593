import React from 'react';
import PropTypes from 'prop-types';

import Label from 'ui/text/Label';

import { TextField } from '@mui/material';

import { t } from "i18n/index";

const Motive = ({onRedeemMotiveChange}) => {

  const handleChange = (event) => {
    onRedeemMotiveChange(event.target.value);
  };

  return (
    <div>
        <TextField
            autoFocus
            fullWidth
            color='warning'
            size='small'
            label={t('literal:reason')}
            onChange={handleChange}
            multiline
            rows={2}
          />
          <Label secondary padded>{t('profile:perks:redeemModal:reasonLabel')}</Label>
    </div>
  )
}

Motive.propTypes = {
    onRedeemMotiveChange: PropTypes.func
};

export default Motive;