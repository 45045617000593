import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';


import MUITable from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Body from './Body';

const Table = ({ rows, fullNameTable }) => {
  return (
    <TableContainer component={Paper}>
      <MUITable
        sx={{ minWidth: 550 }}
        size="small"
        aria-label="a dense table"
        >
          <Header fullNameTable={fullNameTable} />
          <Body rows={rows} fullNameTable={fullNameTable} />
      </MUITable>
    </TableContainer>
  );
};

Table.propTypes = {
  rows: PropTypes.array,
  fullNameTable: PropTypes.bool,
};

export default Table;