import ApiResource from 'resources/ApiResource'

export default class PasswordResetResource extends ApiResource {
  static urlRoot = '/password_resets' as const;

  readonly reset_token: string = '';

  pk() {
    return this.reset_token?.toString();
  }

}
