import React from 'react'
import PropTypes from 'prop-types'

const EmbeddedSpotify = ({id}) => {
    return (
        <div className='embed'>
            <iframe 
                className='spotify-embed-iframe' 
                src={`https://open.spotify.com/embed/${id}`} 
                width="100%" 
                height="152"
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                loading="lazy">
            </iframe>
        </div>
    );
};

EmbeddedSpotify.propTypes = {
    id: PropTypes.string
};

export default EmbeddedSpotify;