import React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@mui/material/FormControlLabel";

import { Checkbox } from "@mui/material";

const Banner = ({ hide_banner, onChange }) => {
  const label =
    "Select this option if you want your Newsletter to be sent without a top banner.";

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox 
            name="hide_banner"
            checked={hide_banner}
            onChange={onChange}
            size="small"
            />
        }
        label={label}
      />
    </>
  );
};

Banner.propTypes = {
  hide_banner: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Banner;
