import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class RewardInstanceResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/reward_instances' as const;

  readonly id: number | undefined = undefined;
  readonly category: string= '';
  readonly title: string= '';
  readonly first_name: string= '';
  readonly last_name: string= '';
  readonly reward_id: string = '';
  readonly student_id: string = '';
  readonly claim_state: string = '';
  readonly tx: string = '';
  readonly badge_tx: string = '';
  readonly badge_chain: string = '';
  readonly tokens: number | undefined = undefined;
  readonly tokens_tx: string = '';
  readonly tokens_chain: string = '';
  readonly created_at: Date = new Date(0);
  readonly updated_at: Date = new Date(0);
  readonly claimed_at: Date = new Date(0);

  pk() {
    return this.id?.toString();
  }
}
