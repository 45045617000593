import React from "react";
import PropTypes from "prop-types";

import { Droppable } from "react-beautiful-dnd";
import DraggableItem from "./DraggableItem";

const DraggableList = ({ items, droppableId, title }) => {
  return (
    <div className="drag-drop-list"> 
      
      <h3>{title}</h3>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <div className={
                `draggable-item ${snapshot.isDraggingOver ? "dragging-over" : ""}`
            }>
              {items.map((tab, index) => (
                <DraggableItem key={tab} tab={tab} index={index} />
              ))}
              {provided.placeholder}
            </div>
          </div>
        )}
      </Droppable>

    </div>
  );
};

DraggableList.propTypes = {
  items: PropTypes.array,
  droppableId: PropTypes.string,
  title: PropTypes.string,
};

export default DraggableList;
