import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/layouts/index';
import TabContainers from 'components/TabContainers';
import PersonalDetailsForm from './PersonalDetailsForm';
import NewsletterPreferences from './NewsletterPreferences';
import ConnectedAccounts from './ConnectedAccounts';
import { t } from 'i18n/index';

const pageInfo = {
    name: t('literal:settings'),
};

const settings = [
    { label: t('literal:personalDetails'), segment: 'details', component: PersonalDetailsForm },
    { label: t('literal:newsletterPreferences'), segment: 'newsletters', component: NewsletterPreferences },
    { label: t('literal:connectedAccounts'), segment: 'social', component: ConnectedAccounts },
]

const UserSettings = ({match}) => {

    return (
        <Layout context='student'
            activeMenuItem="settings"
            pageInfo={ pageInfo }>

            <TabContainers path={match.path}
                           containers={settings}
                           />
        </Layout>

    );
};

UserSettings.propTypes = {
    match: PropTypes.object
}

export default UserSettings;
