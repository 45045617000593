import React from "react";

import TableButton from "components/TableButton";

import { getTableButtons } from "utils/tables/getTableButtons";
import { getDateFormat } from "utils/tables/getDateFormat";
import { getRedeemLabel } from "utils/tables/getRedeemLabel";
import { getDateSelector } from "utils/tables/getDateSelector";

import isMobileDevice from "utils/mobileAndTabletCheck";

export const finalizedOffersColumns = (handleDeleteRequest, handleUrlCopy, handlePreview, loading, organizationTags) => {
  let columns = [
    {
      field: "title",
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      width: "50%",
      editable: true,
    },
    {
      field: "buttons",
      width: "50%",
      selector: (row) => (
      <div className="active-offers-buttons">
        {getTableButtons(row, handleDeleteRequest, handleUrlCopy, handlePreview, loading, organizationTags).map((button, index) => (
          <TableButton
            title={button.title}
            Icon={button.icon}
            link={button.link}
            action={button.action}
            disabled={button.disabled}
            key={index}/>
        ))}
      </div>
      )
    }];

  if (!isMobileDevice()) {
    const additionalColumns = [
        {
          field: "redeemed",
          name: "Redeemed",
          selector: (row) => getRedeemLabel(row.redeemed, row.supply_limit),
          sortable: true,
          width: "20%",
        },
        {
          field: "end_date",
          name: "End Date",
          selector: (row) => getDateSelector(row.end_date),
          format: (row) => getDateFormat(row.end_date),
          sortable: true,
          width: "20%",
        },
      ]

      const modifiedColumns = [
        {
          ...columns[0],
          width: "40%"
        },
        ...additionalColumns,
        {
          ...columns[1],
          width: "20%"
        },
      ];

    columns = modifiedColumns;
  }

  return (columns);
};
