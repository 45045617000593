import React from "react";
import PropTypes from "prop-types";
import Description from "ui/text/Description";
import formattedDate from "utils/formattedDate";
import Button from "ui/Button";

const Sessions = ({ sessions, selectedSession, onChange }) => {

    return (
        <div className="sessions">
            <Description large bold>Select Session</Description>
            <div className="session-selector">
                {sessions.map((session) => (
                    <Button 
                        key={session.id}
                        onClick={() => onChange(session)}
                        fullwidth
                        secondary
                        className={session.id === selectedSession.id ? 'selected' : ''}
                    >
                        <Description bold>{formattedDate(session.start_date, 'wday')}</Description>

                        <Description>{formattedDate(session.start_date, 'month')}</Description>
                        <Description large className={`day ${session.id === selectedSession.id ? 'selected' : ''}`}>{formattedDate(session.start_date, 'day')}</Description>

                        <Description>{formattedDate(session.start_date, 'time')}</Description>
                    </Button>
                ))}
            </div>
        </div>
    );
};

Sessions.propTypes = {
  sessions: PropTypes.array,
  selectedSession: PropTypes.object,
  onChange: PropTypes.func
};

export default Sessions;
