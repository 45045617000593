import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';
import FormSection from 'ui/FormSection';

import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const Content = ({onEmailChange, tickets, onTicketSelect, ticketId}) => {

    const handleTicketSelection = (event, ticketId) => {
        onTicketSelect(ticketId);
    };

    const handleEmailChange = (event) => {
        onEmailChange(event.target.value);
    };

  return (
    <FormSection slim>
        <Description>
            Add an email below to send a new ticket.
        </Description>

        <TextField
            label="Email"
            name="email"
            onChange={handleEmailChange}
            fullWidth
            size='small'
            color="warning"
        />

        <Autocomplete
            fullWidth
            options={tickets}
            onChange={(event, newValue) => {
                handleTicketSelection(event, newValue?.id);
            }}
            defaultValue={tickets.find((element) => element.id === ticketId)}
            getOptionLabel={(option) => option.identifier}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Ticket"
                    name="ticket"
                    fullWidth
                    size='small'
                    color="warning"
                />
            )}
        />

    </FormSection>
  );
};

Content.propTypes = {
  onEmailChange: PropTypes.func,
  tickets: PropTypes.array,
  onTicketSelect: PropTypes.func,
  ticketId: PropTypes.number,
};

export default Content;
