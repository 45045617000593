import React from 'react';
import PropTypes from 'prop-types';
import { useSuspense } from '@rest-hooks/react';
import { useHistory } from 'react-router-dom';

import IntegrationResources from 'resources/organization/IntegrationsResource';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import telegram_logo from 'assets/images/social_elements_logos/telegram_logo.png';
import notion_logo from 'assets/images/social_elements_logos/notion_logo.png';

const integrations = [
  {
    name: 'Telegram',
    logo: telegram_logo,
    path: '/telegram'
  },
  {
    name: 'Notion',
    logo: notion_logo,
    path: '/notion'
  }
];

const List = ({match}) => {
  const history = useHistory();
  const organizationIntegrations = useSuspense(IntegrationResources.list(), {organization_id: match.params.organizationId})
                                   .map(({ service }) => service)

  const isConnected = (integration) => {
    return organizationIntegrations
            .includes(integration.toLowerCase());
  };

  return (
    <div className="badges-grid grid">
    {
      integrations.map((integration, index) => (
        <div className={`card badge-card`}
          key={index}
          onClick={() => history.push(match.url + integration.path)}
        >

        <div className="badge-image">
            <img src={integration.logo} className="badge-image" />
        </div>

          <p className="title">
            { integration.name }
            { isConnected(integration.name) && <CheckCircleIcon style={{ color: 'green' }} /> }</p>
        </div>
      ))
    }
    </div>

  );
};

List.propTypes = {
  match: PropTypes.object
};

export default List;
