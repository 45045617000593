import React, { useEffect } from 'react';

import ProductResource from 'resources/admin/ProductResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import Stats from './Stats';
import ProductFilter from './ProductFilter';

import { ticketsColumns } from 'utils/tables/columns/admin/ticketsColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import downloadCSV from 'utils/downloadCSV';

const pageInfo = {
  label: "Admin",
  name: "Products",
};

const AdminProducts = () => {

  const {
    objectState, 
    fetchData,
    handlePageChange,
    handleFilterChange
  } = usePaginationAndFilteringController({
      fetchDataResource: ProductResource,
      fetchDataParams: {},
  });

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownload = async () => {
    await downloadCSV('/admin/products', objectState.filteringParams);
  };

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem='products' >

      <TabPanels labels={[ "Products" ]} >
            <div>
              <Stats products={objectState.filteredObjects} />
              <DataSelectionTable records={objectState.filteredObjects}
                                  columns={ticketsColumns}
                                  onClick={handleDownload}
                                  serverPaginationProps={{
                                    paginationServer: true,
                                    paginationTotalRows: objectState.totalCount,
                                    page: objectState.page,
                                    onChangePage: handlePageChange
                                  }}
                                  filter={
                                    <ProductFilter
                                        onChange={handleFilterChange}
                                      />
                                    }  
              />
            </div>
      </TabPanels>

    </Layout>
)};

export default AdminProducts;
