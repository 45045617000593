import React from 'react';

import { ordersColumns } from 'utils/tables/columns/ordersColumns';

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import TableButton from 'components/TableButton';

const getActionButtons = (row) => {
  return [
    {
      title: "See Org Orders",
      Icon: ZoomInIcon,
      action: () => window.location.href = '/organizations/' + row.organization_id + '/analytics?defaultIndex=3'
    },
  ];
};

export const adminOrdersColumns = [
  ...ordersColumns.slice(0, 2),
  {
    field: "organization_title",
    name: "Organization",
    selector: (row) => row.organization_title,
    sortable: true,
    width: "15%",
  },
  ...ordersColumns.slice(2, ordersColumns.length),
  {
    selector : (row) =>  
      <div>
        { getActionButtons(row).map((button, index) => (
          <TableButton 
            key={index}
            title={button.title}
            Icon={button.Icon}
            action={button.action}
          />
        ))}
      </div>,
    sortable: false,
    width: '5%'
  },
];
