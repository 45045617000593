import React, { useEffect } from 'react';

import UserResource from 'resources/admin/UserResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import { usersColumns } from 'utils/tables/columns/admin/usersColumns';

const UsersTable = () => {

    const {
        objectState, 
        fetchData,
        handlePageChange,
      } = usePaginationAndFilteringController({
          fetchDataResource: UserResource,
          fetchDataParams: {},
      });
  
      useEffect(() => {
        fetchData();
      }, []);

  return (
    <DataSelectionTable records={objectState.filteredObjects}
                        columns={usersColumns}
                        serverPaginationProps={{
                          paginationServer: true,
                          paginationTotalRows: objectState.totalCount,
                          page: objectState.page,
                          onChangePage: handlePageChange
                        }}
                        subHeader={false}
              />
  );
};

export default UsersTable;