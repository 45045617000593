import React from 'react';
import PropTypes from 'prop-types';

import { rewardType, claimState } from 'utils/constants';
import formattedDate from 'utils/formattedDate';

import Chip from '@mui/material/Chip';
import { Tooltip } from '@mui/material';

export const Labels = ({category, claimState: claim_state, isTemplate, isPublic, isTeacher, isStudent, subscriptionStatus, subscriptionEndDate}) => {

  if (category === rewardType.PASS.value && !isStudent) return null;

  const labelData = [
    {
      show: isTeacher && isTemplate,
      label: 'TEMPLATE',
    },
    {
      show: isTeacher && isPublic,
      label: 'PUBLIC',
    },
    {
      show: isStudent && subscriptionStatus,
      label: subscriptionStatus && subscriptionStatus.toUpperCase(),
      tooltipTitle: formattedDate(subscriptionEndDate)
    },
    {
      show: isStudent && claim_state === claimState.ONCHAIN.value,
      label: claimState.ONCHAIN.label,
      tooltipTitle: claimState.ONCHAIN.label
    }
  ];

  return (
    <div className="badge-labels">
      {labelData.map((item, index) => (
        item.show && (
          <Tooltip
            key={index}
            title={
              item.tooltipTitle && item.tooltipTitle
            }
          >
            <Chip
              label={item.label}
              variant="outlined"
              className="badge-card-chip"
            />
          </Tooltip>
        )
      ))}
    </div>
  );
};


Labels.propTypes = {
  category: PropTypes.string,
  claimState: PropTypes.string,
  isTemplate: PropTypes.bool,
  isPublic: PropTypes.bool,
  isTeacher: PropTypes.bool,
  isStudent: PropTypes.bool,
  subscriptionStatus: PropTypes.string,
  subscriptionEndDate: PropTypes.string
};

export default Labels;
