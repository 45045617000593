import React from 'react';
import PropTypes from 'prop-types';

import CardHeader from './CardHeader';
import ClaimModal from './ClaimModal';
import StatusBar from '../StatusBar';

import Card from 'ui/Card';
import PointsChip from 'ui/Navbar/PointsChip';

import { frequencyConversion } from 'utils/profile/claim/membershipsCardGrid/frequencyConversion';
import { pointsCalculator } from 'utils/profile/claim/membershipsCardGrid/pointsCalculator';

const CardGrid = ({ loading, activeFrequency, userSubscription, filteredPasses, showClaimModal, handleCloseClaimModal, handleClick, handleError, claimWithNFT, organizationSlug }) => {
  
  const already_a_paying_member = userSubscription && userSubscription.user_subscription && userSubscription.paid_subscription;

  return (
    <div className='grid'>
      { filteredPasses.map(({ id, media_type, image_url, video_url, title, shownPrice, description, points, claim_token, subscription_details, already_issued_to, external_contract_address }) => (
        <div key={ id }>
          <Card
            media_type={ media_type }
            video_url={ video_url }
            image_url={ image_url }
            description={ description }
            
            header={ <CardHeader title={ title }
                                      external_contract_address={ external_contract_address }
                                      claim_token={ claim_token }
                                      shownPrice={ shownPrice } 
                                      already_issued_to={ already_issued_to }
                                      already_a_paying_member={ already_a_paying_member }
                                      handleClick={ handleClick } 
                                      loading={ loading } 
                                      activeFrequency={ activeFrequency }
                                      organizationSlug={ organizationSlug }/>}

            statusBar={ subscription_details && 
                          <StatusBar  subscriptionDetails={ subscription_details }
                                      handleError={ handleError } />}

            footer={ points > 0 && 
                          <PointsChip points={pointsCalculator(points, activeFrequency)} 
                                      frequency={frequencyConversion[activeFrequency]}
                                      inCard={true} />} />

          {showClaimModal(claim_token) &&
              <ClaimModal
                rewardId={ id }
                claimToken={ claim_token }
                activeFrequency={ activeFrequency }
                userSubscription={ userSubscription }
                handleCloseClaimModal={ handleCloseClaimModal }
                claimWithNFT={ claimWithNFT }
              />
          }
        </div>
      ))}
    </div>
  );
};

CardGrid.propTypes = {
  loading: PropTypes.bool,
  activeFrequency: PropTypes.string,
  userSubscription: PropTypes.object,
  filteredPasses: PropTypes.array,
  showClaimModal: PropTypes.func,
  handleCloseClaimModal: PropTypes.func,
  handleClick: PropTypes.func,
  handleError: PropTypes.func,
  claimWithNFT: PropTypes.bool,
  organizationSlug: PropTypes.string
};

export default CardGrid;
