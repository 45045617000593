import React from "react";
import PropTypes from "prop-types";

import SubmitButton from "./SubmitButton";
import Status from "./Status";

const Footer = ({ id, status, onChange, loading, isTicket }) => {
  return (
    <div className={`new-form-field-footer`}>
      <SubmitButton id={id} loading={loading} />
      {!isTicket && <Status status={status} onChange={onChange}/>}
    </div>
  );
};

Footer.propTypes = {
  id: PropTypes.number,
  status: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  isTicket: PropTypes.bool
};

export default Footer;
