import React, { useEffect, useState } from 'react';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import OrganizationResource from 'resources/organization/OrganizationResource';

import createFormData from './createFormData';
import TabsOrganizer from './TabsOrganizer';
import HideDescription from './HideDescription';

import Button from 'ui/Button';

import { useError } from 'utils/useErrorController';
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import useOrganizationAdmin from 'utils/useOrganizationAdmin';
import ShowApplicationFee from './ShowApplicationFee';

const PreferencesForm = ({ onUnsavedChanges }) => {

  const { fetch } = useController();
  const { setOpen, setMessage, setSeverity } = useToast();
  const { loading, setLoading, handleError } = useError();

  const organizationData = useOrganizationAdmin();

  const [organization, setOrganization] = useState(organizationData);

  const {profile_tabs, preferences, application_fee_enabled} = organization;

  useEffect(() => {
    setOrganization(organizationData);
  }, [organizationData]);

  const submitOrganizationChanges = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();

      const formData = createFormData(organization);
      const { id } = await fetch(OrganizationResource.update(), { id: organization.id }, formData );

      // success!
      if (id){
          await fetch(OrganizationResource.detail(), { id: id });
          customToast('success', `Organization Preferences Updated`, setOpen, setSeverity, setMessage);
          setLoading(false);
          onUnsavedChanges(false);
      }

    } catch (error) {
      handleError(error);
    }
  };

  const handleChange = ({target :{name, value, checked}}) => {
    onUnsavedChanges(true);
    let newOrganization;
    if (name === 'hide_description' || name === 'show_application_fee') {
      newOrganization = { ...organization, preferences: { ...preferences, [name]: checked}}
    } else {
      newOrganization = { ...organization, [name]: value}
    }
    setOrganization(newOrganization);
  };

  return (
    <div>
    { preferences && profile_tabs &&
        <form onSubmit={ (event) => submitOrganizationChanges(event) } >

          <HideDescription
            hide_description={preferences?.hide_description || false}
            onChange={handleChange}
          />

          {organization && application_fee_enabled &&
            <ShowApplicationFee
              show_application_fee={preferences?.show_application_fee || false}
              onChange={handleChange}
            />}

          
          <TabsOrganizer
            loading={loading}
            profile_tabs={ profile_tabs }
            onChange={handleChange}
            onUnsavedChanges={onUnsavedChanges}
          />

          <div className={ `new-form-field-footer` }>
            <Button loading={loading} minwidth >
              Save
            </Button>
          </div>
        </form>
      }
    </div>
  )
};

PreferencesForm.propTypes = {
    record: PropTypes.object,
    onUnsavedChanges: PropTypes.func
};

export default PreferencesForm;
