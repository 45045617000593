import React from "react";
import PropTypes from "prop-types";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableCell } from "@mui/material";
const Header = ({ fullNameTable }) => {
  return (
    <TableHead>
      <TableRow>
        {fullNameTable ? (
          <TableCell>Name</TableCell>
        ) : (
          <>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
          </>
        )}

        <TableCell>Email</TableCell>
      </TableRow>
    </TableHead>
  );
};

Header.propTypes = {
  fullNameTable: PropTypes.bool,
};

export default Header;
