import React from 'react';
import PropTypes from 'prop-types';

import Images from './Images';

import Description from 'ui/text/Description';

import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DialogComponent = ({ open, handleClose, objects, selectedIndex, title, description }) => {
  return (
    <Dialog open={open} fullScreen className='ui-slider dialog'>
      <DialogTitle>
        {title} 
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: 'black',
          }}
          >
            <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='content'>
        <Images objects={objects} selectedIndex={selectedIndex} handleClose={handleClose}/>
        <Description>{description}</Description>
      </DialogContent>
    </Dialog>
  );
};

DialogComponent.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  objects: PropTypes.array,
  selectedIndex: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string
};

export default DialogComponent;