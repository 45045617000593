import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";

import RewardResource from 'resources/admin/RewardResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import { rewardsColumns } from 'utils/tables/columns/admin/rewardsColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';

const pageInfo = {
  label: "Membership",
  name: "Passes"
}

const AdminCoursesView = () => {
  const params = useParams();
  const courseId = parseInt(params.courseId);

  const {
    objectState, 
    fetchData,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: RewardResource,
      fetchDataParams: { course_id: courseId },
  });

  useEffect(() => {
    fetchData();
  }, []);

  pageInfo["name"] = objectState.filteredObjects.length > 0 ? objectState.filteredObjects[0].course_title : "Passes";

  return (
    <Layout context='admin'
            back={true}
            pageInfo={ pageInfo }
            activeMenuItem='memberships'>

      <TabPanels labels={[ "Passes" ]} >
          <DataSelectionTable records={objectState.filteredObjects}
                              columns={rewardsColumns}
                              serverPaginationProps={{
                                paginationServer: true,
                                paginationTotalRows: objectState.totalCount,
                                page: objectState.page,
                                onChangePage: handlePageChange
                              }}
                              subHeader={false}
            />
      </TabPanels>

    </Layout>
)};

export default AdminCoursesView;
