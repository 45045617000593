import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import AuthChallengeResource from 'resources/auth/ChallengeResource';

import Button from 'ui/Button';

import { useError } from 'utils/useErrorController';

import { t } from 'i18n/index';

import { ConnectButton } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';

import { useAccount, useSignMessage } from 'wagmi';

import { verifyMessage } from 'ethers/lib/utils';

const challengeTypeButton = {
  'connect': t('literal:connect'),
  'claim': t('literal:claim'),
  'signup': t('literal:signUp'),
};

const ConnectWalletButton = ({ challengeType, done, loading }) => {

  const {handleError} = useError();

  const { address, isConnected } = useAccount();
  const { fetch } = useController();

  const { signMessage } = useSignMessage({
    onSuccess(data, variables) {
      const wallet_address = verifyMessage(variables.message, data);
      if (wallet_address !== address) {
        throw new Error(t('errors:unableToValidateSignature'));
      }
      done({ wallet_address, challenge: variables.message, signature: data });
    },
  });

  useEffect(() => {
    if (isConnected) {
      signChallenge(address);
    }
  }, [isConnected]);

  const signChallenge = async (wallet) => {
    try {
      const { challenge } = await fetch(AuthChallengeResource.create(), {wallet_address: wallet, challenge_type: challengeType});
      signMessage({ message: challenge });
      return challenge;
    } catch (e) {
      handleError(e);
    }
  };

  const handleConnect = () => {
    signChallenge(address);
  };

  return (
    <>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          const ready = mounted && authenticationStatus !== 'loading';
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus ||
              authenticationStatus === 'authenticated');

          return (
            <div
              {...(!ready && {
                'aria-hidden': true,
                'style': {
                  opacity: 0,
                  pointerEvents: 'none',
                  userSelect: 'none',
                },
              })}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Button
                      className="connect-wallet-button"
                      onClick={ openConnectModal }
                      loading={loading}
                      minwidth>
                      { challengeTypeButton[challengeType] }
                    </Button>
                  );
                }

                return (
                  <Button
                    className="connect-wallet-button"
                    onClick={ handleConnect }
                    loading={loading}
                    minwidth={loading || challengeType === 'claim' || challengeType === 'signup'}>
                      { challengeTypeButton[challengeType] }
                  </Button>
                );
              })()}
            </div>
          );
        }}
      </ConnectButton.Custom>
    </>
  )
}

ConnectWalletButton.propTypes = {
  challengeType: PropTypes.string,
  done: PropTypes.func,
  loading: PropTypes.bool
};

export default ConnectWalletButton;
