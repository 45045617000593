import { convertPriceFromCents, currencySymbol } from "utils/rewards";

import { t } from "i18n/index";

const getPriceWithCurrency = (price, currency) => {
  const convertedPrice = convertPriceFromCents(price);
  return `${convertedPrice} ${currencySymbol[currency]}`;
};

const sortPassesByPrice = (data) => {
  const sortedData = data.sort((a, b) => {
    const priceA = parseFloat(a.shownPrice);
    const priceB = parseFloat(b.shownPrice);

    if (isNaN(priceA) || isNaN(priceB)) {
      return 0;
    }

    return priceA - priceB;
  });

  return sortedData;
};

const filterPasses = (data, targetFrequency) => {
  const filteredData = [];

  for (const pass of data) {
    if (pass.pricings && pass.pricings.length > 0) {
      const foundPricings = pass.pricings.filter(
        (pricing) => pricing.frequency === targetFrequency
      );

      if (foundPricings.length > 0) {
        const { stripe_price_id, price, currency } = foundPricings[0];

        const priceWithCurrency = getPriceWithCurrency(
          price,
          currency
        );

        filteredData.push({
          ...pass,
          shownPrice: priceWithCurrency,
          stripe_price_id: stripe_price_id,
        });
      }
    } else {
      filteredData.unshift({
        ...pass,
        shownPrice: t("literal:free").toUpperCase(),
      });
    }
  }

  return filteredData;
};

export const filterPassesByFrequency = (data, targetFrequency) => {
  const filteredPasses = filterPasses(data, targetFrequency);
  const sortedPasses = sortPassesByPrice(filteredPasses);

  return sortedPasses;
};
