import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from "react-router-dom";

import logo from 'assets/images/logo-membrzclub.png';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LaunchIcon from '@mui/icons-material/Launch';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Toolbar from '@mui/material/Toolbar';
import { IconButton, Tooltip, Link as MuiLink } from '@mui/material';
import { organizationUrl } from 'utils/urls';
import Button from 'ui/Button';
import { getTeacherNavLinks } from 'utils/navLinks';

const menuActions = {
  'content': { link: 'content/new', Icon: AddCircleOutlineIcon, tooltip: 'Create new post' },
};

const Teacher = ({ organizationsList, activeMenuItem=0 }) => {

  const params = useParams();
  const organizationId = params.organizationId;
  const currentOrganization = organizationsList && organizationsList.find(org => org.id === parseInt(organizationId));
  const { title, slug } = currentOrganization || {};
  const menuItems = getTeacherNavLinks(organizationId);

  return (
    <div>
      <Toolbar className="drawer-logo">
        <img src={ logo } />
      </Toolbar>

      <h2 className='center orange'>{title}</h2>
      {menuItems.map(({id, text, Icon, link}) => (
        <ListItemAction key={id} selected={ id == activeMenuItem } menuId={id} organizationId={organizationId}>
          <ListItemButton
              className={id == 'onboarding' ? 'onboarding' : ''}
              selected={ id != 'onboarding' && id == activeMenuItem }
              component={Link}
              disabled={!organizationId}
              to={ `/organizations/${organizationId}/${link}`}
              >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={ text } />
          </ListItemButton>
        </ListItemAction>
      ))}

      <ListItemAction selected={ false } menuId='preview'>
        <MuiLink className='preview-profile-page' href={ organizationUrl(slug)} target='_blank'>
          <Button icon={LaunchIcon} variant='contained' secondary>
            Preview Page
          </Button>
        </MuiLink>

      </ListItemAction>

    </div>
  );
};

// eslint-disable-next-line react/prop-types
const ListItemAction = ({ selected, menuId, organizationId, children }) => {
  const action = menuActions[menuId];

  if (!action) { return children;}

  const { link, Icon, tooltip } = action;
  const fullLink = `/organizations/${organizationId}/${link}`;

  return (
    <ListItem component="div" disablePadding>
      { children }

      <Tooltip title={tooltip}>
      <IconButton className={ selected ? 'selected' : ''} size="large">
        <Link to={ fullLink }>
          <Icon />
        </Link>
      </IconButton>
      </Tooltip>
    </ListItem>
  );
};

Teacher.propTypes = {
  organizationsList: PropTypes.array,
  activeMenuItem: PropTypes.string
};

export default Teacher;
