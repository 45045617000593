import React from 'react';
import PropTypes from 'prop-types';

import DataTable from 'react-data-table-component';

import DataTableHeader from './DataTableHeader';
import { customStyles } from './customStyles';
import LoadingTable from "./LoadingTable.js";

import { useError } from 'utils/useErrorController';
import { customSort } from 'utils/tables/customSort';

const DataSelectionTable = ({
                              onClick,
                              records,
                              columns,
                              queryString,
                              onQueryChange,
                              searchLabel,
                              serverPaginationProps,
                              filter,
                              subHeader = true,
                            }) => {

  const {loading} = useError();

  const defaultPage = serverPaginationProps ? serverPaginationProps.page : 1;

  return (
        <DataTable
          subHeaderComponent={
            <DataTableHeader
              onClick={onClick}
              searchLabel={searchLabel}
              queryString={queryString}
              onQueryChange={onQueryChange}
              filter={filter}
            />
          }
          data={records}
          columns={columns}
          customStyles={customStyles}
          subHeader={subHeader}
          sortFunction={customSort}
          pagination
          paginationDefaultPage={defaultPage}
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationRowsPerPageOptions={[10]}
          progressComponent={<LoadingTable />}
          progressPending={loading}
          {...serverPaginationProps}
        />
  )
};

DataSelectionTable.propTypes = {
    onClick: PropTypes.func,
    records: PropTypes.array,
    columns: PropTypes.array,
    searchLabel: PropTypes.string,
    queryString: PropTypes.string,
    onQueryChange: PropTypes.func,
    serverPaginationProps: PropTypes.object,
    filter: PropTypes.node,
    subHeader: PropTypes.bool,
};

export default DataSelectionTable;
