import React from "react";
import PropTypes from "prop-types";

import CopiableText from "containers/teacher/Event/EventsList/CheckinCredentialsModal/CopiableText";

import { Alert } from "@mui/material";

const Content = ({ checkinCredentials }) => {
  return (
    <>
      <p>
        Share a secret Check-in link and code with collaborators
        without adding them as managers of your Club.
      </p>

      <p>
        Make sure to keep them safe and only accessible to your collaborators.
      </p>

      { checkinCredentials?.secret_token ?
        <>
          <div style={{ marginBottom: "16px" }}>
            <CopiableText label="Check-in Link:" value={checkinCredentials?.checkin_url} />
            <CopiableText
              label="Secret Code:"
              value={checkinCredentials?.secret_token}
            />
          </div>

          <Alert severity="warning" sx={{ marginBottom: "16px" }}>
            Regenerating the secret link and pin code will result in the automatic expiration of
            previously generated credentials.
          </Alert>
        </>
        :
        <Alert severity="info" sx={{ marginBottom: "16px" }}>
          Click on the button below to generate the secret link and pin code.
        </Alert>
      }
    </>
  );
};

Content.propTypes = {
  checkinCredentials: PropTypes.object,
};

export default Content;
