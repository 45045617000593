/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Resource } from '@rest-hooks/rest';
import resourceUrl  from 'utils/resourceUrl';

class NetworkError extends Error {
  status    = null;
  response  = null;

  constructor(response) {
    super(response.statusText);

    this.status   = response.status;
    this.response = response;
  }
}

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

// Extends Resource to provide appropriate handling of FormData payloads, and
// for building environment specific URLs for API calls.
export default class ApiResource extends Resource {
  static url(urlParams: any) {
    return resourceUrl(this.urlRoot, ':id', urlParams);
  }

  static listUrl(searchParams: any) {

    //avoids params repetition in request

    const url = resourceUrl(this.urlRoot, '', searchParams);
    
    if (!searchParams) {
      return url;

    } else {
      let search = '';
      const searchParamsCopy = {...searchParams}
      const regex = /:(\w+)/g

      for (const match of this.urlRoot.matchAll(regex)) {
        delete searchParamsCopy[match[1]]
      } 
      
      search =  new URLSearchParams(searchParamsCopy);
      return url + '?' + search
  
    }
  }

    // Override Resource.fetchResponse to properly handle FormData.
  static fetchResponse(input, init) {
    let options = init;
    if (!options.body || typeof options.body === 'string') {
      options = {
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...options.headers,
        },
      };
    }

    if(init.body) {
      if(init.body instanceof FormData) {

        options.body  = init.body;
        if (options.headers){
          delete options?.headers['Content-Type'];
        }
      } else {
        // options.body  = JSON.stringify(init.body);
      }
    }

    return fetch(input, options)
      .then(response => {
        if (!response.ok) {
          throw new NetworkError(response);
        }
        return response;
      })
      .catch(error => {
        // ensure CORS, network down, and parse errors are still caught by NetworkErrorBoundary
        if (error instanceof TypeError) {
          error.status = 400;
        }
        throw error;
      });
  }
}
