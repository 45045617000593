import React from "react";
import PropTypes from "prop-types";

import { Checkbox, FormControlLabel } from "@mui/material";

const Modules = ({ activeOrg, onChange }) => {
  const modules = Object.keys(activeOrg).filter((module) =>
    module.startsWith("has")
  );

  return (
    <div>
      {modules.map((moduleName) => (
        <FormControlLabel
          key={moduleName}
          control={
            <Checkbox
              checked={activeOrg[moduleName]}
              onChange={onChange}
              name={moduleName}
              color="primary"
            />
          }
          label={moduleName.slice(3)}
        />
      ))}
    </div>
  );
};

Modules.propTypes = {
  activeOrg: PropTypes.object,
  onChange: PropTypes.func,
};

export default Modules;
