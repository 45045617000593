import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import { useError } from 'utils/useErrorController';

import DialogActions from '@mui/material/DialogActions';

const Actions = ({ onClose, done }) => {

  const {loading} = useError();

  return (
    <DialogActions>
        <Button
          secondary
          minwidth
          onClick={onClose}
          loading={loading}
        >
          Cancel
        </Button>
        <Button
          primary
          minwidth
          onClick={done}
          loading={loading}
        >
          Send
        </Button>
    </DialogActions>
  );
};

Actions.propTypes = {
  onClose: PropTypes.func,
  done: PropTypes.func
};

export default Actions;