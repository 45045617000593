import React from 'react';
import PropTypes from 'prop-types';

import TabPanels from 'components/TabPanels';
import BadgesGrid from './BadgesGrid';

const MembershipsTabPanel = ({labels, passes, onRedirect, onClaim}) => {
    
    return (
        <TabPanels labels={labels} hideMargin={true}>
            {passes.length > 0 &&
                <BadgesGrid key={0} rewards={ passes } onRedirect={ onRedirect } onClaim={ onClaim } />
            }
        </TabPanels>
    )
};

MembershipsTabPanel.propTypes = {
  labels: PropTypes.array,
  passes: PropTypes.array,
  onRedirect: PropTypes.func,
  onClaim: PropTypes.func,
};

export default MembershipsTabPanel;
