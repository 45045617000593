const createFormData = (newsletterSettings, disabled) => {
    const {address, city, country, post_code, reply_to_email, notifications_enabled, welcome_email, hide_banner} = newsletterSettings;
    const formData = new FormData();
    
    const fields = [
      { key: 'welcome_email', value: JSON.stringify(welcome_email) },
      { key: 'notifications_enabled', value: notifications_enabled },
      { key: 'hide_banner', value: hide_banner },
    ]
   
    if (!disabled){
      fields.push(
        { key: 'address', value: address },
        { key: 'city', value: city },
        { key: 'country', value: country },
        { key: 'post_code', value: post_code },
        { key: 'reply_to_email', value: reply_to_email },
      )
    }

    fields.forEach(field => {
        formData.append(field.key, field.value);
    });

    return formData;
  };
  
  export default createFormData;