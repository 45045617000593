import React from 'react';
import PropTypes from 'prop-types';

import Paywall from './Paywall';
import PostTitle from './PostTitle';
import Body from './Body';
import Tags from './Tags';
import Footer from './Footer';

import MediaGallery from 'ui/MediaGallery';

import './styles.css';

const PostCard = ({ post, page, setPosts, posts }) => {

    const { id, body, image_urls, video_urls, tier_titles, created_at, reactions, comments, title, external_url, tag_ids, available_to_free, banner_image_url } = post;
    const locked = !!tier_titles;

  return (
    <div className="ui-post-card">

      {banner_image_url && <img className={'image banner'} src={banner_image_url} />}

      <PostTitle title={title} />

      <Body body={body}/>

      { locked ?
          <Paywall tier_titles={tier_titles} available_to_free={available_to_free}/>
          :
          <MediaGallery image_urls={image_urls} video_urls={video_urls} view={'post'} />
      }

      <Tags tag_ids={tag_ids} created_at={created_at} setPosts={setPosts} />

      <Footer postId={id} reactions={reactions} comments={comments} external_url={external_url} page={page} setPosts={setPosts} posts={posts}/>

    </div>
  );
};

PostCard.propTypes = {
    post: PropTypes.object,
    page: PropTypes.number,
    setPosts: PropTypes.func,
    posts: PropTypes.array
};  

export default PostCard;