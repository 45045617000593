import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';

import RewardInstanceDownloadResource from 'resources/organization/RewardInstanceDownloadResource';
import RewardInstanceResource from 'resources/organization/RewardInstanceResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import Stats from './Stats';

import SearchableFilter from 'ui/SearchableFilter';

import { downloadCSV } from 'utils/downloadFile';
import { getRewardInstancesColumns } from 'utils/tables/columns/getRewardInstancesColumns';
import usePaginationAndFilteringController from "utils/usePaginationAndFilteringController";
import { useError } from 'utils/useErrorController';

const RewardInstancesTabContent = ({organizationId}) => {
  const { fetch } = useController();
  const { handleError } = useError();

  const unpaginatedRewardInstances = useSuspense(RewardInstanceResource.list(), {organization_id: organizationId});

  const {
    objectState,
    fetchData,
    handleFilterChange,
    handleQueryChange,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: RewardInstanceResource,
      fetchDataParams: { organization_id: organizationId },
  })

  useEffect(() => {
    fetchData();
  }, []);

  const handleDownload = async () => {
    try {
        const response = await fetch(RewardInstanceDownloadResource.list(), {
          organization_id: organizationId,
          ...objectState.filteringParams,
         });
        downloadCSV(response, 'claimed_nfts.csv', 'text/csv');
    } catch (error) {
        handleError(error.message);
    }
  };

  const allowMinting = objectState.filteredObjects.length > 0 && objectState.filteredObjects[0].allow_minting;

  return (
    <>
        <Stats rewardInstances={ unpaginatedRewardInstances } />
        <div style={{ flexGrow: 1 }}>
        <DataSelectionTable records={objectState.filteredObjects}
                            columns={getRewardInstancesColumns(allowMinting)}
                            onQueryChange={handleQueryChange}
                            queryString={objectState.queryString}
                            searchLabel="Search by Name, Email or Wallet Address"

                            onClick={handleDownload}
                            serverPaginationProps={{
                              paginationServer: true,
                              paginationTotalRows: objectState.totalCount,
                              page: objectState.page,
                              onChangePage: handlePageChange
                            }}
                            filter={
                              <SearchableFilter
                                label="pass"
                                onChange={handleFilterChange}
                              />
                            }
          />
        </div>
    </>
)};

RewardInstancesTabContent.propTypes = {
  organizationId: PropTypes.number
};

export default RewardInstancesTabContent;
