import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { t } from 'i18n/index';

const ConfirmModal = ({ icon, title, children, open, setOpen, onConfirm, mysteryBox, handleClose, loading }) => {
  
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
        if (handleClose) {
          handleClose();
        }
      }}
      fullWidth 
      maxWidth='md'
    >  
      {icon ?
        <div className='confirm-modal-header'>
          {icon}
          <DialogTitle id="confirm-dialog">{title}</DialogTitle>
        </div>
        : 
        <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      }
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button
          loading={loading}
          secondary
          minwidth
          onClick={() => {
            setOpen(false);
            if (handleClose) {
              handleClose();
            }
          }}
        >
          {mysteryBox ? "No, continue editing" : t('literal:no')}
        </Button>
        <Button
          loading={loading}
          primary
          minwidth
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          {mysteryBox ? "Yes, publish now" : t('literal:yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
  mysteryBox: PropTypes.object,
  handleClose: PropTypes.func,
  loading: PropTypes.bool
};

export default ConfirmModal;