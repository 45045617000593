import React from 'react';

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'

import Subscriptions from './Subscriptions';

const pageInfo = {
  name: 'Community',
  description: 'Track and manage your members information and details',
};

const Community = () => {

  return (
    <Layout context='teacher'
            activeMenuItem='community'
            pageInfo={pageInfo} >

        <TabPanels labels={[ "Subscriptions" ]} >
          <Subscriptions />
        </TabPanels>

    </Layout>
)};

export default Community;
