import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import logo from 'assets/images/logo-membrzclub.png'
import SwipeableDrawer from './SwipeableDrawer';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

const NavBar = ({ handleLogout, activeMenuItem, context, organizationsList  }) => {

  return (
  <Box sx={{ flexGrow: 1 }}>
    <AppBar position="fixed" color='inherit'>
      <Toolbar className='navbar'>

        <Link to="/profile">
          <img src={logo} className='navbar-image' alt="Membrz.Club"/>
        </Link>

        <SwipeableDrawer handleLogout={handleLogout} activeMenuItem={activeMenuItem} context={context} organizationsList={organizationsList}></SwipeableDrawer>

      </Toolbar>
    </AppBar>
  </Box>
)};

NavBar.propTypes = {
    handleLogout: PropTypes.func,
    activeMenuItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    context: PropTypes.string,
    organizationsList: PropTypes.array,
    coursesList: PropTypes.array
};

export default NavBar;
