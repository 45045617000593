import React from 'react';

import Label from 'ui/text/Label';

import { termsOfUseLink, privacyPolicyLink } from "utils/urls";

import Link from '@mui/material/Link';


import { t } from "i18n/index";

const Footer = () => {
  return (

    
    <Label className="footer" secondary>{t("profile:freeSubscriptionModal:agreement")}&nbsp;
      <Link href={termsOfUseLink} target="_blank">
        {t("literal:termsAndConditions")}
      </Link>
      &nbsp;{t("literal:and_sc")}&nbsp;
      <Link href={privacyPolicyLink} target="_blank">
        {t("literal:privacyPolicy")}
      </Link>
    </Label>
  );
};

export default Footer;