import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Alert, Button as LinkButton } from '@mui/material';
import Button from "ui/Button";

const ActionButtons = ({ post, organizationId, loading, onSubmit, onPublish, onSendNewsletter, onSendTestNewsletter }) => {

  const { status, newsletter_sent, disable_newsletter, id } = post;
  const saveButtonLabel = status === 'draft' ? 'Save as Draft' : 'Save';
  const sendButtonLabel = status === 'draft' ? 'Publish & Send Newsletter' : 'Send Newsletter';

  return (
    <>
      {disable_newsletter &&
        <Alert severity="warning"
            sx={{marginTop: "20px"}}
            action={
              <LinkButton size="small" component={Link} to={`/organizations/${organizationId}/settings/email`} target="_blank">
                Complete
              </LinkButton>
            }>
            To send your Post as Newsletter you must first finish your Newsletter setup.
        </Alert>
      }

      <div className="new-form-field-footer wrap">
          <Button secondary loading={loading} onClick={onSubmit}>{saveButtonLabel}</Button>
          <Button loading={loading} secondary onClick={onSendTestNewsletter} disabled={!id}>Send Test Newsletter</Button>
          { status === 'draft' &&
            <Button loading={loading} onClick={onPublish}>Publish</Button>
          }
          { !newsletter_sent &&
            <Button loading={loading} disabled={disable_newsletter} onClick={onSendNewsletter}>{sendButtonLabel}</Button>
          }
      </div>
    </>
  );
};

ActionButtons.propTypes = {
  post: PropTypes.object,
  organizationId: PropTypes.number,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onPublish: PropTypes.func,
  onSendNewsletter: PropTypes.func,
  onSendTestNewsletter: PropTypes.func
};

export default ActionButtons;
