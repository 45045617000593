import React, { Suspense, useState} from 'react';
import { NetworkErrorBoundary } from '@rest-hooks/react';

import Routes from 'routes';

import CustomSnackbar from 'components/CustomSnackbar';
import CrowdclassFallback from 'components/CrowdclassFallback';
import NetworkError from 'components/NetworkError';

import Plausible from 'plausible-tracker'
import { wagmiChains, wagmiClient } from 'utils/wagmiClient';
import { ToastContext } from 'utils/context/ToastContext';
import { PermissionsContext } from 'utils/context/PermissionsContext';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiConfig } from 'wagmi';
import { PreferencesContext } from 'utils/context/PreferencesContext';
import { ErrorProvider } from 'utils/useErrorController';

const theme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif'
  }
});

const { enableAutoPageviews } = Plausible({
  domain: 'membrz.club',
  // trackLocalhost: true,
})

function App() {
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("warning");
  const [message, setMessage] = useState("Membrz Club");
  const [permissions, setPermissions] = useState([]);
  const [preferences, setPreferences] = useState([]);

  enableAutoPageviews();

  return (
    <Suspense fallback={ <CrowdclassFallback /> }>
      <NetworkErrorBoundary fallbackComponent={NetworkError}>
          <ThemeProvider theme={theme}>
            <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={wagmiChains}>

            <ToastContext.Provider value={{ open: open, setOpen: setOpen, message: message, setMessage: setMessage, severity: severity, setSeverity: setSeverity }}>
                <CustomSnackbar />
                <PermissionsContext.Provider value={{ permissions: permissions, setPermissions: setPermissions }}>
                  <PreferencesContext.Provider value={{ preferences: preferences, setPreferences: setPreferences }}>
                    <ErrorProvider>
                      <Routes />
                    </ErrorProvider>
                  </PreferencesContext.Provider>
                </PermissionsContext.Provider>
            </ToastContext.Provider>

            </RainbowKitProvider>
            </WagmiConfig>
          </ThemeProvider>
      </NetworkErrorBoundary>
    </Suspense>
  );
}

export default App;
