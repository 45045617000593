import React from 'react'
import PropTypes from 'prop-types'

const Spotify = ({content}) => {
    return (
        <div className='spotify-container'>
            <iframe src={`https://open.spotify.com/embed/${content}?utm_source=generator`} width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
        </div> 
    );
};

Spotify.propTypes = {
    content: PropTypes.string
};

export default Spotify;