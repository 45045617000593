import React from "react";
import PropTypes from "prop-types";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

const DateSelector = ({ tiers, subscription, onSubscriptionChange }) => {

  const handleDateTimeChange = (value) => {
    onSubscriptionChange({ ...subscription, end_date: value });
  };

  const selectedTier = tiers.find((tier) => tier.id === subscription.tier);

  return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label={`Subscription End Date${selectedTier && selectedTier.sellable ? "*" : ""}`}
          inputFormat="DD/MMM/YYYY"
          value={subscription.end_date}
          name="end_date"
          onChange={handleDateTimeChange}
          disabled={selectedTier && !selectedTier.sellable}
          renderInput={(params) => (
            <TextField {...params} color="warning" fullWidth size="small"/>
          )}
        />
      </LocalizationProvider>
  );
};

DateSelector.propTypes = {
  tiers: PropTypes.array,
  subscription: PropTypes.object,
  onSubscriptionChange: PropTypes.func,
};

export default DateSelector;
