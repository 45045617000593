import React from 'react';
import PropTypes from 'prop-types';

import { markdownCustomHTML } from 'utils/markdownCustomHTML';

import { Viewer } from '@toast-ui/react-editor';

import 'assets/css/toast-ui-styles.css';

const Body = ({ body }) => {

  if (!body) return null;

  return (
    <div className='larger-viewer'>
      <Viewer
        initialValue={body}
        extendedAutolinks={true}
        linkAttributes={{
          target: '_blank',
          rel: 'noopener noreferrer',
        }}
        customHTMLRenderer={markdownCustomHTML}
      />
    </div>
  );
};

Body.propTypes = {
    body: PropTypes.string,
    created_at: PropTypes.string,
    tag_ids: PropTypes.array,
    setPosts: PropTypes.func,
};

export default Body;
