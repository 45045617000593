import React from 'react';
import PropTypes from 'prop-types';

import { FormControlLabel, FormGroup, Stack, Switch, Typography } from '@mui/material'

const Status = ({ status, onChange }) => {

  return (
    <FormGroup sx={{ m: 1 }} >
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography>Archived</Typography>
            <FormControlLabel 
                control={
                    <Switch
                        color="warning"
                        checked={status === "active"}
                        onChange={onChange}/>} 
                    />
            <Typography>Active</Typography>
        </Stack>
    </FormGroup>
  );
};

Status.propTypes = {
  status: PropTypes.string,
  onChange: PropTypes.func
};

export default Status;