import React from 'react'
import PropTypes from 'prop-types'

import AddressForm from 'components/AddressForm';

import ReplyTo from './ReplyTo';

const Settings = ({ onChange, newsletterSettings, onNewsletterSettingsChange, disabled }) => {
  return (
    <>
        <ReplyTo
            reply_to_email={newsletterSettings?.reply_to_email}
            onChange={onChange}
            disabled={disabled}
        />
        
        <AddressForm
            onChange={onChange}
            data={newsletterSettings}
            onDataChange={onNewsletterSettingsChange}
            disabled={disabled}
        />
    </>
  )
}

Settings.propTypes = {
  newsletterSettings: PropTypes.object,
  onChange: PropTypes.func,
  onNewsletterSettingsChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default Settings;