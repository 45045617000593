import { React } from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import Layout from 'components/layouts/index';

import OrganizationResource from 'resources/organization/OrganizationResource';
import Content from './Content';

const Memberships = () => {
  const params    = useParams();
  const organizationId = parseInt(params.organizationId);

  const organization = useSuspense(OrganizationResource.detail(), {id: organizationId});

  return (
    <Layout context='teacher'
            activeMenuItem='memberships' >
            
      {organization &&
        <Content organization={ organization }/>
      }

    </Layout>
)};

export default Memberships;
