import React from 'react';

import Layout from 'components/layouts/index'
import PostsList from './PostsList';
import TabPanels from 'components/TabPanels';

const pageInfo = {
  name: 'Newsletter',
  description: 'Create and organize personalized content to be displayed on the Content section of your club',
};

const Posts = () => {

  return (
    <Layout context='teacher'
            activeMenuItem='content'
            pageInfo={pageInfo}>
      <TabPanels labels={["Exclusive Posts"]}>      
        <PostsList />
      </TabPanels>
    </Layout>
  )
};

export default Posts;
