import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Subtitle from "ui/text/Subtitle";

import Calendar from './Calendar';
import Location from './Location';

import useSliderController from 'utils/useSliderController';
import TicketList from './TicketList';
import Sessions from './Sessions';

const Header = ({image_url, title, start_date, end_date, location, tickets, onAction, showApplicationFee, single, sessions}) => {

  const showSessions = !single && sessions && sessions.length > 0;

  const [selectedSession, setSelectedSession] = useState(showSessions ? sessions[0] : null );

  const { handleImageClick, SliderComponent} = useSliderController();

  const shownTickets = selectedSession ? selectedSession.tickets : tickets;

  return (
    <div className="event-header">

      <div className='sub-header'>
        
        <img src={image_url} alt={title} onClick={() => handleImageClick([image_url])} />
      
        <div>
          <Subtitle>{title}</Subtitle>
        
          {single && <Calendar start_date={start_date} end_date={end_date} />}

          {location && <Location location={location} />}
        </div>
      </div>

      {showSessions && <Sessions sessions={sessions} selectedSession={selectedSession} onChange={setSelectedSession}/>}

       <TicketList tickets={shownTickets} showApplicationFee={showApplicationFee} onAction={onAction}/>

      <SliderComponent />
    </div>
  );
};

Header.propTypes = {
    image_url: PropTypes.string,
    title: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    location: PropTypes.string,
    tickets: PropTypes.array,
    onAction: PropTypes.func,
    showApplicationFee: PropTypes.bool,
    single: PropTypes.bool,
    sessions: PropTypes.array
};

export default Header;