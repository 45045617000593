import React, { createContext, useContext, useState } from "react";
import FreeSubscriptionModal from "components/FreeSubscriptionModal";

const FreeSubscriptionContext = createContext(null);

export const useFreeSubscriptionController = () => {
  const [open, setOpen] = useState(false);
  const [customDescription, setCustomDescription] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (customDescription='') => {
    setOpen(true);
    setCustomDescription(customDescription);
  };

  const FreeSubscriptionModalComponent = () => {
    return (
      <FreeSubscriptionModal
        open={open}
        onClose={handleClose}
        customDescription={customDescription}
      />
    );
  };

  return {
    openFreeSubscriptionModal: handleOpen,
    FreeSubscriptionModalComponent,
  };
};

// eslint-disable-next-line react/prop-types
export const FreeSubscriptionProvider = ({ children }) => {
  const controller = useFreeSubscriptionController();

  return (
    <FreeSubscriptionContext.Provider value={controller}>
      {children}
      {controller.FreeSubscriptionModalComponent()}
    </FreeSubscriptionContext.Provider>
  );
};

export const useFreeSubscription = () => {
  return useContext(FreeSubscriptionContext);
};
