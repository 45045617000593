import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class EventResource extends AuthenticatedResource {
  static urlRoot = '/checkin/events' as const;

  readonly id: number | undefined = undefined;
  
  pk() {
    return this.id?.toString();
  }

}
