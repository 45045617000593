import React from 'react';
import PropTypes from 'prop-types';

import { isSocialElement, isEmbeddedElement, pageElementTypes } from 'utils/pageElementsHelper';

const TypeSelector = ({ element_type, onChange }) => {

  const onClick = (e, value) => {
      e.preventDefault();
      onChange({target: {name: "element_type", value}});
    };

  const selected = (type) => element_type === type || (isSocialElement(element_type) && type === "social") || isEmbeddedElement(element_type) && type === "embed"; 

  return (
    <div className="element-type-button-group">
      {pageElementTypes.map((type, index) => (
            <button className={`element-type-button ${selected(type.value) ? "selected" : ""}`} key={index} value={type.value} onClick={(e) => onClick(e, type.value)}>
              <type.icon color="warning" fontSize="large"/>
              <p >{type.title}</p>
            </button>
          ))}
    </div>
     
  );
};

TypeSelector.propTypes = {
  element_type: PropTypes.string,
  onChange: PropTypes.func
};

export default TypeSelector;