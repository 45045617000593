import React from 'react';
import PropTypes from 'prop-types';

import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Title = ({onClose}) => {
  return (
    <DialogTitle>
        Add new Group
        <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
            }}
            >
            <CloseIcon />
        </IconButton>
      
    </DialogTitle>
  );
};

Title.propTypes = {
    onClose: PropTypes.func
};

export default Title;