import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'components/StatCard';

import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import {countBy} from 'lodash';

const defaultSettings = {
  datasets: [{
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(54, 162, 235)',
      'rgb(255, 205, 86)'
    ],
    hoverOffset: 4
  }]
};

const Stats = ({ rewardInstances }) => {

  const stat1 = rewardInstances.length;

  const stats = countBy(rewardInstances, (reward) => reward.title);
  const chart2 = {
    ...defaultSettings
  };
  chart2.labels = Object.keys(stats).map(label => 
    label.length > 10 ? label.slice(0, 10) + '...' : label
  );
  chart2.datasets[0].data = Object.values(stats);

  const stat2 = chart2;

  ChartJS.register(ArcElement, Tooltip);

  return (
    <div className="stats-grid grid custom-margin">
      <StatCard type='number'
                color='rgb(255, 99, 132)'
                highlight={ stat1 }
                title='Active Memberships'
                />

      <StatCard type='chart'
                highlight={<Doughnut data={ stat2 } />}
                title='Memberships by Tier'
                />
    </div>
)};

Stats.propTypes = {
  rewardInstances: PropTypes.object,
};

export default Stats;
