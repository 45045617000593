const storage = localStorage;
// const key     = 'cc.auth_token';
const key     = 'cc.auth_token';

// -----------------------------------------------------
// Util Definition
// -----------------------------------------------------

const AuthToken = {
  read:   () => storage.getItem(key),
  write:  (authToken) => storage.setItem(key, authToken),
  remove: () => storage.removeItem(key)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default AuthToken;