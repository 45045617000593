import React from 'react';
import PropTypes from 'prop-types';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

const HideDetails = (hide_details, onChange) => {
    
  return (
        <Box>
            <FormControlLabel
                label={`Hide Details in Claim Page`}
                control={<Checkbox
                  name="hide_details"
                  checked={hide_details}
                  onChange={onChange}
                  size='small' />} />
        </Box>
  );
};

HideDetails.propTypes = {
  hide_details: PropTypes.bool,
  onChange: PropTypes.func
};

export default HideDetails;