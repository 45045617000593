import React from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";

import { DialogActions } from "@mui/material";

const Actions = ({ error, onClose, onConfirm }) => {
  return (
    <DialogActions>
      <Button 
        minwidth 
        secondary 
        onClick={onClose} 
        sx={{ m: 0.8, mt: 2 }}>
            Back
      </Button>
      <Button
        minwidth
        disabled={error}
        onClick={() => onConfirm()}
        sx={{ m: 0.8, mt: 2 }}>
            Next
      </Button>
    </DialogActions>
  );
};

Actions.propTypes = {
  error: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default Actions;
