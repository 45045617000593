import React from 'react';
import { courseType } from "utils/constants";

import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import EuroIcon from '@mui/icons-material/Euro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

export const frequencies = ["monthly", "quarterly", "yearly"];
export const priceRegex = /^(?=.*[1-9])\d{0,6}(\.\d{0,2})?$/
export const priceErrors = frequencies.reduce( (acc, frequency) => ({...acc, [frequency]: false}), {});

export const currencies = {
  "EUR": { value: 'EUR', label: 'EUR €', icon: <EuroIcon />, symbol: '€' },
  "USD": { value: 'USD', label: 'USD $', icon: <AttachMoneyIcon />, symbol: '$' },
  "GBP": { value: 'GBP', label: 'GBP £', icon: <CurrencyPoundIcon />, symbol: '£' },
  "BRL": { value: 'BRL', label: 'BRL R$', icon: <span className='alternative-currency-icon'>R<AttachMoneyIcon /></span>, symbol: 'R$' },
};

export const payableTypes = [courseType.SELLABLECOLLECTIBLES.value, courseType.MEMBERSHIP.value];

export const labels = {
  "monthly": "Monthly price",
  "quarterly": "Quarterly price",
  "yearly": "Yearly price",
  // "collectible": "One-off price",
  // "lifetime": "One-off / Lifetime price",
  "lifetime": "Price",
  "remove": "Price. Leave empty to disable price",
  "format": "Please use this format: 25.50",
};