import { t } from 'i18n/index';
import { validateEmail } from 'utils/validations/validateEmail';

export const handleValidation = (invoiceData) => {
    const {name, email, country, post_code, vat} = invoiceData;

    validateName(name);
    validateEmail(email, null, t('errors:emailMissing'));
    if (vat) {
        validateCountry(country);
    }
    if (country === "US" || country === "CA") {
        validatePostCode(post_code);
    }
};

const validateName = (name) => {
    if(name.trim() === undefined || name.trim() === "") {
        throw t('errors:nameMissing');
    }
};

const validateCountry = (country) => {
    if (!country){
        throw t('errors:countryMissing');
   }
};

const validatePostCode = (postCode) => {
    if (!postCode || postCode.trim() === undefined || postCode.trim() === ""){
        throw t('errors:postalCodeMissing');
   }
};