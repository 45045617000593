import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { useSuspense } from '@rest-hooks/react';

import ProfileResource from 'resources/Profile/ProfileResource';

import Body from './Body';

import NavBar from 'ui/Navbar';

import CrowdclassFallback from 'components/CrowdclassFallback';

import useThemeController from 'utils/useThemeController';
import { usePreferences } from 'utils/context/PreferencesContext';
import isMobileDevice from 'utils/mobileAndTabletCheck';
import { SocialMetatagsProvider } from 'utils/socialMetatagsController';

import './styles.css';

const Profile = () => {
    const [loading, setLoading] = useState(true);
    const {organizationSlug} = useParams();
    const organization = useSuspense(ProfileResource.detail(), {id: organizationSlug})

    useThemeController(organizationSlug, setLoading);

    const isMobile = isMobileDevice();

    const { setPreferences} = usePreferences();

    useEffect(() => {
          setPreferences(organization.preferences);
    }, []);

   return (
    <div className='profile'>
      {loading ?
        <CrowdclassFallback />
        : 
        <>
          {isMobile && <NavBar organization={ organization } />}
          <SocialMetatagsProvider>
            <Body organization={ organization } />
          </SocialMetatagsProvider>
        </>
      }
    </div>
  );
};

export default Profile;
