import * as React from 'react';
import PropTypes from 'prop-types';

import CircularProgressWithLabel from './CircularProgressWithLabel';

import { t } from 'i18n/index';

const MobileStepper = ({ activeStep, steps }) => {

    const progress = ( (activeStep) / steps.length) * 100;
    const activeLabel = steps[activeStep];
    const nextLabel = steps[activeStep + 1];

    return (
        <div className='mobile-manual-subscriptions-stepper'>
            <div className='progress'>
                <CircularProgressWithLabel value={progress} 
                                   activeStep={activeStep}
                                   totalSteps={steps.length}
                                   />
            </div>
            <div className='label'>
                <h4>{activeLabel}</h4>
                { activeStep != steps.length - 1 && <p>{t('literal:next')}: {nextLabel}</p> }
            </div>
        </div>

    );
}

MobileStepper.propTypes = {
    activeStep: PropTypes.number,
    steps: PropTypes.array,
};

export default MobileStepper;