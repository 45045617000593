import React from 'react';
import PropTypes from 'prop-types';

import AttachedImage from './AttachedImage';
import ProductGallery from './ProductGallery';
import PostGallery from './PostGallery';

import useSliderController from 'utils/useSliderController';

const Images = ({ image_urls, view }) => {

    if (!image_urls || image_urls.length === 0) return null;

    const { handleImageClick, SliderComponent} = useSliderController();

    const Gallery = view === 'product' ? ProductGallery : PostGallery;

    const renderImages = () => {
        if (image_urls.length === 1) {
            return <AttachedImage imageUrl={image_urls[0]} onClick={() => handleImageClick(image_urls)} />
        } else {
            return <Gallery image_urls={image_urls} onImageClick={handleImageClick} />
        }
    };

  return (
        <>
            {renderImages()}
            <SliderComponent />
        </>
  );
};

Images.propTypes = {
    image_urls: PropTypes.array,
    view: PropTypes.string

};

export default Images;