import React, { createContext, useContext, useState } from "react";
import SocialMetaTags from "components/SocialMetatags";

const SocialMetatagsContext = createContext(null);

const defaultState = {
    title: "",
    description: "",
    image_url: "",
    external_url: "",
};

export const useSocialMetatagsController = () => {
  const [state, setState] = useState(defaultState);
  const [open, setOpen] = useState(false);

  const setSocialMetatags = (title, description, image_url, external_url) => {
    setOpen(true);
    setState({ title, description, image_url, external_url });
  };

  const SocialMetatagsComponent = () => {
    if (!open) return null;
    
    const { title, description, image_url, external_url } = state;
    
    return (
      <SocialMetaTags
        title={title}
        description={description}
        image_url={image_url}
        external_url={external_url}
      />
    );
  };

  return {
    setSocialMetatags,
    SocialMetatagsComponent,
  };
};

// eslint-disable-next-line react/prop-types
export const SocialMetatagsProvider = ({ children }) => {
  const controller = useSocialMetatagsController();

  return (
    <SocialMetatagsContext.Provider value={controller}>
      {children}
      {controller.SocialMetatagsComponent()}
    </SocialMetatagsContext.Provider>
  );
};

export const useSocialMetatags = () => {
  return useContext(SocialMetatagsContext);
};
