import React from "react";
import PropTypes from "prop-types";

import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const fullLabels = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const SessionTimesEditor = ({ event, onEventChange, setUnsavedChanges }) => {

    const { repeat_days } = event;

    const handleSessionStartTimeChange = (newValue, dayNumber) => {  
        setUnsavedChanges(true);

        const newRepeatDays = [...event.repeat_days];
        const indexToUpdate = newRepeatDays.findIndex(day => Object.keys(day)[0] === dayNumber.toString());
    
        if (indexToUpdate !== -1) {
            newRepeatDays[indexToUpdate] = { [dayNumber]: newValue };
            
            const newEvent = { ...event, repeat_days: newRepeatDays };
            onEventChange(newEvent);
        }
    };

    return (
    <>
      {repeat_days.map((day, index) => {
        const dayNumber = parseInt(Object.keys(day));
        const dayLabel = `${fullLabels[dayNumber]} session`;
        return (
          <LocalizationProvider
            key={`day-${index}`}
            dateAdapter={AdapterMoment}
          >
            <TimePicker
              label={dayLabel}
              value={day[dayNumber] || null}
              onChange={(newValue) => handleSessionStartTimeChange(newValue, dayNumber)}
              renderInput={(params) => (
                <TextField {...params} color="warning" fullWidth size="small" />
              )}
            />
          </LocalizationProvider>
        );
      })}
    </>
  );
};

SessionTimesEditor.propTypes = {
  event: PropTypes.object,
  onEventChange: PropTypes.func,
  setUnsavedChanges: PropTypes.func,
};

export default SessionTimesEditor;
