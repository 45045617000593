import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';

import formattedDate from 'utils/formattedDate';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';

import { t } from 'i18n/index';

const Date = ({start_date, end_date, sessionsCount}) => {

    const fullStartDate = formattedDate(start_date, 'datetime');
    const fullEndDate = formattedDate(end_date, 'datetime');

    const cta = sessionsCount > 1 ? t('profile:eventCardSessions', {sessionsCount}) : t('profile:eventCardSingleSession', {sessionsCount});

    return (
        <div className='dates-container'>
            <div className='date'>
                <EventAvailableIcon color="warning" fontSize='14px'/>
                <Description small>{fullStartDate}</Description>
            </div>

            {end_date  &&
                <div className='date'>
                    <EventBusyIcon color="warning" fontSize='14px'/>
                    <Description small>{fullEndDate}</Description>
                </div>
            }

            {sessionsCount &&
                <div className='date'>
                    <EventRepeatIcon color="warning" fontSize='14px'/>
                    <Description small>{cta}</Description>
                </div>
            }
        </div>
    );
};

Date.propTypes = {
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    sessionsCount: PropTypes.number
};

export default Date;
