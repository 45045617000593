export const sortPasses = (rewards) => {
    return rewards.sort( (a,b) => {
      const activeStateComparison = b.active_state - a.active_state;
  
      if (activeStateComparison !== 0) {
        return activeStateComparison;
      }
  
      const isFreeA = a.pricings.length === 0 || false;
      const isFreeB = b.pricings.length === 0 || false;
  
      if (isFreeA !== isFreeB) {
        return isFreeB - isFreeA;
      } else if (isFreeA && isFreeB) {
        return 0;
      }
      
      const frequencyOrder = { monthly: 1, quarterly: 2, yearly: 3 };
  
      const priceA = a.pricings[0].price;
      const frequencyA = a.pricings[0].frequency;
      const priceB = b.pricings[0].price;
      const frequencyB = b.pricings[0].frequency;
  
      const priceComparison = priceA - priceB;
      const frequencyComparison = frequencyOrder[frequencyA] - frequencyOrder[frequencyB];
  
      return frequencyComparison !== 0 ? frequencyComparison : priceComparison;
  
    });
  };