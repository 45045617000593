import { countriesList } from "utils/coutriesList";
// import { countriesRegionsList } from "utils/countriesRegionsList";

const defaultSettings = {
  datasets: [
    {
      backgroundColor: "#F75201",
    },
  ],
};

const transformLabels = (labelKey, label) => {
  if (labelKey === "country") {
    const country = countriesList.find((country) => country.code === label)
    return country ? country.label : label;
  } else {
    return label;
  }
};

  const prepareChartData = (data, labelKey, dataKey) => {

    const chartData = JSON.parse(JSON.stringify(defaultSettings));
    const labels = data.map((entry) => transformLabels(labelKey, entry[labelKey]));
    const dataValues = data.map((entry) => entry[dataKey]);

    chartData.labels = labels;
    chartData.datasets[0].data = dataValues;

    return chartData;
  };

  export default prepareChartData;
