import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import MarkdownEditor from "components/MarkdownEditor";

import Label from "ui/text/Label";
import Button from "ui/Button";

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';

const ExtendedDescription = ({ object, onObjectChange }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(object.extended_description);
  }, [object.extended_description]);

  return (
    <>
      {show ? (
        <>
          <MarkdownEditor
            object={object}
            setObject={onObjectChange}
            attribute={"extended_description"}
            placeholder={"Detailed Description"}
          />
          <Label secondary padded>This detailed description will be displayed in the product&apos;s page only.</Label>
        </>
      ) : (
        <Button text small icon={AddCircleOutlineOutlinedIcon} onClick={() => setShow(!show)}>Add a longer description to Product Page</Button>
      )}
    </>
  );
};

ExtendedDescription.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
};

export default ExtendedDescription;
