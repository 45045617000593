import React from 'react';

import EventsList from './EventsList';

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels';

const pageInfo = {
  name: 'Events',
  description: 'Create and organize your organization events here',
};

const Events = () => {

  return (
    <Layout context='teacher'
            activeMenuItem='events'
            pageInfo={pageInfo}>
      <TabPanels labels={["Upcoming Events", "Past Events"]}>      
        <EventsList upcoming={true}/>
        <EventsList past={true} />
      </TabPanels>
    </Layout>
  )
};

export default Events;
