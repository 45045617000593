import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import TabPanels from 'components/TabPanels';

import { CircularProgress } from '@mui/material';

const TabContainers = ({ path, containers }) => {

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const tabs = containers.filter(({ label }) => !!label);

  return <>
    <TabPanels items={tabs} unsavedChanges={unsavedChanges} onUnsavedChanges={setUnsavedChanges}/>

    <Suspense fallback={ <CircularProgress className='circular-progress' /> }>
      <Switch>
        {
          containers.map(({ segment, component }) =>
            <Route key={segment}
                   path={path + `/` + segment}
                   render={(props) => React.createElement(component, { ...props, onUnsavedChanges: setUnsavedChanges })}
                   />
          )
        }
      </Switch>
    </Suspense>
  </>;
};

TabContainers.propTypes = {
  path: PropTypes.string,
  containers: PropTypes.array,
  onUnsavedChanges: PropTypes.func
};

export default TabContainers;
