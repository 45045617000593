import customToast from "./customToast";

import { t }from "i18n/index";

export default function copyToClipboard(text, setOpen, setSeverity, setMessage, handleClose) {
    if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(text)
        .then(() => {
            customToast("success", t('utils:copyToClipboard:success'), setOpen, setSeverity, setMessage, handleClose);
        })
        .catch((error) => {
            customToast("warning", t('utils:copyToClipboard:failure', {error}), setOpen, setSeverity, setMessage, handleClose);
        });
      }    
}