export const organizationUsersColumns = [
  {
    field: "organization",
    name: "Organization",
    selector: (row) => `${row.organization_title} #${row.organization_id}`,
    sortable: true,
    width: "30%",
  },
  {
    field: "name",
    name: "Full Name",
    selector: (row) => row.name,
    sortable: true,
    width: "35%",
  },
  {
    field: "email",
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
    width: "35%",
  },
];
