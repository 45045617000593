import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

const VideoPreview = ({ url, poster, playAudio=true, showDetails=true }) => {

  const videoRef = useRef(null);
  const [mute, setMute] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleVideoPlay = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (videoRef.current) {
      isPlaying ? videoRef.current.play() : videoRef.current.pause();
    }
  }, [ videoRef, isPlaying ]);

  return (
    <div className={`container-video ${!showDetails ? 'blur' : ''}`}>
      <video  className={`video`}
              poster={poster}
              ref={videoRef}
              autoPlay
              muted={mute}
              loop
      >
        <source src={url} type="video/mp4"/>
      </video>
      {playAudio &&
        <>
          { mute &&
            <button className={`button-audio`} onClick={() => setMute(false)}>
              <VolumeOffIcon sx={{fontSize: 25, width: 'auto', color: 'black'}}/>
            </button>
          }
        </>
      }
      <button  className={`button-play`} onClick={handleVideoPlay}>
          {!isPlaying && <PlayArrowIcon sx={{fontSize: 50, color: 'white'}}/>}
      </button>
    </div>
  );
}

VideoPreview.propTypes = {
  url: PropTypes.string,
  poster: PropTypes.string,
  playAudio: PropTypes.bool,
  showDetails: PropTypes.bool,
};

export default VideoPreview;
