import React from 'react';
import PropTypes from 'prop-types';

import TicketList from './TicketList';
import CapacityEditor from '../CapacityEditor';

import FormSection from 'ui/FormSection';
import Button from 'ui/Button';
import Label from 'ui/text/Label';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const SingleEventTickets = ({event, handleDeleteRequest, handleDragEnd, handleRedirect, handleUrlCopy, onChange}) => {

    const { tickets, over, id, capacity } = event

  return (
    <FormSection title={"Tickets"}>
        <TicketList
            tickets={tickets}
            disabled={over}
            handleDeleteRequest={handleDeleteRequest}
            handleDragEnd={handleDragEnd}
            handleRedirect={handleRedirect}
            handleUrlCopy={handleUrlCopy} />

        { id ? (
            <>
                <Button
                    onClick={(e) => handleRedirect(e, `/new?eventId=${id}`)} 
                    secondary 
                    fullwidth  
                    icon={AddCircleOutlineIcon}
                    disabled={over} >
                        Add New Ticket
                </Button>
                <CapacityEditor
                    capacity={capacity}
                    onChange={onChange} />
            </>
        ) : (
            <Label secondary padded>
                Tickets creation will be enabled after saving the event.
            </Label>
        )}
    </FormSection>
  )
}

SingleEventTickets.propTypes = {
    event: PropTypes.object,
    handleDeleteRequest: PropTypes.func,
    handleDragEnd: PropTypes.func,
    handleRedirect: PropTypes.func,
    handleUrlCopy: PropTypes.func,
    onChange: PropTypes.func
};

export default SingleEventTickets;