import { rewardType } from "utils/constants";

export const passes = (reward) => {
  return reward.category === rewardType.PASS.value
};

export const activePasses = (reward) => {
  return reward.category === rewardType.PASS.value && reward.active_state === true;
};

export const activeSellablePasses = (reward) => {
  return reward.category === rewardType.PASS.value && reward.active_state === true && reward.sellable === true;
};