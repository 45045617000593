import React from 'react';
import PropTypes from 'prop-types';
import Button from 'ui/Button';

import { t } from 'i18n/index';
import Provider from './Accounts/Provider';

const ConnectButton = ({provider, loading}) => {

    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button
                variant="contained"
                color="warning"
                onClick={() => setOpen(true)}
                loading={loading}
                minwidth
            >
                {t('literal:connect')}
            </Button>

            <Provider open={open} onClose={() => setOpen(false)} provider={provider} />
        </>
    );
};

ConnectButton.propTypes = {
    provider: PropTypes.string,
    loading: PropTypes.bool,
};

export default ConnectButton;
