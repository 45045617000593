import React from 'react';
import PropTypes from 'prop-types';

import { offerCategory } from 'utils/offersFormHelper';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const CategorySelector = ({ category, onChange }) => {

  return (
    <FormControl>
      <InputLabel color="warning">Category</InputLabel>
      <Select
        label="Category"
        name="category"
        color="warning"
        size="small"
        fullWidth
        value={ category }
        onChange={ onChange }
      >
        { Object.values(offerCategory).map((option) => {
          if (option.value === 'ticket') {
            return null
          } else {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          }
        })}
      </Select>
    </FormControl>
  );
};

CategorySelector.propTypes = {
  onChange: PropTypes.func,
  category: PropTypes.string
};

export default CategorySelector;