import React from "react";
import PropTypes from "prop-types";

import { embedTypes } from "utils/pageElementsHelper";

import { FormControl, InputLabel, MenuItem, Select, } from "@mui/material";

const EmbedSelector = ({ element_type, onChange }) => {

  return (
    <FormControl fullWidth >
      <InputLabel color="warning" size="small">Source</InputLabel>
      <Select
        label="Source"
        color="warning"
        name="element_type"
        value={element_type === "embed" ? null : element_type}
        onChange={onChange}
        size="small"
      >

        {embedTypes.map((type, index) => (
          <MenuItem key={index} value={type.value}>
            {type.title}
          </MenuItem>
        ))}

      </Select>

    </FormControl>
  );
};

EmbedSelector.propTypes = {
  element_type: PropTypes.string,
  onChange: PropTypes.func,
};

export default EmbedSelector;
