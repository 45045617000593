import React from "react";
import PropTypes from "prop-types";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import moment from 'moment';

const TierSelector = ({ tiers, subscription, onSubscriptionChange }) => {

  const handleTierChange = (tier_id) => {
    const selectedTier = tiers.find((tier) => tier.id === tier_id);
    if (selectedTier.sellable) {
      onSubscriptionChange({ ...subscription, tier: tier_id, end_date: moment().add(1, 'months'), sellable: true });
    } else {
      onSubscriptionChange({ ...subscription, tier: tier_id, end_date: null, sellable: false });
    }
  };

  return (
    <FormControl fullWidth>
      <InputLabel color="warning" size="small">Subscription Tier</InputLabel>
      <Select
        fullWidth
        name="tier"
        label={"Subscription Tier"}
        color="warning"
        value={subscription.tier || ""}
        size="small"
      >
        {tiers.map((tier) => (
          <MenuItem
            key={tier.id}
            value={tier.id}
            onClick={() => {
              handleTierChange(tier.id);
            }}
          >
            {tier.identifier}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

TierSelector.propTypes = {
  tiers: PropTypes.array,
  subscription: PropTypes.object,
  onSubscriptionChange: PropTypes.func,
};

export default TierSelector;
