import React, { useState } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import PropTypes from 'prop-types';

import UserName from 'utils/localStorage/UserName';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonIcon from '@mui/icons-material/Person';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
// import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import Avatar from '@mui/material/Avatar';

import { t } from 'i18n/index';

const SwitcherMenu = ({ context, coursesList, handleLogout, organizationsList}) => {

    const history = useHistory();
    const params = useParams();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const organizationId = params.organizationId && parseInt(params.organizationId);
    const logoUrl = params.organizationId && organizationsList.find((item) => item.id === organizationId).logo_url

    const userName = UserName.read();

    const handleOrganizationChange = async (id) => {
        history.push(`/organizations/${id}`);
    }

    const handleViewSwitch = () =>  {
        history.push(`/profile`);
    };

    const handleOpen= (event)=> {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={`container-menu-switcher`}>
            <MenuItem
                onClick={handleOpen}
                size="medium"
                aria-controls={open ? 'menu-switcher' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className='switcher-menu-item'
            >
                <Avatar sx={{ width: 32, height: 32 }} src={logoUrl} />
                <p> {userName} </p>
            </MenuItem>
            <Menu
                keepMounted
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    style: {
                    width: 229,
                    },
                }}
            >
                {  coursesList && coursesList.length > 0 &&
                    <div>
                        { organizationsList && organizationsList.map((item, key) => (
                        <MenuItem key={key}
                            fullWidth
                            onClick={() => handleOrganizationChange(item.id)}
                            className='switcher-menu-item'
                            >
                            <Avatar sx={{ width: 32, height: 32, border: '2px solid #e76d3e'}} src={item.logo_url} />
                            { <p className={`organization-name`}> {organizationId === item.id ?
                                <>
                                {item.title}
                                <CheckIcon sx={{ml: 2, color: '#e76d3e'}} />
                                </>
                                :
                                item.title
                                }
                            </p>
                            }
                        </MenuItem>
                            ))
                        }
                        <Divider/>
                        {context === "teacher" &&
                            <MenuItem key={organizationsList && organizationsList.length}
                                    fullWidth
                                    onClick={handleViewSwitch}
                                    className='switcher-menu-item'>
                                        <PersonIcon fontSize='medium' />
                                        USER PROFILE
                            </MenuItem>
                        }
                    </div>
                }
                <MenuItem fullWidth
                        component={ Link }
                        to='/settings/details'
                        className='switcher-menu-item'>
                            <EditIcon fontSize='medium' />
                            {context === "teacher" ? "EDIT PROFILE" : t('literal:editProfile').toUpperCase()}
                </MenuItem>
                <MenuItem fullWidth
                        onClick={handleLogout}
                        className='switcher-menu-item'>
                            <PowerSettingsNewIcon fontSize='medium' color='warning' />
                            {context === "teacher" ? "LOGOUT" : t('literal:logout').toUpperCase()}
                </MenuItem>
            </Menu>
        </div>
    );
};

SwitcherMenu.propTypes = {
    context: PropTypes.string,
    coursesList: PropTypes.array,
    handleLogout: PropTypes.func,
    organizationsList: PropTypes.array,
}

export default SwitcherMenu;
