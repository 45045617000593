import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ClaimResource extends AuthenticatedResource {
  static urlRoot = '/claim'

  readonly id: number | undefined = undefined;
  readonly claim_state: string = '';
  readonly claimed_at: Date = new Date(0);

  pk() {
    return this.id?.toString();
  }
}