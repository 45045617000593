import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

const Title = ({reward, isDisabled, onChange}) => {

  return (
      <TextField
        size='small'
        autoFocus
        fullWidth
        name="title"
        label={`Title *`}
        color="warning"
        value={reward.title}
        onChange={onChange}
        disabled={isDisabled} />
  );
};

Title.propTypes = {
  reward: PropTypes.object,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Title;


