import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index, hideMargin, ...other }) => (
  <div className={hideMargin ? "" : "tabpanel"}
    role="tabpanel"
    hidden={value !== index}
    {...other}
  >
    {value === index &&
        children
    }
  </div>
);
 
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
  hideMargin: PropTypes.bool
};

export default TabPanel;