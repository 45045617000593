import React from "react";
import PropTypes from 'prop-types'

import AttachmentChip from "components/MediaUploader/AttachmentChip";
import UploadButtons from "components/MediaUploader/UploadButtons";

import Label from "ui/text/Label";
import { getAttachmentTypes, handleFileUpload, handleRemoveUpload, hasAttachments } from "utils/attachments";

const MediaUploader = ({
        hiddenFileInputs, 
        multiple=false, 
        mandatory=false, 
        objectType, 
        object, 
        onObjectChange, 
        loading, 
        onLoadingChange, 
        onError, 
        label,
        setUnsavedChanges
    }) => {

    const { image_filename, image_filenames, video_filenames } = object;

    const hasAttachedImages = hasAttachments(object, "image", multiple);

    const video = hiddenFileInputs.video;
    const hasAttachedVideos = video && hasAttachments(object, "video", multiple);

  return (
    <>
        <UploadButtons 
            attachmentTypes={getAttachmentTypes(multiple ? image_filenames : image_filename, video ? video_filenames : null)} 
            handleFileUpload={(e) => handleFileUpload(e, object, onObjectChange, objectType, onLoadingChange, onError, multiple, setUnsavedChanges)} 
            loading={loading}
            multiple={multiple}
            mandatory={mandatory}
            hiddenFileInputs={hiddenFileInputs} 
            hasAttachedImages={hasAttachedImages}
            hasAttachedVideos={hasAttachedVideos}
        />

        {label &&
            <Label padded secondary>
                {label}
            </Label>}

        {hasAttachedImages && (
            <AttachmentChip
                filenames={multiple ? image_filenames : [image_filename]}
                type={"image"}
                object={object}
                onObjectChange={onObjectChange}
                onDelete={handleRemoveUpload}
                hiddenFileInputs={hiddenFileInputs} 
                multiple={multiple}
                setUnsavedChanges={setUnsavedChanges}
            />
        )}

        {hasAttachedVideos && (
            <AttachmentChip
                filenames={video_filenames}
                type={"video"}
                object={object}
                onObjectChange={onObjectChange}
                onDelete={handleRemoveUpload}
                hiddenFileInputs={hiddenFileInputs} 
                multiple={multiple}
                setUnsavedChanges={setUnsavedChanges}
            />
        )}
      </>
  )
}

MediaUploader.propTypes = {
    hiddenFileInputs: PropTypes.object,
    multiple: PropTypes.bool,
    mandatory: PropTypes.bool,
    objectType: PropTypes.string,
    object: PropTypes.object,
    onObjectChange: PropTypes.func,
    loading: PropTypes.bool,
    onLoadingChange: PropTypes.func,
    onError: PropTypes.func,
    label: PropTypes.string,
    setUnsavedChanges: PropTypes.func
};

export default MediaUploader;