import React from "react"
import PropTypes from "prop-types"

import { TextField } from "@mui/material";

const Description = ({description, onChange, isTicket}) => {

  const label = `Short Description ${isTicket ? "" : "*"}`;

  return (
      <TextField
        fullWidth
        multiline
        color="warning"
        name="description"
        size="small"
        rows={3}
        value={description}
        onChange={onChange}
        label={label}
        inputProps={{ maxLength: 300 }} />
  );
};

Description.propTypes = {
    description: PropTypes.string,
    onChange: PropTypes.func,
    isTicket: PropTypes.bool
};

export default Description;