const validateTitle = (title) => {
    if (title === undefined || title === null || title.trim() === "") {
      throw 'Please provide a title.';
    }
  };
  
  const validateSlug = (slug) => {
    if (slug === undefined || slug === null || slug.trim() === "") {
      throw 'Please provide a username.';
    }
  };
  
  const handleValidation = (organization) => {
    const {title, slug} = organization;
    
    validateTitle(title);
    validateSlug(slug);
  };
  
  export default handleValidation;