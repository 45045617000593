import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { mapKeys } from "lodash";
import * as XLSX from "xlsx/xlsx.mjs";

import UploadFileIcon from "@mui/icons-material/UploadFile";

import Button from "ui/Button";
import CsvModal from "components/CsvModal";

const emptyFile = { file: null, filename: "" };

const Import = ({subscription, onSubscriptionChange}) => {

  const [file, setFile] = useState({ ...emptyFile });
  const [openCSVModal, setOpenCSVModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [fullNameTable, setFullNameTable] = useState(true);

  const hiddenFileInput = useRef(null);

  const handleFileUpload = async (event) => {
    if (!event.target.files) {
      return;
    }

    const file = event.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const newFile = {
      file: file,
      filename: file.name,
    };
    setFile(newFile);

    const json = XLSX.utils
      .sheet_to_json(workbook.Sheets["Sheet1"] || workbook.Sheets.import_template)
      .map((row) => mapKeys(row, (value, key) => key.trim()));
    setRows(json);

    setFullNameTable(
      !Object.prototype.hasOwnProperty.call(json[0], "first_name")
    );
    setOpenCSVModal(true);

    event.target.value = "";
  };

  const handleCloseModals = () => {
    setOpenCSVModal(false);
    setFile({ ...emptyFile });
    setRows([]);
    setFullNameTable(false);
  };

  const handleOnSubmit = (file) => {
    const newSubscription = { ...subscription };
    newSubscription["file"] = file.file;
    newSubscription["filename"] = file.filename;
    onSubscriptionChange(newSubscription);

    handleCloseModals();
  };

  return (
    <>
      <>
        <p>
          You can add up to 300 members to your community by uploading any .csv or .xlsx file.&nbsp;
          <a
            href={process.env.PUBLIC_URL + "/import_template.csv"}
            download={"import_template.csv"}
            style={{ color: "#F75201" }}
          >
            Download template file here
          </a>
        </p>
      </>

      <Button
        fullwidth
        highlighted
        icon={UploadFileIcon}
        onClick={() => {
          hiddenFileInput.current.click();
        }}
      >
        {subscription.filename
          ? `Uploaded ${subscription.filename}`
          : "Upload spreadsheet"}
        <input
          ref={hiddenFileInput}
          type="file"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          name="csv"
          hidden
          onChange={handleFileUpload}
        />
      </Button>

      {openCSVModal &&
        <div className={`container-preview`}>
          <CsvModal
            open={openCSVModal}
            onClose={handleCloseModals}
            onConfirm={() => handleOnSubmit(file)}
            rows={rows}
            fullNameTable={fullNameTable}
          />
        </div>}
    </>
  );
};

Import.propTypes = {
  subscription: PropTypes.object,
  onSubscriptionChange: PropTypes.func,
};

export default Import;
