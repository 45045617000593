import React from 'react';

import Layout from 'components/layouts';

import Form from './form'

const pageInfo = {
  name: "Create new promo code"
};

const NewPromoCode = () => {

    pageInfo.name = "Create new Promo Code"
    
    return (
        <Layout context='teacher'
                back={ true }
                pageInfo={ pageInfo }
                activeMenuItem='perks'>

          <Form />

        </Layout>
    );
};

export default NewPromoCode;
