import React, { useState } from "react";
import PropTypes from "prop-types";
import { useController } from '@rest-hooks/react';

import PageElementResource from 'resources/Profile/PageElementResource';
import ProfileResource from 'resources/Profile/ProfileResource';
import MembershipResource from 'resources/Profile/MembershipResource';
import ProductResource from 'resources/Profile/ProductResource';

import AuthModal from 'components/authForms/AuthModal';

import Menu from "./Menu";

import UserName from 'utils/localStorage/UserName';
import { useLogout } from 'utils/useLogout';

import './styles.css';

import Avatar from "@mui/material/Avatar";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Tooltip } from "@mui/material";

import { t } from 'i18n';

const SwipeableTemporaryDrawer = ({ points, slug}) => {
  const [state, setState] = useState({ right: false });
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const logout = useLogout();
  const {fetch} = useController();

  const userName = UserName.read();
  const isLoggedIn = !!userName;

  const inContentPage = window.location.pathname.includes('/content') || window.location.pathname.includes('/tags');

  const label = isLoggedIn ? t('literal:logout') : t('literal:signUp');
  const action = () => isLoggedIn ? handleLogout() : setOpenAuthModal(true);
  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleSuccessfulAuth = async () => {
    await fetch(ProfileResource.detail(), {id: slug});
    await fetch(MembershipResource.list(), {organization_slug: slug});
    await fetch(ProductResource.list(), {organization_slug: slug});
    await fetch(PageElementResource.detail(), {organization_slug: slug})
    if (inContentPage) window.location.reload(); // no need to refresh content as that is being fetched on render an not using useSuspense
    setOpenAuthModal(false);
  };

  const handleLogout = async () => {
    await logout(false, false);
    await fetch(ProfileResource.detail(), {id: slug});
    await fetch(MembershipResource.list(), {organization_slug: slug});
    await fetch(ProductResource.list(), {organization_slug: slug});
    await fetch(PageElementResource.detail(), {organization_slug: slug})
    if (inContentPage) window.location.reload(); // no need to refresh content as that is being fetched on render an not using useSuspense
  };

  return (
    <React.Fragment key={"right"}>
      <IconButton onClick={toggleDrawer("right", true)} className="menu-button">
        {userName 
          ? <Tooltip title={userName}>
              <Avatar alt={userName} src="/static/images/avatar/1.jpg" />
            </Tooltip>
          : <MenuIcon />}
      </IconButton>

      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
        className="swipeable-drawer"
      >
        <Menu
          anchor={"right"}
          label={label}
          points={points}
          action={action}
          toggleDrawer={toggleDrawer}
          userName={userName}
        />
      </SwipeableDrawer>

      <AuthModal open={ openAuthModal }
               onClose={ () => setOpenAuthModal(false) }
               done={ handleSuccessfulAuth }
               organizationSlug={ slug } />
               
    </React.Fragment>
  );
};

SwipeableTemporaryDrawer.propTypes = {
  points: PropTypes.number,
  slug: PropTypes.string,
};

export default SwipeableTemporaryDrawer;
