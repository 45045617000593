import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import CardHeader from '../CardHeader';
import Footer from '../Footer';

import Card from 'ui/Card';

const CardGrid = ({products, onAction, organizationTags }) => {

  const history = useHistory();
  const {organizationSlug} = useParams();
  
  return (
    <div className='grid'>
        { products.map((product) => {
            const { id, image_urls, title, description, show_settings, end_date, issued_counter, supply_limit, extended_description, slug } = product;
            
            const external_url = `/${organizationSlug}/store/${slug}`;
            const handleRedirect = extended_description ? () => history.push(external_url) : null;

            return (
              <Card
                key={ id }
                image_url={ image_urls ? image_urls[0] : null }
                onClick={ handleRedirect }

                header={<CardHeader
                          title={ title }
                          show_settings={ show_settings }
                          end_date={ end_date }
                          issued_counter={ issued_counter }
                          supply_limit={ supply_limit }
                          onClick={ handleRedirect }
                        />}

                description={description}

                footer={<Footer
                          product={ product }
                          onAction={ onAction }
                          organizationTags={ organizationTags }
                          onClick={ handleRedirect }
                        />}
              />
        )})}
    </div>
  );
};

CardGrid.propTypes = {
    products: PropTypes.array,
    onAction: PropTypes.func,
    organizationTags: PropTypes.array
};

export default CardGrid;
