import React, { useCallback, useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import StudentResource from 'resources/organization/StudentResource';

import Layout from 'components/layouts/index';
import Form from './form';
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';
import StatusModal from 'components/StatusModal';

const pageInfo = {
  name: "Add new participant",
}

const NewStudent = () => {
  const { fetch } = useController();
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const params = useParams();
  const courseId = parseInt(params.courseId);
  const organizationId = parseInt(params.organizationId);

  const { setOpen, setMessage, setSeverity } = useToast();
  
  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleValidation = (student) => {
    if (student.first_name === "" || student.last_name === ""){
      setError("Please tell us your first and last name.");
      handleOpenErrorModal();
      throw '';
    }
    if (student.email === ""){
      setError("Please provide a valid email.");
      handleOpenErrorModal();
      throw '';
    }
  };

  const handleOnSubmit = useCallback(
    async (e, student) => {
      e.preventDefault();
      handleValidation(student);
      try {
        const formData = new FormData();
        formData.append('first_name', student.first_name);
        formData.append('last_name', student.last_name);
        formData.append('email', student.email);

        const { id } = await fetch(StudentResource.create(), {organization_id: organizationId, course_id: courseId}, formData);
        
        // success!
        if (id){
          customToast('success', "New participant created", setOpen, setSeverity, setMessage);
          await fetch(StudentResource.list(), {organization_id: organizationId, course_id: courseId});
          window.location.reload();
        }
      }  catch (error) {        
        const { errors } = await error.response.json();
        if (errors.base[0].includes("already enrolled")) {
          setError(errors.base);
          setFeedback(true);
        } else {
          setError(error.message);
        }
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  return (
    <Layout context='teacher'
            back={ true }
            activeMenuItem={ 1 }
            pageInfo={ pageInfo } >

      <Form onSubmit= { handleOnSubmit } />

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal } feedback={ feedback }/>}

    </Layout>
  )
};

export default NewStudent;