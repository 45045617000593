import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

import logo from 'assets/images/logo-membrzclub.png';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ArticleIcon from '@mui/icons-material/Article';
import EventIcon from '@mui/icons-material/Event';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';

const menuItems = [
  { id: 'organizations', text: 'Organizations',  Icon: BusinessIcon, link: '/admin/organizations' },
  { id: 'users', text: 'Users',  Icon: PeopleIcon, link: '/admin/users' },
  { id: 'memberships', text: 'Memberships', Icon: WidgetsIcon, link: '/admin/memberships' },
  { id: 'posts', text: 'Posts', Icon: ArticleIcon, link: '/admin/posts' },
  { id: 'orders', text: 'Orders', Icon: ShoppingBasketIcon, link: '/admin/orders' },
  { id: 'events', text: 'Events', Icon: EventIcon, link: '/admin/events' },
  { id: 'tickets', text: 'Tickets', Icon: LocalActivityIcon, link: '/admin/tickets' },
  { id: 'products', text: 'Products', Icon: RedeemTwoToneIcon, link: '/admin/products' },
]

const Admin = ({ activeMenuItem=0 }) => {
  return (
    <div>
      <Toolbar className="drawer-logo">
        <img src={ logo } />
      </Toolbar>
      <List>

        {menuItems.map(({text, Icon, link, id}) => (
            <ListItemButton key={id}
                            selected={ id == activeMenuItem }
                            component={Link}
                            to={ link } >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={ text } />
            </ListItemButton>
        ))}

      </List>
    </div>
  );
};

Admin.propTypes = {
  activeMenuItem: PropTypes.number,
};

export default Admin;
