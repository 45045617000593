import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import MediaUploader from 'components/MediaUploader';

const Media = ({object, onObjectChange, isDisabled, loading, onError}) => {
  if (isDisabled) { return null; }

  const hiddenFileInputs = {
    image: useRef(null),
  };

  const label = "To ensure a smoother user experience any image you upload must be smaller than 4 MB and the videos must be smaller than 20 MB.";

  return (
    <MediaUploader
      hiddenFileInputs={hiddenFileInputs}
      objectType={"reward"}
      object={object}
      onObjectChange={onObjectChange}
      loading={loading}
      label={label}
      onError={onError}
      mandatory
    />
  );
};

Media.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  onFileUpload: PropTypes.func,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  loading: PropTypes.bool,
};

export default Media;


