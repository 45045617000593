import React from "react";
import PropTypes from "prop-types";

import SearchableFilter from "ui/SearchableFilter";

const TicketsFilter = ({ onChange, recurrentEventExists }) => {

  const eventFilter = <SearchableFilter label="event" onChange={onChange} />;

  const sessionFilter = <SearchableFilter label="session" onChange={onChange} />;

  const ticketFilter = <SearchableFilter label="ticket" onChange={onChange} />;

  return (
    [eventFilter, recurrentEventExists ? sessionFilter : null, ticketFilter]
  );
};

TicketsFilter.propTypes = {
  onChange: PropTypes.func,
  recurrentEventExists: PropTypes.bool,
};

export default TicketsFilter;
