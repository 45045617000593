import React from 'react'
import FacebookShare from "./FacebookShare";
import TwitterShare from "./TwitterShare";
import LinkedinShare from "./LinkedinShareButton";

/* eslint-disable react/prop-types */
const SocialIcons = (props) => {
    
    const {socialTypes = ['facebook', 'twitter', 'linkedin']} = props;

    return (
        <div className="social-share-container">
            <div>
                {Array.isArray(socialTypes) && socialTypes.map((type, idx) => (
                    <React.Fragment key={"social_item_" + idx}>
                        {type === 'facebook' && <FacebookShare {...props} />}
                        {type === 'twitter' && <TwitterShare {...props} />}
                        {type === 'linkedin' && <LinkedinShare  {...props} />}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}

export default SocialIcons;
