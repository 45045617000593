import React from 'react';
import PropTypes from 'prop-types';

import DesktopStepper from './DesktopStepper';
import MobileStepper from './MobileStepper';

const index = ({step, multiple}) => {

  const activeStep =  step === 1 ? 0 : 1; 
  const steps = [ `Add Member${multiple ? "s" : ""}`, "Welcome Email" ];

  return (
    <div>
      <DesktopStepper activeStep={ activeStep } steps={ steps } /> 
      <MobileStepper activeStep={ activeStep } steps={ steps } />
    </div>
  );
};

index.propTypes = {
    step: PropTypes.number,
    multiple: PropTypes.bool,
};

export default index;