import React from 'react'
import PropTypes from 'prop-types'
import DownloadButtons from './DownloadButtons'

import Backdrop from '@mui/material/Backdrop';

const BackdropModal = ({open, handleClose, imageUrl, title, handleToggle, showDownloadButtons=false, id}) => {
  
  return (
    <Backdrop open={open}
            onClick={handleClose}
            sx={{ zIndex: (1500)}}
            >
            { imageUrl &&
                <img src={imageUrl}
                    className="backdrop-badge-image"
                    alt={ `${ title }` }
                    onClick={handleToggle}
                />
            }
            { showDownloadButtons &&
                <DownloadButtons id={ id } />
            }

    </Backdrop>
  )
}

BackdropModal.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    handleToggle: PropTypes.func,
    showDownloadButtons: PropTypes.bool,
    id: PropTypes.number
}

export default BackdropModal;
