import React from 'react';
import PropTypes from 'prop-types';
import { useSuspense } from '@rest-hooks/react';

import RewardResource from 'resources/organization/RewardResource';

import LockSelector from 'components/LockSelector';

import { activeSellablePasses } from 'utils/filterFunctions';
import FormSection from 'ui/FormSection';
import Label from 'ui/text/Label';

const PassSelector = ({reward_ids, onChange, organizationId}) => {

  const rewards = useSuspense(RewardResource.list(), {organization_id: organizationId});
  const passes = rewards.filter(activeSellablePasses);

  return (
    <FormSection title={'Viewing Criteria'}>
        <LockSelector 
              reward_ids={reward_ids}
              onChange={onChange}
              passes={passes}
              showPrivate={false}
              />

        <Label secondary padded>
          {passes.length > 0 
            ? 'Choose any number of paid tiers to limit who can view this post' 
            : 'This post will be created as public. Once you create a Membership tier you will be able to make exclusive.'}
        </Label>
    </FormSection>
  )
}

PassSelector.propTypes = {
  reward_ids: PropTypes.array,
  organizationId: PropTypes.number,
  onChange: PropTypes.func
};

export default PassSelector;