import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory, useLocation } from 'react-router-dom';

import SwipeableTemporaryDrawer from 'ui/SwipeableDrawer';

import isMobileDevice from 'utils/mobileAndTabletCheck';
import { useFreeSubscription } from 'utils/freeSubscriptionController';
import AuthToken from 'utils/localStorage/AuthToken';

import './styles.css';

import PersonIcon from '@mui/icons-material/Person';
import ImageIcon from '@mui/icons-material/Image';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import RedeemIcon from '@mui/icons-material/Redeem';
import LinkIcon from '@mui/icons-material/Link';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EventIcon from '@mui/icons-material/Event';

import { t } from 'i18n/index';
import { checkCustomFields } from 'utils/signUpCustomFields';

const slStore = (slug) => {
  return slug === 'unicornfactorylisboa' ? 'Perks' : t('literal:store');
}

const ContentMenu = ({organization}) => {

  const {openFreeSubscriptionModal} = useFreeSubscription();

  const history = useHistory();

  const location = useLocation();
  const currentPath = location.pathname;
  const isRegisterRoute = currentPath.includes('/register');

  const authToken = AuthToken.read();
  const isLoggedIn = !!authToken;

  const { profile_tabs, slug, hasLinks, user_points } = organization;

  const showLinksTab = (hasLinks && !isMobileDevice()) || isMobileDevice();

  const aboutTextKey = showLinksTab && !isMobileDevice() ? t("literal:links") : t('literal:about')
  const aboutIcon = showLinksTab && !isMobileDevice() ? LinkIcon : PersonIcon;

  const items = [
    { id: 'content', text: t('literal:newsletter'), Icon: ImageIcon, link: '/content', shouldRender: profile_tabs.includes('content') },
    { id: 'store', text: slStore(slug), Icon: RedeemIcon, link: '/store', shouldRender: profile_tabs.includes('store') },
    { id: 'memberships', text: t('literal:memberships'), Icon: WorkspacePremiumIcon, link: '/memberships', shouldRender: profile_tabs.includes('memberships')},
    { id: 'events', text: t('literal:events'), Icon: EventIcon, link: '/events', shouldRender: true},
    { id: 'about', text: aboutTextKey, Icon: aboutIcon, link: '', shouldRender: showLinksTab },
  ];

  const notionItem = profile_tabs.find(tab => tab.startsWith('notion:'));
  if (notionItem) {
    const tabTitle = notionItem.split(':')[1];
    items.push({ id: notionItem, text: tabTitle, Icon: AccountBoxIcon, link: '/more', shouldRender: true });
  }

  const sortedItems = profile_tabs.map(tabId => items.find(item => item.id === tabId)).filter(item => item);

  const hasCustomFields = checkCustomFields(organization?.slug);

  const isActive = (id, link) => {
    const fullLink = `/${slug}${link}`;

    if (id === 'about') {
      if (hasCustomFields) {
        return currentPath === `/${slug}/about`;
      } else {
        return currentPath === `/${slug}`;
      }
    } else {
      return currentPath.startsWith(fullLink);
    }
  };

  useEffect(() => {
    if (isRegisterRoute && !isLoggedIn) {
      openFreeSubscriptionModal();
    }
    if (isRegisterRoute) {
      history.push(`/${slug}`);
    }
  }, []);

  return (
    <div className='ui-content-menu' >
      {sortedItems.filter((item) => item.shouldRender)
                  .map(({id, text, Icon, link}) => (
        <NavLink
            key={id}
            id={id}
            to={hasCustomFields && link === "" ? `/${slug}/about` : `/${slug}${link}`}
            className={isActive(id, link) ? 'nav-link is-active' : 'nav-link'}
          >
          <div className="nav-item">
            <Icon />
            {text}
          </div>
        </NavLink>
      ))}
      <SwipeableTemporaryDrawer points={user_points} slug={slug} />
    </div>
  )
};

ContentMenu.propTypes = {
  organization: PropTypes.object,
};

export default ContentMenu;
