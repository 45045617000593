import React from 'react';
import PropTypes from 'prop-types';

import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import { FormControl, IconButton, InputAdornment } from '@mui/material';

const DataTableHeader = ({ 
                           onClick,
                           onQueryChange,
                           queryString,
                           searchLabel = "Search Members",
                           filter,
                         }) => {

  return (
    <div className='data-table-header'>

      {filter && filter}

      { onQueryChange &&
        <FormControl sx={{'width' : 275}} size="small">
          <TextField label={searchLabel}
            type="search"
            size="small"
            value={queryString}
            color="warning"
            onChange={(e) => { onQueryChange(e.target.value) }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      }
      {onClick &&
        <IconButton  onClick={onClick}>
          <DownloadIcon />
        </IconButton>
      }
    </div>
  )
};

DataTableHeader.propTypes = {
  onClick: PropTypes.func,
  onQueryChange: PropTypes.func,
  queryString: PropTypes.string,
  searchLabel: PropTypes.string,
  filter: PropTypes.node,
};

export default DataTableHeader;
