import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Button = ({onClick, children, fullwidth, secondary, highlighted, text, rounded, icon: Icon = null, iconColor, loading, minwidth, disabled, className, disabledOnClick, small, grey, smallPadded }) => {
  const classes = ['ui-button']
  if (fullwidth) classes.push('fullwidth')
  if (secondary) classes.push('secondary')
  if (highlighted) classes.push('highlighted')
  if (text) classes.push('text')
  if (rounded) classes.push('rounded')
  if (loading) classes.push('loading')
  if (minwidth) classes.push('minwidth')
  if (disabled) classes.push('disabled')
  if (small) classes.push('small')
  if (grey) classes.push('grey')
  if (smallPadded) classes.push('small-padded')
  if (className) classes.push(className)

  const content = loading
                    ? <div className="spinner"></div>
                    : Icon 
                      ? <div className="label"><Icon fontSize={small ? 'small' : 'medium'} color={iconColor}/> {children}</div>
                      : children

  const action = loading 
                  ? event => event.preventDefault()
                  : disabled
                    ? disabledOnClick
                      ? disabledOnClick
                      : event => event.preventDefault()
                    : onClick 
  
  return (
    <button onClick={action} 
      className={classes.join(' ')}>
        {content}
    </button>
  )
};

Button.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    fullwidth: PropTypes.bool,
    secondary: PropTypes.bool,
    highlighted: PropTypes.bool,
    text: PropTypes.bool,
    rounded: PropTypes.bool,
    icon: PropTypes.object,
    iconColor: PropTypes.string,
    loading: PropTypes.bool,
    minwidth: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    disabledOnClick: PropTypes.func,
    small: PropTypes.bool,
    smallPadded: PropTypes.bool,
    grey: PropTypes.bool
};

export default Button;
