const handleSize = (size) => {
  return size.toString().includes("px") ? size : `${size}px`
};

const createThemeFormData = (theme) => {
  const formData = new FormData();
  const fields = [
    { key: 'layout', value: theme.layout },
    { key: 'primary_background_color', value: theme.primary_background_color },
    { key: 'banner_size', value: theme.banner_size},
    { key: 'title_color', value: theme.title_color },
    { key: 'buy_button_color', value: theme.buy_button_color },
    { key: 'button_text_color', value: theme.button_text_color },
    { key: 'text_color', value: theme.text_color },
    { key: 'metadata_text_color', value: theme.metadata_text_color },
    { key: 'metadata_box_color', value: theme.metadata_box_color },
    { key: 'secondary_background_color', value: theme.secondary_background_color },
    { key: 'title_size', value: handleSize(theme.title_size) },
    { key: 'subtitle_size', value: handleSize(theme.subtitle_size) },
    { key: 'description_size', value: handleSize(theme.description_size) },
    { key: 'padding_page', value: theme.padding_page },
    { key: 'font_family', value: theme.font_family },
    { key: 'highlight_color', value: theme.highlight_color },
  ];

  if (theme.custom_cta) {
    fields.push({ key: 'custom_cta', value: theme.custom_cta });
  }

  fields.forEach(field => {
    formData.append(field.key, field.value);
  });
  return formData;
};
  
export default createThemeFormData;