import React, { useCallback, useState } from "react";
import Slider from "ui/Slider";

const useSliderController = (title, description) => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [objects, setObjects] = useState([]);
  
    const handleImageClick = useCallback((objects, index = 0) => {
      setObjects(objects);
      setSelectedIndex(index);
      setOpen(true);
    }, []);
  
    const handleClose = useCallback(() => {
      setObjects([]);
      setSelectedIndex(0);
      setOpen(false);
    }, []);
  
    const SliderComponent = useCallback(() => {
      if (!open || objects.length === 0) return null;
  
      return (
        <Slider
          open={open}
          handleClose={handleClose}
          objects={objects}
          selectedIndex={selectedIndex}
          title={title}
          description={description}
        />
      );
    }, [open, objects, handleClose, selectedIndex]);
  
  
    return {
      openSliderComponent: open,
      handleImageClick,
      SliderComponent
    };
  };
  
  export default useSliderController;