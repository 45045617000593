import React from "react";
import PropTypes from "prop-types";

import Filter from "ui/Filter";
import SearchableFilter from "ui/SearchableFilter";

const SubscriptionFilter = ({ onChange }) => {
  
  const passFilter = <SearchableFilter label="pass" onChange={onChange} />;

  const statusFilter = <Filter onChange={onChange} label="status" table={"subscriptions"} />;

  return (
    [passFilter, statusFilter]
  );
};

SubscriptionFilter.propTypes = {
  titles: PropTypes.array,
  onChange: PropTypes.func,
};

export default SubscriptionFilter;
