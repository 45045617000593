import { React, useCallback } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import RewardResource from 'resources/organization/RewardResource';

import TabPanels from 'components/TabPanels';
import BadgeCard from 'components/BadgeCard';
import ActionCard from 'components/ActionCard';
import PageTop from 'components/PageTop';
import StripeConnectAlert from 'components/StripeConnectAlert';

import { sortPasses } from 'utils/sortPasses';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Button, Link } from '@mui/material';

const pageInfo = {
    name: "Memberships",
    description: "Create and manage multiple membership tiers. Free or Paid options available",
    action: true
  };

const newTierUrl = (organizationId, courseId) => `/organizations/${organizationId}/memberships/${courseId}/tiers/new`;

/* eslint-disable react/prop-types */
const BadgesGrid = ({rewards, courseId, organizationId, onDelete, published}) => (
    <div className="badges-grid grid">
      { rewards.map( ({id, title, category, media_type, image_url, video_url, stats, public:isPublic, active_state, supply_limit, end_date, pricings}) => (
          <BadgeCard key={ id }
                    id={ id }
                    course_id={ courseId }
                    title={ title }
                    category={ category }
                    media_type={ media_type }
                    image_url={ image_url }
                    video_url={ video_url }
                    stats={ stats }
                    isPublic={ isPublic}
                    onDelete={ () => onDelete(id) }
                    active_state={ active_state }
                    supply_limit={ supply_limit }
                    end_date={ end_date }
                    published = { published }
                    pricings={ pricings }
          />
        ))
      }

      <ActionCard to={ newTierUrl(organizationId, courseId) }
                    Icon={ AddCircleOutlineIcon }
                    title="Add Tier" />

    </div>
);

const Content = ({ organization }) => {

    const { fetch } = useController();
    const {id, membership_id, stripe_charges_enabled} = organization;

    const rewards  = useSuspense(RewardResource.list(), {organization_id: id, course_id: membership_id});
    const tiers    = sortPasses(rewards);

    const hasTiers = tiers.length > 0;
    const hasPaidTiers = tiers.filter(tier => tier.pricings && tier.pricings.length > 0).length > 0
    const showAlert = !stripe_charges_enabled && hasPaidTiers;

    const cta = <div style={{marginTop: '20px'}}>
      { !hasTiers &&
        <Link href={ newTierUrl(id, membership_id)  }>
          <Button variant='contained'>
            New Tier
          </Button>
        </Link>
      }
    </div>

    const handleDelete = useCallback(
        async (rewardId) => {
            await fetch(RewardResource.delete(), { id: rewardId, course_id: membership_id, organization_id: id });
            },
        [fetch],
    );

  return (
    <div>
        <PageTop pageInfo={ pageInfo }
                 cta={ cta } />


        <TabPanels labels={ [`Membership Tiers (${tiers.length})`] } >
          <>
            {showAlert &&
              <StripeConnectAlert organizationId={id} />
            }
            { hasTiers &&
              <BadgesGrid
                key={0}
                rewards={ tiers }
                organizationId={ id }
                courseId={ membership_id }
                onDelete={ handleDelete }
                sellableCollectibles={true} />
            }
          </>
        </TabPanels>
    </div>
  );
};

Content.propTypes = {
    organization: PropTypes.object,
};

export default Content;
