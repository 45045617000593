import React, { useEffect } from 'react';

import EventResource from 'resources/admin/EventResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import Stats from './Stats';

import SearchableFilter from 'ui/SearchableFilter';

import { eventsColumns } from 'utils/tables/columns/admin/eventsColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import downloadCSV from 'utils/downloadCSV';

const pageInfo = {
  label: "Admin",
  name: "Events",
};

const AdminEvents = () => {

  const {
    objectState,
    fetchData,
    handlePageChange,
    handleFilterChange
  } = usePaginationAndFilteringController({
      fetchDataResource: EventResource,
      fetchDataParams: {},
  });

  useEffect(() => {
    fetchData();
  }, []);


  const handleDownload = async () => {
    await downloadCSV('/admin/events', objectState.filteringParams);
  };

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem='events' >

      <TabPanels labels={[ "Events" ]} >
            <div>
              <Stats events={objectState.filteredObjects} />
              <DataSelectionTable records={objectState.filteredObjects}
                                  columns={eventsColumns}
                                  onClick={handleDownload}
                                  serverPaginationProps={{
                                    paginationServer: true,
                                    paginationTotalRows: objectState.totalCount,
                                    page: objectState.page,
                                    onChangePage: handlePageChange
                                  }}
                                  filter={
                                    <SearchableFilter
                                      label={"organization"}
                                      onChange={handleFilterChange}
                                    />
                                  }
              />
            </div>
      </TabPanels>

    </Layout>
)};

export default AdminEvents;
