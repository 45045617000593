import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Title from './Title';
import Content from './Content';
import Actions from './Actions';

import { Dialog } from '@mui/material';

const ConnectModal = ({ open, onClose, done, isLoggedIn, handleError, loading, setLoading }) => {

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' className='connect-modal'>
        
        <Title />

        <Content 
          isLoggedIn={isLoggedIn}
          step={step}
          email={email}
          setEmail={setEmail} />

        <Actions 
          onClose={onClose} 
          done={done}
          isLoggedIn={isLoggedIn} 
          handleError={handleError}
          step={step}
          setStep={setStep}
          email={email}
          loading={loading}
          setLoading={setLoading}
          />

    </Dialog>
  );
};

ConnectModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  done: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  handleError: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
};

export default ConnectModal;