import React from 'react';
import PropTypes from 'prop-types';

import Date from 'ui/Date';
import Description from 'ui/text/Description';

import formattedDate from 'utils/formattedDate';

import moment from 'moment';

const Calendar = ({ start_date, end_date }) => {

  const shownStartDate = formattedDate(start_date, 'date');
  const startDateTime = formattedDate(start_date, 'time');
  const endDateTime = formattedDate(end_date, 'time');

  const isSameDate = moment(start_date).diff(moment(end_date), 'days') === 0;

  const shownTime = isSameDate ? `${startDateTime} - ${endDateTime}` : `${startDateTime}`

  return (
    <div className="calendar">
      <Date date={start_date} single={true} />

      <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
        <Description bold>{shownStartDate}</Description>
        <Description small>{shownTime}</Description>
      </div>

    </div>
  );
};

Calendar.propTypes = {
  start_date: PropTypes.string,
  end_date: PropTypes.string
};

export default Calendar;
