import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Form from './form';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import LoadingComponent from 'components/CheckoutModal/PaymentModal/LoadingComponent';

/** CONSTANTS **/
let stripePromise;

const appearance = {
  theme: 'stripe',
  variables: { colorPrimary: '#e55e29' },
};

/** COMPONENT **/
const StripePaymentForm = ({
    object,
    orderPropertiesState,
    onStepChange,
    onContentChange,
    stripeState,
    onShowTickets,
    pricingId
  }) => {

  const { clientSecret, stripePublishableKey, stripeAccountId } = stripeState;
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    if (stripePublishableKey && stripePublishableKey.length > 0) {
      const stripeOptions = stripeAccountId
        ? { stripeAccount: stripeAccountId, locale: 'auto' }
        : { locale: 'auto' };

      if (!stripePromise) {
        stripePromise = loadStripe(stripePublishableKey, stripeOptions);
      }
      stripePromise.then(stripeInstance => setStripe(stripeInstance));
    }
  }, [stripePublishableKey, stripeAccountId]);

  if (!clientSecret || !stripePublishableKey || !stripe) return (
    <LoadingComponent />
  );

  const options = { clientSecret, appearance, loader: 'never' };

  return (
    <Elements options={options} stripe={stripe}>
      <Form
        object={object}
        orderPropertiesState={orderPropertiesState}
        onStepChange={onStepChange}
        onContentChange={onContentChange}
        onShowTickets={onShowTickets}
        pricingId={pricingId}
      />
    </Elements>
  );
};

StripePaymentForm.propTypes = {
  object: PropTypes.object,
  orderPropertiesState: PropTypes.object,
  onStepChange: PropTypes.func,
  onContentChange: PropTypes.func,
  stripeState: PropTypes.object,
  onShowTickets: PropTypes.func,
  pricingId: PropTypes.number
};

export default StripePaymentForm;
