export const usersColumns = [
  {
    field: "id",
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
    width: "15%",
  },
  {
    field: "name",
    name: "Full Name",
    selector: (row) => row.name,
    sortable: true,
    width: "37.5%",
  },
  {
    field: "email",
    name: "Email Address",
    selector: (row) => row.email,
    sortable: true,
    width: "37.5%",
  },
];
