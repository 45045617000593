import React from 'react';
import PropTypes from 'prop-types';

import copyToClipboard from "utils/copyToClipboard";
import { useToast } from 'utils/context/ToastContext';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopiableText = ({label, value}) => {

  const { setOpen, setMessage, setSeverity } = useToast();

  const handleCopy = (content) => {
    copyToClipboard(content, setOpen, setSeverity, setMessage)
  };

  return (
    <div>
      <b>{label}</b>&nbsp;
      <span style={{float: "right"}} onClick={() => handleCopy(value)} className='pointer'>{value} <ContentCopyIcon fontSize='smaller' /></span>
    </div>
  );
};

CopiableText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

export default CopiableText;
