import React, { useEffect } from 'react'
import { useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";

import ProductResource from 'resources/Profile/ProductResource';
import ProfileResource from 'resources/Profile/ProfileResource';
import TagResource from 'resources/Profile/TagResource';

import AuthModal from 'components/authForms/AuthModal';
import RedeemModal from 'components/RedeemModal';
import CheckoutModal from 'components/CheckoutModal';

import ProductsList from './ProductsList/index';
import SingleCard from './SingleCard';

import { scrollToTop } from 'utils/scrollToTop';
import { usePreActionController } from 'utils/usePreActionController';
import generateMetatags from "utils/generateMetatags";
import { useSocialMetatags } from "utils/socialMetatagsController";

import './styles.css';

const Store = ({organization_title, organization_logo}) => {

  const { organizationSlug, productSlug } = useParams();
  const { setSocialMetatags } = useSocialMetatags();

  scrollToTop();

  const searchParams = new URLSearchParams(window.location.search);
  const tagName = searchParams.get('tag');
  const organizationTags = useSuspense(TagResource.list(), {organization_slug: organizationSlug, tag_type: 'product'});
  const activeTag = tagName ? organizationTags.find(tag => tag.name === tagName) : null;

  const products = productSlug
                  ? [useSuspense(ProductResource.detail(), {organization_slug: organizationSlug, id: productSlug})]
                  : useSuspense(ProductResource.list(), {organization_slug: organizationSlug, tag_id: activeTag?.id});

  useEffect(() => {
    const isSingleProduct = productSlug && products.length > 0;
    const page = isSingleProduct ? 'singleProduct' : 'store';
    const product = isSingleProduct ? products[0] : null;

    generateMetatags(page, setSocialMetatags, organization_title, organization_logo, product?.title, product?.description, product?.image_urls[0]);
  }, []);

  const { show_application_fee } = useSuspense(ProfileResource.detail(), {id: organizationSlug});

  const {
    currentAction,
    onProductChange,
    modalsState,
    onAction,
    onSuccessfulAuth,
    onClose
  } = usePreActionController(organizationSlug, activeTag);
  
  return (
    <>
      { productSlug ?
        <SingleCard
          product={products[0]}
          onAction={onAction}
          organizationSlug={organizationSlug}
          organizationTags={organizationTags}
          showApplicationFee={show_application_fee}
          />
      :
        <ProductsList 
          products={products}
          onAction ={onAction}
          organizationSlug={organizationSlug}
          organizationTags={organizationTags}
          activeTag={activeTag}
          />
      }

      {modalsState.authModal &&
        <AuthModal 
          open={ modalsState.authModal }
          done={ onSuccessfulAuth }
          onClose={ onClose } 
          organizationSlug={ organizationSlug }
          allowPartialSignIn={ true } />}

      {modalsState.redeemModal && 
        <RedeemModal
          product={currentAction.product}
          onClose={onClose}
          organizationSlug={organizationSlug}
          activeTag={activeTag}
        />}

      {modalsState.checkoutModal &&
        <CheckoutModal
          product={currentAction.product}
          onProductChange={onProductChange}
          onClose={onClose}
        />}
    </>
  );
};

Store.propTypes = {
  organization_title: PropTypes.string,
  organization_logo: PropTypes.string,
};

export default Store;
