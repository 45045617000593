import React from 'react';
import { useParams } from 'react-router-dom';
import { useSuspense } from '@rest-hooks/react';

import IntegrationResource from 'resources/Profile/IntegrationResource';

import './styles.css';

const Notion = () => {

  const {organizationSlug} = useParams();

  const integration = useSuspense(IntegrationResource.detail(), { organization_slug: organizationSlug, id: 'notion' });
  const embed = integration?.settings?.url || '';

  return (
      <div className='more-content'>
        <iframe src={embed}></iframe>
      </div>
  );
};

export default Notion;
