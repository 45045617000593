import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import StatCard from 'components/StatCard';
import { useController } from '@rest-hooks/react';

const Stats = ({TicketResource, ticketResourceParams, stats}) => {

    const {checked_in_count, not_checked_in_count, total_count} = stats[0];

    const {fetch} = useController();
    
    const refreshStatsData = async () => {
        await fetch(TicketResource.list(), {...ticketResourceParams, stats: true});
    };

    useEffect(() => {
        refreshStatsData()
    }, []);

    return (
        <div className="event-stats-grid grid custom-margin">
            <StatCard type='number'
                color='rgb(255, 99, 132)'
                highlight={ checked_in_count }
                title='Checked In' />

            <StatCard type='number'
                color='rgb(54, 162, 235)'
                highlight={ not_checked_in_count }
                title='Not Checked In' />

            <StatCard type='number'
                    color='rgb(255, 205, 86)'
                    highlight={ total_count }
                    title='Total Sold Tickets' />
        </div>
    )};

Stats.propTypes = {
    TicketResource: PropTypes.object,
    ticketResourceParams: PropTypes.object,
    stats: PropTypes.array,
};

export default Stats;