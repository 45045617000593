import React, { useState } from "react";
import PropTypes from "prop-types";
import { useController } from "@rest-hooks/react";

import Content from "./Content";
import ButtonGroup from "./ButtonGroup";
import { handleValidation } from "./handleValidation";

import { useError } from "utils/useErrorController";
import { useToast } from "utils/context/ToastContext";
import customToast from "utils/customToast";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const NewTicketModal = ({ open, onClose, tickets, done, TicketResource, ticketResourceParams }) => { 
  const defaultTicketId = tickets.length > 1 ? null : tickets[0].id;

  const [email, setEmail] = useState("");
  const [ticketId, setTicketId] = useState(defaultTicketId);

  const { handleError, setLoading, loading } = useError();
  const { setOpen, setMessage, setSeverity } = useToast();
  const { fetch } = useController();

  const handleNewTicketSubmit = async () => {
    try {
      setLoading(true);
      handleValidation(email, ticketId);
      await fetch(
        TicketResource.create(),
        ticketResourceParams,
        {
          email: email,
          ticket_id: ticketId,
        }
      );
      done();
      customToast("success", "Ticket Added", setOpen, setSeverity, setMessage);
      handleClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleClose = () => {
    setEmail("");
    setTicketId(defaultTicketId);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm">
      <DialogTitle>
        Add new Ticket
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Content
          onEmailChange={setEmail}
          tickets={tickets}
          onTicketSelect={setTicketId}
          ticketId={ticketId}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex" }}>
        <ButtonGroup
          handleClose={handleClose}
          handleNewTicketSubmit={handleNewTicketSubmit}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
};

NewTicketModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  tickets: PropTypes.array,
  done: PropTypes.func,
  TicketResource: PropTypes.object,
  ticketResourceParams: PropTypes.object
};

export default NewTicketModal;
