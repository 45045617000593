import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useController } from "@rest-hooks/react";

import StripeDeepLinkResource from "resources/Profile/StripeDeepLink";
import SubscriptionResource from "resources/Profile/SubscriptionResource";
import MembershipResource from "resources/Profile/MembershipResource";
import StripeDeepLink from "resources/Profile/StripeDeepLink";

import ConfirmModal from "components/ConfirmModal";

import { t } from "i18n/index";

const useSubscriptionController = (
  subscriptionDetails,
  onError,
  mustReactivate
) => {

  const [downgradingToFree, setDowngradingToFree] = useState(false);
  const [downgradingToPaid, setDowngradingToPaid] = useState(false);
  const [reactivating, setReactivating] = useState(false);

  const { id, end_date, status, scheduled_change, paid_subscription, user_subscription, manual_subscription, nft_subscription } = subscriptionDetails ?? {};

  const user_paid_subscription = user_subscription && paid_subscription;
  const manual_paid_subscription = manual_subscription && paid_subscription;
  
  const { organizationSlug } = useParams();
  const { fetch } = useController();

  const handleDowngrade = async () => {
    try {
      if (downgradingToFree) {
        const { url } = await fetch(StripeDeepLinkResource.delete(), { organization_slug: organizationSlug });
        window.location.href = url;
      } else {
        await fetch(SubscriptionResource.update(), { organization_slug: organizationSlug, id: id }, { operation: "downgrade", ...downgradingToPaid } );
        await fetch(MembershipResource.list(), { organization_slug: organizationSlug });
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleScheduleCancel = async () => {
    try {
      await fetch(SubscriptionResource.update(), { organization_slug: organizationSlug, id: id }, { operation: "cancel_schedule" });
      await fetch(MembershipResource.list(), { organization_slug: organizationSlug });
    } catch (error) {
      onError(error);
    }
  };

  const handleReactivation = async () => {
    try {
      await fetch(SubscriptionResource.update(), { organization_slug: organizationSlug, id: id }, { operation: "reactivate" });
      await fetch(MembershipResource.list(), { organization_slug: organizationSlug, });
    } catch (error) {
      onError(error);
    }
  };

  const handleStripeCancel = async () => {
    try {
      const { url } = await fetch(StripeDeepLink.delete(), { organization_slug: organizationSlug });
      window.location.href = url;
    } catch (error) {
      onError(error);
    }
  };

  const handleManualPaidCancel = () => {
    onError(t("errors:manualSubscription"));
  };

  const handleNftCancel = () => {
    onError(t("errors:nftSubscription"));
  }

  const handleFreeCancel = async () => {
    try {
      await fetch(SubscriptionResource.update(), { organization_slug: organizationSlug, id: id }, { operation: "cancel" });
      await fetch(MembershipResource.list(), { organization_slug: organizationSlug, });
    } catch (error) {
      onError(error);
    }
  };

  const handleUpdatePaymentDetails = async () => {
    try {
      const { url } = await fetch(StripeDeepLink.update(), { organization_slug: organizationSlug });
      window.open(url, "_blank");
    } catch (error) {
      onError(error);
    }
  };

  const handleCloseConfirmModal = () => {
    setDowngradingToFree(false);
    setDowngradingToPaid(false);
    setReactivating(false);
  };

  const confirmModalTitle = mustReactivate 
    ? t('literal:reactivate')
    : `${t('literal:downgrading')} ${downgradingToFree ? t('literal:toFree'): ""}`;
  
  const openConfirmModal =
    downgradingToFree || downgradingToPaid || reactivating;

  
  const confirmModalAction = mustReactivate
    ? handleReactivation
    : scheduled_change
    ? handleScheduleCancel
    : handleDowngrade;

  const futureRewardTitle = scheduled_change && scheduled_change.reward_title;

  const confirmModalMessage = mustReactivate
    ? t('useSubscriptionController:confirmModalMessage:mustReactivate')
    : scheduled_change
    ? t('useSubscriptionController:confirmModalMessage:mustCancelDowngrade', { futureRewardTitle })
    : downgradingToFree
    ? t('useSubscriptionController:confirmModalMessage:downgradetoFreeInstructions')
    : t('useSubscriptionController:confirmModalMessage:downgradeInstructions')

  const ConfirmModalComponent = () => {
    return (
      <ConfirmModal
        title={confirmModalTitle}
        open={openConfirmModal}
        setOpen={handleCloseConfirmModal}
        onConfirm={confirmModalAction}
      >
        {confirmModalMessage}
      </ConfirmModal>
    );
  };

  const menuItems = [
    {
      label: t('useSubscriptionController:cancelSubscription'),
      onClick: user_paid_subscription ? handleStripeCancel : manual_paid_subscription ? handleManualPaidCancel : nft_subscription ? handleNftCancel : handleFreeCancel,
      condition: status === "active" && !scheduled_change,
    },
    {
      label: t('useSubscriptionController:reactivateSubscription'),
      onClick: handleReactivation,
      condition: (user_paid_subscription && status === "expiring" && !scheduled_change) || (!paid_subscription && status === "expired"),
    },
    {
      label: t('useSubscriptionController:cancelScheduledChange'),
      onClick: handleScheduleCancel,
      condition: scheduled_change, //exclusive to stripe subscriptions
    },
    {
      label: t('useSubscriptionController:updatePaymentDetails'),
      onClick: handleUpdatePaymentDetails,
      condition: user_paid_subscription && end_date,
    },
  ];

  return {
    ConfirmModalComponent,

    setDowngradingToFree,
    setDowngradingToPaid,
    setReactivating,

    menuItems,
  };
};

export default useSubscriptionController;
