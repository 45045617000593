import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';
import { useHistory, useParams } from 'react-router-dom';

import ContentResource from 'resources/Profile/ContentResource';
import ProfileResource from 'resources/Profile/ProfileResource';
import MembershipResource from 'resources/Profile/MembershipResource';
import ProductResource from 'resources/Profile/ProductResource';
import ReactionResource from 'resources/Profile/ReactionResource';
import CommentResource from 'resources/Profile/CommentResource';

import AuthModal from 'components/authForms/AuthModal';
import ConfirmModal from 'components/ConfirmModal';

import { handleValidation } from './handleValidation';
import Accordion from './Accordion';
import Interactions from './Accordion/Interactions';
import CommentsList from './Accordion/CommentsList';

import Description from 'ui/text/Description';

import UserName from 'utils/localStorage/UserName';
import { useError } from 'utils/useErrorController';

import { t } from 'i18n/index';

const Footer = ({ postId, reactions=[], comments=[], external_url, page, setPosts, posts }) => {

    const [openAuthModal, setOpenAuthModal] = useState(false);
    const [initiatedInteraction, setInitiatedInteraction] = useState(null);
    const [showComments, setShowComments] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [confirmModal, setConfirmModal] = useState(false);
    const [activePost, setActivePost] = useState(null);

    const history = useHistory();
    const {fetch} = useController();
    const {organizationSlug, postSlug} = useParams();
    const { handleError, loading, setLoading, validate} = useError();

    const redirectToMemberships = () => {
        history.push(`/${organizationSlug}/memberships`);
    };

    const handleInteraction = async (interactionType, interactionDetail, updatedPosts) => {
        try {
            const userName = UserName.read();
            const isLoggedIn = !!userName;

            const { user_is_member } = await fetch(ProfileResource.detail(), {id: organizationSlug});

            if (isLoggedIn && user_is_member) {
                if (interactionType == 'reaction') {
                    handleReaction(interactionDetail, updatedPosts);
                } else {
                    if (updatedPosts) setPosts(updatedPosts);
                    setShowComments(!showComments);
                }
                setInitiatedInteraction(null);
            } else if (isLoggedIn) {
                setConfirmModal(true);
            } else {
                setInitiatedInteraction({type: interactionType, detail: interactionDetail});
                setOpenAuthModal(true);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const updatePostsData = (postToUpdate, updatedContent, target, originalPosts) => {

        const updatedPosts = originalPosts ? [...originalPosts] : [...posts];

        if (target === 'reactions') {
            updatedPosts[updatedPosts.indexOf(postToUpdate)] = { ...postToUpdate, reactions: updatedContent };

        } else {
            updatedPosts[updatedPosts.indexOf(postToUpdate)] = { ...postToUpdate, comments: updatedContent || [] };

        }

        setPosts(updatedPosts);
        setLoading(false);
        setActivePost(null);
    };

    const handleReaction = async (interactionDetail, updatedPosts) => {
        try {
            setLoading(true);
            setActivePost({id: postId, interaction: 'reaction'});

            const userPreviousReaction = 
                    updatedPosts 
                        ? updatedPosts.find(post => post.id === postId).reactions.find(reaction => reaction.user_is_owner) 
                        : reactions.find(reaction => reaction.user_is_owner);
            
            const post = 
                    updatedPosts 
                        ? updatedPosts.find(post => post.id === postId) 
                        : posts.find(post => post.id === postId);

            if (userPreviousReaction) {
                await fetch(ReactionResource.delete(), {organization_slug: organizationSlug, id: userPreviousReaction.id });

                const updatedReactions = post.reactions.filter(reaction => reaction.id !== userPreviousReaction.id);
                updatePostsData(post, updatedReactions, 'reactions', updatedPosts);

            } else {

                const formData = new FormData();
                formData.append('post_id', postId)
                formData.append('reaction_type', interactionDetail);
                const {id} = await fetch(ReactionResource.create(), {organization_slug: organizationSlug}, formData);

                const updatedReactions = [...post.reactions, { id, reaction_type: interactionDetail, user_is_owner: true }];
                updatePostsData(post, updatedReactions, 'reactions', updatedPosts);
            }

        } catch (error) {
            handleError(error)
        }
    };

    const handleCommentCreate = async () => {
        try {
            validate(newComment, handleValidation)
            setActivePost({id: postId, interaction: 'comment'});

            const formData = new FormData();
            formData.append('post_id', postId)
            formData.append('content', newComment);
            const response = await fetch(CommentResource.create(), {organization_slug: organizationSlug}, formData);

            const post = posts.find(post => post.id === postId);
            const updatedComments = [...post.comments, response];

            updatePostsData(post, updatedComments, 'comments');

        } catch (error) {
            handleError(error)
        }
    };

    const handleCommentDelete = async (commentId) => {
        try {
            setLoading(true);
            setActivePost({id: postId, interaction: 'comment'});

            await fetch(CommentResource.delete(), {organization_slug: organizationSlug, id: commentId});

            const post = posts.find(post => post.id === postId);
            const updatedComments = post.comments.filter(comment => comment.id !== commentId);
            updatePostsData(post, updatedComments, 'comments');

        } catch (error) {
            handleError(error)
        }
    };

    const handleSuccessfulAuth = async () => {
        try {
            await fetch(MembershipResource.list(), {organization_slug: organizationSlug});
            await fetch(ProductResource.list(), {organization_slug: organizationSlug});
            const updatedPosts = [];
            if (postSlug) {
                updatedPosts.push(await fetch(ContentResource.detail(), {organization_slug: organizationSlug, id: postSlug}));
            } else {
                let i = 1;
                while (i <= page) {
                    const pagePosts = await fetch(ContentResource.list(), {organization_slug: organizationSlug, page: i});
                    updatedPosts.push(...pagePosts);
                    i++;
                }
            }
            setOpenAuthModal(false);
            handleInteraction(initiatedInteraction.type, initiatedInteraction.detail, updatedPosts);
        } catch (error) {
            handleError(error)
        }
    };

  return (
    <div className='footer'>
        <Accordion expanded={showComments}>
            <Interactions loading={loading && (activePost &&activePost.id === postId && activePost.interaction === 'reaction')}
                          handleInteraction={handleInteraction}
                          reactions={reactions}
                          comments={comments}
                          external_url={external_url}
                          />

            <CommentsList comments={comments}
                          loading={loading && (activePost && activePost.id === postId && activePost.interaction === 'comment')}
                          OnCommentCreate={handleCommentCreate}
                          onCommentDelete={handleCommentDelete}
                          onCommentChange={setNewComment} />
        </Accordion>

        <AuthModal
            open={ openAuthModal }
            onClose={ () => setOpenAuthModal(false) }
            done={ handleSuccessfulAuth }
            organizationSlug={ organizationSlug } />

        <ConfirmModal
            title={`${t('ui:postCard:becomeAMember')}?`}
            open={ confirmModal }
            onConfirm={ redirectToMemberships }
            setOpen={ setConfirmModal }
            >
            <Description>
                {t('ui:postCard:becomeAMemberDescriptionFirst')}
            </Description>
            <Description>
                {t('ui:postCard:becomeAMemberDescriptionSecond')}
            </Description>
        </ConfirmModal>
    </div>
  )
};

Footer.propTypes = {
    postId: PropTypes.number,
    reactions: PropTypes.array,
    comments: PropTypes.array,
    external_url: PropTypes.string,
    page: PropTypes.number,
    setPosts: PropTypes.func,
    posts: PropTypes.array
};

export default Footer;
