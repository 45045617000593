import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

const Subject = ({ subject, onSubjectChange }) => {

  return (
      <TextField 
          label={'Subject'}
          name={'subject'}
          value={subject || ''}
          size='small'
          onChange={onSubjectChange}
          fullWidth
          color="warning">
      </TextField>
  )
};

Subject.propTypes = {
  subject: PropTypes.string,
  onChange: PropTypes.func,
  onSubjectChange: PropTypes.func
};

export default Subject;