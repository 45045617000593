import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import QrCodeIcon from "@mui/icons-material/QrCode";
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';

const Header = ({ onCheckIn, onAddTicket, onShareEvent, addTicketDisabled, editingEmail, onEditingEmailChange, onEmailCancel}) => {

  const params = useParams();
  const organizationId = params.organizationId ? parseInt(params.organizationId) : null;

  const emailButtonAction = editingEmail ? onEmailCancel : () => onEditingEmailChange(true);
  const emailButtonText = editingEmail ? 'Cancel' : 'Send Email';

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <div className="tickets-tab-header">
        <Button highlighted onClick={onCheckIn} fullwidth icon={QrCodeScannerIcon}>
          Check In
        </Button>
        <Button
          secondary
          icon={ExpandMoreIcon}
          onClick={handleClick}
          fullwidth
        >
          More Options
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: "300px",
            },
          }}
        >
          <MenuItem onClick={() => { onAddTicket(); handleClose(); }} disabled={addTicketDisabled} >
            <ListItemIcon>
              <AddCircleOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add Ticket</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => { onShareEvent(); handleClose(); }}>
            <ListItemIcon>
              <QrCodeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Storefront</ListItemText>
          </MenuItem>
          {organizationId &&
            <MenuItem onClick={() => { emailButtonAction(); handleClose(); }}>
              <ListItemIcon>
                <EmailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{emailButtonText}</ListItemText>
            </MenuItem>}
        </Menu>
      </div>
  );
};

Header.propTypes = {
  onCheckIn: PropTypes.func,
  onAddTicket: PropTypes.func,
  onShareEvent: PropTypes.func,
  addTicketDisabled: PropTypes.bool,
  editingEmail: PropTypes.bool,
  onEditingEmailChange: PropTypes.func,
  onEmailCancel: PropTypes.func
};

export default Header;
