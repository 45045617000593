/* eslint-disable  @typescript-eslint/no-explicit-any */
import ApiResource  from 'resources/ApiResource';
// import { getAuthToken } from 'authorization-singleton';
import AuthToken    from 'utils/localStorage/AuthToken';

// -----------------------------------------------------
// Constants
// -----------------------------------------------------

const authHeaderKey = 'Authorization';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

// Manages auth headers for API calls, as well as rotating the UI's authToken
// on each request to the API.
export default abstract class AuthenticatedResource extends ApiResource {
  static accessToken?: string;

  // Adds Auth header to API requests
  static getFetchInit(init: any) {
    const authToken   = AuthToken.read();

    return {
      ...init,
      headers: {
        ...init.headers,
        [authHeaderKey]: `Bearer ${authToken}`
      },
    };
  }

  // Override to capture and store the next authToken from the response header.
  // static fetchResponse(method: any, url: any, body: any) {
  //   return  super
  //           .fetchResponse(method, url, body)
  //           .then(response => {
  //             const { headers } = response;
  //             const authToken   = headers.get(authHeaderKey);

  //             AuthToken.write(authToken);

  //             return response;
  //           });
  // }
}


// import { getAuthToken } from 'authorization-singleton';
// import { Resource } from '@rest-hooks/rest';

// abstract class AuthdResource extends Resource {
//   declare static accessToken?: string;
//   static getFetchInit = (init: RequestInit): RequestInit => ({
//     ...init,
//     headers: {
//       ...init.headers,
//       [authHeaderKey]: `Bearer ${this.accessToken}`
//     },
//   });
// }