import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import CourseResource from 'resources/admin/CourseResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import { coursesColumns } from 'utils/tables/columns/admin/coursesColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';

const pageInfo = {
  label: "Admin",
  name: "Memberships"
};

const AdminCourses = () => {

  const {
    objectState, 
    fetchData,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: CourseResource,
      fetchDataParams: {},
  })

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem='memberships' >

      <TabPanels labels={[ "Memberships" ]} >
        <DataSelectionTable records={objectState.filteredObjects}
                            columns={coursesColumns}
                            serverPaginationProps={{
                              paginationServer: true,
                              paginationTotalRows: objectState.totalCount,
                              page: objectState.page,
                              onChangePage: handlePageChange
                            }}
                            subHeader={false}
        />
      </TabPanels>

    </Layout>
)};


AdminCourses.propTypes = {
  history: PropTypes.object,
}

export default AdminCourses;
