import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const CsvModal = ({ open, onClose, onConfirm, rows, fullNameTable }) => {

    const boxDefeault = {
        display: 'flex',
        position: 'relative'
    }

    return (
        <>
            <Dialog open={open}
                fullWidth
            >
                <DialogTitle id="modal-csv-confirmation">
                    Import preview & confirmation
                    {onClose && (
                        <IconButton
                            aria-label='close'
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'black',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    )}
                </DialogTitle>
                <DialogContent dividers>
                    <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 550 }} size='small' aria-label="a dense table">
                                <TableHead>
                                    <TableRow>

                                        {fullNameTable ? 
                                            <TableCell>Name</TableCell> :
                                            <>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell> 
                                            </>
                                        }

                                        <TableCell>Email</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.slice(0, 10).map((item, key) => (
                                        <TableRow key={key}>

                                            { fullNameTable ? 
                                                <TableCell component="th" scope="row">
                                                    {item.full_name || item.name }
                                                </TableCell>
                                                :
                                                <>
                                                <TableCell component="th" scope="row">
                                                    {item.first_name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {item.last_name}
                                                </TableCell>
                                                </>
                                            }

                                            <TableCell >{item.email}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        { rows.length > 10 && <p className='label-message'>{`Preview of the first ${rows.slice(0, 10).length} records`}</p> }
                        <p className='message' > {`You're about to import ${rows.length} contacts. Click 'Next' to proceed.`}</p>
                    </>
                </DialogContent>
                <Box justifyContent='end' sx={boxDefeault}>
                    <Button variant="outlined"
                        color="warning"
                        onClick={onClose}
                        sx={{ m: 0.8, mt: 2 }}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained"
                        type="submit"
                        onClick={() => onConfirm()}
                        sx={{ m: 0.8, mt: 2 }}
                    >
                        Next
                    </Button>
                </Box>
            </Dialog>
        </>
    );
};

CsvModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    rows: PropTypes.array,
    fullNameTable: PropTypes.bool,
};

export default CsvModal;