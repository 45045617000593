import { FacebookShareButton, FacebookIcon } from "react-share";
import React from "react";

/* eslint-disable react/prop-types */
const FacebookShare = ({ url, title }) => {
    return (
        <FacebookShareButton
            url={url}
            quote={title}
        >
            <FacebookIcon
                size={40}
                round
            />
        </FacebookShareButton>
    )
}

export default FacebookShare