import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import Label from 'ui/text/Label';

import copyToClipboard from 'utils/copyToClipboard';
import { useToast } from 'utils/context/ToastContext';
import isMobileDevice from 'utils/mobileAndTabletCheck';

import WhatshotIcon from '@mui/icons-material/Whatshot';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccordionSummary from '@mui/material/AccordionSummary';

import { t } from 'i18n/index';

const Interactions = ({loading, handleInteraction, reactions, comments, external_url}) => {

    const { setOpen, setMessage, setSeverity } = useToast();

    const userPreviousReaction = reactions.find(reaction => reaction.user_is_owner);
    
    const reactionsLabel = 
        userPreviousReaction ?
            reactions.length > 1 ?
                `${t('literal:you')} & ${reactions.length-1} ${t('literal:other_sc')}${reactions.length == 1 ? '' : 's'}`
                : `${t('literal:liked')}`
            : `${reactions.length} ${t('literal:like')}${reactions.length == 1 ? '' : 's'}`
    
    const commentsLabel = `${comments.length} ${t('literal:comment')}${comments.length == 1 ? '' : 's'}`

    const isMobile = isMobileDevice();

  return (
    <AccordionSummary>
        <div className='buttons'>
            <Button
                loading={loading}
                className={'interactions'}
                onClick={() => handleInteraction("reaction", "fire")}
                icon={WhatshotIcon}
                iconColor={'warning'}
                text >
                    <Label className={'tabular-nums'} secondary>{reactionsLabel}</Label> 
            </Button>
            <Button
                className={!isMobile ? 'interactions' : ''}
                onClick={() => handleInteraction("comment")}
                icon={ChatBubbleOutlineIcon}
                iconColor={'info'}
                text >
                    {!isMobile && <Label className={'tabular-nums'} secondary>{commentsLabel}</Label>}
            </Button>
            <Button
                className={!isMobile ? 'interactions' : ''}
                onClick={() => copyToClipboard(external_url, setOpen, setSeverity, setMessage)}
                icon={ContentCopyIcon}
                iconColor={'warning'}
                text >
                    {!isMobile &&<Label secondary>{t('literal:share')}</Label>}
            </Button>
        </div>
    </AccordionSummary>
  );
};

Interactions.propTypes = {
    loading: PropTypes.bool,
    handleInteraction: PropTypes.func,
    reactions: PropTypes.array,
    comments: PropTypes.array,
    external_url: PropTypes.string,
};

export default Interactions;