import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Alert = ({Icon, primaryText, secondaryText=null, action=null}) => {
  return (
    <div className="ui-alert">
        <Icon color="info"/>
        <div>
            <p>{primaryText}</p>
            {secondaryText && <p>{secondaryText}</p>}
            {action}
        </div>
    </div>
  );
};

Alert.propTypes = { 
  Icon: PropTypes.object,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  action: PropTypes.node,
};

export default Alert;