import React from 'react';
import { useController } from '@rest-hooks/react';
import { useHistory } from "react-router-dom";

import PublicLayout from 'components/layouts/Public';
import LogInForm from 'components/authForms/LogInForm';

import { termsOfUseLink, privacyPolicyLink } from 'utils/urls';
import UserAccountResource from 'resources/user/UserAccountResource';
import redirectToView from 'utils/redirectToView';

import AuthToken from 'utils/localStorage/AuthToken';
import CoursesList from 'utils/localStorage/CoursesList';

import Link from '@mui/material/Link';

import { t } from 'i18n/index';
import FormSection from 'ui/FormSection';

const Login = () => {
  const { fetch } = useController();
  const history = useHistory();

  const authToken = AuthToken.read();

  const coursesList = CoursesList.read();

  if(authToken) {
    fetch(UserAccountResource.detail(), {id: 1})
    .then(({ role }) => {
      redirectToView(history, role, coursesList);
    })
    .catch(() => {
      // do nothing
    });
  }

  return (
    <PublicLayout isLogin={true} >
        <FormSection slim>
          <div className="form-title hidden">{t('account:login:pageTitle')}</div>

          <LogInForm />
        </FormSection>

        <div className='footer-labels'>
          <p className="label-terms" >
            <Link href={termsOfUseLink}
                  target="_blank"
                  underline="none"
            >
               {t('literal:termsAndConditions')}
            </Link>
          </p>
          <p className="label-privacy">
            <Link href={privacyPolicyLink}
                  target="_blank"
                  underline="none"
            >
              {t('literal:privacyPolicy')}
            </Link>
          </p>
          <p className="label-crowdclass">Membrz Club {new Date().getFullYear()}</p>
        </div>
    </PublicLayout>
  );
};

export default Login;
