import React from 'react';
import PropTypes from 'prop-types';

import Images from './Images';
import CloseButton from "./CloseButton";

import { Backdrop } from "@mui/material";

const BackdropComponent = ({ open, handleClose, objects, selectedIndex }) => {
  return (
    <Backdrop open={open} sx={{ zIndex: 1500 }} className="ui-slider backdrop" fullScreen>
      
      <CloseButton handleClose={handleClose} />
      <Images objects={objects} selectedIndex={selectedIndex} handleClose={handleClose}/>

    </Backdrop>
  );
};

BackdropComponent.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  objects: PropTypes.array,
  selectedIndex: PropTypes.number
};

export default BackdropComponent;