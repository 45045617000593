import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from "@mui/material";

const UniversalTimeSelector = ({event, onEventChange, setUnsavedChanges}) => {

    const {repeat_days, start_time, universal_time = true} = event;

    const handleCheckBox = ({target: {checked}}) => {
        setUnsavedChanges(true);

        let newEvent
        if (checked) {
            const newRepeatDays = repeat_days.map(day => {
                const dayKey = Object.keys(day)[0];
                return { [dayKey]: start_time };
            });
            newEvent = { ...event, universal_time: true, repeat_days: newRepeatDays };
        } else {
            newEvent = { ...event, universal_time: false };
        }

        onEventChange(newEvent);
    };

  return (
    <FormControlLabel
        control={
            <Checkbox
                size="small"
                checked={universal_time}
                onChange={handleCheckBox}
                color="primary"
            />
        }
        style={{ margin: "0px" }}
        label={"Sessions have same start time"}
    />
  );
};

UniversalTimeSelector.propTypes = {
    event: PropTypes.object,
    onEventChange: PropTypes.func,
    setUnsavedChanges: PropTypes.func,
};

export default UniversalTimeSelector;