import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Button from 'ui/Button';
import Subtitle from 'ui/text/Subtitle';

import { t } from "i18n/index";

const BuyButton = ({onAction, loading, disableBuyButton, priceWithCurrency, discountPriceWithCurrency, available, isTicket}) => {

  const priceContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6px'
  };

  const handleRedirect = () => {
    window.open('https://shotgun.live/pt-pt/events/casa-da-gira-15-11', '_blank');
  };

  const {organizationSlug} = useParams();
  const isGira = organizationSlug === 'coletivogira';

  return (
    <>
      {isTicket ?
        <div style={{display: 'flex', flexDirection: 'column', gap: '8px', width: '100%'}}>
          {!isGira &&
            <div style={priceContainerStyle}>
              <Subtitle struck={discountPriceWithCurrency} small>
                {priceWithCurrency}
              </Subtitle>
              {discountPriceWithCurrency && <Subtitle small>{discountPriceWithCurrency}</Subtitle>}
            </div>}

          <Button onClick={ onAction } highlighted fullwidth smallPadded loading={loading} disabled={disableBuyButton}>
            {available ?
              isGira ? t('literal:buyWithMbWay') : t('literal:buyNow')
            :
              t('literal:soldOut')}
          </Button>

          {isGira &&
            <Button onClick={ handleRedirect } secondary fullwidth smallPadded loading={loading} disabled={disableBuyButton}>
              {t('literal:buyWithCard')}
            </Button>}
        </div>
      :
        <Button onClick={ onAction } secondary fullwidth loading={loading} disabled={disableBuyButton}>
          {available ?
          <div className='button-content'>
            <div style={priceContainerStyle}>
              <span style={{textDecoration: discountPriceWithCurrency ? 'line-through' : 'none'}}>
                {priceWithCurrency}
              </span>
              {discountPriceWithCurrency && <span>{discountPriceWithCurrency}</span>}
            </div>
            <span>{t('literal:buyNow')}</span>
          </div>
          :
            <span>{t('literal:soldOut')}</span>
          }
        </Button>
      }
    </>
  );
};

BuyButton.propTypes = {
  onAction: PropTypes.func,
  loading: PropTypes.bool,
  disableBuyButton: PropTypes.bool,
  priceWithCurrency: PropTypes.string,
  discountPriceWithCurrency: PropTypes.string,
  available: PropTypes.bool,
  isTicket: PropTypes.bool
};

export default BuyButton;
