import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useToast } from 'utils/context/ToastContext';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackbar = () => {

  const { open, message, severity, setOpen } = useToast();

  const handleClose = () => {
    setOpen("");
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'right'}} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity={severity} sx={{ width: '100%' }} onClose={handleClose} onClick={handleClose}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  )
}

CustomSnackbar.propTypes = {
  open: PropTypes.bool,
  severity: PropTypes.string,
  message: PropTypes.string,
};

export default CustomSnackbar;