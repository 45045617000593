import AuthenticatedResource from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class SubscriptionResource extends AuthenticatedResource {
  static urlRoot = 'profile/:organization_slug/subscriptions'
  
  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }

}