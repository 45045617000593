import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Image = ({ image, rounded, border, height, className }) => {

  const classes = ['ui-image']
  if (rounded) classes.push('rounded')
  if (border) classes.push('border')
  if (className) classes.push(className)

  return (
    <div className={classes.join(' ')}
         style={{backgroundImage: `url(${image})`,
                 height: height,
                 width: rounded ? height : "100%"}} 
         />
  )
};

Image.propTypes = {
    image: PropTypes.string,
    rounded: PropTypes.bool,
    border: PropTypes.bool,
    height: PropTypes.string,
    className: PropTypes.string
};

export default Image;