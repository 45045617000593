import { convertPriceToCents } from "utils/rewards";

export  const createFormData = (offer, isDisabled) => {

    const { coupons, redeem_limit, supply_limit, end_date, title, description, points, slug, content_text, content_text_link, extended_description, category, new_promo_codes, existing_promo_codes, status, show_end_date, show_supply, visible, tag_ids, reward_ids, notification_list, unit_price, currency, image_files, event_id} = offer;

    const isArray = Array.isArray(notification_list);
    const emailsArray = isArray ? notification_list : notification_list.replace(/\s+/g, '').split(',');
    
    const existing_promo_codes_ids = existing_promo_codes && existing_promo_codes.map(item => item.id);    

    const formData = new FormData();
    const fields = [
        { key: 'title', value: title },
        { key: 'points', value: points ? points : 0 },
        { key: 'status', value: status },
        { key: 'show_end_date', value: show_end_date },
        { key: 'show_supply', value: show_supply },
        { key: 'visible', value: visible },
        { key: 'currency', value: currency },
        { key: "category", value: category },
        { key: "coupons", value: JSON.stringify(coupons) },
        { key: "tag_ids", value: JSON.stringify(tag_ids) },
        { key: "notification_list", value: JSON.stringify(emailsArray) },
        { key: "new_promo_codes", value: JSON.stringify(new_promo_codes) },
        { key: "existing_promo_codes", value: JSON.stringify(existing_promo_codes_ids) },
    ]

    if (end_date) {
        fields.push({ key: "end_date", value: end_date });
    }

    if (description) {
        fields.push({ key: 'description', value: description });
    }

    if (extended_description) {
        fields.push({ key: 'extended_description', value: extended_description });
    }

    if (slug) {
        fields.push({ key: "slug", value: slug });
    }

    if (event_id) {
        fields.push({ key: 'event_id', value: event_id });
    }

    if (content_text) {
        fields.push({ key: 'text', value: content_text });
    }

    if (content_text_link) {
        fields.push({ key: 'link', value: content_text_link });
    }

    if (supply_limit) {
        fields.push({ key: 'supply_limit', value: supply_limit });
    }

    if (redeem_limit) {
        fields.push({ key: 'redeem_limit', value: redeem_limit });
    }

    if (unit_price) {
        fields.push({ key: 'price', value: convertPriceToCents(unit_price) });
    }

    if (!isDisabled){
        fields.push(
            { key: 'reward_ids', value: reward_ids },
        )
    }

    if (image_files && image_files.length > 0) {
        image_files.forEach((file) => {
            formData.append("images[]", file);
        });
    }

    fields.forEach(field => {
        formData.append(field.key, field.value);
    });

    return formData;
};
