const handleValidation = (post) => {
    const { slug, body, reward_ids } = post;
    validateBody(body)
    validateSlug(slug)
    validateViewingCriteria(reward_ids)
};

const validateBody = (body) => {
    if (body.trim() === "") {
        throw "Content must not be empty.";
    }
};

const validateSlug = (slug) => {
    const regex = /^[a-z0-9-]{2,48}$/;
    if (slug && !regex.test(slug)) {
        throw "Url slug must be 2-48 characters long, lowercase and contain only letters, numbers, and hyphens that you can use to separate words.";
    }
};

const validateViewingCriteria = (reward_ids) => {
    if (!reward_ids || reward_ids.length === 0) {
        throw "Please specify in Viewing Criteria, who you would like to be able to see this post";
    }
};

    
export default handleValidation;