import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from 'react-router-dom';

import OrderResource from 'resources/user/OrderResource';
import UserAccountResource from 'resources/user/UserAccountResource';

import FormSection from 'ui/FormSection';
import Button from 'ui/Button';
import { useError } from 'utils/useErrorController';
import { validateEmail } from 'utils/validations/validateEmail';

import { t } from "i18n/index";
import { TextField } from '@mui/material';

const TicketDetails = ({setStep, handleClaimReset, paymentIntentId, amount}) => {
    
    const {email} = useSuspense(UserAccountResource.detail(), {id: 1});

    const {fetch} = useController();
    const {organizationSlug} = useParams();
    const history = useHistory()
    const [emails, setEmails] = useState(Array.from({ length: amount }, (_, index) => (index === 0 ? email : undefined)));
    const {handleError, setLoading, loading} = useError();

    const handleRedirect = () => {
      handleClaimReset();
      history.push(`/${organizationSlug}`);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            emails.forEach((email, index) => {
                validateEmail(email, '', `${t('errors:invalidTicketEmail')} ${index+1}`);
            });
            await fetch(OrderResource.update(), {id: paymentIntentId} , {organization_slug: organizationSlug, emails});
            setStep(4);
            setLoading(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleEmailChange = (index, event) => {
        const newEmails = [...emails];
        newEmails[index] = event.target.value;
        setEmails(newEmails);
    };

    const renderTextField = (label, name, value, index) => {
        return (
            <TextField label={label}
                        name={name}
                        value={value || ''}
                        onChange={(event) => handleEmailChange(index, event)}
                        fullWidth
                        size='small'
                        color="warning">
            </TextField>
        );
    };

    const textfields = Array.from({length: amount}, (_, i) =>
        renderTextField(`Ticket ${i+1} email`, `email ${i+1}`, emails[i] || '', i));

    return (
    <div className='payment-processed'>

        <FormSection title={t('literal:ticketDetails')} slim>
            {textfields}
        </FormSection>
        
        <div className='button-group'>

            <Button onClick={handleRedirect} fullwidth secondary loading={loading}>{t('literal:close')}</Button>

            <Button onClick={handleSubmit} fullwidth loading={loading}>{t('literal:submit')}</Button>

        </div>  
    </div>
  );
};

TicketDetails.propTypes = {
  setStep: PropTypes.func,
  handleClaimReset: PropTypes.func,
  paymentIntentId: PropTypes.string,
  amount: PropTypes.number
};

export default TicketDetails;