import React from 'react'
import PropTypes from 'prop-types'

const Video = ({content}) => {
    return (
        <div className='video-container'>
            <iframe
                src={`https://www.youtube.com/embed/${content}`}
                width="560" 
                height="315"
                allowFullScreen
            ></iframe>
        </div>
    );
};

Video.propTypes = {
  content: PropTypes.string
};

export default Video;