import React from 'react';

import { DialogTitle } from '@mui/material';

import { t } from 'i18n/index';

const Title = () => {
  return (
    <DialogTitle>{t('literal:claimWithNFT')}</DialogTitle>
  );
};

export default Title;