import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import PublicLayout from 'components/layouts/Public'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import PasswordResetResource from 'resources/user/PasswordResetResource';
import StatusModal from 'components/StatusModal';
import { t } from 'i18n/index';

const forgotPassword = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const { fetch } = useController();

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
    setLoading(false);
  };

  const handleValidation = (e) => {
    if (e.target.email.value === ""){
      setError(t('account:forgotPassword:errorEmailMissing'));
      handleOpenErrorModal();
      throw '';
    }
  };

  const handleLogin = useCallback(
    async e => {
      e.preventDefault();
      handleValidation(e);
      try {
        setLoading(true);
        await fetch(PasswordResetResource.create(), new FormData(e.target));
        setStep(2);
      } catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }
    },
    [fetch],
  );

  return (
    <PublicLayout isLogin={true} >
        <div className="form-title">{t('account:common:forgotPasswordAndResetPasswordPageTitle')}</div> 
        <p>{t('account:common:forgotPasswordAndResetPasswordPageDescription')}</p>

        { step === 1 &&
        <form onSubmit={ handleLogin } >
          <TextField 
            label="Email" 
            name="email" 
            color="warning" 
            fullWidth 
            margin="normal">
          </TextField>
          <Button type='submit'
                  disabled={loading}
                  fullWidth
                  variant="contained"
                  color="primary">
            {loading
              ? t('literal:sending')
              : t('account:forgotPassword:requestInstructions')
            }
          </Button>
        </form>
        }

        { step === 2 &&
          <p>{t('account:forgotPassword:resetInstructions')}</p>
        }

        { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </PublicLayout>
  );
};

forgotPassword.propTypes = {
  history: PropTypes.object,
}

export default forgotPassword;
