import React from 'react';
import PropTypes from 'prop-types';

import Description from 'ui/text/Description';

import { Viewer } from '@toast-ui/react-editor';

const EmailReviewContent = ({content, subject}) => {
  return (
    <div className='email-viewer'>
      <Description bold>{subject}</Description>
      <div className='content'>
          <Viewer
              initialValue={content}
              extendedAutolinks={true}
              linkAttributes={{
                target: '_blank',
                rel: 'noopener noreferrer',
              }}
          />
      </div>
    </div>
  );
};

EmailReviewContent.propTypes = {
  content: PropTypes.string,
  subject: PropTypes.string
};

export default EmailReviewContent;