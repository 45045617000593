import React from 'react';

import shortLogo from 'assets/images/logo.png';

const ShortLogo = () => {
  return (
    <img src={shortLogo} className='mobile-logo' alt="Membrz.Club"/>
  );
};

export default ShortLogo;