import React from 'react';
import PropTypes from 'prop-types';

import OrderCard from 'components/Cards/OrderCard';

const OrdersGrid = ({ data, hideDetails }) => {
    
    return (
        <div className='orders-grid'>
            {data.map((item, index) => {
            return <OrderCard item={ item } 
                              key={ index } 
                              hideDetails={ hideDetails }
                              />
            })}
        </div>
    );
};

OrdersGrid.propTypes = {
    data:PropTypes.array,
    hideDetails: PropTypes.bool,
};

export default OrdersGrid;
