import AuthToken from './localStorage/AuthToken';

const decodeJWT = (token) => {
    try {
        //https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
        //the following approach is based on the examples in the thread above
        
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(window.atob(base64));    
    } catch (error) {
        console.error('Error decoding JWT:', error);
        return null;    
    }
};

export const checkLoginStatus = () => {
    const authToken = AuthToken.read();
    if (!authToken) return false;

    const decodedToken = decodeJWT(authToken);
    if (!decodedToken) return false;

    const currentTime = Date.now() / 1000;
    return decodedToken.exp && decodedToken.exp > currentTime;
};