export const createFormData = (pageElement) => {
    const formData = new FormData();
    const fields = [
        { key: 'element_type', value: pageElement.element_type },
        { key: 'content', value: pageElement.content },
        { key: 'reward_ids', value: pageElement.reward_ids },
        { key: 'link_title', value: pageElement.link_title },
    ]
    
    if (pageElement.file) {
        fields.push({ key: 'image', value: pageElement.file });
    }

    fields.forEach(field => {
        formData.append(field.key, field.value);
    });
    
    return formData;
};