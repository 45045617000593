import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class SearchOrderInstanceResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/search_order_instance' as const;

  readonly id: number | undefined = undefined;
  readonly order_id: number | undefined = undefined;
  readonly order_id_main: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}
