import React from 'react';

import { Checkbox } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNew from '@mui/icons-material/OpenInNew';

const handleClick = (organization, handleActiveOrg, handleOpenEditModal) => {
  handleActiveOrg(organization);
  handleOpenEditModal(true);
};

/* eslint-disable react/prop-types */
const EditButton = ({ organization, handleActiveOrg, handleOpenEditModal }) => (
  <IconButton onClick={() => handleClick(organization, handleActiveOrg, handleOpenEditModal)}>
    <EditIcon fontSize='small'/>
  </IconButton>
);

export const organizationColumns = (handleActiveOrg, handleOpenEditModal) => [
  {
    name: 'ID',
    selector: (row) => row.id,
    sortable: true,
    width: '10%'
  },
  {
    name: 'Title',
    selector : (row) => row.title,
    sortable: true,
    width: '17.5%',
    editable: true
  },
  {
    name: 'Status',
    selector : (row) => row.status,
    sortable: true,
    width: '17.5%',
    editable: true
  },
  {
    name: 'Minting',
    selector : (row) => <Checkbox disabled checked={row.hasMinting} />,
    sortable: false,
    width: '10%'
  },
  {
    name: 'Whitelabel',
    selector : (row) => <Checkbox disabled checked={row.hasWhitelabel} />,
    sortable: false,
    width: '10%'
  },
  {
    name: 'Sales',
    selector : (row) => <Checkbox disabled checked={row.hasSales} />,
    sortable: false,
    width: '10%'
  },
  {
    name: 'Offers',
    selector : (row) => <Checkbox disabled checked={row.hasProducts} />,
    sortable: false,
    width: '10%'
  },
  {
    name: 'Surprise NFT',
    selector : (row) => <Checkbox disabled checked={row.hasMysterybox} />,
    sortable: false,
    width: '10%'
  },
  {
    selector : (row) => <IconButton onClick={ () => window.location.href = '/organizations/' + row.id }><OpenInNew fontSize='small'/></IconButton>,
    sortable: false,
    width: '10%'
  },
  {
    selector : (row) => <EditButton organization={row} handleActiveOrg={handleActiveOrg} handleOpenEditModal={handleOpenEditModal} />,
    sortable: false,
    width: '10%'
  }
];
