import React  from 'react';
import { useSuspense } from '@rest-hooks/react';

import OrdersGrid from 'components/CardGrids/OrdersGrid';
import Layout from 'components/layouts/index';
import TabPanels from 'components/TabPanels';

import OrderResource from 'resources/user/OrderResource';

import { t } from 'i18n/index';

const pageInfo = {
    name: t('student:orders:pageTitle'),
    description: t('student:orders:pageDescription'),
  };

const Orders = () => {

    const orders = useSuspense(OrderResource.list());
    const completedOrders = orders.filter( order => order.status === "completed" )
    const cancelledOrders = orders.filter( order => order.status === "cancelled" )

    const labels = [ `${t('literal:completed')}`]
    cancelledOrders.length > 0 && labels.push(t('literal:cancelled'))

    return (
        <Layout context='student'
                activeMenuItem="orders"
                pageInfo={ pageInfo } >

            <TabPanels labels={labels}>

                    {completedOrders.length > 0 ?
                        <OrdersGrid data={ completedOrders } hideDetails={ false }/> :
                        <p>{t('student:orders:noneFoundMessage')}</p>
                    }

                    <OrdersGrid data={ cancelledOrders } hideDetails={ true }/>

            </TabPanels>
        </Layout>
    );
};

export default Orders;
