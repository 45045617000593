const maxSize = {
    image: 4,
    video: 20,
  };  

const maxCount = {
    post : {
      image: 20,
      video: 1,
    },
    product : {
      image: 20,
    }
  };
  

export const validateFileSize = (file, type, handleError) => {
    if (file.size > maxSize[type] * 1024 * 1024) {
      handleError(`${file.name} is too big. Please upload ${type}s smaller than ${maxSize[type]} MB.`);
      return false;
    }
    return true;
  };


export const validateFileCount = (object, files, type, handleError, objectType) => {
    const filesCount = object[`${type}_files`]?.length + files.length ;
    if (filesCount > maxCount[objectType][type]) {
      handleError(`You can upload up to ${maxCount[objectType].image} images and 1 video per ${objectType}.`);
      return false;
    }
    return true;
  };