import React from 'react'
import PropTypes from 'prop-types'

import QRCode from 'qrcode.react';

const QRCodeGenerator = ({url, image_url}) => {

    const imageSettings = (
        image_url ? {
          src: image_url,
          excavate: true,
          width: 100,
          height: 100,
          padding: 10,
        } : null
    );

  return (
    <div style={{textAlign: 'center'}}>
      <QRCode id='qrCodeId'
              size={300}
              value={url}
              bgColor='white'
              fgColor='black'
              level='H'
              includeMargin={true}
              imageSettings={imageSettings}
        />
    </div>
  );
};

QRCodeGenerator.propTypes = {
  url: PropTypes.string,
  image_url: PropTypes.string
};

export default QRCodeGenerator;
