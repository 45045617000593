import React from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';
import Label from 'ui/text/Label';

const SupplyLimit = ({ supply_limit, onChange, published, mysteryBoxReward, mysteryBox }) => {
  return (
    <>
      <TextField
        fullWidth
        size='small'
        name="supply_limit"
        label={`Total ${mysteryBoxReward ? "collection" : ""} stock${mysteryBox ? " *" : ""}`}
        color="warning"
        value={supply_limit}
        type="number"
        onChange={onChange}
        disabled={published}
        inputProps={{ inputMode: 'numeric'}}
        />
        <Label secondary padded>Leave empty for unlimited</Label>
    </>
    
  );
};

SupplyLimit.propTypes = {
  supply_limit: PropTypes.number,
  onChange: PropTypes.func,
  published: PropTypes.bool,
  mysteryBoxReward: PropTypes.bool,
  mysteryBox: PropTypes.bool,
};

export default SupplyLimit;