import React from 'react';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import Button from 'ui/Button';

const LoadingComponent = () => {
  return (
    <Stack spacing={2}>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" height={100} />
      </Stack>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" height={220} />
      </Stack>
      <Button
          loading
          highlighted
          fullwidth
        >
        </Button>
    </Stack>
  );
};

export default LoadingComponent;