import React from 'react';

import Layout from 'components/layouts';

import Form from './form'

const pageInfo = {
  name: "Create new product"
};

const NewOffer = () => {

    const searchParams = new URLSearchParams(window.location.search);
    const eventId = searchParams.get('eventId');

    pageInfo.name = `Create new ${eventId ? "ticket" : "product"}`
    
    return (
        <Layout context='teacher'
                back={ true }
                pageInfo={ pageInfo }
                activeMenuItem='perks'>

          <Form />

        </Layout>
    );
};

export default NewOffer;
