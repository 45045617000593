import React from 'react';
import PropTypes from 'prop-types';

const AttachedImage = ({imageUrl, last, onClick}) => {
  return (
    <img
        className={'image' + (last ? ' last' : '')}
        src={imageUrl}
        onClick={onClick}
    />
  );
};

AttachedImage.propTypes = {
    imageUrl: PropTypes.string,
    last: PropTypes.bool,
    onClick: PropTypes.func,
};

export default AttachedImage;