import React from 'react';
import { useController, useSuspense } from '@rest-hooks/react';

import ConnectedAccountsResource from 'resources/user/ConnectedAccountsResource';

import ConnectWalletButton from 'components/authForms/WalletConnect/ConnectWalletButton';
import TableButton from 'components/TableButton';

import ConnectButton from './ConnectButton';

import whatsapp_logo from 'assets/images/social_elements_logos/whatsapp_logo.png';
import telegram_logo from 'assets/images/social_elements_logos/telegram_logo.png';
import ethereum_logo from 'assets/images/social_elements_logos/ethereum_logo.png';

import { useError } from 'utils/useErrorController';

import './style.css';

import DeleteIcon from '@mui/icons-material/Delete';

import { t } from 'i18n/index';

const socialProviders = [
    { name: 'WhatsApp', provider: 'whatsapp', icon: whatsapp_logo },
    { name: 'Telegram', provider: 'telegram', icon: telegram_logo, prefix: '@' },
    { name: 'Ethereum / Polygon Wallet', provider: 'ethereum', icon: ethereum_logo },
];


const ConnectedAccounts = () => {

    const {setLoading, loading, handleError} = useError();
    const {fetch} = useController();

    const connectedAccounts = useSuspense(ConnectedAccountsResource.list());

    const handleWalletConnect = async ({wallet_address}) => {
        try {
            setLoading(true);

            await fetch(ConnectedAccountsResource.update(), {id: 'ethereum'}, { username: wallet_address });
            await fetch(ConnectedAccountsResource.list());
    
            setLoading(false);
        } catch (error) {
            handleError(error);
        }
    };

    const handleDelete = (provider) => async () => {
        try {
            setLoading(true);

            await fetch(ConnectedAccountsResource.delete(), {id: provider});
            await fetch(ConnectedAccountsResource.list());

            setLoading(false);
        } catch (error) {
            handleError(error);
        }   
    };

    return (
        <div className="connected-accounts-container">
            { socialProviders.map(({name, icon, provider, prefix=''}) => {
                const account = connectedAccounts.find(account => account.id === provider);
                return (
                    <div className="connected-account grid" key={provider}>
                        <img src={icon} alt={name} />
                        <div> { name }</div>
                        { account ?
                            <div className="username">
                                { prefix + account.username }
                                { provider === 'whatsapp' && <TableButton Icon={DeleteIcon} action={handleDelete(provider)} title={t('literal:delete')} /> } 
                            </div> :
                                provider === 'ethereum' 
                                    ? <ConnectWalletButton challengeType={ 'connect' } done={ handleWalletConnect } loading={loading} />
                                    : <ConnectButton provider={provider} loading={loading} />
                        }
                    </div>
                );
                })
            }
        </div>
    );
};

export default ConnectedAccounts;
