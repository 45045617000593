import { t } from "i18n/index";

import { validateEmail } from "utils/validations/validateEmail";

const validatePasswordPresence = (password) => {
  if (
    password === undefined ||
    password === null ||
    password.trim() === ""
  ) {
    throw t("errors:passwordMissing");
  }
};

const loginValidation = (data) => {
  const {email, password} = data;
  validateEmail(email, null, t('errors:emailMissing'));
  validatePasswordPresence(password);
};

export default loginValidation;