import React from "react";
import mbwayApp from "assets/images/mbwayApp.png";

import { t } from "i18n/index";

const EupagoPending = () => {

  return (
    <div className="payment-method-mbway-app" >
      <img src={mbwayApp} className='image' />
      <p>{t('profile:memberships:claimModal:processingEupago')}</p>
    </div>
  );
};

export default EupagoPending;
