import React from 'react';
import PropTypes from 'prop-types';

import Title from 'ui/text/Title';

const PostTitle = ({title}) => {

    if (!title) return null;
    
  return (
      <div className='title'>
          <Title>{title}</Title>
      </div>
  )
};

PostTitle.propTypes = {
    title: PropTypes.string
};

export default PostTitle;