import React from 'react';
import PropTypes from 'prop-types';

const StatCard = ({type, color, highlight, title}) => (
  <div className="stat-card card hidden" >
    { type === 'number' &&
      <div className="stats-number"  style={ { color } }>
        { highlight }
      </div>
    }
    { type === 'chart' &&
      <div className="canvas-wrapper" >
        { highlight }
      </div>
    }
    <div className="title" >
      { title }
    </div>
  </div>
);

StatCard.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  highlight: PropTypes.oneOfType([
              PropTypes.node,
              PropTypes.number
            ]),
  title: PropTypes.string
};

export default StatCard;