import React, { useMemo, useState } from "react";
import { useSuspense } from "@rest-hooks/react";
import { useHistory, useParams } from "react-router-dom";

import Layout from 'components/layouts';
import TicketsList from "components/TicketsList";

import TicketResource from "resources/organization/TicketResource";
import EventResource from "resources/organization/EventResource";
import SessionSelector from "components/SessionSelector";

const Tickets = () => {    
  
  const history = useHistory();
  const {organizationId, eventId, sessionId} = useParams();
  const [selectedSessionId, setSelectedSessionId] = useState(sessionId);

  const requestParams = useMemo(() => {
    const params = { organization_id: organizationId, event_id: eventId };
    if (selectedSessionId) {
      params.session_id = selectedSessionId;
    }
    return params;
  }, [organizationId, eventId, selectedSessionId]);

  //fetch event details to get ticket titles, event title, external_url and organization_logo_url
  const event = useSuspense(EventResource.detail(), { organization_id: organizationId, id: eventId });

  const pageInfo = useMemo(
    () => ({
      name: event?.title || "Tickets",
      description: "Manage your tickets and check-ins here",
    }),
    [event]
  );

  const handleSessionChange = ({target: {value}}) => {
    setSelectedSessionId(value);
    history.push(`/organizations/${organizationId}/events/${eventId}/tickets/${value}`);
  };
      
  return (
    <Layout context="teacher" activeMenuItem="events" pageInfo={pageInfo}>
      {event.single || selectedSessionId ? (  
        <TicketsList TicketResource={TicketResource} ticketResourceParams={requestParams} event={event} sessionId={selectedSessionId}/>
      ) : (
        <SessionSelector options={event.sessions} onChange={handleSessionChange} />
      )}
    </Layout>
  );
};

export default Tickets;