import { useController } from '@rest-hooks/react';

import ElementStatsResource from 'resources/Profile/ElementStatsResource';

function useElementStatsController(organizationSlug) {

  const {fetch} = useController();

  const createStat = async (id) => {
    try {
      await fetch(ElementStatsResource.create(), {organization_slug: organizationSlug}, {id: id});
    } catch (error) {
      console.log(error);
    }
  };

  return {createStat};
}

export default useElementStatsController;
