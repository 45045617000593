import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSuspense } from "@rest-hooks/react";

import EventResource from "resources/Profile/EventResource";

import Event from "ui/Modules/Event";
import Button from "ui/Button";

import { t } from "i18n/index";

const Calendar = () => {

    const {organizationSlug} = useParams();
    const events = useSuspense(EventResource.list(), {organization_slug: organizationSlug});
    const showViewAll = events.length > 0 && events[0].show_view_all;

    const history = useHistory();
    const handleRedirect = () => {
      history.push(`/${organizationSlug}/events`);
    };

  return (
    <div className="calendar">
      {events.map((event, index) => (
        <Event key={index} event={event} />
      ))}

      {showViewAll &&
        <Button minwidth secondary onClick={handleRedirect}>{t('literal:viewAll')}</Button>
      }
    </div>
  );
};


export default Calendar;
