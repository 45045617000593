import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class OfferInstanceResource extends AuthenticatedResource {
  static urlRoot = '/offer_instances' as const;

  readonly id: number | undefined = undefined;


  pk() {
    return this.id?.toString();
  }
}
