import React from 'react';
import PropTypes from 'prop-types';
import Label from 'ui/text/Label';
import QRCodeGenerator from 'components/QRCodeGenerator';

const Image = ({reference}) => {
  return (
    <div className={"fullwidth-card-image-container"}>
        <QRCodeGenerator url={reference} />
        <Label>{reference}</Label>
      </div>
  );
};

Image.propTypes = {
    reference: PropTypes.string,
};

export default Image;