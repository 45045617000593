import React from 'react';
import PropTypes from 'prop-types';

import CountrySelector from 'components/AddressForm/CountrySelector';

import TextField from '@mui/material/TextField';

import { t } from 'i18n/index';
import FormSection from 'ui/FormSection';

const AddressForm = ({ onChange, data, onDataChange, end_user = false, disabled = false }) => {

    const { address, city, post_code, country } = data;

    const addressLabel = end_user ? t('literal:address') : 'Address *';
    const cityLabel = end_user ? t('literal:city') : 'City *';
    const postCodeLabel = end_user ? `${t('literal:postCode')} ${(country === "US" || country === "CA") ? "*" : ""}` : 'Post Code *';

    const textFieldsContent = {
        address : {
            label: addressLabel,
            name: 'address',
            value: address,
            onChange: onChange,
        },
        city : {
            label: cityLabel,
            name: 'city',
            value: city,
            onChange: onChange,
        },
        post_code : {
            label: postCodeLabel,
            name: 'post_code',
            value: post_code,
            onChange: onChange,
        }
    };

    const renderTextField = ({label, name, value, onChange}) => {
        return (
            <TextField 
                label={label}
                name={name}
                value={value || ''}
                onChange={onChange}
                fullWidth
                size={"small"}
                color="warning"
                disabled={disabled} >
            </TextField>
        );
    };


  return (
    <FormSection title={end_user ? t('literal:address') : 'Company Address'} slim={end_user}>
            {renderTextField(textFieldsContent["address"])}

        <div className='row'>
            {renderTextField(textFieldsContent["post_code"])}
            {renderTextField(textFieldsContent["city"])}
        </div>

        <CountrySelector data={data} onDataChange={onDataChange} end_user={end_user} disabled={disabled}/>
    </FormSection> 
  );
};

AddressForm.propTypes = {
    onChange: PropTypes.func,
    end_user: PropTypes.bool,
    data: PropTypes.object,
    onDataChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default AddressForm;