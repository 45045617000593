import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import StatsResource from 'resources/organization/StatsResource';

import Subtitle from 'ui/text/Subtitle';

import { useError } from 'utils/useErrorController';

import LineChart from './LineChart';
import HorizontalBarChart from './HorizontalBarChart';
import Overview from './Overview';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement } from "chart.js";

import { Skeleton } from '@mui/material';

const StatsTabContent = ({ organizationId}) => {

  const [data, setData] = useState(null);

  const { handleError } = useError();

  const { fetch } = useController();

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement);

  const fetchData = async () => {
      const response = await fetch(StatsResource.detail(), {organization_id: organizationId});

      if (response.error) {
        handleError(response.error);

      } else {
        setData(response);
      }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      { data
      ? <>
          <Overview data={data.aggregate} />

          <Subtitle>Daily Visitors</Subtitle>
          <LineChart data={data.timeseries} labelKey="date" dataKey="visitors" />

          <Subtitle>Top Pages</Subtitle>
          <HorizontalBarChart data={data['event:page']} labelKey="page" dataKey="visitors" />

          <Subtitle>Top Sources</Subtitle>
          <HorizontalBarChart data={data['visit:source']} labelKey="source" dataKey="visitors" />

          <Subtitle>Top Countries</Subtitle>
          <HorizontalBarChart data={data['visit:country']} labelKey="country" dataKey="visitors" />

          {/* <Subtitle>Top Regions</Subtitle>
          <HorizontalBarChart data={data['visit:region']} labelKey="region" dataKey="visitors" />

          <Subtitle>Top Cities</Subtitle>
          <HorizontalBarChart data={data['visit:city']} labelKey="city" dataKey="visitors" /> */}
        </>
      : <Skeleton variant="rectangular" width={'100%'} height={500} animation={false}/> }
    </>
  
  );
};

StatsTabContent.propTypes = {
    organizationId: PropTypes.number
};

export default StatsTabContent;