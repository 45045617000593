import React from 'react';
import PropTypes from 'prop-types';

import Image from './Image';
import Content from './Content';

import './styles.css';
import MediaGallery from 'ui/MediaGallery';

const Card = ({ media_type, video_url, image_url, image_urls, description, fullwidth, statusBar, header, footer, cropped, onClick }) => {

  const classes = ['ui-card']
  if (fullwidth) classes.push('fullwidth')

  const contentClasses = ['content']
  if (!statusBar) contentClasses.push('even-border-radius')

  return (
    <div className={classes.join(' ')}>
      {statusBar}

      <div className={contentClasses.join(' ')}>
        {image_url &&
          <Image
            cropped={cropped}
            image_url={image_url}
            video_url={video_url}
            media_type={media_type}
            onClick={onClick} />}

        {image_urls &&
          <MediaGallery
            image_urls={image_urls}
            view={'product'} />}

        <div className='details'>
          {header}

          <Content description={description} />

          {footer}
        </div>
      </div>
    </div>
)};

Card.propTypes = {
  media_type: PropTypes.string,
  video_url: PropTypes.string,
  image_url: PropTypes.string,
  image_urls: PropTypes.array,
  description: PropTypes.string,
  fullwidth: PropTypes.bool,
  statusBar: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  cropped: PropTypes.bool,
  onClick: PropTypes.string
};

export default Card;
