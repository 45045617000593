import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import { linkSelectorButtons } from 'utils/pageElementsHelper';
import EventIcon from '@mui/icons-material/Event';

const LinkSelector = ({element_type, onChange, orgHasCalendar}) => {

  const handleClick = (e, value) => {
    e.preventDefault();
    onChange({target: {name: "element_type", value}});
  };

  return (
    <>
      <div className='row'>
        {linkSelectorButtons.map(button => (
          <Button
            key={button.type}
            icon={button.icon}
            fullwidth
            highlighted={button.highlightedCondition(element_type)}
            secondary={button.secondaryCondition(element_type)}
            onClick={(e) => handleClick(e, button.type)}
          >
            {button.label}
          </Button>
        ))}
      </div>
      { !orgHasCalendar && 
        <Button
          icon={EventIcon}
          fullwidth
          highlighted={element_type === 'calendar'}
          secondary={element_type !== 'calendar'}
          onClick={(e) => handleClick(e, 'calendar')}
        >
          Add Event Calendar
        </Button>}
    </>
  );
};

LinkSelector.propTypes = {
  element_type: PropTypes.string,
  onChange: PropTypes.func,
  orgHasCalendar: PropTypes.bool
};

export default LinkSelector;