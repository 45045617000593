import React from 'react'
import PropTypes from 'prop-types'

import LocationOnIcon from '@mui/icons-material/LocationOn';
import Description from 'ui/text/Description';

const Location = ({ location }) => {
  return (
    <div style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
      <LocationOnIcon color="warning" fontSize='14px'/>
      <Description small>{location}</Description>
    </div>
  );
};

Location.propTypes = {
  location: PropTypes.string
};

export default Location;