import React from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import ConnectedAccountsResource from 'resources/user/ConnectedAccountsResource';

import Button from 'ui/Button';

import { useError } from 'utils/useErrorController';

import { Dialog, DialogTitle, DialogContent, DialogActions, Alert, TextField } from '@mui/material';

import { t } from 'i18n/index';

const Provider = ({ open, onClose, onSuccess, provider, showJoin=false, }) => {

  const { fetch } = useController();
  const [username, setUsername] = React.useState('');
  const {loading, setLoading, handleError} = useError();

  const handleSave = async () => {
    if (!username) {
      return;
    }

    try {
        setLoading(true);
        await fetch(ConnectedAccountsResource.update(), {id: provider}, { username: username });
        await fetch(ConnectedAccountsResource.list());

        setLoading(false);
        if(onSuccess) { onSuccess(); }
        onClose();

    } catch (error) {
        setLoading(false);
        handleError(error);
    }
 };

  return (
    <Dialog open={ open }
            onClose={ onClose }>

      <DialogTitle>
          { t(`student:connectAccountModal:title:${provider}`) }
      </DialogTitle>

      <DialogContent dividers>

        <Alert severity="info">
          { showJoin && 
            <>
              {t('student:connectAccountModal:showJoin') + " "}
              <br />
            </> }
          { t(`student:connectAccountModal:description:${provider}`)  }
        </Alert>

        <TextField
          label={ t(`student:connectAccountModal:inputLabel:${provider}`) }
          color="warning"
          fullWidth
          margin="normal"
          value={username}
          onChange={( e ) => setUsername(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button secondary onClick={onClose} minwidth> {t('literal:cancel')} </Button>
        <Button loading={loading} onClick={ handleSave } minwidth={!showJoin}>
        {t('literal:save')} { showJoin && `& ${t('literal:joinNow')}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Provider.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  provider: PropTypes.string,
  showJoin: PropTypes.bool,
};

export default Provider;
