import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import SaveIcon from '@mui/icons-material/Save';

import { t } from 'i18n/index';

const NewComment = ({comments, loading, onCommentChange, OnCommentCreate}) => {
  return (
    <div key={comments.length + 1} className='new-comment'>
        <textarea
            className='text-area' 
            placeholder={t('ui:postCard:commentPlaceholder')} 
            rows="3" 
            onChange={(e) => onCommentChange(e.target.value)} >
        </textarea>

        <Button 
            loading={loading} 
            icon={SaveIcon} 
            text 
            onClick={OnCommentCreate}>
        </Button>
    </div>
  );
};

NewComment.propTypes = {
    comments: PropTypes.array,
    loading: PropTypes.bool,
    onCommentChange: PropTypes.func,
    OnCommentCreate: PropTypes.func,
};

export default NewComment;