import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ActionCard = ({ to, onClick, Icon, title, description }) => {
  return (
    <Link to={ to ? to : "#" }
          onClick={ onClick ? onClick : null } >
      <div className={`card action-card`}>
        <Icon fontSize='large' />
        <h3>{ title }</h3>
        <p className='action-card-description'>{ description }</p>
      </div>
    </Link>
)};

ActionCard.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  Icon: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ActionCard;