import ApiResource from "../ApiResource";

export default class UnsubscribeResource extends ApiResource {
    static urlRoot = '/unsubscribe/:uuid' as const;

    readonly id: number | undefined = undefined;

    pk() {
        return this.id?.toString();
    }
}