import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useSuspense } from '@rest-hooks/react';

import PayoutResource from 'resources/organization/PayoutResource';

import PayoutGenerator from './PayoutGenerator';
import RequestConfirmedModal from './RequestConfirmedModal';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import WithPermission from 'components/WithPermission';
import SalesFallback from 'components/fallbacks/Sales';

import { payoutColumns } from 'utils/tables/columns/payoutColumns';
import { useError } from 'utils/useErrorController';

const StripePayoutsTabContent = ({ organizationId }) => {

  const [open, setOpen] = useState(false);

  const payouts = useSuspense(PayoutResource.list(), {organization_id: organizationId});
  
  const {fetch} = useController();
  const {handleError} = useError();

  const handleSelect = async (event) => {
    try {
      await fetch(PayoutResource.create(), { organization_id: organizationId}, {start_date: event.target.value })
      setOpen(true);
    } catch (error) {
      handleError(error)
    }
  };

  return (
    <>
      <WithPermission permission={'hasSales'} fallback={<SalesFallback />}>
        <div style={{ flexGrow: 1 }}>
            <DataSelectionTable records={payouts}
                                columns={payoutColumns}
                                filter={
                                  <PayoutGenerator
                                    onSelect={handleSelect}
                                  />
                                }
            />
        </div>
      </WithPermission>
      <RequestConfirmedModal open={open} onClose={() => setOpen(false)}/>
    </>
)};

StripePayoutsTabContent.propTypes = {
  organizationId: PropTypes.number,
};

export default StripePayoutsTabContent;
