import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class MembershipResource extends AuthenticatedResource {
  static urlRoot = 'profile/:organization_slug/memberships' as const;

  readonly id: string | number | undefined = undefined;
  readonly organization_title: string = '';
  readonly title: string = '';
  readonly media_type: string = '';
  readonly image_url: string = '';
  readonly video_url: string = '';
  readonly claim_token: string = "";

  pk() {
    return this.id?.toString();
  }
}
