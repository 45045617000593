import React from 'react';
import ErrorPage from './ErrorPage';

const page = {
  title: 'Internal Server Error',
  description: 'Oh no! Something went wrong. Don\'t worry, we won\'t let the music stop. Go back and try again.',
};

const Internal = () => {
  return (
    <ErrorPage page={page} />
  );
};

export default Internal;
