import React from "react";
import PropTypes from "prop-types";

import SessionTimesEditor from "./SessionTimesEditor";
import WeekdaySelector from "./WeekdaySelector";
import DateTimeSelector from "./DateTimeSelector";

import FormSection from "ui/FormSection";

const Frequency = ({ event, onEventChange, setUnsavedChanges }) => {

    const {frequency_type = 'week', universal_time = true} = event;

  return (
    <FormSection title={"Frequency"}>

        <DateTimeSelector event={event} onEventChange={onEventChange} setUnsavedChanges={setUnsavedChanges} />

        {frequency_type === "week" && (
            <WeekdaySelector event={event} onEventChange={onEventChange} setUnsavedChanges={setUnsavedChanges} />
        )}

        {!universal_time && 
            <SessionTimesEditor event={event} onEventChange={onEventChange} setUnsavedChanges={setUnsavedChanges} />}

    </FormSection>
  ) 
};

Frequency.propTypes = {
  event: PropTypes.object,
  onEventChange: PropTypes.func,
  setUnsavedChanges: PropTypes.func,
};

export default Frequency;
