import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import Description from 'ui/text/Description';
import Button from 'ui/Button';
import Date from 'ui/Date';

import { convertPriceFromCents } from 'utils/rewards';
import { currencySymbol } from 'utils/rewards';

import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { t } from 'i18n/index';
import './styles.css';

const minPrice = (tickets) => {
  if (tickets.length === 0) return t("literal:freeEntrance");

  let min = tickets[0].display_price;
  let currency = tickets[0].currency;
  tickets.forEach((ticket) => {
    if (ticket.display_price < min) {
      min      = ticket.display_price;
      currency = ticket.currency;
    }
  });

  const currencySign = currencySymbol[currency];

  const shownPrice = min && currencySign ? `${convertPriceFromCents(min)} ${currencySign}` : t("literal:freeEntrance");

  return shownPrice;
};

const Event = ({event}) => {

  const {title, start_date, location, ticketing, external_url, ticket_price, tickets, slug, single, sessions} = event;
  const shownStartDate = sessions?.length > 0 ? sessions[0].start_date : start_date;
  let price = ticketing ? minPrice(tickets) : ticket_price;
  const secondary = [location, price]
                    .filter(Boolean)
                    .join('・');

  const history = useHistory();
  const {organizationSlug} = useParams();

  const handleRedirect = (e) => {
    e.stopPropagation();

    const isInternal = external_url.startsWith(`${window.location.origin}/${organizationSlug}`);

    { isInternal
      ? history.push(`/${organizationSlug}/events/${slug}`)
      : window.open(external_url, "_blank")
    }
  };

  return (
    <div className='ui-event' onClick={(e) => handleRedirect(e)}>
        <Date date={shownStartDate} single={single} />

      <div className='details'>
        <Description bold>{title}</Description>
        <Description secondary>{secondary}</Description>
        {!single && 
          <div className='cta'>
            <EventRepeatIcon fontSize='small' />
            <Description secondary>{t('profile:sessionsCalendarCta')}</Description>
          </div>}
      </div>

      <Button highlighted small icon={ChevronRightIcon} onClick={(e) => handleRedirect(e)}></Button>
    </div>
  )
};

Event.propTypes = {
    event: PropTypes.object
};

export default Event;
