import { validateStringField } from "./validateStringField";

import { signUpCustomFields, transformCustomField } from 'utils/signUpCustomFields';

import { t } from 'i18n/index';

export const validateCustomFields = (organizationSlug, data) => {

    const customFields = signUpCustomFields[organizationSlug];

    customFields.forEach(field => {
        validateStringField(data[field], null, t('errors:fieldMissing', {field: transformCustomField(field, organizationSlug)}));
    })

};
