import React from 'react';
import PropTypes from 'prop-types';

import StatCard from 'components/StatCard';

const Stats = ({ events }) => {

    const stat1 = events && events.length ? events[0].total_count : 0;
    
    const totalRevenue = events && events.length ? events[0].total_revenue : 0;
    const stat2 = events && events.length ? totalRevenue ? totalRevenue : 0 : 0;
    
    const stat3 = events && events.length ? events.reduce ((acc, event) => acc + event.sold_tickets_count, 0) : 0;

    return (
        <div className="event-stats-grid grid custom-margin">

            <StatCard type='number'
                color='rgb(255, 99, 132)'
                highlight={ stat1 }
                title='Events Count' />

            <StatCard type='number'
                color='rgb(54, 162, 235)'
                highlight={ stat2 }
                title={`Total Revenue`} />

            <StatCard type='number'
                    color='rgb(255, 205, 86)'
                    highlight={ stat3 }
                    title='Sold Tickets' />

        </div>
    )};

Stats.propTypes = {
    events: PropTypes.array
};

export default Stats;