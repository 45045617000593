import React from 'react';
import PropTypes from 'prop-types';

import { List } from '@mui/material';


const ListComponent = ({children}) => {
  return (
    <List sx={{padding: 'var(--mobilePadding)'}}>
        {children}
    </List>
  );
};

ListComponent.propTypes = {
    children: PropTypes.node
};

export default ListComponent;