import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class StudentResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/students/' as const;

  readonly id: number | undefined = undefined;
  readonly first_name: string = '';
  readonly last_name: string = '';
  readonly email: string = '';
  readonly wallet_address: string = '';
  readonly rewards_count: number = 0;
  readonly points: number = 0;


  pk() {
    return this.id?.toString();
  }
}
