import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ErrorPage from './ErrorPage';

import { parseErrorMessages } from 'utils/parseErrorMessages';

import { t } from "i18n/index";

const Unprocessable = ({ error }) => {
  const [message, setMessage] = useState(t('errors:default'));

  useEffect(() => {
    const fetchErrorMessage = async () => {
      const errorMessages = await parseErrorMessages(error);
      if (errorMessages && errorMessages.length > 0) {
        setMessage(errorMessages.join(' '));
      }
    };

    fetchErrorMessage();
  }, [error]);

  const page = {
    title: 'Unprocessable Entity',
    description: message,
  };

  return <ErrorPage page={page} />;
};

Unprocessable.propTypes = {
  error: PropTypes.object.isRequired,
};

export default Unprocessable;