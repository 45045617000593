import React from 'react'
import { Box, Skeleton } from '@mui/material';

const LoadingTable = () => {
  return (
    <Box sx={{ width: '100%' }}>
        <Skeleton variant="rounded" height={'70vh'} />
    </Box>
  )
}

export default LoadingTable