import React from 'react';
import PropTypes from 'prop-types';

import prepareChartData from './prepareChartData';

import { Bar } from 'react-chartjs-2';

const HorizontalBarChart = ({data, labelKey, dataKey}) => {

  const options = {
    indexAxis: 'y'
  };

  const chartData = prepareChartData(data, labelKey, dataKey);

  return (
    <div style={{ padding: '40px' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

HorizontalBarChart.propTypes = {
    data: PropTypes.object,
    labelKey: PropTypes.string,
    dataKey: PropTypes.string
};

export default HorizontalBarChart;