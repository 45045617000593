import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import PublicLayout from 'components/layouts/Public';
import FormSection from 'ui/FormSection';

import AuthToken from 'utils/localStorage/AuthToken';
import CoursesList from 'utils/localStorage/CoursesList';

import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const ConnectRedirect = () => {
    const history = useHistory();
    const location = useLocation();
    const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);

    const authToken = AuthToken.read();
    const isLoggedIn = !!authToken;

    if (!isLoggedIn) {
        history.push('/login', { from: location.pathname });
        return null;
    }

    const coursesList = CoursesList.read();

    if (coursesList.length > 1 && !selectedOrganizationId) {
        return renderOrganizationSelector(coursesList, selectedOrganizationId, setSelectedOrganizationId);
    }

    const organizationId = selectedOrganizationId || coursesList[0].organization_id;

    const targetPath = getTargetPath(location.pathname, organizationId);
    history.push(targetPath);

    return null;
};

const renderOrganizationSelector = (coursesList, selectedOrganizationId, setSelectedOrganizationId) => {
    const handleChange = ({ target: { value } }) => setSelectedOrganizationId(value);

    return (
        <PublicLayout isLogin={true}>
            <FormSection>
                <FormControl fullWidth>
                    <InputLabel color="warning">Select Organization</InputLabel>
                    <Select
                        color='warning'
                        label="Select Organization"
                        value={selectedOrganizationId || ''}
                        onChange={handleChange}
                    >
                        {coursesList.map(course => (
                            <MenuItem key={course.organization_id} value={course.organization_id}>
                                {course.organization}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </FormSection>
        </PublicLayout>
    );
};

const getTargetPath = (pathname, organizationId) => {
    switch (pathname) {
        case '/connect/settings':
            return `/organizations/${organizationId}/settings/payments`;
        case '/connect/payouts':
            return `/organizations/${organizationId}/analytics/payouts`;
        case '/connect/orders':
            return `/organizations/${organizationId}/analytics/orders`;
        default:
            return `/organizations/${organizationId}`;
    }
};

export default ConnectRedirect;