import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class PromoCodeResource extends AuthenticatedResource {
  static urlRoot = 'profile/:organization_slug/products/:product_id/promo_codes';

  readonly id: number | undefined = undefined;

  pk() {
    return this.id?.toString();
  }
}