import React from "react";
import PropTypes from "prop-types";

import MarkdownEditor from "components/MarkdownEditor";

const InternalEventAttributes = ({ object, onObjectChange, setUnsavedChanges }) => {

  return (
      <MarkdownEditor 
        object={object}
        setObject={onObjectChange}
        attribute={"description"}
        placeholder={'Description'}
        allowImageUpload={true}
        allowEmbeds={true}
        setUnsavedChanges={setUnsavedChanges}
      />
  );
};

InternalEventAttributes.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  tickets: PropTypes.array,
  setUnsavedChanges: PropTypes.func
};

export default InternalEventAttributes;
