import React, {Children,useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import UnsavedChangesModal from './UnsavedChangesModal';

import TabPanel from 'components/TabPanel';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

/* We're in the process of migrating labels + defaultIndex to items + Route Switching
 * TODO: remove labels completely
 */
const TabPanels = ({ items, labels, children, hideMargin, unsavedChanges, onUnsavedChanges, slim, onChange }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);

  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);
  const defaultIndex = searchParams.has('defaultIndex') && parseInt(searchParams.get('defaultIndex'));
  const segment = window.location.pathname.split("/").pop();
  const subsegments = ["notion", "telegram"]

  const content = [];
  Children.forEach(children, child => {
    if(child !== false && child !== null) {
      content.push(child)}
  });

  useEffect(() => {
    if (labels && labels.length === 1){
      setTabIndex(0);
    }
    if (defaultIndex) {
      setTabIndex(defaultIndex)
    }
    if (items && segment) {
      const index = items.findIndex(item => item.segment === segment);
      setTabIndex(index);
    }

  }, [items, labels])

  const handleTabChangeRequest = (_, newValue) => {
    if (unsavedChanges) {
      setConfirmModal(newValue);
    } else {
      handleTabChange(newValue);
    }
  };

  const handleTabChange = (newValue) => {
    setConfirmModal(false);
    setTabIndex(newValue);
    const segment = items[newValue].segment;
    
    const currentLocationSplit = window.location.pathname.split("/");
    const lastSegment = currentLocationSplit.slice(-1)[0];

    const target =
      subsegments.includes(lastSegment)
        ? currentLocationSplit.slice(0, -2).join("/") + "/" + segment
        : segment;
  
    history.push(target)
  };

  const handleDiscardChanges  = () => {
    handleTabChange(confirmModal)
    onUnsavedChanges(false);
    setConfirmModal(false);
  };

  return (
    <>
      { items &&
        <Box className="tabpanel-menu" sx={{ borderBottom: 1, borderColor: 'divider' }} >
          <Tabs value={tabIndex} onChange={ handleTabChangeRequest }>
            { items.map( (item, index) => (
                <Tab key={ index } label={ item.label } />
              ))
            }
          </Tabs>
        </Box>
      }

      { labels &&
        <Box className={`tabpanel-menu ${slim ? 'slim' : ''}`} sx={{ borderBottom: 1, borderColor: 'divider' }} >
          <Tabs value={tabIndex} onChange={ onChange ? (event, newValue) => onChange(newValue) : (event, newValue) => setTabIndex(newValue) }>
            { labels.map( (label, index) => (
                <Tab key={ index } label={ label } />
              ))
            }
          </Tabs>
        </Box>
      }

      { labels && content.map( (tabContent, index) => (
          <TabPanel key={ index } value={ tabIndex } index={ index } hideMargin={ hideMargin }>
            { tabContent }
          </TabPanel>
        ))
      }

      {confirmModal !== false &&
        <UnsavedChangesModal
          open={confirmModal !== false}
          setOpen={setConfirmModal}
          onConfirm={ () => handleDiscardChanges() }  
        />}

    </>
)};

TabPanels.propTypes = {
  labels: PropTypes.array,
  items: PropTypes.array,
  children: PropTypes.node,
  hideMargin: PropTypes.bool,
  unsavedChanges: PropTypes.bool,
  onUnsavedChanges: PropTypes.func,
  slim: PropTypes.bool,
  onChange: PropTypes.func
};

export default TabPanels;
