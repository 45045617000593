import React, { useRef } from "react";
import PropTypes from "prop-types";

import MediaUploader from "components/MediaUploader";

const Media = ({ offer, onOfferChange, loading, setLoading, onError }) => {
  const hiddenFileInputs = {
    image: useRef(null),
    video: useRef(null),
  };

  return (
    <MediaUploader
      hiddenFileInputs={hiddenFileInputs}
      multiple
      objectType={"product"}
      object={offer}
      onObjectChange={onOfferChange}
      loading={loading}
      onLoadingChange={setLoading}
      onError={onError}
      mandatory
    />
  );
};

Media.propTypes = {
  offer: PropTypes.object,
  onOfferChange: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onError: PropTypes.func,
};

export default Media;
