import InsertLinkIcon from '@mui/icons-material/InsertLink';
import TitleIcon from '@mui/icons-material/Title';
import GroupsIcon from '@mui/icons-material/Groups';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import discord from '../assets/images/remix_icons/discord.svg';
import email from '../assets/images/remix_icons/email.svg';
import facebook from '../assets/images/remix_icons/facebook.svg';
import instagram from '../assets/images/remix_icons/instagram.svg';
import linkedin from '../assets/images/remix_icons/linkedin.svg';
import soundcloud from '../assets/images/remix_icons/soundcloud.svg';
import spotify from '../assets/images/remix_icons/spotify.svg';
import telegram from '../assets/images/remix_icons/telegram.svg';
import tiktok from '../assets/images/remix_icons/tiktok.svg';
import website from '../assets/images/remix_icons/website.svg';
import x from '../assets/images/remix_icons/x.svg';
import youtube from '../assets/images/remix_icons/youtube.svg';
import bandcamp from '../assets/images/remix_icons/bandcamp.svg';
import whatsapp from '../assets/images/remix_icons/whatsapp.svg';

export const emptyPageElement = {
    content: "",
    image_file: null,
    element_type: "",
    reward_ids: ["public"],
    link_title: "",
};

export const pageElementTypes = [
  {
    value: 'link',
    title: 'Link',
    icon: InsertLinkIcon,
  },
  {
    value: "social",
    title: 'Social',
    icon: GroupsIcon,
  },
  {
    value: 'title',
    title: 'Section Header',
    icon: TitleIcon,
  },
  {
    value: 'embed',
    title: 'Embed',
    icon: PlayCircleOutlineIcon,
  },
];

export const pageInfo = {
  name: "Link-In-Bio",
  description: "Set up and organize links and social media profiles to be displayed on the About section of your club.",
  action: true,
};

export const isSocialElement = (element_type) => {
  return socialTypes.some(socialType => socialType.value === element_type);
};

export const socialTypes = [
  {
    value: "facebook",
    title: "Facebook",
    logo: facebook,
  },
  {
    value: "x",
    title: "X",
    logo: x,
  },
  {
    value: "instagram",
    title: "Instagram",
    logo: instagram,
  },
  {
    value: "youtube",
    title: "Youtube",
    logo: youtube,
  },
  {
    value: "linkedin",
    title: "Linkedin",
    logo: linkedin,
  },
  {
    value: "tiktok",
    title: "TikTok",
    logo: tiktok,
  },
  {
    value: "whatsapp",
    title: "WhatsApp",
    logo: whatsapp,
  },
  {
    value: "telegram",
    title: "Telegram",
    logo: telegram,
  },
  {
    value: "discord",
    title: "Discord",
    logo: discord,
  },
  {
    value: "spotify",
    title: "Spotify",
    logo: spotify,
  },
  {
    value: "soundcloud",
    title: "SoundCloud",
    logo: soundcloud,
  },
  {
    value: "bandcamp",
    title: "Bandcamp",
    logo: bandcamp,
  },
  {
    value: "email",
    title: "Email",
    logo: email,
  },
  {
    value: "website",
    title: "Website",
    logo: website,
  },
];

export const isEmbeddedElement = (element_type) => {
  if (element_type === "embed") return true;
  return embedTypes.some(embedType => embedType.value === element_type);
};

export const embedTypes = [
  {
    value: "youtube_embed",
    title: "Youtube",
    logo: youtube,
  },
  {
    value: "spotify_embed",
    title: "Spotify",
    logo: spotify,
  },
  {
    value: "soundcloud_embed",
    title: "SoundCloud",
    logo: soundcloud,
  },
]

export const linkSelectorButtons = [
  {
    type: 'link',
    icon: InsertLinkIcon,
    label: 'Add Link',
    highlightedCondition: (elementType) => elementType === 'link',
    secondaryCondition: (elementType) => elementType !== 'link',
  },
  {
    type: 'embed',
    icon: PlayCircleOutlineIcon,
    label: 'Add Embed',
    highlightedCondition: (elementType) => isEmbeddedElement(elementType),
    secondaryCondition: (elementType) => !isEmbeddedElement(elementType),
  },
];
