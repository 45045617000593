import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useController } from '@rest-hooks/react';

import ProductResource from "resources/organization/ProductResource";
import EventResource from 'resources/organization/EventResource';

import UnsavedChangesModal from 'components/UnsavedChangesModal';
import DeleteModal from 'components/DeleteModal';

import copyToClipboard from 'utils/copyToClipboard';
import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import { useError } from 'utils/useErrorController';
import { updateDraggableList } from 'utils/updateDraggableList';

import SingleEventTickets from './SingleEventTickets';
import RecurrentEventTickets from './RecurrentEventTickets';

const Tickets = ({event, onEventChange, onChange, unsavedChanges, setUnsavedChanges}) => {
    const {ticketing, tickets, single}  = event;

    if (!ticketing) return null;

    const [unsavedChangesModal, setUnsavedChangesModal] = useState(false);
    const [deletingTicket, setDeletingTicket] = useState(null);
    const [redirectTarget, setRedirectTarget] = useState(null);

    const params = useParams();
    const organizationId = parseInt(params.organizationId);
    const eventId = parseInt(params.eventId);

    const history = useHistory();
    const {fetch} = useController();
    const {handleError} = useError();

    const handleRedirect = (e, target) => {
        e.preventDefault();
        if (unsavedChanges) {
            setUnsavedChangesModal(true);
            setRedirectTarget(target);
        } else {
            history.push(`/organizations/${organizationId}/perks${target}`);
        }
    };

    const handleDiscardChanges = () => {
        setUnsavedChangesModal(false);
        setUnsavedChanges(false);
        setRedirectTarget(null);
        history.push(`/organizations/${organizationId}/perks${redirectTarget}`);
    };

    const { setOpen, setMessage, setSeverity } = useToast();
    const handleUrlCopy = (external_url) => {
        copyToClipboard(external_url, setOpen, setSeverity, setMessage)
      };

    const handleDeleteRequest = (id, identifier) => {
        setDeletingTicket({ id: id, title: identifier });
    };

    const handleDelete = async () => {
        try {
            await fetch(ProductResource.delete(), { id: deletingTicket.id, organization_id: organizationId });

            const newTickets = tickets.filter(ticket => ticket.id !== deletingTicket.id);
            updateTickets(newTickets);

            setDeletingTicket(null);
            customToast('success', 'Ticket Deleted', setOpen, setSeverity, setMessage);
        } catch (error) {
            handleError(error)
        }
    };

    const handleDragEnd = async (result) => {
        const savedEvent = event
        try {
            const startIndex = result.source.index;
            const endIndex = result.destination.index;
            let tickets = savedEvent.tickets;
            let newPosition = endIndex;

            const ticketId = +result.draggableId;
            const session = !savedEvent.single && savedEvent.sessions.find(session => session.tickets.find(ticket => ticket.id === ticketId));
            const sessionId = session && session.id;

            if (session) {
                tickets = session.tickets;
                newPosition = tickets[endIndex].position;
            }

            updateDraggableList(tickets, startIndex, endIndex, updateTickets, sessionId);
            await fetch(EventResource.update(), { organization_id: organizationId, id: eventId }, { ticket_id: ticketId, position: newPosition });

        } catch (error) {
            onEventChange(savedEvent);
            handleError(error);
        }
    };

    const updateTickets = (tickets, sessionId) => {
        let newEvent;

        if (sessionId) {
            newEvent = { ...event, sessions: event.sessions.map(session => session.id === sessionId ? { ...session, tickets } : session) };
        } else {
            newEvent = { ...event, tickets };
        }

        onEventChange(newEvent);
    };

  return (
    <>
        {single ? (
            <SingleEventTickets
                event={event}
                handleDeleteRequest={handleDeleteRequest}
                handleDragEnd={handleDragEnd}
                handleRedirect={handleRedirect}
                handleUrlCopy={handleUrlCopy} 
                onChange={onChange}
                />
        ) : (
            <RecurrentEventTickets
                event={event}
                handleDeleteRequest={handleDeleteRequest}
                handleDragEnd={handleDragEnd}
                handleRedirect={handleRedirect}
                handleUrlCopy={handleUrlCopy} 
                onChange={onChange}
                />
        )}

        {unsavedChangesModal &&
            <UnsavedChangesModal
                open={unsavedChangesModal}
                setOpen={setUnsavedChangesModal}
                onConfirm={() => handleDiscardChanges()}
            />}

        <DeleteModal
            deletingObject={deletingTicket}
            objectCategory={'ticket'}
            setOpen={setDeletingTicket}
            onConfirm={() => handleDelete()} />
    </>
)};

Tickets.propTypes = {
    event: PropTypes.object,
    onEventChange: PropTypes.func,
    onChange: PropTypes.func,
    unsavedChanges: PropTypes.bool,
    setUnsavedChanges: PropTypes.func,
};

export default Tickets;
