import React from 'react';
import PropTypes from 'prop-types';

import { DialogTitle } from '@mui/material';

const Title = ({pageElementId}) => {
  return (
    <DialogTitle>
        {pageElementId ? 'Edit Element' : 'Create new Element'}
    </DialogTitle>
  );
};

Title.propTypes = {
    pageElementId: PropTypes.number,
};

export default Title;