import AuthenticatedResource from 'resources/public/AuthenticatedResource'

export default class CourseResource extends AuthenticatedResource {
  static urlRoot = '/organizations/:organization_id/courses' as const;

  readonly id: number | undefined = undefined;
  readonly title: string = '';
  readonly description: string = '';
  readonly category: string = '';
  readonly external_link: string = '';
  readonly invite: string = '';
  readonly created_at: Date = new Date(0);
  readonly organization_id: number | undefined = undefined;
  readonly organization: string = '';
  
  pk() {
    return this.id?.toString();
  }

}
