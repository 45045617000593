import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { t } from "i18n/index";

import Button from 'ui/Button';

import CustomModal from 'components/CustomModal';
import { useHistory } from 'react-router-dom';

const getDefaultContent = (title, points, handleRedirect) => {
  return {
    title: title,
    content: t('profile:perks:insuficientPointsMessage', { points }),
    button: <Button highlighted onClick={handleRedirect}>{t('literal:seeMemberships')}</Button>
  }
};

const NotEnoughPointsModal = ({title, points, onClose}) => {

    const history = useHistory();
    const handleRedirect = () => {
      history.push(`/memberships`);
    };

    const [content, setContent] = useState(getDefaultContent(title, points, handleRedirect));

    const handleClose = () => {
      setContent(null);
      onClose();
    };

  return (
    <CustomModal handleClose={handleClose} customModalContent={content} />
  );
};

NotEnoughPointsModal.propTypes = {
  title: PropTypes.string,
  points: PropTypes.number,
  onClose: PropTypes.func
};

export default NotEnoughPointsModal;