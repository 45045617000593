import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useController } from '@rest-hooks/react';

import PromoCodeResource from 'resources/organization/PromoCodeResource';

import { useError } from 'utils/useErrorController';
import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';

import FormSection from 'ui/FormSection';

import PercentOff from './Form/PercentOff';
import Code from './Form/Code';
import Footer from './Form/Footer';
import ProductSelector from './Form/ProductSelector';
import CategorySelector from './Form/CategorySelector';

import { handleValidation } from './handleValidation';
import { createFormData } from './createFormData';

const initialValue = { 
    code: "",
    percent_off: null,
    category: "global",
    products: [],
};

const OffersForm = ({ record={} }) => {
    const { fetch } = useController();
    const history = useHistory();
    const params  = useParams();

    const { handleError, loading, setLoading, validate } = useError();
    const { setOpen, setMessage, setSeverity } = useToast();

    const organizationId = parseInt(params.organizationId);

    const [promoCode, setPromoCode] = useState({ ...initialValue, ...record });

    const handleChange = ({target: {name, value}}) => {
        let newPromoCode = { ...promoCode, [name]: value };

        if (name === "category" && value !== "specific") {
            newPromoCode.products = null;
        }

        setPromoCode(newPromoCode);
    };

    const request = record.id ? PromoCodeResource.update() : PromoCodeResource.create();
    const requestBody = record.id ?  {organization_id: organizationId, id: record.id} : {organization_id: organizationId};

    const handleSubmit = useCallback(
        async () => {
          try {
            validate(promoCode, handleValidation);

            const formData = createFormData(promoCode);
            const {id} = await fetch(request, requestBody, formData);

            handleSuccessfulResponse(id);
          } catch (error) {
            handleError(error)
          }
        },
        [fetch, promoCode],
    );

    const handleSuccessfulResponse = async (id) => {
        await fetch(PromoCodeResource.list(), {organization_id: organizationId});
        setLoading(false);
        history.push(`/organizations/${organizationId}/perks/promo-codes/${id}/edit`);
        customToast("success", `Promo Code ${id ? 'updated' : 'created'} successfully`, setOpen, setSeverity, setMessage);
    };

    const {id, code, percent_off, products, category} = promoCode;

    return (
        <>
            <FormSection title={"Details"}>
                <Code
                    title={code}
                    onChange={handleChange} />

                <PercentOff
                    percent_off={percent_off}
                    onChange={handleChange} />

                <CategorySelector
                    category={category}
                    onChange={handleChange} />

                {category === "specific" && 
                    <ProductSelector
                        organizationId={organizationId}
                        products={products}
                        onChange={handleChange} />}

            </FormSection>
            <Footer
                    loading={loading}
                    id={id}
                    onSubmit={handleSubmit} />
        </>
    );
};

OffersForm.propTypes = {
    record: PropTypes.object,
};

export default OffersForm;
