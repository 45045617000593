import React from 'react'
import PropTypes from 'prop-types'
import { Box, Chip } from '@mui/material';

const Chips = ({ selected, passes }) => {

    const chipLabel = (value) => {
        if (value === "public" ) {
          return "Everyone";
        } else {
          return passes.find((item) => item.id === value)?.title;
        }
    };

    if (selected?.includes("private")) {
        return (

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            <Chip label={"Members only"} />
        </Box>

        );
    } else {
        return (

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected?.map((value) => (
                <Chip key={value} label={chipLabel(value)} />
            ))}
        </Box>

        );
    }
};

Chips.propTypes = {
    selected: PropTypes.array,
    passes: PropTypes.array,
};

export default Chips;