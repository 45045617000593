import React, { useEffect, useState } from 'react';

import OrganizationResource from 'resources/admin/OrganizationResource';

import CreateOrganizationModal from './create/CreateOrganizationModal';
import EditOrganizationModal from './edit/EditOrganizationModal';

import Layout from 'components/layouts/index';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TabPanels from 'components/TabPanels';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import Button from 'ui/Button';

import { organizationColumns } from 'utils/tables/columns/admin/organizationsColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';

const pageInfo = {
  label: "Admin",
  name: "Organizations",
  action: true
};

const AdminUsers = () => {

  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [activeOrg, setActiveOrg] = useState({name:"", id: "", modules: []});

  const {
    objectState, 
    fetchData,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: OrganizationResource,
      fetchDataParams: {},
  });

  useEffect(() => {
    fetchData();
  }, []);
  
  const handleOpenCreateModal = () => setOpenCreateModal(true);

  const handleOpenEditModal = () => setOpenEditModal(true);

  const handleCloseModals = () => {
    setOpenCreateModal(false);
    setOpenEditModal(false);
    fetchData();
  };

  return (
    <Layout context='admin'
            activeMenuItem={'organizations'}
            pageInfo={ pageInfo }
            cta={
              <Button highlighted icon={AddCircleOutlineIcon} onClick={handleOpenCreateModal}>
                Add Organization
              </Button>
            } >

      <CreateOrganizationModal open={ openCreateModal }
                          onClose={ handleCloseModals }
                          />

      <EditOrganizationModal open={ openEditModal }
                          onClose={ handleCloseModals }
                          activeOrg={ activeOrg } 
                          handleActiveOrg ={ setActiveOrg }
                          /> 
    
      <TabPanels labels={[ "Organizations" ]} >
        <DataSelectionTable records={objectState.filteredObjects}
                            columns={organizationColumns(setActiveOrg, handleOpenEditModal)}
                            serverPaginationProps={{
                                paginationServer: true,
                                paginationTotalRows: objectState.totalCount,
                                page: objectState.page,
                                onChangePage: handlePageChange
                              }}
                              subHeader={false}
        />
      </TabPanels>

    </Layout>
)};

export default AdminUsers;