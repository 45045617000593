import React from 'react';
import PropTypes from 'prop-types';

import MarkdownEditor from 'components/MarkdownEditor';

import { slugify } from 'utils/slugify';

import { TextField } from '@mui/material';
import Label from 'ui/text/Label';
import FormSection from 'ui/FormSection';


const Details = ({post, setPost, onChange, title}) => {

    const placeholder = 'Content'

  return (
    <FormSection title={"Post Details"}>
        <TextField
            fullWidth
            size='small'
            color='warning'
            name={"title"}
            label={"Title"}
            value={title}
            onChange={onChange}
            onBlur={() => slugify(post, setPost)}
        />

        <Label secondary padded>Optional: You may use markdown to format your post and set the title instead</Label>

        <MarkdownEditor 
            object={post}
            setObject={setPost}
            attribute={"body"}
            placeholder={placeholder}
            allowImageUpload={true}
            allowEmbeds={true}
        />

        <Label secondary padded>Paste Spotify, SoundCloud, YouTube and Vimeo links on their own paragraph to embed them in your post</Label>

    </FormSection>
  );
};

Details.propTypes = {
    post: PropTypes.object,
    setPost: PropTypes.func,
    onChange: PropTypes.func,
    title: PropTypes.string
};

export default Details;