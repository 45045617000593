import AuthenticatedResource  from 'resources/public/AuthenticatedResource';

// -----------------------------------------------------
// Resource Definition
// -----------------------------------------------------

export default class ProductResource extends AuthenticatedResource {
  static urlRoot = '/admin/products'

  readonly id: number | undefined = undefined;
  
  pk() {
    return this.id?.toString();
  }
}