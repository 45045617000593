import React from "react";
import PropTypes from 'prop-types';

import { countriesList } from 'utils/coutriesList';

import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';

import { t } from 'i18n/index';

const CountrySelector = ({ data, onDataChange, end_user, disabled }) => {

  const handleCountryChange = (event, country) => {
    if (country) {
      onDataChange( {
          ...data,
          country: country.code
      });
    }
  };

  return (
    <Autocomplete
      id="country-select-demo"
      fullWidth
      disabled={disabled}
      options={countriesList}
      onChange={(event, newValue) => {
        handleCountryChange(event, newValue);
      }}
      autoHighlight
      getOptionLabel={(option) => option.label}
      defaultValue={ data.country ? {
                                      code: data.country,
                                      label: countriesList.find((country) => country.code === data.country).label,
                                      suggested: true,
                                    } 
                                    : null
                    }
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{
            "& > img": {
              mr: 2,
              flexShrink: 0,
            },
          }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={end_user ? `${t('literal:country')} ${data.vat ? "*" : ""}` : 'Country *'}
          size={"small"}
          color="warning"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          sx={end_user ? {
            marginBottom: "40px"} : {}
          }
        />
      )}
    />
  );
};

CountrySelector.propTypes = {
  data: PropTypes.object,
  onDataChange: PropTypes.func,
  end_user: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CountrySelector;
