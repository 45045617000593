import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { Box, FormControlLabel, FormHelperText, Link } from '@mui/material';import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const SendWelcomeEmail = ({ send_welcome_email, onChange}) => {

    const params = useParams();
    const organizationId = parseInt(params.organizationId);
    
  return (
    <Box sx={{ m: 1 }} >
      <RadioGroup
          value={send_welcome_email}
          defaultValue={null}
          onChange={onChange}
        >
          <FormControlLabel value={'false'} control={<Radio />} label="No, thanks" />
          <FormControlLabel value={"personalized"} control={<Radio />} label="Yes, Send Personalized Email" />
          <FormControlLabel value={'true'} control={<Radio />} label="Yes, Send Default Email" />
          <FormHelperText>
                You can personalise your default welcome email
                <Link underline="hover" color="inherit" href={`/organizations/${organizationId}/settings/email`}> here</Link>
                .
          </FormHelperText>
        </RadioGroup>
    </Box>
  );
};

SendWelcomeEmail.propTypes = {
  send_welcome_email: PropTypes.bool,
  onChange: PropTypes.func
};

export default SendWelcomeEmail;