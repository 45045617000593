import React from 'react';
import PropTypes from 'prop-types';

import { IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";

const CloseButton = ({handleClose}) => {
  return (
    <IconButton
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 10,
          top: 10,
          color: "white",
          zIndex: 1501,
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>
  );
};

CloseButton.propTypes = {
  handleClose: PropTypes.func,
};

export default CloseButton;