import React from 'react';
import PropTypes from 'prop-types';

import { Droppable, Draggable } from "react-beautiful-dnd";

import { List, ListItem } from "@mui/material";

const DraggableList = ({data, droppableId}) => {
  return (
    <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            <List className={`${snapshot.isDraggingOver ? "dragging-over" : ""}`}>
              {data.map((element, index) => (
                <Draggable
                  key={element.id.toString()}
                  draggableId={element.id.toString()}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ListItem disableGutters className={`${snapshot.isDragging ? "dragging" : ""}`} >
                        {element.component}
                      </ListItem>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          </div>
        )}
      </Droppable>
  );
};

DraggableList.propTypes = {
  data: PropTypes.array,
  droppableId: PropTypes.string
};

export default DraggableList;