import { t } from "i18n/index";

const validatePasswordPresence = (password, confirmPassword) => {
  if (
    password === undefined ||
    password === null ||
    password.trim() === "" ||
    confirmPassword === undefined ||
    confirmPassword === null ||
    confirmPassword.trim() === ""
  ) {
    throw t("errors:passwordMissing");
  }
};

const validatePasswordMatch = (password, confirmPassword) => {
  const passwordsMatch = password === confirmPassword;
  if (!passwordsMatch) {
    throw t("errors:passwordsDontMatch");
  }
};

const resetValidation = (event) => {
  const password = event.target.password.value;
  const confirmPassword = event.target.password_confirmation.value;
  validatePasswordPresence(password, confirmPassword);
  validatePasswordMatch(password, confirmPassword);
};

export default resetValidation;
