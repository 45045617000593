import React from 'react';
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import Button from "ui/Button";

const Footer = ({id, loading, onSubmit}) => {
  return (
    <div className={`new-form-field-footer`}>
        <Button minwidth loading={loading} icon={SaveIcon} onClick={onSubmit}>
            {!id ? "Create" : "Save"}
        </Button>
    </div>
  );
};

Footer.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default Footer;