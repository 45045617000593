import React from 'react';
import PropTypes from 'prop-types';
import { claimState } from 'utils/constants';
import Chip from '@mui/material/Chip';
import { t } from 'i18n/index';
import { organizationUrl } from 'utils/urls';

const ClaimStatus = ({id, category, claim_state, external_url, organization_slug, onClaim}) => {
  if (category === 'pass') {
    return <div style={{ textAlign: "center" }}>
      <Chip
        label={t('literal:goTo')}
        color="warning"
        onClick={() => window.open(organizationUrl(organization_slug), "_blank")} />
    </div>;
  }

  const claimed = claim_state !== claimState.UNCLAIMED.value;

  return <div style={{ textAlign: "center" }}>
    {claimed ?
      <Chip
        label={claimState[claim_state.toUpperCase()].label}
        color="success"
        onClick={() => window.open(external_url, "_blank")} />
      :
      <Chip
        label={t('literal:claim')}
        color="warning"
        onClick={() => onClaim(id)} />}
  </div>;
};

ClaimStatus.propTypes = {
  id: PropTypes.number,
  category: PropTypes.string,
  claim_state: PropTypes.string,
  external_url: PropTypes.string,
  organization_slug: PropTypes.string,
  onClaim: PropTypes.func,
};

export default ClaimStatus;
