import React from 'react';
import PropTypes from 'prop-types';

import ImageUploadButton from 'components/ImageUploadButton';

import { TextField } from "@mui/material";


const LinkComposer = ({ object, onObjectChange, onChange }) => {
    
    return ( 
        <>
            <TextField
                size='small'
                name="link_title"
                label="Link Description"
                value={object.link_title}
                onChange={onChange}
                fullWidth
                color="warning"
                />
    
            <ImageUploadButton 
                object={object}
                onObjectChange={onObjectChange}
            />
        </>
    );
};

LinkComposer.propTypes = {
    object: PropTypes.object,
    onObjectChange: PropTypes.func,
    link_title: PropTypes.string,
    onChange: PropTypes.func,
};

export default LinkComposer;