import React from 'react';
import ErrorPage from './ErrorPage';

const page = {
  title: 'Club Missing',
  description: 'Seems like we\'ve made a detour. The club you\'re looking for has evaporated into thin ether! But don\'t let that stop the music.',
  secondaryButtonAction: () => window.location.href = 'https://membrz.club',
  secondaryButtonLabel: 'Claim your Club',
};

const NotFound = () => {
  return (
    <ErrorPage page={page} />
  );
};

export default NotFound;
