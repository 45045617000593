import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';

const Subtitle = ({children, highlighted, small, struck}) => {
  
  const classes = ['ui-subtitle'];
  if (highlighted) classes.push('ui-highlighted-text');
  if (small) classes.push('ui-small-text');
  if (struck) classes.push('ui-struck-text');

  return (
    <h2 className={classes.join(' ')}>{children}</h2>
  )
};

Subtitle.propTypes = {
  children: PropTypes.string,
  highlighted: PropTypes.bool,
  small: PropTypes.bool,
  struck: PropTypes.bool
};

export default Subtitle;