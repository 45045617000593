import React from 'react';
import PropTypes from 'prop-types';

import { activeOffersColumns } from "utils/tables/columns/activeOffersColumns";

const ListItem = ({handleDeleteRequest, handleUrlCopy, handlePreview, loading, organizationTags, offer}) => {

  return (
    <div className={`draggable-perk ${!offer.available && 'unavailable'}`}>
        {activeOffersColumns(handleDeleteRequest, handleUrlCopy, handlePreview, loading, organizationTags).map((column, index) => (
            <div key={index} style={{width: column.width, display:'flex', flexDirection: 'column'}} >
                {column.selector(offer)}
            </div>
        ))}
    </div>
  );
};

ListItem.propTypes = {
  handleDeleteRequest: PropTypes.func,
  handleUrlCopy: PropTypes.func,
  handlePreview: PropTypes.func,
  loading: PropTypes.bool,
  organizationTags: PropTypes.array,
  offer: PropTypes.object
};

export default ListItem;
