import React from 'react';
import PropTypes from 'prop-types';

import CustomChip from 'ui/CustomChip';
import MenuButton from 'ui/MenuButton';
import Description from 'ui/text/Description';

import useSubscriptionController from 'utils/useSubscriptionController';

import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import moment from 'moment';
import { t } from "i18n/index";

const StatusBar = ({subscriptionDetails, handleError}) => {

  const {end_date, status, scheduled_change} = subscriptionDetails;

  const date = moment(end_date).format('DD/MMM');
  
  const descriptionLabel = scheduled_change 
                              ? `${scheduled_change.reward_title} ${t('literal:after')} ${date}` 
                              : end_date && status === 'active' 
                                ? `${t('literal:renewsOn')} ${date}` 
                                : status === 'expired'
                                  ? t('useSubscriptionController:expiredSubscription')
                                  : end_date
                                    ? `${t('literal:expiresOn')} ${date}`
                                    : "";

  const customChipLabel = scheduled_change ? t('literal:downgrading_sc') : status;

  const {menuItems} = useSubscriptionController(subscriptionDetails, handleError)

  return (
    <div className='status-bar'>
      <div className='details'>
        <CustomChip label={customChipLabel}/>
        {descriptionLabel && <Description secondary>{descriptionLabel}</Description>}
      </div>
     
      <MenuButton icon={<SettingsOutlinedIcon />} menuItems={menuItems} />      
    </div>
  );
};

StatusBar.propTypes = {
  subscriptionDetails: PropTypes.object,
  handleError: PropTypes.func,
  onScheduledCancel: PropTypes.func,
  onReactivation: PropTypes.func
};

export default StatusBar;