export const getFilterOptions = (table) => { 
  return Object.keys(statuses[table]).map((status, index) => ({ identifier: status, id: index })); 
};

export const getUserFriendlyIdentifier = (table, identifier) => {
  return statuses[table][identifier];
};

const statuses = {
  tickets : {
    "initiated": "Not Checked In",
    "completed": "Checked In",
  },
  orders : {
    "pending": "Pending",
    "completed": "Completed",
  },
  subscriptions: {
    "active": "Active",
    "expired": "Expired",
    "expiring": "Expiring",
  }
};

