import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useController } from '@rest-hooks/react';

import OrderResource from 'resources/user/OrderResource'

import StripePaymentForm from './StripePaymentForm';
import EupagoPaymentForm from './EupagoPaymentForm';
import { handleValidation } from './handleValidation';
import LoadingComponent from '../LoadingComponent';

import { useError } from 'utils/useErrorController';
import { findOrderResult } from 'utils/profile/claim/findOrderInstance';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const orderPropertiesDefaultState = {
    itemPrice: '',
    quantity: '',
    percentOff: '',
};

const PaymentCollection = ({ 
  object, 
  pricingId,
  requestedAmount,
  userPhone, 
  onUserPhoneChange,
  stripeState, 
  onStripeStateChange, 
  orderState, 
  onOrderChange,
  onStepChange,
  onContentChange, 
  isPass,
  onShowTickets,
  promoCode
}) => {

  const { fetch } = useController();
  const { handleError, setLoading, validate, loading } = useError();

  const {selectedProvider, providers} = orderState;

  const [orderPropertiesState, setOrderPropertiesState] = useState(orderPropertiesDefaultState);
  const [processingEupago, setProcessingEupago] = useState(false);

  const handleProviderChange = (provider) => {
    onOrderChange((previousValue) => ({...previousValue, selectedProvider: provider, paymentIntentId: ''}));
  };

  const handleErrorFindingOrderResult = (error) => {
    setProcessingEupago(false);
    onUserPhoneChange('');
    handleError(error);
  };

  const handleEupagoFormSubmission = async (e) => {
    e.preventDefault();
    if (processingEupago) {
      setLoading(true);
      findOrderResult( processingEupago, fetch, onStepChange, handleErrorFindingOrderResult, setLoading, onContentChange, onShowTickets);
    } else {
      createPaymentIntent(e);
    }
  };
  
  const createPaymentIntent = async (e, provider) => {
    try {
      const activeProvider = provider || selectedProvider;
      
      if (e) e.preventDefault();
      if (activeProvider === "eupago") validate(userPhone, handleValidation);
      
      setLoading(true);
      
      const response = await fetch( OrderResource.create(),
        {
          organization_id: object.organization.id 
        },
        { 
          payment_provider: activeProvider, 
          pricing_id: pricingId, 
          amount: requestedAmount,
          user_phone: userPhone,
          promo_code: promoCode
        },
      );
      
      if (activeProvider === "stripe") {
        const {client_secret, id, item_price, amount} = response;

        onStripeStateChange((previousValue) => ({...previousValue, clientSecret: client_secret}));
        onOrderChange((previousValue) => ({...previousValue, paymentIntentId: id, selectedProvider: 'stripe'}));
        setOrderPropertiesState((previousValue) => (
            {
              ...previousValue, 
              itemPrice: item_price,
              quantity: amount,
            }
          ));
        setLoading(false);
      } else {
        onOrderChange((previousValue) => ({...previousValue, paymentIntentId: response.id}));
        setProcessingEupago(response.id);
        setLoading(false);
      } 
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (selectedProvider === "stripe") createPaymentIntent();
  }, [selectedProvider])

  const showTabs = providers.length > 1 && !isPass;

  return (
    <>
      {showTabs && 
        <Box className="tabpanel-menu slim" sx={{ borderBottom: 1, borderColor: 'divider' }} >
          <Tabs value={providers.indexOf(selectedProvider)}>
            { providers.map( (provider, index) => (
                <Tab key={ index } label={ provider === "stripe" ? "Other Payment Methods" : "MBWay" } disabled={loading} onClick={() => handleProviderChange(provider)} />
              ))
            }
          </Tabs>
        </Box>
      }

      {!selectedProvider &&
        <LoadingComponent />
      }

      {selectedProvider === "stripe" &&
        <StripePaymentForm
          object={ object }
          orderPropertiesState={ orderPropertiesState }
          onStepChange={ onStepChange }
          onContentChange={ onContentChange }
          stripeState={ stripeState }
          onShowTickets={ onShowTickets }
          pricingId={ pricingId }
        />}

      {selectedProvider === "eupago" &&
        <EupagoPaymentForm
          object={ object } 
          orderPropertiesState={ orderPropertiesState }
          pricingId={ pricingId }
          userPhone={ userPhone }
          onUserPhoneChange={ onUserPhoneChange }
          requestedAmount={ requestedAmount }
          onSubmit={ handleEupagoFormSubmission }
          processingEupago={ processingEupago }
        />
      }
    </> 
  );
};

PaymentCollection.propTypes = {
  object: PropTypes.object,
  pricingId: PropTypes.number,
  requestedAmount: PropTypes.number,
  userPhone: PropTypes.string,
  selectedProvider: PropTypes.string,
  onStepChange: PropTypes.func,
  onContentChange: PropTypes.func,
  onOrderChange: PropTypes.func,
  onUserPhoneChange: PropTypes.func,
  onBack: PropTypes.func,
  stripeState: PropTypes.object,
  onStripeStateChange: PropTypes.func,
  orderState: PropTypes.object,
  isPass: PropTypes.bool,
  onShowTickets: PropTypes.func,
  promoCode: PropTypes.string,
};

export default PaymentCollection;
