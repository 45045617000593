import AuthToken    from 'utils/localStorage/AuthToken';

export default function downloadCSV (endpoint, params) {

    const url = new URL(`${process.env.REACT_APP_API_HOST}${endpoint}/download`);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

    const authToken   = AuthToken.read();

    fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'text/csv',
          'Content-Type': 'text/csv',
          'Authorization': `Bearer ${authToken}`,
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob();
      })
      .then(blob => {
        // Create a link element to trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${endpoint}-${new Date().toISOString().slice(0, 10)}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('There was a problem with the fetch operation:', error);
      });
    
    }
