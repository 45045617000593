import React, { useEffect } from "react";
import { useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";

import EventResource from 'resources/Profile/EventResource';

import EventsList from './EventsList/index';
import SingleCard from './SingleCard';

import { scrollToTop } from 'utils/scrollToTop';
import generateMetatags from "utils/generateMetatags";
import { useSocialMetatags } from "utils/socialMetatagsController";

import './styles.css';

const Events = ({organization_title, organization_logo}) => {

  const { organizationSlug, eventSlug } = useParams();
  const {setSocialMetatags} = useSocialMetatags();

  useEffect(() => {
    const page = eventSlug ? 'singleEvent' : 'events';
    const event = events?.[0];

    generateMetatags(page, setSocialMetatags, organization_title, organization_logo, event?.title, event?.short_description, event?.image_url);
  }, []);

  scrollToTop();

  const events = eventSlug
                  ? [useSuspense(EventResource.detail(), {organization_slug: organizationSlug, id: eventSlug})] //EXCLUSIVE TO SINGLE EVENT
                  : useSuspense(EventResource.list(), {organization_slug: organizationSlug, all: true});

  return (
    <>
      { eventSlug ?
        <SingleCard event={events[0]} organizationSlug={organizationSlug} />
      :
        <EventsList events={events} />
      }
    </>
  );
};

Events.propTypes = {
  organization_title: PropTypes.string,
  organization_logo: PropTypes.string,
};

export default Events;
