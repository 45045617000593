import { styled } from '@mui/material/styles';
import MUIAccordion from '@mui/material/Accordion';

const Accordion = styled(MUIAccordion)(() => {
    return {
      boxShadow: 'none',
      '.MuiAccordionSummary-root': {
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0',
      },
    };
  });
  

export default Accordion;