import React from 'react';
import PropTypes from 'prop-types';

import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const categoryOptions = [
    {
        value: 'global',
        label: 'Store & Tickets'
    },{
        value: 'store',
        label: 'Store only'
    },{
        value: 'specific',
        label: 'Specific Items'
    }
];

const CategorySelector = ({ category, onChange }) => {

  return (
    <FormControl>
      <InputLabel color="warning">Category</InputLabel>
      <Select
        label="Category"
        name="category"
        color="warning"
        size="small"
        fullWidth
        value={ category }
        onChange={ onChange }
      >
        {categoryOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
              {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CategorySelector.propTypes = {
  onChange: PropTypes.func,
  category: PropTypes.string
};

export default CategorySelector;