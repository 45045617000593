import React from 'react';
import PropTypes from 'prop-types';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';

const EndDate = ({ end_date, onChange, isDisabled}) => {

    const handleDateTimeChange = (value) => {
        onChange({target: {name: "end_date", value }});
    };

  return (  
    <>
        <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker label="Expiration date. Leave empty for no expiration."
            value={end_date}
            onChange={handleDateTimeChange}
            disablePast
            renderInput={(params) => <TextField {...params} color="warning" fullWidth size='small'/>}
            disabled={isDisabled} />
        </LocalizationProvider>
    </>
  );
};

EndDate.propTypes = {
    end_date: PropTypes.string,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool
};

export default EndDate;