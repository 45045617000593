import React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@mui/material/FormControlLabel";

import { Checkbox } from "@mui/material";

const Notifications = ({ notifications_enabled, onChange }) => {
  const label =
    "Select this option if you want your Newsletter e-mails to redirect members to open up the link, instead of having the entire content in the e-mail.";

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox 
            name="notifications_enabled"
            checked={notifications_enabled}
            onChange={onChange}
            size="small"
            />
        }
        label={label}
      />
    </>
  );
};

Notifications.propTypes = {
  notifications_enabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Notifications;
