import React from 'react';
import PropTypes from 'prop-types';

const EmbeddedVideo = ({ type, id }) => {
    
    const videoSrc =
        type === 'youtube'
        ? `https://www.youtube.com/embed/${id}`
        : `https://player.vimeo.com/video/${id}`;

    return (
        <div className='embed'>
             <div className={`embeded-video ${type == 'vimeo' ? 'vimeo' : ''}`}>
                <iframe
                    width="560" 
                    height="315"
                    src={videoSrc}
                    allowFullScreen
                ></iframe>
            </div>
        </div>
       
    );
};

EmbeddedVideo.propTypes = {
    type: PropTypes.string,
    id: PropTypes.string,
};

export default EmbeddedVideo;