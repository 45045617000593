import React, { useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useHistory } from 'react-router-dom';

import OrganizationResource from 'resources/organization/OrganizationResource';

import Form from './form';
import handleValidation from './handleValidation';
import createFormData from './createFormData';

import { useError } from 'utils/useErrorController';

const NewOrganization = () => {

  const history = useHistory();
  const { fetch } = useController();
  const { loading, setLoading, validate, handleError } = useError();

  const emptyOrganization = { title: '', category: '', external_link: '', slug: ''};
  const [organization, setOrganization] = useState(emptyOrganization);

  const submitOrganizationChanges = async (event) => {
    try {
      event.preventDefault();
      validate(organization, handleValidation);

      const formData = createFormData(organization);
      const { id } = await fetch(OrganizationResource.create(), {}, formData );

      // success!
      if (id){
        await fetch(OrganizationResource.list());
        await fetch(OrganizationResource.detail(), {id: id});
        setLoading(false);
        history.push(`/organizations/${id}/memberships`);
      }

    } catch (error) {
        handleError(error);
    }
  };

  return (
    <Form organization={organization}
          onOrganizationChange={ setOrganization }
          newOrganization
          onSubmit={ (event) => submitOrganizationChanges(event) }
          loading={loading}
    />
  )
};

export default NewOrganization;
