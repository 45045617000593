import React from 'react';

import UserName from 'utils/localStorage/UserName';

import { IconButton, Tooltip } from "@mui/material";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export const membersColumns = (handleClick, loading) => {

  const userName = UserName.read();
  
  return (
    [
      {
        field: 'fullName',
        name: 'Full name',
        sortable: true,
        width: 200,
        selector: (row) => `${row.name || ''}`
      },
      { 
        field: 'email',
        name: 'Email Address',
        sortable: true,
        width: 210,
        selector : (row) => row.email
      },
      {
        cell: (row) =>
        <Tooltip title='Delete' >
          <IconButton disabled={row.name === userName || loading} onClick={() => handleClick(row)} color='warning'>
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Tooltip>,
        ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        style: {
          cursor: 'pointer',
        }
      },
    ]
  )
};