import { courseType, rewardType } from "utils/constants";

// Form.js
const defaultReward = {
  title: '',
  description: '',
  template: false,
  public: true,
  image_file: null,
  image_filename: '',
  video_file: null,
  video_filename: null,
  end_date: null,
  active_state: true,
  supply_limit: null,
  pricings: null,
  currency: "EUR",
  media_type: "image",
  hide_details: false,
  points: null,
  metadata: [],
};

const emptyTier = { category: rewardType.PASS.value, ...defaultReward };
const emptyBadge = { category: rewardType.BEHAVIOUR.value, ...defaultReward };
const emptyCertificate = { category: rewardType.ACHIEVEMENT.value, ...defaultReward };
const emptyCollectible = { category: rewardType.COLLECTIBLE.value, ...defaultReward };
export const emptyProperty = { key: '', value: '' };

export const emptyReward = {
  [courseType.EVENT.value]: emptyBadge,
  [courseType.CHALLENGE.value]: emptyBadge,
  [courseType.WORKSHOP.value]: emptyBadge,
  [courseType.EVENT.value]: emptyBadge,
  [courseType.COURSE.value]: emptyCertificate,
  [courseType.MYSTERYBOX.value]: emptyBadge,
  [courseType.SELLABLECOLLECTIBLES.value]: emptyCollectible,
  [courseType.MEMBERSHIP.value]: emptyTier,
};

export const booleanAttrs = ['template', 'active_state', 'hide_details'];
