import React from "react";
import PropTypes from "prop-types";

import Footer from "../Footer";
import EupagoPending from "./EupagoPending";

import OrderSummary from "components/OrderSummary";

import { InputAdornment, TextField } from "@mui/material";

import mbwayIcon from "assets/images/mbwayIcon.png";

import { t } from "i18n/index";

const EupagoPaymentForm = ({
  object,
  orderPropertiesState,
  pricingId,
  userPhone,
  onUserPhoneChange,
  requestedAmount,
  onSubmit,
  processingEupago,
}) => {


  const { title, pricings, image_url, organization, application_fee, discount_price, net_discount, start_date } = object;
  const { organization_title, show_application_fee, currency } = organization;

  const price = pricings.find((pricing) => pricing.id === pricingId).price;

  return (
      <form onSubmit={(e) => onSubmit(e)} className="stripe-payment-form">
        { !processingEupago ?
          <>
            <TextField
              label={t("literal:phoneNumber")}
              name="userPhone"
              value={userPhone || ""}
              onChange={(event) => onUserPhoneChange(event.target.value)}
              fullWidth
              margin="normal"
              color="warning"
              autoFocus
              inputProps={{
                maxLength: 9,
                inputMode: "numeric",
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+ 351</InputAdornment>,
                endAdornment: <img src={mbwayIcon} className="payment-method-button-icon" />,
              }}
            ></TextField>

            <OrderSummary
              currency={currency}
              unit_price={price}
              discount_price={discount_price}
              net_discount={net_discount}
              quantity={requestedAmount}
              frequency={"lifetime"}
              imageUrl={image_url}
              organizationTitle={organization_title}
              title={title}
              applicationFee={application_fee}
              start_date={start_date}
              showApplicationFee={show_application_fee}
            />
          </> : <EupagoPending />
        }

        <Footer
          itemPrice={orderPropertiesState.itemPrice}
          object={object}
          caller="eupago"
          processingEupago={processingEupago}
        />
      </form>
  );
};

EupagoPaymentForm.propTypes = {
  object: PropTypes.object,
  showAlternativeProviderButton: PropTypes.bool,
  orderPropertiesState: PropTypes.object,
  onProviderChange: PropTypes.func,
  pricingId: PropTypes.number,
  userPhone: PropTypes.string,
  onUserPhoneChange: PropTypes.func,
  requestedAmount: PropTypes.number,
  onSubmit: PropTypes.func,
  processingEupago: PropTypes.bool
};

export default EupagoPaymentForm;
