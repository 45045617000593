import { validateEmail } from "utils/validations/validateEmail";

const validateTier = (tier) => {
    if (!tier) {
        throw 'Please select a tier';
    }
};

const validateEndDate = (sellable, end_date) => {
    if (sellable && !end_date) {
        throw 'Please select an end date';
    }
};

const handleSingleMemberValidation = (subscription) => {
    const {email, tier, sellable, end_date} = subscription

    validateEmail(email, "email");
    validateTier(tier); 
    validateEndDate(sellable, end_date)
};

export default handleSingleMemberValidation;