import React, { useEffect } from 'react';

import PostResource from 'resources/admin/PostResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import SearchableFilter from 'ui/SearchableFilter';

import { postsColumns } from 'utils/tables/columns/admin/postsColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';

const pageInfo = {
  label: "Admin",
  name: "Posts",
};

const AdminPosts = () => {

  const {
    objectState,
    fetchData,
    handlePageChange,
    handleFilterChange
  } = usePaginationAndFilteringController({
      fetchDataResource: PostResource,
      fetchDataParams: {},
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem='posts' >

      <TabPanels labels={[ "Posts" ]} >
            <div>
              <DataSelectionTable records={objectState.filteredObjects}
                                  columns={postsColumns}
                                  serverPaginationProps={{
                                    paginationServer: true,
                                    paginationTotalRows: objectState.totalCount,
                                    page: objectState.page,
                                    onChangePage: handlePageChange
                                  }}
                                  filter={
                                      <SearchableFilter
                                        label={"organization"}
                                        onChange={handleFilterChange}
                                      />
                                    }
              />
            </div>
      </TabPanels>

    </Layout>
)};

export default AdminPosts;
