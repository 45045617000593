const storage = localStorage;
const key     = 'cc.user_name';

// -----------------------------------------------------
// Util Definition
// -----------------------------------------------------

const UserToken = {
  read:   () => storage.getItem(key),
  write:  (name) => storage.setItem(key, name),
  remove: () => storage.removeItem(key)
};

// -----------------------------------------------------
// Exports
// -----------------------------------------------------

export default UserToken;