export  const createFormData = (promoCode) => {

    const { code, percent_off, products, category} = promoCode;

    const product_ids = products.map(item => item.id);    
    
    const formData = new FormData();
    const fields = [
        { key: "code", value: code },
        { key: "percent_off", value: percent_off },
        { key: "category", value: category },
        { key: "product_ids", value: JSON.stringify(product_ids) },
    ];

    fields.forEach(field => {
        formData.append(field.key, field.value);
    });

    return formData;
};
